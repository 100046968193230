import i18next from 'i18next';
import type { ActionContext, NoArgAction } from '@stimcar/libs-uikernel';
import { SubcontractorBackendRoutes } from '@stimcar/libs-base';
import { getHttpStatusCode, HttpErrorCodes } from '@stimcar/libs-kernel';
import type { LoginModalDialogState } from '../../lib/components/login/typings/store.js';
import type { SubcontractorStore, SubcontractorStoreState } from '../state/typings/store.js';
import { EMPTY_LOGIN_MODAL_DIALOG_STATE } from '../../lib/components/login/typings/store.js';
import { userAvailableAccessPaths } from '../../utils/generalUtils.js';
import { SUBCONTRACTOR_SELECT_KANBAN_FULL_PATH } from '../subcontractorConstants.js';

export const loginAction: NoArgAction<SubcontractorStore, LoginModalDialogState> = async ({
  actionDispatch,
  globalActionDispatch,
  getState,
  httpClient,
  navigate,
}: ActionContext<SubcontractorStore, LoginModalDialogState>): Promise<void> => {
  const noSubcontractorPermissions = 'No subcontractor permissions';
  try {
    const { login, password } = getState().formData;
    // Try to authenticate
    const user = await httpClient.authenticate(login, password);

    const { hasSubcontractorAccess } = userAvailableAccessPaths(user);
    if (!hasSubcontractorAccess) {
      await httpClient.logout();
      throw new Error(noSubcontractorPermissions);
    }

    // If successful
    globalActionDispatch.scopeProperty('session').applyPayload({
      user,
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    actionDispatch.reduce((state) => EMPTY_LOGIN_MODAL_DIALOG_STATE);

    await globalActionDispatch.exec(postLoginChecksAndInitializationAction);

    navigate(SUBCONTRACTOR_SELECT_KANBAN_FULL_PATH);
  } catch (e) {
    actionDispatch.setProperty('formSubmitted', false);
    if (e instanceof Error && getHttpStatusCode(e) === HttpErrorCodes.UNAUTHORIZED) {
      actionDispatch.setProperty(
        'formWarning',
        i18next.t('globals:login.warnings.connectionRefused')
      );
    } else if (e instanceof Error && e.message === noSubcontractorPermissions) {
      actionDispatch.setProperty('formWarning', i18next.t('globals:login.warnings.noAccessRights'));
    } else {
      actionDispatch.setProperty(
        'formWarning',
        i18next.t('globals:login.warnings.connectionError')
      );
    }
  }
};

export async function postLoginChecksAndInitializationAction(
  context: ActionContext<SubcontractorStore, SubcontractorStoreState>
): Promise<void> {
  const { httpClient, actionDispatch, getState, navigate } = context;

  const { user } = getState().session;
  if (!user) {
    return;
  }

  const { hasSubcontractorAccess, hasCorporateAccess } = userAvailableAccessPaths(user);

  // User can't access to subcontractor but can access to corporate, redirect
  if (!hasSubcontractorAccess && hasCorporateAccess) {
    navigate(SUBCONTRACTOR_SELECT_KANBAN_FULL_PATH);
    return;
  }
  // User can't access to subcontractors and corporate, logout
  if (!hasSubcontractorAccess && !hasCorporateAccess) {
    await httpClient.logout();
  }

  const { availableSites } = await httpClient.httpGetAsJson<{
    availableSites: { id: string; label: string }[];
  }>(SubcontractorBackendRoutes.GET_SUBCONTRACTOR_AVAILABLE_SITES);

  actionDispatch.reduce((initial) => {
    return {
      ...initial,
      // loadingStatus: undefined,
      session: {
        ...initial.session,
        availableSites,
        selectedSite: availableSites[0].id,
      },
    };
  });
}
