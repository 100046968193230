import type { TFunction } from 'i18next';
import type { AttributeType, Kanban } from '@stimcar/libs-base';
import {
  kanbanHelpers,
  shortDayMonthFullYearDateFormatOptions,
  shortDayMonthYearDateFormatOptions,
  shortDayMonthYearHourFormatOptions,
} from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';

export interface GeneralInformationsComputedData {
  readonly key: string;
  readonly label: string;
  readonly value: string;
}

export const computeGeneralInformationsDatas = (
  t: TFunction,
  kanban: Kanban,
  attributesToDisplay: Record<string, AttributeType>
): GeneralInformationsComputedData[] => {
  const internalData: GeneralInformationsComputedData[] = [];
  internalData.push({
    key: 'contractAndWorkflow',
    label: t('kanbanGeneralInformations.contractAndWorkflow'),
    value: `${kanban.contract.code}/${kanban.workflowId}`,
  });

  internalData.push({
    key: 'customer',
    label: t('kanbanGeneralInformations.customer'),
    value: kanbanHelpers.getCustomerLabel(kanban.customer, t),
  });

  internalData.push({
    key: 'keyLocation',
    label: t('kanbanGeneralInformations.keyLocation'),
    value: !kanban.logisticInfos.keyNumber
      ? t('kanbanGeneralInformations.noKeyLocation')
      : kanban.logisticInfos.keyNumber,
  });
  internalData.push({
    key: 'creationDate',
    label: t('kanbanGeneralInformations.creationDate'),
    value: new Date(kanban.creationDate).toLocaleDateString(
      'fr-FR',
      shortDayMonthYearHourFormatOptions
    ),
  });

  Object.keys(attributesToDisplay).forEach((k) => {
    const attribute = attributesToDisplay[k];
    if (isTruthy(attribute)) {
      internalData.push({
        key: `attribute.${k}`,
        label: k,
        value: String(attribute),
      });
    }
  });

  internalData.push({
    key: 'lastModified',
    label: t('kanbanGeneralInformations.lastModified'),
    value: new Date(kanban.timestamp).toLocaleDateString(
      'fr-FR',
      shortDayMonthYearHourFormatOptions
    ),
  });
  internalData.push({
    key: 'vin',
    label: t('kanbanGeneralInformations.vin'),
    value: kanban.infos.vin,
  });
  internalData.push({
    key: 'motor',
    label: t('kanbanGeneralInformations.motor'),
    value: kanban.infos.motor,
  });
  internalData.push({
    key: 'dateOfRegistration',
    label: t('kanbanGeneralInformations.dateOfRegistration'),
    value: isTruthy(kanban.infos.dateOfRegistration)
      ? new Date(kanban.infos.dateOfRegistration).toLocaleDateString(
          'fr-FR',
          shortDayMonthFullYearDateFormatOptions
        )
      : t('kanbanGeneralInformations.invalidDate'),
  });
  internalData.push({
    key: 'mileage',
    label: t('kanbanGeneralInformations.mileage'),
    value: kanban.infos.mileage.toFixed(0),
  });
  internalData.push({
    key: 'deliveryDate',
    label: t('kanbanGeneralInformations.dueDate'),
    value: kanban.dueDate
      ? new Date(kanban.dueDate).toLocaleDateString('fr-FR', shortDayMonthYearDateFormatOptions)
      : t('kanbanGeneralInformations.notFilled'),
  });
  internalData.push({
    key: 'refitEndDate',
    label: t('kanbanGeneralInformations.refitEndDate'),
    value: kanban.refitEndDate
      ? new Date(kanban.refitEndDate).toLocaleDateString(
          'fr-FR',
          shortDayMonthYearDateFormatOptions
        )
      : t('kanbanGeneralInformations.notFilled'),
  });
  return internalData;
};
