import type {
  Kanban,
  WithProgress,
  WorkshopPostCategory,
  WorkshopStandImplantation,
} from '@stimcar/libs-base';
import type { WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import type { FormWithValidationState, SortState } from '@stimcar/libs-uitoolkit';
import { EMPTY_FORM_WITH_VALIDATION_STATE } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../app/state/typings/store.js';
import type { AdditionalStepsForOperationCompletionModalState } from '../../../../app/utils/operationCompletion/typings/store.js';
import type { RawActionButtonDesc } from '../../misc/ActionButtonComponent.js';
import type { DispatchOperationsState } from '../../operationsDispatch/typings/store.js';
import {
  EMPTY_SELECT_SUBCONTRACTOR_FIRM_MODAL_STATE,
  EMPTY_UPLOAD_DOCUMENT_FOR_COMPLETION_MODAL_STATE,
} from '../../../../app/utils/operationCompletion/typings/store.js';
import { DISPATCH_OPERATIONS_EMPTY_STATE } from '../../operationsDispatch/typings/store.js';

export type KanbanDropHandlerSignature = (
  kanbanId: string,
  destinationPostCategory: string,
  destinationPostName: string | undefined,
  originCategory: string
) => void;

export type WorkshopActionProviderType = (
  actualKanbanId: string | undefined,
  allKanbans: readonly Kanban[],
  postCategoryId: string,
  postName?: string
) => readonly RawActionButtonDesc<Store, WorkshopImplantationViewState>[];

export type RedispatchMotiveType = 'kanbanMoving' | 'default';

export type SelectableKanbanSortValues =
  | 'mecaWorkload'
  | 'exteWorkload'
  | 'inteWorkload'
  | 'miscWorkload'
  | 'totalWorkload';

export type KanbanItem = WithProgress<Kanban> & {
  readonly mecaWorkload: number;
  readonly exteWorkload: number;
  readonly inteWorkload: number;
  readonly miscWorkload: number;
  readonly totalWorkload: number;
};

export type SelectKanbanWizardState = DispatchOperationsState & {
  readonly isActive: boolean;
  readonly wizardPage: number;
  readonly firstPageSelectedKanbanId: string | undefined;
  // This is the unqualified postId on which the user clicked the action
  readonly originPostForAction: string;
  // The kanbans that can be selected on the wizard first page
  readonly selectableKanbans: readonly KanbanItem[];
  readonly preDispatchOriginKanban: Kanban | undefined;
  readonly sortedCategories: readonly WorkshopPostCategory[];
  readonly users: readonly string[];
  readonly implantation: WorkshopStandImplantation | undefined;
  readonly standId: string;
  readonly expandedKanbanIds: readonly string[];
  readonly expendedOperationIds: readonly string[];
  readonly originCategoryForAction: string;
  readonly fromLoadingPost: boolean;
  readonly sort: SortState<SelectableKanbanSortValues>;
};

export const EMPTY_SELECT_KANBAN_WIZARD_STATE: SelectKanbanWizardState = {
  ...EMPTY_FORM_WITH_VALIDATION_STATE,
  isActive: false,
  wizardPage: 0,
  firstPageSelectedKanbanId: undefined,
  preDispatchOriginKanban: undefined,
  selectableKanbans: [],
  originPostForAction: '',
  users: [],
  standId: '',
  implantation: undefined,
  sortedCategories: [],
  expandedKanbanIds: [],
  expendedOperationIds: [],
  kanbanWithOperationsDispatched: undefined,
  selectedOperationsIds: [],
  originCategoryForAction: '',
  fromLoadingPost: false,
  sort: { by: undefined, direction: 'DOWN' },
};

export type PostEjectionModalState = {
  readonly isActive: boolean;
  readonly kanban: Kanban | undefined;
  readonly qualifiedPostId: string | undefined;
};

export const POST_EJECTION_MODAL_EMPTY_STATE: PostEjectionModalState = {
  isActive: false,
  kanban: undefined,
  qualifiedPostId: undefined,
};

export type MoveForwardModalState = {
  readonly isActive: boolean;
  readonly kanban: Kanban | undefined;
  readonly currentPostQualifiedId: string;
  readonly selectedNextPostId: string;
  readonly availableCategoriesAndPosts: readonly WorkshopPostCategory[];
};

export const MOVE_FORWARD_MODAL_EMPTY_STATE: MoveForwardModalState = {
  isActive: false,
  kanban: undefined,
  currentPostQualifiedId: '',
  selectedNextPostId: '',
  availableCategoriesAndPosts: [],
};

type OperationsRedispatchFormData = {
  readonly hasUnfinishedOperations: boolean;
};

export type OperationsRedispatchModalState = FormWithValidationState<OperationsRedispatchFormData> &
  DispatchOperationsState & {
    readonly isActive: boolean;
    readonly originKanban: Kanban | undefined;
    readonly destinationCategory: string;
    readonly implantation: WorkshopStandImplantation | undefined;
    readonly standId: string;
    readonly users: readonly string[];
    readonly redispatchMotive: RedispatchMotiveType;
    readonly destinationPostLabel?: string;
    readonly originPostCategory: string;
  };

export const OPERATIONS_REDISPATCH_MODAL_EMPTY_STATE: OperationsRedispatchModalState = {
  ...DISPATCH_OPERATIONS_EMPTY_STATE,
  isActive: false,
  formData: {
    hasUnfinishedOperations: true,
    warnings: {},
  },
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
  redispatchMotive: 'default',
  originKanban: undefined,
  destinationCategory: '',
  implantation: undefined,
  standId: '',
  users: [],
  originPostCategory: '',
};

export type SendKanbanToAnomalyValidationData = {
  readonly reason: string;
  readonly sendToAnomalyPost: boolean;
};

export const EMPTY_SEND_KANBAN_TO_ANOMALY_VALIDATION_DATA: WithFormValidationWarnings<SendKanbanToAnomalyValidationData> =
  {
    reason: '',
    sendToAnomalyPost: false,
    warnings: {},
  };

export type SendKanbanToAnomalyModalState =
  FormWithValidationState<SendKanbanToAnomalyValidationData> & {
    readonly active: boolean;
    readonly qualifiedPostId: string;
    readonly kanban: Kanban | undefined;
    readonly standId: string;
    readonly user: string;
  };

export const SEND_KANBAN_TO_ANOMALY_MODAL_STATE: SendKanbanToAnomalyModalState = {
  active: false,
  formSubmitted: false,
  formSubmitClickedOnce: false,
  formWarning: undefined,
  kanban: undefined,
  qualifiedPostId: '',
  user: '',
  standId: '',
  formData: {
    ...EMPTY_SEND_KANBAN_TO_ANOMALY_VALIDATION_DATA,
  },
};

export type EditAnomalyMessageModalState =
  FormWithValidationState<SendKanbanToAnomalyValidationData> & {
    readonly active: boolean;
    readonly kanban: Kanban | undefined;
    readonly user: string;
    readonly standId: string;
    readonly qualifiedPostId: string;
  };

export const EDIT_ANOMALY_MESSAGE_MODAL_STATE: EditAnomalyMessageModalState = {
  active: false,
  formSubmitted: false,
  formSubmitClickedOnce: false,
  formWarning: undefined,
  kanban: undefined,
  qualifiedPostId: '',
  standId: '',
  user: '',
  formData: {
    ...EMPTY_SEND_KANBAN_TO_ANOMALY_VALIDATION_DATA,
  },
};

export type WorkshopPostOperationsModalState = AdditionalStepsForOperationCompletionModalState & {
  readonly active: boolean;
  readonly kanbanId: string | undefined;
  readonly expendedOperationIds: readonly string[];
  readonly qualifiedPostId: string;
  readonly standId: string;
  readonly allowOperationsToggling: boolean;
};

export const EMPTY_WORKSHOP_OPERATIONS_MODAL_STATE: WorkshopPostOperationsModalState = {
  active: false,
  kanbanId: undefined,
  expendedOperationIds: [],
  qualifiedPostId: '',
  standId: '',
  allowOperationsToggling: false,
  selectSubcontractorFirmModal: EMPTY_SELECT_SUBCONTRACTOR_FIRM_MODAL_STATE,
  uploadDocumentForCompletionModal: EMPTY_UPLOAD_DOCUMENT_FOR_COMPLETION_MODAL_STATE,
};

export type ActionUnavailableWhenDisconnectedModalState = {
  readonly isActive: boolean;
};

export type WorkshopImplantationViewState = {
  readonly kanbans: readonly Kanban[];
  readonly kanbanInAnomalyIds: readonly string[];
  readonly selectKanbanWizardState: SelectKanbanWizardState;
  readonly postEjectionModal: PostEjectionModalState;
  readonly operationsRedispatchModal: OperationsRedispatchModalState;
  readonly sendKanbanToAnomalyModal: SendKanbanToAnomalyModalState;
  readonly editAnomalyMessageModal: EditAnomalyMessageModalState;
  readonly workshopPostOperationsModal: WorkshopPostOperationsModalState;
  readonly moveForwardModal: MoveForwardModalState;
  readonly standId: string;
  readonly implantationId: string;
  readonly actionUnavailableWhenDisconnectedModal: ActionUnavailableWhenDisconnectedModalState;
  readonly mirrorEffect?: boolean;
  readonly selectedKanbanForEstimate?: Kanban;
};

export const WORKSHOP_IMPLANTATION_VIEW_EMPTY_STATE: WorkshopImplantationViewState = {
  kanbans: [],
  kanbanInAnomalyIds: [],
  selectKanbanWizardState: EMPTY_SELECT_KANBAN_WIZARD_STATE,
  postEjectionModal: POST_EJECTION_MODAL_EMPTY_STATE,
  operationsRedispatchModal: OPERATIONS_REDISPATCH_MODAL_EMPTY_STATE,
  sendKanbanToAnomalyModal: SEND_KANBAN_TO_ANOMALY_MODAL_STATE,
  editAnomalyMessageModal: EDIT_ANOMALY_MESSAGE_MODAL_STATE,
  workshopPostOperationsModal: EMPTY_WORKSHOP_OPERATIONS_MODAL_STATE,
  moveForwardModal: MOVE_FORWARD_MODAL_EMPTY_STATE,
  standId: '',
  implantationId: '',
  actionUnavailableWhenDisconnectedModal: {
    isActive: false,
  },
};
