import type { JSX } from 'react';
import React, { useMemo } from 'react';
import type { Defect, PositionOnCar, StandardPicturesConfigurationKey } from '@stimcar/libs-kernel';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { getPicturesIdsForSelector, isTruthy } from '@stimcar/libs-kernel';
import {
  MarkerOnPictureComponentWithSelector,
  PictureSelector,
  useGetState,
} from '@stimcar/libs-uikernel';
import type { Store } from '../../../state/typings/store.js';
import { useComputeStandardPictureUrl } from '../../../utils/useComputeAttachmentUrl.js';
import {
  getDefectsToDisplay,
  getPictureIconPath,
  getPictureSrcFromPictureIdForSelector,
  isPositionCarOnPicture,
} from './defectUtils.js';

type PositionOnCarWithIndex = {
  readonly index: number;
  readonly positionOnCar: PositionOnCar;
};

interface DefectsOnCarDisplayProps extends AppProps<Store> {
  readonly $selectedPictureId: StoreStateSelector<Store, string>;
  readonly $kanbanId: StoreStateSelector<Store, string>;
  readonly $defects: StoreStateSelector<Store, readonly Defect[]>;
  readonly standardPicturesConfigurationKey: StandardPicturesConfigurationKey;
}

export function DefectsOnCarDisplay({
  $selectedPictureId,
  $kanbanId,
  $defects,
  standardPicturesConfigurationKey,
  $gs,
}: DefectsOnCarDisplayProps): JSX.Element {
  const kanbanId = useGetState($kanbanId);

  const selectedPictureId = useGetState($selectedPictureId);

  const defects = useGetState($defects);

  const defectsPositions: readonly PositionOnCarWithIndex[] = useMemo(() => {
    const sortedDefects = getDefectsToDisplay(defects, selectedPictureId, undefined, 'UP');
    return sortedDefects
      .map(({ positionOnCar, index }) => {
        if (isTruthy(positionOnCar)) {
          return { positionOnCar, index };
        }
        return null;
      })
      .filter(isTruthy);
  }, [defects, selectedPictureId]);

  const atLeastOneDefectPresentOnPictureCallback = (selectedPictureId: string) => {
    const hasDefect = defects.some(({ positionOnCar }) =>
      isPositionCarOnPicture(positionOnCar, selectedPictureId)
    );
    return hasDefect ? 'button-has-defect' : undefined;
  };

  const picturesIds = useMemo(
    () => getPicturesIdsForSelector(standardPicturesConfigurationKey),
    [standardPicturesConfigurationKey]
  );

  const computeStandardPictureUrl = useComputeStandardPictureUrl($gs);

  const getPictureSrcFromPictureId = (pictureId: string): string =>
    getPictureSrcFromPictureIdForSelector(kanbanId, pictureId, computeStandardPictureUrl);

  return (
    <PictureSelector
      $selectedPictureId={$selectedPictureId}
      picturesIds={picturesIds}
      getIconSrcFromPictureId={getPictureIconPath}
      getPictureSrcFromPictureId={getPictureSrcFromPictureId}
      getAdditionalLayerOnButtonClassnameCallback={atLeastOneDefectPresentOnPictureCallback}
    >
      <>
        {defectsPositions.map(({ positionOnCar, index }) => (
          <MarkerOnPictureComponentWithSelector
            key={index}
            positionOnPicture={positionOnCar}
            $selectedPictureId={$selectedPictureId}
          >
            <div className="bullet-marker defect-marker has-text-centered is-size-7 has-text-white">
              {index}
            </div>
          </MarkerOnPictureComponentWithSelector>
        ))}
      </>
    </PictureSelector>
  );
}
