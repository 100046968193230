import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Operation } from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import type {
  ActionContext,
  NoArgActionCallback,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type {
  AppProps,
  CheckFormConsistencyAction,
  CheckFormFieldContentActions,
} from '@stimcar/libs-uitoolkit';
import { applyPayload, computePayload } from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import {
  HORIZONTAL_FORM_SMALL_LABEL,
  InputFormField,
  ModalCardDialog,
  SelectFormField,
  useFormWithValidation,
  useMergedCheckFormConsistencyAction,
  useMergedCheckFormFieldContentActions,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../state/typings/store.js';
import { formatStringNumber, isFloatCheck } from '../../lib/utils/commonFormChecks.js';
import type { EditOperationModalState, OperationForm, OperationFormData } from './typings/store.js';

export function convertOperationToFormData(operation: Operation): OperationForm {
  const { workload, label, standId } = operation;
  return {
    label,
    standId,
    workload: String(workload),
    warnings: {},
  };
}

function computeOperationFormDataPayload(
  initialOperation: Operation | undefined,
  operation: OperationForm
): undefined | DeepPartial<OperationForm> {
  return initialOperation === undefined
    ? operation
    : computePayload(convertOperationToFormData(initialOperation), operation);
}

export function operationUpdateNotificationInEditDialogAction(
  { getState, actionDispatch }: ActionContext<Store, EditOperationModalState>,
  updated: Operation
): void {
  const { active, initialOperation, formData } = getState();
  if (active && initialOperation && initialOperation.id === updated.id) {
    actionDispatch.setProperty('initialOperation', updated);
    const updatedToFormData = convertOperationToFormData(updated);
    const payload = computeOperationFormDataPayload(initialOperation, formData);
    const newFormData = payload ? applyPayload(updatedToFormData, payload) : updatedToFormData;
    actionDispatch.setProperty('formData', newFormData);
  }
}

const checkFormConsistencyAction: CheckFormConsistencyAction<Store, EditOperationModalState> = ({
  formState,
  t,
}): string | undefined => {
  const { initialOperation, formData } = formState;
  if (initialOperation) {
    const payload = computeOperationFormDataPayload(initialOperation, formData);
    const updated = payload !== undefined && Reflect.ownKeys(payload).length !== 0;
    if (!updated) {
      return t('globals:warnings.noChange');
    }
  }
  return undefined;
};

const checkFieldContentActions: CheckFormFieldContentActions<Store, EditOperationModalState> = {
  workload: ({ value, t }) => {
    return isFloatCheck(formatStringNumber(value), t);
  },
};

const mandatoryFields: (keyof OperationFormData)[] = ['label', 'standId', 'workload'];

interface EditOperationModalProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, EditOperationModalState>;
  readonly submitValidDataAction: NoArgActionCallback<Store>;
  readonly additionalCheckFieldContentActions?: CheckFormFieldContentActions<
    Store,
    EditOperationModalState,
    []
  >;
  readonly additionalCheckFormConsistencyAction?: CheckFormConsistencyAction<
    Store,
    EditOperationModalState
  >;
}

export function EditOperationModal({
  $gs,
  $,
  submitValidDataAction,
  additionalCheckFieldContentActions,
  additionalCheckFormConsistencyAction,
}: EditOperationModalProps): JSX.Element {
  const [t] = useTranslation('details');

  const stands = useGetState($gs.$siteConfiguration.$stands);

  const initialOperation = useGetState($.$initialOperation);
  const originalStandId = initialOperation?.standId ?? '';
  const originalStand = stands.find((s) => s.id === originalStandId);

  const [onFormSubmit, , $formWithChangeTrigger] = useFormWithValidation<
    Store,
    EditOperationModalState
  >({
    $,
    mandatoryFields,
    checkFieldContentActions: useMergedCheckFormFieldContentActions(
      checkFieldContentActions,
      additionalCheckFieldContentActions
    ),
    checkFormConsistencyAction: useMergedCheckFormConsistencyAction(
      checkFormConsistencyAction,
      additionalCheckFormConsistencyAction
    ),
    submitValidDataAction,
    t,
  });

  const standIds = useMemo(
    (): string[] =>
      stands
        .filter((s) => {
          return s.id === originalStandId || !s.noReallocationTo;
        })
        .map(({ id }): string => id),
    [originalStandId, stands]
  );

  const isExpertisePackageDeal = useGetState($.$isExpertisePackageDeal);

  const isStandEditionForbidden =
    isExpertisePackageDeal || (originalStand?.noReallocationFrom ?? false);

  const formWarning = useGetState($.$formWarning);
  return (
    <ModalCardDialog
      title={t('tabs.operations.editModal.title')}
      $active={$.$active}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <InputFormField
        label={t('tabs.operations.editModal.form.label')}
        $={$formWithChangeTrigger.$label}
        horizontal={HORIZONTAL_FORM_SMALL_LABEL}
      />
      <SelectFormField
        label={t('tabs.operations.editModal.form.stand')}
        entries={standIds}
        $={$formWithChangeTrigger.$standId}
        disabled={isStandEditionForbidden}
        isEmptyValueAllowed
        isFullWidth
        horizontal={HORIZONTAL_FORM_SMALL_LABEL}
      />
      <InputFormField
        label={t('tabs.operations.editModal.form.workload')}
        $={$formWithChangeTrigger.$workload}
        horizontal={HORIZONTAL_FORM_SMALL_LABEL}
      />
    </ModalCardDialog>
  );
}
