import type {
  MainSidesStandardPictureKey,
  StandardPictureId,
  StandardPictureInfo,
  StandardPicturesConfiguration,
  StandardPicturesConfigurationKey,
} from '../typings/standardPictures.js';
import { isTruthy, isTruthyAndNotEmpty } from '../asserts.js';
import {
  CAR_INTERIOR_PICTURE_ID,
  MARKETPLACE_STANDARD_PICTURES_CONFIGURATION_KEY,
  TOYOTA_STANDARD_PICTURES_CONFIGURATION_KEY,
} from '../typings/standardPictures.js';
import {
  MARKETPLACE_MAIN_SIDES_STANDARD_PICTURE_INFOS,
  MARKETPLACE_STANDARD_PICTURES_CONFIGURATION,
  MARKETPLACE_TARGET_FOLDER_FOR_STANDARD_PICTURES,
} from './marketplace/marketplaceSpecificConstants.js';
import {
  TOYOTA_MAIN_SIDES_STANDARD_PICTURE_INFOS,
  TOYOTA_STANDARD_PICTURES_CONFIGURATION,
  TOYOTA_TARGET_FOLDER_FOR_STANDARD_PICTURES,
} from './toyota/toyotaSpecificConstants.js';

export function getStandardPicturesConfiguration(
  key: StandardPicturesConfigurationKey
): StandardPicturesConfiguration {
  switch (key) {
    case MARKETPLACE_STANDARD_PICTURES_CONFIGURATION_KEY:
      return MARKETPLACE_STANDARD_PICTURES_CONFIGURATION;
    case TOYOTA_STANDARD_PICTURES_CONFIGURATION_KEY:
      return TOYOTA_STANDARD_PICTURES_CONFIGURATION;
    default:
      return {};
  }
}

export function getOrderedStandardPictureIds(
  standardPictureInfos: readonly StandardPictureInfo[]
): readonly StandardPictureId[] {
  return [...standardPictureInfos]
    .sort(({ index: index1 }, { index: index2 }) => index1 - index2)
    .map(({ id }) => id);
}

export function getStandardPictureInfosForConfigurationKey(
  key: StandardPicturesConfigurationKey
): readonly StandardPictureInfo[] {
  const configuration = getStandardPicturesConfiguration(key);
  return Object.values(configuration).flat();
}

export function getOrderedStandardPictureIdsForConfigurationKey(
  key: StandardPicturesConfigurationKey
): readonly StandardPictureId[] {
  return getOrderedStandardPictureIds(getStandardPictureInfosForConfigurationKey(key));
}

export function getStandardPictureInfosForMainSides(
  key: StandardPicturesConfigurationKey
): Record<MainSidesStandardPictureKey, StandardPictureInfo> | null {
  switch (key) {
    case MARKETPLACE_STANDARD_PICTURES_CONFIGURATION_KEY:
      return MARKETPLACE_MAIN_SIDES_STANDARD_PICTURE_INFOS;
    case TOYOTA_STANDARD_PICTURES_CONFIGURATION_KEY:
      return TOYOTA_MAIN_SIDES_STANDARD_PICTURE_INFOS;
    default:
      return null;
  }
}

export function getMainSideFromPictureId(
  key: StandardPicturesConfigurationKey,
  standardPictureId: StandardPictureId
): MainSidesStandardPictureKey | null {
  const mainSidesPicturesInfo = getStandardPictureInfosForMainSides(key);
  if (isTruthy(mainSidesPicturesInfo)) {
    const foundEntry = Object.entries(mainSidesPicturesInfo).find(
      ([, pictureInfo]) => pictureInfo.id === standardPictureId
    );
    if (isTruthy(foundEntry)) {
      return foundEntry[0] as MainSidesStandardPictureKey;
    }
  }
  return null;
}
export function getPicturesIdsForSelector(
  key: StandardPicturesConfigurationKey
): readonly string[] {
  const standardPictureInfos = Object.values(getStandardPictureInfosForMainSides(key) ?? []).flat();
  const mainSidesStandardPicturesIds = getOrderedStandardPictureIds(standardPictureInfos);
  return [...mainSidesStandardPicturesIds, CAR_INTERIOR_PICTURE_ID];
}

export function getStandardPictureInfoFromId(
  key: StandardPicturesConfigurationKey,
  standardPictureId: StandardPictureId
): StandardPictureInfo | undefined {
  const standardPictureInfos = getStandardPictureInfosForConfigurationKey(key);
  return standardPictureInfos.find(({ id }) => id === standardPictureId);
}

export function getTargetFolderForStandardPictures(key: StandardPicturesConfigurationKey): string {
  switch (key) {
    case MARKETPLACE_STANDARD_PICTURES_CONFIGURATION_KEY:
      return MARKETPLACE_TARGET_FOLDER_FOR_STANDARD_PICTURES;
    case TOYOTA_STANDARD_PICTURES_CONFIGURATION_KEY:
      return TOYOTA_TARGET_FOLDER_FOR_STANDARD_PICTURES;
    default:
      return '';
  }
}

export function getPlaceholderPicturePath(
  key: StandardPicturesConfigurationKey,
  standardPictureId: StandardPictureId
) {
  const placeholderPath = getStandardPictureInfoFromId(key, standardPictureId)?.placeholderPath;
  return isTruthyAndNotEmpty(placeholderPath) ? `img/standard-pictures/${placeholderPath}` : '';
}

export function getMaskPicturePath(
  key: StandardPicturesConfigurationKey,
  standardPictureId: StandardPictureId
) {
  const maskPath = getStandardPictureInfoFromId(key, standardPictureId)?.maskPath;
  return isTruthyAndNotEmpty(maskPath) ? `img/standard-pictures/${maskPath}` : '';
}
