import { type PackageDealCategory } from '@stimcar/libs-base';

export const COMPANY_TYPE_UNKNOWN = '';
export const COMPANY_TYPE_SOLE_TRADER = 'Auto Entrepreneur';
export const COMPANY_TYPE_SAS = 'SAS';
export const COMPANY_TYPE_SARL = 'SARL';
export const COMPANY_TYPES = [
  COMPANY_TYPE_UNKNOWN,
  COMPANY_TYPE_SOLE_TRADER,
  COMPANY_TYPE_SAS,
  COMPANY_TYPE_SARL,
];
export type CompanyType = (typeof COMPANY_TYPES)[number];

export type MarketplacePackageDeal = {
  readonly category: PackageDealCategory;
  readonly carElement: string;
  readonly label: string;
  readonly VATRate: number;
  readonly priceWithVAT: number;
};

export type MarketplaceSaleOverview = {
  readonly salePriceWithVAT: number;
  readonly buyFixedFeePackageDeals: readonly MarketplacePackageDeal[];
  readonly saleFixedFeePackageDeals: readonly MarketplacePackageDeal[];
  readonly saleRefurbishFeePackageDeals: readonly MarketplacePackageDeal[];
};

export type MarketplaceBuyOverview = {
  readonly salePriceWithVAT: number;
  readonly deposit: number;
  readonly buyFixedFeePackageDeals: readonly MarketplacePackageDeal[];
  readonly buyRegistrationFeePackageDeals: readonly MarketplacePackageDeal[];
  readonly buyRefurbishFeePackageDeals: readonly MarketplacePackageDeal[];
};

export type MarketplaceWorkOverview = {
  readonly packageDeals: readonly MarketplacePackageDeal[];
};

export type AvailableStimer = {
  readonly id: string;
  readonly name: string;
};

export type StimerCommissionLine = {
  readonly label: string;
  readonly priceWithoutVAT: number;
};

export type MarketplaceStimerOverview = {
  readonly stimerName: string;
  readonly ignoreVAT: boolean;
  readonly commissions: readonly StimerCommissionLine[];
  readonly totalStimerWithoutVAT: number;
  readonly totalStimerVAT: number;
  readonly totalStimerWithVAT: number;
};

export type MarketplaceOverviewRequest = {
  readonly saleOverview?: MarketplaceSaleOverview;
  readonly buyOverview?: MarketplaceBuyOverview;
  readonly workOverview?: MarketplaceWorkOverview;
  readonly stimerOverview?: MarketplaceStimerOverview;
};
