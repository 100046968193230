import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';

export type InvoicingExportType = 'invoices' | 'revenue';

export type AdminExportMarketplaceInvoicingFormState = {
  readonly startDate: number;
  readonly endDate: number;
};

export type AdminExportMarketplaceInvoicingDialogState =
  FormWithValidationState<AdminExportMarketplaceInvoicingFormState> & {
    readonly active: boolean;
    readonly exportType: InvoicingExportType;
    readonly launchExport: boolean;
  };

export const EMPTY_ADMIN_EXPORT_MARKETPLACE_INVOICING_DIALOG_STATE: AdminExportMarketplaceInvoicingDialogState =
  {
    active: false,
    exportType: 'invoices',
    launchExport: false,
    formData: {
      startDate: NaN,
      endDate: NaN,
      warnings: {},
    },
    formSubmitClickedOnce: false,
    formSubmitted: false,
  };

export type AdminInvoicesReminderFormState = {
  readonly reminderDate: number;
  readonly sendEmails: boolean;
  readonly invoicesSummaryFile: File | null;
};

export type AdminInvoicesReminderDialogState =
  FormWithValidationState<AdminInvoicesReminderFormState> & {
    readonly active: boolean;
    readonly launchProcess: boolean;
  };

export const EMPTY_ADMIN_INVOICES_REMINDER_DIALOG_STATE: AdminInvoicesReminderDialogState = {
  active: false,
  launchProcess: false,
  formData: {
    reminderDate: NaN,
    sendEmails: false,
    invoicesSummaryFile: null,
    warnings: {},
  },
  formSubmitClickedOnce: false,
  formSubmitted: false,
};
