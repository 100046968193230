import type { JSX } from 'react';
import React from 'react';
import type { SortDirection } from '@stimcar/libs-kernel';
import type { ActionContext, AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';

export type SortState<T extends string> = {
  readonly by: T | undefined;
  readonly direction: SortDirection;
};

function SortIcon<SD extends AnyStoreDef, T extends string>({
  $sort,
  sortedField,
}: Pick<TableSortableHeaderComponentProps<SD, T>, '$sort' | 'sortedField'>) {
  const sortBy = useGetState($sort.$by);
  const sortDirection = useGetState($sort.$direction);
  if (sortedField === sortBy) {
    if (sortDirection === 'UP') {
      return <i className="fa fa-sort-up" />;
    }
    if (sortDirection === 'DOWN') {
      return <i className="fa fa-sort-down" />;
    }
  }
  return <i className="fa fa-sort" />;
}

export interface TableSortableHeaderComponentProps<SD extends AnyStoreDef, T extends string> {
  readonly content: string;
  readonly tooltip?: string;
  readonly centerLabel?: boolean;
  readonly sortedField: T;
  readonly $sort: StoreStateSelector<SD, SortState<T>>;
  readonly style?: React.CSSProperties;
  readonly className?: string;
  readonly isTruncable?: boolean;
}

export function TableSortableHeaderComponent<SD extends AnyStoreDef, T extends string>({
  content,
  tooltip,
  centerLabel = true,
  sortedField,
  $sort,
  style,
  className,
  isTruncable = false,
}: TableSortableHeaderComponentProps<SD, T>): JSX.Element {
  const changeFilterDirectionActionCallback = useActionCallback(
    function changeFilterDirectionAction({
      actionDispatch,
      getState,
    }: ActionContext<SD, SortState<T>>) {
      const { by, direction } = getState();
      if (sortedField === by) {
        if (direction === 'UP') {
          actionDispatch.setProperty('direction', 'DOWN');
        } else if (direction === 'DOWN') {
          actionDispatch.setProperty('by', undefined);
        } else {
          actionDispatch.setProperty('direction', 'UP');
        }
      } else {
        actionDispatch.setProperty('direction', 'UP');
        actionDispatch.setProperty('by', sortedField);
      }
    },
    [sortedField],
    $sort
  );

  const keyDownHandlerActionCallback = useActionCallback(
    async ({ actionDispatch }, e: React.KeyboardEvent<HTMLSpanElement>): Promise<void> => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        await actionDispatch.execCallback(changeFilterDirectionActionCallback);
      }
    },
    [changeFilterDirectionActionCallback],
    $sort
  );

  return (
    <th style={style} className={className}>
      <div
        style={
          isTruncable ? { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } : {}
        }
        className={centerLabel ? 'has-text-centered' : ''}
      >
        <span
          role="button"
          tabIndex={0}
          className="icon is-small m-r-xs"
          onClick={changeFilterDirectionActionCallback}
          onKeyDown={keyDownHandlerActionCallback}
          aria-label="sort"
        >
          <SortIcon $sort={$sort} sortedField={sortedField} />
        </span>
        <span title={tooltip || content}>{content}</span>
      </div>
    </th>
  );
}
