import type { SpecificFields } from '@stimcar/libs-kernel';
import type { CarElement, CarViewCategory, CoreFields } from '../../model/index.js';

function getCarElementIndexToDisplayWithPadding<CE extends CoreFields<CarElement>>(
  ce: CE | undefined
): string {
  const displayedIndex = (ce?.index ?? -1) + 1;
  let indexString = String(displayedIndex);
  while (indexString.length < 3) {
    indexString = `0${indexString}`;
  }
  return indexString;
}

function getCarElementCategory(ce: SpecificFields<CarElement> | undefined): CarViewCategory {
  return ce?.category ?? 'MISC';
}

export const carElementHelpers = {
  getCarElementIndexToDisplayWithPadding,
  getCarElementCategory,
};
