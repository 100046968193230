import type {
  StandardPictureInfo,
  StandardPicturesConfiguration,
  StandardPicturesConfigurationKey,
} from '../typings/standardPictures.js';
import { nonnull } from '../asserts.js';

export function getStandardPictureInfoById(
  picturesInfos: readonly StandardPictureInfo[],
  pictureId: string
): StandardPictureInfo {
  return nonnull(picturesInfos.find(({ id }) => id === `${pictureId}.webp`));
}

function toStandardPictureInfos(
  configurationKey: StandardPicturesConfigurationKey,
  pictureIds: readonly string[],
  startingIndex: number
): readonly StandardPictureInfo[] {
  return pictureIds.map((pictureId, index) => {
    return {
      id: `${pictureId}.webp`,
      index: index + startingIndex,
      placeholderPath: `${configurationKey}/placeholder/${pictureId}.webp`,
      maskPath: `${configurationKey}/mask/mask_${pictureId}.webp`,
    };
  });
}

export function toStandardPictureConfiguration(
  configurationKey: StandardPicturesConfigurationKey,
  picturesIdsByCategory: Record<string, readonly string[]>
): StandardPicturesConfiguration {
  return Object.entries(picturesIdsByCategory).reduce<StandardPicturesConfiguration>(
    (acc, [category, picturesIds]) => {
      const currentIndex = Object.values(acc).flat().length;
      const picturesInfos = toStandardPictureInfos(configurationKey, picturesIds, currentIndex);
      acc[category] = picturesInfos;
      return acc;
    },
    {}
  );
}
