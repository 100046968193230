import type { Deletable, Entity } from '@stimcar/libs-kernel';

export type Lang = 'fr' | 'en';
export type LabelDef = Record<Lang, string>;

export type TimedInterval = Entity & {
  readonly startDate: number;
  readonly endDate?: number | null;
};

export type AttributeType = string | number | boolean;

export type AttributesHolder = {
  readonly attributes: Record<string, AttributeType>;
};

export type BrowserBaseInfos = {
  readonly id: string;
  readonly label: string;
  readonly lastConnection: number;
  readonly lastUser: string;
};

export type Ordered = {
  readonly orderIndex: number;
};

export type AttachmentMetadata = {
  readonly lastModified: number;
  readonly etag: string;
  readonly size: number;
};

export type Attachment = Deletable &
  Entity & {
    readonly folder: string;
    readonly name: string;
  };

export type AttachmentDesc = AttachmentMetadata & Pick<Attachment, 'folder' | 'name'>;

export type WithAttachments = {
  readonly attachments?: readonly Attachment[] | null;
};

export type StorageCategories = 'kanban';

export type PreAllocationEntity = {
  packageDealCode: string;
  operationLabel: string;
  categoryId: string;
};

export type PreAllocationRequestResult = {
  preAllocation: readonly PreAllocationEntity[];
};

export const SPARE_PART_MANAGEMENT_TYPES = [
  'fullyManagedByStimcar', // DoRefDoSell
  'fullyManagedByCustomer', // NoRefNoSell
  'orderedByStimcarFromCustomersCatalog', // DoRefNoSell
] as const;

export type SparePartManagementType = (typeof SPARE_PART_MANAGEMENT_TYPES)[number];

export type AttachmentFolder = {
  readonly id: string;
  readonly isShareable: boolean;
  readonly label?: string;
  readonly shortLabel?: string;
};

export type Priceable = {
  readonly price: number;
};
