import i18next from 'i18next';
import type { RegisteredBrowserInfos } from '@stimcar/core-libs-repository';
import type { AuthenticatedUser } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { NavigateFunction } from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import {
  retrieveEntryPointInfo,
  Role,
  SUBCONTRACTOR_ENTRY_POINT,
  WORKSHOP_PASSWORD,
} from '@stimcar/libs-base';
import {
  getHttpStatusCode,
  HttpErrorCodes,
  isTruthy,
  isTruthyAndNotEmpty,
  nonnull,
} from '@stimcar/libs-kernel';
import type { LoginModalDialogState } from '../../../lib/components/login/typings/store.js';
import type { LightRegisteredAppStore } from '../../state/typings/store.js';
import { DEFAULT_AFTER_LOGIN_PATHS_FOR_ROLES } from '../../../app/coreConstants.js';
import { EMPTY_LOGIN_MODAL_DIALOG_STATE } from '../../../lib/components/login/typings/store.js';
import { userAvailableAccessPaths } from '../../../utils/generalUtils.js';

export const redirectAfterLoginOrRegister = (
  navigate: NavigateFunction,
  browserInfos: RegisteredBrowserInfos | undefined
): void => {
  if (isTruthyAndNotEmpty(browserInfos?.role)) {
    const defaultPath = nonnull(
      DEFAULT_AFTER_LOGIN_PATHS_FOR_ROLES[
        nonnull(browserInfos).role as keyof typeof DEFAULT_AFTER_LOGIN_PATHS_FOR_ROLES
      ]
    );
    navigate(defaultPath);
  }
};

export async function loginAction<SD extends LightRegisteredAppStore>({
  actionDispatch,
  globalActionDispatch,
  getState,
  httpClient,
  keyValueStorage,
  getGlobalState,
  navigate,
}: ActionContext<SD, LoginModalDialogState>) {
  const noCorePermissions = 'No core permissions';
  try {
    const { login, password } = getState().formData;
    const role = getGlobalState().session.infos?.role;
    const isWorkshopLogin = role === Role.WorkshopOperator;

    // Try to authenticate
    let user: AuthenticatedUser;
    if (isWorkshopLogin) {
      user = await httpClient.authenticate(login, WORKSHOP_PASSWORD);
    } else {
      user = await httpClient.authenticate(login, password);
    }
    const { hasCorporateAccess, hasSubcontractorAccess } = userAvailableAccessPaths(user);

    if (!hasCorporateAccess && hasSubcontractorAccess) {
      if (hasSubcontractorAccess) {
        // FIXME In the future, subcontractors shouldn't use the core URL (so that they
        // shouldn't pass in the current login action)
        const { protocol, host } = document.location;
        const { companyId } = retrieveEntryPointInfo(document.location.hostname);
        // Redirect to SST
        document.location.href = `${protocol}//${host.replace(/^[a-zA-Z0-9]+\./, `${nonnull(companyId)}-${SUBCONTRACTOR_ENTRY_POINT}.`)}`;
      } else {
        await httpClient.logout();
        throw new Error(noCorePermissions);
      }
    } else {
      // If successful
      globalActionDispatch.scopeProperty('session').setProperty('user', user);
      const knownUsers = keyValueStorage.getObjectItem<readonly string[]>(
        LocalStorageKeys.ALREADY_KNOWN_USERS_LIST
      );
      let knownUsersSet: Set<string>;
      if (isTruthy(knownUsers)) {
        knownUsersSet = new Set(knownUsers);
      } else {
        knownUsersSet = new Set();
      }
      knownUsersSet.add(login);
      keyValueStorage.setObjectItem(LocalStorageKeys.ALREADY_KNOWN_USERS_LIST, [...knownUsersSet]);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      actionDispatch.reduce((state) => EMPTY_LOGIN_MODAL_DIALOG_STATE);

      const { infos } = getGlobalState().session;
      redirectAfterLoginOrRegister(navigate, infos);
    }
  } catch (e) {
    actionDispatch.setProperty('formSubmitted', false);
    if (e instanceof Error && getHttpStatusCode(e) === HttpErrorCodes.UNAUTHORIZED) {
      actionDispatch.setProperty(
        'formWarning',
        i18next.t('globals:login.warnings.connectionRefused')
      );
    } else if (e instanceof Error && e.message === noCorePermissions) {
      actionDispatch.setProperty('formWarning', i18next.t('globals:login.warnings.noAccessRights'));
    } else {
      actionDispatch.setProperty(
        'formWarning',
        i18next.t('globals:login.warnings.connectionError')
      );
    }
  }
}
