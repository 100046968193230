import type {
  MainSidesStandardPictureKey,
  StandardPictureInfo,
  StandardPicturesConfiguration,
} from '../../typings/standardPictures.js';
import { TOYOTA_STANDARD_PICTURES_CONFIGURATION_KEY } from '../../typings/standardPictures.js';
import {
  getStandardPictureInfoById,
  toStandardPictureConfiguration,
} from '../standardPicturesConfigurationHelpers.js';

const picturesIdsByCategory: Record<string, readonly string[]> = {
  external: [
    'frontLeftThreeQuarters',
    'front',
    'frontRightThreeQuarters',
    'rightSide',
    'rearRightThreeQuarters',
    'rear',
    'rearLeftThreeQuarters',
    'leftSide',
    'wheelRim',
    'trunkOpen',
  ],
  internal: ['passengerFrontSeat', 'passengerRearSeat', 'driverFrontSeat', 'driverRearSeat'],
  dashboard: ['interior', 'steeringWheel', 'steeringWheelZoom', 'dashboard', 'controlScreen'],
};

export const TOYOTA_STANDARD_PICTURES_CONFIGURATION: StandardPicturesConfiguration =
  toStandardPictureConfiguration(TOYOTA_STANDARD_PICTURES_CONFIGURATION_KEY, picturesIdsByCategory);

export const TOYOTA_MAIN_SIDES_STANDARD_PICTURE_INFOS: Record<
  MainSidesStandardPictureKey,
  StandardPictureInfo
> = {
  LEFT_SIDE: getStandardPictureInfoById(
    TOYOTA_STANDARD_PICTURES_CONFIGURATION.external,
    'leftSide'
  ),
  FRONT: getStandardPictureInfoById(TOYOTA_STANDARD_PICTURES_CONFIGURATION.external, 'front'),
  RIGHT_SIDE: getStandardPictureInfoById(
    TOYOTA_STANDARD_PICTURES_CONFIGURATION.external,
    'rightSide'
  ),
  REAR: getStandardPictureInfoById(TOYOTA_STANDARD_PICTURES_CONFIGURATION.external, 'rear'),
};

export const TOYOTA_TARGET_FOLDER_FOR_STANDARD_PICTURES = 'photosCommerciales';
