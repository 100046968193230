import type { TFunction } from 'i18next';
import type { KanbanInfos, RepositoryEntityStatus, SortDirection } from '@stimcar/libs-kernel';
import {
  compareBooleans,
  compareNumbers,
  compareStrings,
  createSortByBooleanField,
  createSortByNumericField,
  createSortByStringField,
  isTruthy,
  toStr,
} from '@stimcar/libs-kernel';
import type {
  BaseMemo,
  BasePackageDeal,
  CarElement,
  CoreFields,
  Kanban,
  KanbanLogisticInfos,
  Operation,
  PackageDeal,
  PackageDealDesc,
  PackageDealVariable,
  StandHandlingStatus,
} from '../../model/index.js';
import { STAND_HANDLING_STATUS_VALUES } from '../../model/index.js';
import type { WithProgress } from './workflowProgressHelpers.js';
import { handlingHelpers } from './handlingHelpers.js';
import { kanbanHelpers } from './kanbanHelpers.js';
import { packageDealHelpers } from './packageDealHelpers.js';

function createSortPackageDealsByWorkflowDefaultFunction(
  sortDirection: SortDirection,
  workflowDefaultPackageDealCodes: string[]
): (packDeal1: PackageDeal, packDeal2: PackageDeal) => number {
  return (packDeal1, packDeal2): number => {
    const code1 = workflowDefaultPackageDealCodes.find((code) => code === packDeal1.code);
    const code2 = workflowDefaultPackageDealCodes.find((code) => code === packDeal2.code);
    return compareStrings(code1, code2, sortDirection);
  };
}

function comparePackageDeals(
  packDeal1: PackageDeal | undefined,
  packDeal2: PackageDeal | undefined,
  sortDirection: SortDirection
): number {
  const carElement1 = packDeal1?.carElement?.label;
  const carElement2 = packDeal2?.carElement?.label;
  return compareStrings(carElement1, carElement2, sortDirection);
}

function createSortPackageDealsByCarElementLabelFunction(
  sortDirection: SortDirection
): (packDeal1: PackageDeal, packDeal2: PackageDeal) => number {
  return (packDeal1, packDeal2): number => comparePackageDeals(packDeal1, packDeal2, sortDirection);
}

function createSortOperationsByPackageDealsCarElementFunction(
  sortDirection: SortDirection,
  operationIdToPackageDealMap: Map<string, PackageDeal>
): (operation1: Operation, operation2: Operation) => number {
  return (operation1, operation2): number =>
    comparePackageDeals(
      operationIdToPackageDealMap.get(operation1.id),
      operationIdToPackageDealMap.get(operation2.id),
      sortDirection
    );
}

function createSortOperationsByPackageDealsCodeFunction(
  sortDirection: SortDirection,
  operationIdToPackageDealMap: Map<string, PackageDeal>
): (operation1: Operation, operation2: Operation) => number {
  return (operation1, operation2): number => {
    const pd1 = operationIdToPackageDealMap.get(operation1.id)?.code;
    const pd2 = operationIdToPackageDealMap.get(operation2.id)?.code;
    return compareStrings(pd1, pd2, sortDirection);
  };
}

function createSortCarElementsByElementCountFunction(
  sortDirection: SortDirection
): (c1: CarElement, c2: CarElement) => number {
  return (c1, c2): number => {
    return compareNumbers(c1.shapes.length, c2.shapes.length, sortDirection);
  };
}

function createSortPackageDealDescsByCarElementCountFunction(
  sortDirection: SortDirection
): (pdd1: PackageDealDesc, pdd2: PackageDealDesc) => number {
  return (pdd1, pdd2): number => {
    return compareNumbers(pdd1.carElementIds.length, pdd2.carElementIds.length, sortDirection);
  };
}

function createSortKanbansByStringFieldFunction(
  sortDirection: SortDirection,
  key: keyof KanbanInfos
): (k1: Kanban, k2: Kanban) => number {
  return (k1, k2): number => {
    return compareStrings(toStr(k1.infos[key]), toStr(k2.infos[key]), sortDirection);
  };
}

function getDisplayedPosition(t: TFunction, k: Kanban): string {
  let location: string | undefined;
  const currentLocation = kanbanHelpers.getCurrentLocation(k);
  if (currentLocation) {
    location = currentLocation;
  }

  if (!isTruthy(location)) {
    return t('globals:position.noLocation');
  }
  if (location === 'workshop') {
    return t('globals:position.workshopLocation');
  }
  return location;
}

interface DurationInterface {
  readonly startDate: number;
  readonly endDate?: number;
}

function createSortHandlingByDurationFunction<T extends DurationInterface>(
  sortDirection: SortDirection
): (handle1: T, handle2: T) => number {
  return (handle1, handle2): number => {
    const duration1 = handle1?.endDate ?? Date.now() - handle1.startDate;
    const duration2 = handle2?.endDate ?? Date.now() - handle2.startDate;
    return compareNumbers(duration1, duration2, sortDirection);
  };
}

function createSortKanbansByAttributeFunction(
  attributeKey: string,
  sortDirection: SortDirection
): (k1: Kanban, k2: Kanban) => number {
  return (k1, k2): number => {
    const attributeValue1 = k1.attributes[attributeKey] ?? '';
    const attributeValue2 = k2.attributes[attributeKey] ?? '';
    return compareStrings(String(attributeValue1), String(attributeValue2), sortDirection);
  };
}

function createSortKanbansByPositionFunction(
  sortDirection: SortDirection,
  t: TFunction
): (k1: Kanban, k2: Kanban) => number {
  return (k1, k2): number => {
    const location1 = getDisplayedPosition(t, k1);
    const location2 = getDisplayedPosition(t, k2);
    return compareStrings(location1, location2, sortDirection);
  };
}

function createSortKanbansByWorkloadFunction(
  sortDirection: SortDirection
): (k1: Kanban, k2: Kanban) => number {
  return (k1, k2): number => {
    const k1Workload = packageDealHelpers.getTotalWorkload(k1.packageDeals);
    const k2Workload = packageDealHelpers.getTotalWorkload(k2.packageDeals);
    return compareNumbers(k1Workload, k2Workload, sortDirection);
  };
}

function createSortPackageDealByCarElementIndexThenLabelFunction(
  sortDirection: SortDirection
): (pd1: BasePackageDeal, pd2: BasePackageDeal) => number {
  return (pd1, pd2): number => {
    const index1 = pd1.carElement?.index ?? -1;
    const index2 = pd2.carElement?.index ?? -1;
    const result = compareNumbers(index1, index2, sortDirection);
    if (result === 0) {
      return packageDealHelpers
        .getPackageDealDisplayedLabel(pd1)
        .localeCompare(packageDealHelpers.getPackageDealDisplayedLabel(pd2));
    }
    return result;
  };
}

function createSortPackageDealsByCarElementCategoryFunction(
  sortDirection: SortDirection,
  t: TFunction
): (pd1: BasePackageDeal, pd2: BasePackageDeal) => number {
  return (pd1, pd2): number => {
    const category1 = t(`globals:operationCategories.${pd1.carElement?.category ?? 'MISC'}`);
    const category2 = t(`globals:operationCategories.${pd2.carElement?.category ?? 'MISC'}`);
    return compareStrings(category1, category2, sortDirection);
  };
}

function createSortPackageDealsByPriceFunction(
  sortDirection: SortDirection
): (pd1: PackageDeal, pd2: PackageDeal) => number {
  return (pd1, pd2): number => {
    const price1 = pd1.price;
    const price2 = pd2.price;
    return compareNumbers(price1, price2, sortDirection);
  };
}

function createSortKanbansByLogisticInfosStringFieldFunction(
  sortDirection: SortDirection,
  key: keyof KanbanLogisticInfos
): (k1: Kanban, k2: Kanban) => number {
  return (k1, k2): number =>
    compareStrings(toStr(k1.logisticInfos[key]), toStr(k2.logisticInfos[key]), sortDirection);
}

function categoryComparatorForMemo<T extends BaseMemo<unknown>>(m1: T, m2: T): number {
  if (!m1) {
    return 1;
  }
  if (!m2) {
    return -1;
  }
  return m1.category.localeCompare(m2.category);
}

/**
 * Compare 2 status of stand handlings
 * The comparison uses the order in STAND_HANDLING_STATUS_VALUES
 * @param s1 First status
 * @param s2 Second status
 * @returns -1 if s1 < s2, 1 if s1 > s2, 0 if s1 == s2
 */
function compareStandHandlingStatus(
  s1: StandHandlingStatus,
  s2: StandHandlingStatus,
  sortDirection: SortDirection = 'UP'
): number {
  // Get index for first status
  const i1 = STAND_HANDLING_STATUS_VALUES.indexOf(s1);
  const i2 = STAND_HANDLING_STATUS_VALUES.indexOf(s2);

  let result = -1;
  if (i1 > i2) {
    result = 1;
  }
  if (i1 === i2) {
    result = 0;
  }
  return sortDirection === 'UP' ? -result : result;
}

function createSortKanbansBySelectedStandsHandleStatusFunction<K extends CoreFields<Kanban>>(
  sortedSelectedStandIds: readonly string[],
  sortDirection: SortDirection
): (k1: WithProgress<K>, k2: WithProgress<K>) => number {
  return (k1, k2): number => {
    const statusInfo1 = handlingHelpers.computeKanbanHandlingStatusInfo(k1, sortedSelectedStandIds);
    const standHandlingStatus1 = handlingHelpers.getStatusFromKanbanHandlingStatusInfo(statusInfo1);
    const statusInfo2 = handlingHelpers.computeKanbanHandlingStatusInfo(k2, sortedSelectedStandIds);
    const standHandlingStatus2 = handlingHelpers.getStatusFromKanbanHandlingStatusInfo(statusInfo2);
    return compareStandHandlingStatus(standHandlingStatus1, standHandlingStatus2, sortDirection);
  };
}

function createSortKanbansByDueDateFunction<K extends CoreFields<Kanban>>(
  dueDateThreshold: number | undefined,
  sortDirection: SortDirection
): (k1: WithProgress<K>, k2: WithProgress<K>) => number {
  return (k1, k2): number => {
    const currentDate = Date.now();
    const getRelevantDueDate = (kanban: WithProgress<K>): number | null => {
      const mostRelevantDate = kanbanHelpers.getMostRestrictiveDueDate(
        kanban.dueDate,
        kanban.refitEndDate
      );
      if (!isTruthy(dueDateThreshold)) {
        return mostRelevantDate;
      }
      if (
        isTruthy(mostRelevantDate) &&
        kanbanHelpers.getDifferenceInDay(mostRelevantDate, currentDate) <= dueDateThreshold
      ) {
        return mostRelevantDate;
      }
      return Number.POSITIVE_INFINITY;
    };

    const relevantDueDate1 = getRelevantDueDate(k1);
    const relevantDueDate2 = getRelevantDueDate(k2);

    return compareNumbers(relevantDueDate1, relevantDueDate2, sortDirection);
  };
}

/**
 * Compare 2 kanbans by the stand handling status, the dueDate/refitEndDate, and then by age
 * @param kanban1 First kanban
 * @param kanban2 Second kanban
 * @param sortedSelectedStandIds Sorted stand Ids
 * @param dueDateThreshold Threshold to take dueDate/refitEndDate into account or not
 * @returns -1 if kanban1 is before kanban2, +1 if kanban1 is after kanban2, else 0
 */
function compareKanbansByStandHandlingThenDueDateThenAge<K extends CoreFields<Kanban>>(
  kanban1: WithProgress<K>,
  kanban2: WithProgress<K>,
  sortedSelectedStandIds: readonly string[],
  dueDateThreshold: number | undefined
): number {
  let comparison = 0;
  const standStatusSortDirection = 'DOWN';
  const dueDateSortDirection = 'UP';
  const creationDateSortDirection = 'UP';

  // First, compare the stand handling status
  const sortByStandStatus = createSortKanbansBySelectedStandsHandleStatusFunction(
    sortedSelectedStandIds,
    standStatusSortDirection
  );
  comparison = sortByStandStatus(kanban1, kanban2);
  // Continue the comparison only when status are equal
  if (comparison !== 0) {
    return comparison;
  }

  // Then, compare by dueDate or refitEndDate
  const dueDateSortFunction = createSortKanbansByDueDateFunction(
    dueDateThreshold,
    dueDateSortDirection
  );
  comparison = dueDateSortFunction(kanban1, kanban2);
  // Continue the comparison only when status are equal
  if (comparison !== 0) {
    return comparison;
  }

  // Finally, compare by age
  const creationDateSortFunction = createSortByNumericField(
    creationDateSortDirection,
    'creationDate'
  );
  comparison = creationDateSortFunction(kanban1, kanban2);

  return comparison;
}

function sortKanbansByStandHandlingThenByDueDateThenByAge<K extends CoreFields<Kanban>>(
  kanbans: readonly WithProgress<K>[],
  sortedSelectedStandIds: readonly string[],
  dueDateThreshold: number | undefined,
  reverseSort?: boolean
): WithProgress<K>[] {
  return kanbans.slice().sort((kanban1: WithProgress<K>, kanban2: WithProgress<K>): number => {
    const comparison = compareKanbansByStandHandlingThenDueDateThenAge(
      kanban1,
      kanban2,
      sortedSelectedStandIds,
      dueDateThreshold
    );
    return reverseSort ? -comparison : comparison;
  });
}

export type ObjectComparator<T extends object> = (t1: T, t2: T) => number;

function orderlyApplySortings<T extends object>(
  objectsToBeSorted: readonly T[],
  comparators: readonly ObjectComparator<T>[]
): readonly T[] {
  let sortedObjects: readonly T[] = objectsToBeSorted;
  comparators.forEach((comparator) => {
    sortedObjects = sortedObjects.slice().sort(comparator);
  });
  return sortedObjects;
}

const statusOrder = ['closed', 'archived', 'open'];

export function repositoryEntityStatusByOpennessComparator(
  s1: RepositoryEntityStatus,
  s2: RepositoryEntityStatus
): number {
  // Closed is the "smallest" status, open is the "greatest" status
  const idx1 = statusOrder.indexOf(s1);
  const idx2 = statusOrder.indexOf(s2);

  return idx1 - idx2;
}

function createSortPackageDealVariablesByIndexThenName(
  pkgDealVariable1: PackageDealVariable,
  pkgDealVariable2: PackageDealVariable,
  pkgDealVariableName1: string,
  pkgDealVariableName2: string
): number {
  const index1 = pkgDealVariable1.index ?? Number.MAX_VALUE;
  const index2 = pkgDealVariable2.index ?? Number.MAX_VALUE;
  const result = compareNumbers(index1, index2, 'DOWN');
  if (result === 0) {
    return compareStrings(pkgDealVariableName1, pkgDealVariableName2, 'UP');
  }
  return result;
}

export const sortingHelpers = {
  compareNumbers,
  compareBooleans,
  compareStrings,
  createSortByNumericField,
  createSortByStringField,
  createSortByBooleanField,
  createSortKanbansByStringFieldFunction,
  createSortPackageDealsByWorkflowDefaultFunction,
  createSortPackageDealsByCarElementLabelFunction,
  createSortOperationsByPackageDealsCarElementFunction,
  createSortOperationsByPackageDealsCodeFunction,
  createSortCarElementsByElementCountFunction,
  createSortPackageDealDescsByCarElementCountFunction,
  createSortHandlingByDurationFunction,
  createSortKanbansByAttributeFunction,
  createSortKanbansByPositionFunction,
  createSortKanbansByWorkloadFunction,
  createSortPackageDealByCarElementIndexThenLabelFunction,
  createSortPackageDealsByCarElementCategoryFunction,
  createSortKanbansByLogisticInfosStringFieldFunction,
  createSortPackageDealsByPriceFunction,
  createSortPackageDealVariablesByIndexThenName,
  categoryComparatorForMemo,
  compareKanbansByStandHandlingThenDueDateThenAge,
  sortKanbansByStandHandlingThenByDueDateThenByAge,
  orderlyApplySortings,
};
