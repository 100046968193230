import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { type Kanban, type RepositoryEntityPayload } from '@stimcar/libs-base';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { EMPTY_FORM_WITH_VALIDATION_STATE, ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { PaymentDeletionModalState } from '../typings/store.js';

function computeDeletionPayload(
  kanbanId: string,
  paymentId: string
): RepositoryEntityPayload<Kanban> {
  return {
    entityId: kanbanId,
    payload: {
      marketplaceInfos: {
        paymentHistory: [
          {
            id: paymentId,
            deleted: true,
          },
        ],
      },
    },
  };
}

export function openPaymentDeleteModalAction(
  { actionDispatch }: ActionContext<Store, PaymentDeletionModalState>,
  paymentId: string
): void {
  actionDispatch.setValue({
    ...EMPTY_FORM_WITH_VALIDATION_STATE,
    isActive: true,
    paymentId,
  });
}

type PaymentDeleteModalProps = {
  readonly $: StoreStateSelector<Store, PaymentDeletionModalState>;
  readonly kanbanId: string;
};

export function PaymentDeleteModal({ $, kanbanId }: PaymentDeleteModalProps): JSX.Element {
  const [t] = useTranslation('details');
  const paymentId = useGetState($.$paymentId);

  const paymentDeleteSubmitActionCallback = useActionCallback(
    async ({ actionDispatch, kanbanRepository }) => {
      const payload = computeDeletionPayload(kanbanId, paymentId);
      await kanbanRepository.updateEntityFromPayload(payload);
      actionDispatch.setProperty('isActive', false);
    },
    [kanbanId, paymentId],
    $
  );

  return (
    <ModalCardDialog
      title={t('tabs.marketplace.overview.paymentHistory.modal.deleteTitle')}
      $active={$.$isActive}
      onOkClicked={paymentDeleteSubmitActionCallback}
    >
      <></>
    </ModalCardDialog>
  );
}
