import type { TFunction } from 'i18next';
import type {
  KanbanInfos,
  MarketplaceMandate,
  MarketplaceStatus,
  SpecificFields,
} from '@stimcar/libs-kernel';
import {
  compareStrings,
  isTruthy,
  isTruthyAndNotEmpty,
  Logger,
  MKTP_MANDATE_BUY,
  MKTP_MANDATE_SALE,
  MKTP_MANDATE_UNKNOWN,
  MKTP_OFFLINE_STATUS,
  MKTP_ONLINE_STATUS,
  MKTP_SOLD_STATUS,
  nonnull,
} from '@stimcar/libs-kernel';
import type {
  BaseKanban,
  CarElement,
  CoreFields,
  Decorator,
  DecoratorDefinition,
  DefaultKanbanMessage,
  GetStringValueFromKanbanFunction,
  Kanban,
  KanbanColorationCharter,
  KanbanCustomer,
  KanbanHandling,
  KanbanLocationElement,
  KanbanOrigin,
  KanbanSummary,
  KanbanWorkInterval,
  KanbanWorkIntervalType,
  LabelDef,
  Lang,
  MarketplacePurchaseOrder,
  Operation,
  OperationRequestMessage,
  OperationType,
  PackageDeal,
  PackageDealDesc,
  PackageDealStatus,
  PriceableKanban,
  PriceablePackageDeal,
  PriceableSparePart,
  PurchaseOrder,
  SharedKanban,
  SiteConfiguration,
  SparePart,
  SparePartManagementType,
  ValuableFromKanbanVariable,
  Workflow,
} from '../../model/index.js';
import type { AuthenticatedUser } from '../httpclient/index.js';
import type { Sequence } from '../sequence.js';
import {
  ADMIN_PACKAGE_DEAL_CODE,
  ADMIN_STAND_ID,
  DELIVERY_STAND_ID,
  EMPTY_OPERATION,
  EMPTY_PACKAGE_DEAL,
  KANBAN_AUTOMATED_SOURCES,
  KanbanPriorityLevel,
  MARKETPLACE_BUY_PURCHASE_ORDER,
  MARKETPLACE_SALE_PURCHASE_ORDER,
  OPERATION_ATTRIBUTES,
  SUBCONTRACTOR_REQUEST_MESSAGE_ICON_ID,
} from '../../model/index.js';
import { nonDeleted } from '../misc.js';
import { globalHelpers } from './globalHelpers.js';
import { operationHelpers } from './operationHelpers.js';
import { packageDealHelpers } from './packageDealHelpers.js';
import { transverseHelpers } from './transverseHelpers.js';
import { workflowHelpers } from './workflowHelpers.js';
import { workflowProgressHelpers } from './workflowProgressHelpers.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

const MARKETPLACE_MIN_VALID_PRICE = 1_000;

/**
 * Sorting function used to sort location elements. After applying this function, most recent location
 * will be first in the array, oldest will be last
 */
function compareLocationHistory(a: KanbanLocationElement, b: KanbanLocationElement): number {
  if (a.date < b.date) {
    return 1;
  }
  if (a.date > b.date) {
    return -1;
  }
  return 0;
}

function createIssueComment(
  id: string,
  username: string,
  elementId: string,
  content: string
): OperationRequestMessage {
  return {
    type: 'request',
    status: 'pending',
    id,
    content,
    username,
    timestamp: Date.now(),
    elementType: 'operation',
    elementId,
  };
}

function createKanbanComment(id: string, username: string, content: string): DefaultKanbanMessage {
  return {
    id,
    username,
    content,
    timestamp: Date.now(),
    type: 'default',
  };
}

function hasBeenHandledOnPost(kanban: Kanban, postId: string): boolean {
  return kanban.handlings.filter((h) => h.postId === postId && isTruthy(h.endDate)).length !== 0;
}

function hasBeenHandledOnStand(kanban: Kanban, standId: string): boolean {
  return kanban.handlings.filter((h) => h.standId === standId && isTruthy(h.endDate)).length !== 0;
}

function isCurrentlyHandled(kanban: Kanban): boolean {
  return kanban.handlings.filter((h) => !isTruthy(h.endDate)).length !== 0;
}

function getOpenOperatorHandles(kanban: Kanban): KanbanHandling[] {
  return kanban.handlings.filter((h) => !isTruthy(h.endDate));
}

function getOpenOperatorHandleForPost(kanban: Kanban, postId: string): KanbanHandling | undefined {
  // In order to be deterministic, the list is ordered by id
  const handlingsSortedById = kanban.handlings
    .slice()
    .sort((kh1, kh2) => compareStrings(kh1.id, kh2.id, 'UP'));
  const openHandlingsForGivenPost = handlingsSortedById.filter(
    (h) => h.postId === postId && !isTruthy(h.endDate)
  );

  return openHandlingsForGivenPost[0] ?? undefined;
}

function getOpenOperatorHandleForPostStartingWith(
  kanban: Kanban,
  partialId: string
): KanbanHandling | undefined {
  const handles = kanban.handlings.filter(
    (h) => !isTruthy(h.endDate) && h.postId.startsWith(partialId)
  );
  if (handles && handles.length > 1) {
    throw new Error('A post can handle a kanban only once');
  }
  return handles[0] ?? undefined;
}

function isHandledOnPost(kanban: Kanban, postId: string): boolean {
  return kanban.handlings.filter((h) => h.postId === postId && !isTruthy(h.endDate)).length !== 0;
}

function getKanbanAgeing<K extends CoreFields<Kanban>>(
  k: K | KanbanSummary
): { days: number; hours: number } {
  const actualDate = Date.now();
  const differenceInSeconds = (actualDate - k.creationDate) / 1000;
  const differenceInHours = Math.floor(differenceInSeconds / 3600);
  let differenceInDays = 0;
  if (differenceInHours >= 24) {
    differenceInDays = Math.floor(differenceInHours / 24);
  }
  const remainingHours = differenceInHours - differenceInDays * 24;
  return { days: differenceInDays, hours: remainingHours };
}

/**
 * Open a new interval in the open Handle on the given kanban.
 * Throw an error if an interval is already open or if the handle is closed
 *
 * @param kanban The kanban for  which a handle must be open
 * @param postId The ID of the post for which the handling must be unpaused
 * @param sequence The sequence used to compute object IDs
 * @param userLogin *OPTIONAL* If provided stored in the new open interval.
 */
function unpauseKanbanHandling(
  kanban: Kanban,
  sequence: Sequence,
  postId: string,
  userLogin?: string
): Kanban {
  const startDate = Date.now();

  const unfinishedHandles = kanban.handlings.filter(
    (h) => !isTruthy(h.endDate) && h.postId === postId
  );

  if (unfinishedHandles.length === 0) {
    throw Error('The kanban does not seems to be handled');
  }

  const handle = unfinishedHandles[0];
  const unfinishedIntervals = handle.intervals.filter((i) => !isTruthy(i.endDate));

  if (unfinishedIntervals.length !== 0) {
    throw Error('A work interval is already opened on the kanban');
  }

  const interval: KanbanWorkInterval = {
    id: sequence.next(),
    users: userLogin ? [userLogin] : [],
    startDate,
    comment: '',
    type: 'work',
  };

  const updatedHandling = { ...handle, intervals: [...handle.intervals, interval] };
  return {
    ...kanban,
    handlings: kanban.handlings.map((h): KanbanHandling => {
      if (h.id === updatedHandling.id) {
        return updatedHandling;
      }
      return h;
    }),
  };
}

function isKanbanCurrentlyInImplantation(
  kanban: Kanban,
  standId: string,
  implantation: string
): boolean {
  return (
    kanban.handlings.find(
      (h) => !isTruthy(h.endDate) && h.standId === standId && h.postId.startsWith(implantation)
    ) !== undefined
  );
}

function getAllKanbansWithWorkOnStand(kanbans: readonly Kanban[], standId: string): Kanban[] {
  return kanbans.filter(
    ({ packageDeals }) =>
      packageDeals
        .filter(packageDealHelpers.buildPackageDealFilter('achievable'))
        .filter(({ operations, spareParts }) => {
          return (
            operations.filter(
              ({ standId: opStandId, deleted: opDeleted, completionDate }) =>
                !completionDate && !opDeleted && opStandId === standId
            ).length > 0 ||
            spareParts.filter(
              ({ standId: spStandId, deleted: spDeleted, dateOfReception }) =>
                !dateOfReception && !spDeleted && spStandId === standId
            ).length > 0
          );
        }).length > 0
  );
}

function getKanbansCurrentlyInImplantation(
  kanbans: readonly Kanban[],
  standId: string,
  implantation: string
): Kanban[] {
  return kanbans.filter((k) => isKanbanCurrentlyInImplantation(k, standId, implantation));
}

function getKanbanCurrentPositionInPostImplantation<K extends CoreFields<Kanban>>(
  companyId: string,
  kanban: K,
  standId: string,
  implantation: string
): { implantationId: string; categoryId: string; postName: string | undefined } | undefined {
  let result;
  for (const h of kanban.handlings) {
    if (
      h.standId === standId &&
      !isTruthy(h.endDate) &&
      globalHelpers.isQualifiedCategoryOrPostId(h.postId)
    ) {
      const { implantationId, categoryId, postName } =
        transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(h.postId);
      if (implantation === implantationId) {
        result = {
          implantationId: nonnull(implantationId),
          categoryId: nonnull(categoryId),
          postName,
        };
        return result;
      }
    }
  }
  return result;
}

function getRelatedOperationForGivenMessageRequestId(
  kanban: CoreFields<Kanban>,
  messageId: string
): Operation | undefined {
  const message: OperationRequestMessage | undefined = kanban.messages.find(
    (m) => m.id === messageId && m.type === 'request' && m.elementType === 'operation'
  ) as OperationRequestMessage | undefined;

  for (const packageDeal of kanban.packageDeals) {
    for (const op of packageDeal.operations) {
      if (message?.elementId === op.id) {
        return op;
      }
    }
  }
  return undefined;
}

function getDecorators(
  kanban: Kanban,
  configurationDecorators: readonly DecoratorDefinition[]
): readonly Decorator[] {
  const availablePkgs = kanban.packageDeals
    .filter(packageDealHelpers.buildPackageDealFilter('achievable'))
    .filter(
      ({ operations }) =>
        operations.filter(({ deleted, completionDate }) => !deleted && !completionDate).length > 0
    );

  if (kanban.messages.some((m) => m.type === 'request' && m.status === 'pending')) {
    return [
      {
        type: 'icon',
        id: 'warning',
        icon: SUBCONTRACTOR_REQUEST_MESSAGE_ICON_ID,
        bgColor: 'yellow',
        fgColor: 'black',
        pulsating: true,
      },
    ];
  }

  return (
    configurationDecorators
      .filter((decorator) => {
        const pkgsToDecorate = availablePkgs.filter(({ code, variables }) => {
          let shouldBeDisplayed = false;
          decorator.packageDeals.forEach((packageDeal) => {
            if (typeof packageDeal === 'string') {
              if (packageDeal === code) {
                shouldBeDisplayed = true;
              }
            } else if (
              packageDeal.id === code &&
              (!isTruthyAndNotEmpty(packageDeal.variable) ||
                packageDeal.expectedValues.includes(variables[packageDeal.variable]?.value))
            ) {
              shouldBeDisplayed = true;
            }
          });
          return shouldBeDisplayed;
        });
        return pkgsToDecorate.length > 0;
      })
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      .map(({ packageDeals, ...rest }) => rest)
  );
}

/**
 * Get all the spare parts that have not been received yet for achievable packageDeals
 *
 * return An array with the not received spare parts
 */
function getAllNotReceivedSpareParts<T extends PriceableKanban>(
  kanban: T
): readonly PriceableSparePart[] {
  const packageDeals = packageDealHelpers.getAllPackageDeals(
    kanban.packageDeals,
    'available',
    true,
    false
  );
  return getAllNotReceivedSparePartsForPkgsDeals(packageDeals);
}

/**
 * Get all the spare parts that have not been received yet for the packageDeals
 *
 * return An array with the not received spare parts
 */
function getAllNotReceivedSparePartsForPkgsDeals<T extends PriceablePackageDeal>(
  packageDeals: readonly T[]
): readonly PriceableSparePart[] {
  return packageDeals
    .flatMap(({ spareParts }) => spareParts.filter(nonDeleted))
    .filter(({ dateOfReception }) => !isTruthy(dateOfReception));
}

function isKanbanCurrentlyOnWorkshopPost(
  kanban: Kanban,
  standId: string,
  qualifiedPostId: string
): boolean {
  return (
    kanban.handlings.find(
      (h) => !isTruthy(h.endDate) && h.standId === standId && h.postId === qualifiedPostId
    ) !== undefined
  );
}

function isKanbanCurrentlyOnOneOfWorkshopPosts(
  kanban: Kanban,
  standId: string,
  qualifiedPostIds: string[]
): boolean {
  return (
    kanban.handlings.find(
      (h) => !isTruthy(h.endDate) && h.standId === standId && qualifiedPostIds.includes(h.postId)
    ) !== undefined
  );
}

function getKanbanCurrentlyOnWorkshopQualifiedPost(
  kanbans: readonly Kanban[],
  standId: string,
  qualifiedPostId: string
): Kanban | undefined {
  return kanbans.filter((k) => isKanbanCurrentlyOnWorkshopPost(k, standId, qualifiedPostId))[0];
}

function getKanbanCurrentlyOnWorkshopPost(
  kanbans: readonly Kanban[],
  standId: string,
  implantationId: string,
  postId: string
): Kanban | undefined {
  const qualified = globalHelpers.computeQualifiedWorkshopPostId(implantationId, postId);
  return getKanbanCurrentlyOnWorkshopQualifiedPost(kanbans, standId, qualified);
}

function internalCloseOrPauseHandling<K extends CoreFields<Kanban>>(
  kanban: K,
  event: 'close' | 'pause',
  postId: string,
  handlingDetectionMode: 'exactMatch' | 'startWith',
  closeOnlyFirstMatch: boolean
): K {
  const endDate = Date.now();
  let unfinishedHandles = kanban.handlings.filter((h) => {
    let isThePost = false;
    switch (handlingDetectionMode) {
      case 'exactMatch':
        isThePost = h.postId === postId;
        break;
      case 'startWith':
        isThePost = h.postId.startsWith(postId);
        break;
      default:
        break;
    }

    return !isTruthy(h.endDate) && isThePost;
  });
  if (unfinishedHandles.length === 0) {
    return kanban;
  }

  if (closeOnlyFirstMatch) {
    unfinishedHandles = [unfinishedHandles[0]];
  }

  const finishedHandlings = unfinishedHandles.map((unfinishedHandle): KanbanHandling => {
    let handle = unfinishedHandle;
    const unfinishedIntervals = unfinishedHandle.intervals.filter((u) => !isTruthy(u.endDate));
    if (unfinishedIntervals.length === 0) {
      if (event === 'close') {
        handle = {
          ...unfinishedHandle,
          endDate,
        };
      }
    } else if (unfinishedIntervals.length === 1) {
      const interval = { ...unfinishedIntervals[0], endDate };
      handle = {
        ...unfinishedHandle,
        endDate: event === 'close' ? endDate : undefined,
        intervals: [
          ...unfinishedHandle.intervals.map((i): KanbanWorkInterval => {
            if (i.id === interval.id) {
              return interval;
            }
            return i;
          }),
        ],
      };
    } else {
      throw Error('Two intervals cannot be openend at the same time on a kanban');
    }
    return handle;
  });

  const newKanban = {
    ...kanban,
    handlings: [
      ...kanban.handlings.map((h): KanbanHandling => {
        const finished = finishedHandlings.find((f) => f.id === h.id);
        if (finished) {
          return finished;
        }
        return h;
      }),
    ],
  };

  return newKanban;
}

function internalOpenNewHandling<K extends CoreFields<Kanban>>(
  sequence: Sequence,
  kanban: K,
  standId: string,
  postId: string,
  users: readonly string[],
  typeOfEventualIntervalToOpen: KanbanWorkIntervalType | undefined,
  comment: string
): K {
  const startDate = Date.now();
  const intervals: KanbanWorkInterval[] = [];
  if (typeOfEventualIntervalToOpen !== undefined) {
    intervals.push({
      id: sequence.next(),
      startDate,
      users,
      comment,
      type: typeOfEventualIntervalToOpen,
    });
  }
  const newHandling: KanbanHandling = {
    id: sequence.next(),
    postId,
    standId,
    intervals,
    startDate,
  };
  return {
    ...kanban,
    handlings: [...kanban.handlings, newHandling],
  };
}

/**
 * Open a new Handle and a new Interval on the given kanban.
 * Throw an error if an handle is already open
 *
 * @param sequence The sequence used to compute object IDs
 * @param kanban The kanban for  which a handle must be open
 * @param standId The Id of the stand handling the kanban
 * @param postId The ID of the post handling the kanban
 * @param users An array of users working on the kanban during the interval
 * @param typeOfEventualIntervalToOpen the type of new interval for this new handling, undefined means no interval will be created
 * @param comment **Optional** a comment to attach to the handling
 */
function openNewHandlingWithInterval<K extends CoreFields<Kanban>>(
  sequence: Sequence,
  kanban: K,
  standId: string,
  postId: string,
  users: readonly string[],
  typeOfEventualIntervalToOpen: KanbanWorkIntervalType,
  comment = ''
): K {
  return internalOpenNewHandling(
    sequence,
    kanban,
    standId,
    postId,
    users,
    typeOfEventualIntervalToOpen,
    comment
  );
}

/**
 * Open a new Handle without a new Interval on the given kanban.
 * Throw an error if an handle is already open
 *
 * @param sequence The sequence used to compute object IDs
 * @param kanban The kanban for  which a handle must be open
 * @param standId The Id of the stand handling the kanban
 * @param postId The ID of the post handling the kanban
 * @param users An array of users working on the kanban during the interval
 */
function openNewHandlingWithoutInterval<K extends CoreFields<Kanban>>(
  sequence: Sequence,
  kanban: K,
  standId: string,
  postId: string,
  users: readonly string[]
): K {
  return internalOpenNewHandling(sequence, kanban, standId, postId, users, undefined, '');
}

/**
 * Close the open handle on the given kanban.
 * If multiple handles are open or multiple intervals inside a handle, throw an error.
 *
 * @param kanban The kanban with a Handle to close
 * @param postId The ID of the post for which the handling must be closed
 */
function closeCurrentHandling<K extends CoreFields<Kanban>>(kanban: K, postId: string): K {
  return internalCloseOrPauseHandling(kanban, 'close', postId, 'exactMatch', true);
}

/**
 * Close all handlings that start with the given partial postId.
 *
 * @param kanban The kanban with a Handle to close
 * @param postId The ID of the post for which the handling must be closed
 */
function closeAllHandlingStartingWith(kanban: Kanban, postId: string): Kanban {
  return internalCloseOrPauseHandling(kanban, 'close', postId, 'startWith', false);
}

/**
 * Close all handlings open handlings.
 *
 * @param kanban The kanban with a some Handles to close
 */
function closeAllHandlings(kanban: Kanban): Kanban {
  return internalCloseOrPauseHandling(kanban, 'close', '', 'startWith', false);
}

/**
 * Pause open handle on the given kanban (by closing the open interval).
 * If multiple handles are open or multiple intervals inside a handle, throw an error.
 *
 * @param kanban The kanban with a Handle to close
 * @param postId The ID of the post for which the handling must be paused
 */
function pauseCurrentHandling(kanban: Kanban, postId: string): Kanban {
  return internalCloseOrPauseHandling(kanban, 'pause', postId, 'exactMatch', true);
}

function getCurrentLocationElement<K extends SpecificFields<Kanban>>(
  kanban: K
): KanbanLocationElement | undefined {
  const sortedHistory = kanban.logisticInfos.locationHistory.slice().sort(compareLocationHistory);
  if (sortedHistory && sortedHistory.length > 0) {
    return sortedHistory[0];
  }
  return undefined;
}

function getCurrentLocation<K extends SpecificFields<Kanban>>(kanban: K): string | undefined {
  const locationElement = getCurrentLocationElement(kanban);
  return locationElement ? locationElement.location : undefined;
}

function getUpdatedLogisticInfos(kanban: Kanban, carLocation: string, historyEntryId: string) {
  return {
    ...kanban.logisticInfos,
    locationHistory: [
      ...kanban.logisticInfos.locationHistory,
      {
        id: historyEntryId,
        location: carLocation,
        date: Date.now(),
      },
    ],
  };
}

function isKanbanFinished<K extends CoreFields<Kanban>>(kanban: K): boolean {
  // TODO for now the only way to know if a kanban is finished is to look if all operations are done
  // But it might not be enough, a reflexion must be done on what determine if a kanban is finished
  // (returning it to the client, etc.)
  return kanban.packageDeals.reduce<boolean>((pkgIsFinished, cPkg) => {
    if (!cPkg.deleted) {
      // If the status is null, this kanban has unvalidated packageDeals, it is considered not finished
      if (cPkg.status === null) {
        return false;
      }
      if (packageDealHelpers.isPackageDealAvailableAndAchievable(cPkg)) {
        return (
          pkgIsFinished &&
          cPkg.operations
            .filter((o) => !o.deleted)
            .reduce<boolean>((pOpIsFinished, cOp) => pOpIsFinished && !!cOp.completionDate, true)
        );
      }
    }
    // If the package deal is deleted, canceled or not achievable, it shouldn't help determine if the kanban is finished or not
    return pkgIsFinished;
  }, true);
}

function getChronologicallySortedFinishedHandles(
  kanban: Kanban,
  oldestFirst: boolean
): KanbanHandling[] {
  const finishedHandles = kanban.handlings.filter((h) => isTruthy(h.endDate));
  const sortedHandles = finishedHandles.sort((a, b) => {
    if (nonnull(a.endDate) < nonnull(b.endDate)) {
      return 1;
    }
    if (nonnull(a.endDate) > nonnull(b.endDate)) {
      return -1;
    }
    return 0;
  });
  if (oldestFirst) {
    sortedHandles.reverse();
  }
  return sortedHandles;
}

function getPostLabelForHandling(
  handling: KanbanHandling,
  addStandId = false,
  separator = '/'
): string {
  if (addStandId) {
    return `${handling.standId}${separator}${handling.postId}`;
  }
  return handling.postId;
}

/**
 * Compute the revenue of selected PackageDeals. This is intended to be called before validation to get the refurbish
 * price if oll the selectedPackageDealIds and only them are considered available
 * @param kanbanOrPackageDeal
 * @param selectedPackageDealIds the ids of packageDeals that must be considered available in the given packageDeals
 */
function computeSelectedWorkRevenue(
  kanbanOrPackageDeal: PriceableKanban | readonly PriceablePackageDeal[],
  selectedPackageDealIds: readonly string[]
): number {
  let packageDeals: readonly PriceablePackageDeal[];
  if (Array.isArray(kanbanOrPackageDeal)) {
    packageDeals = kanbanOrPackageDeal;
  } else {
    packageDeals = (kanbanOrPackageDeal as PriceableKanban).packageDeals;
  }
  const updatedPackageDeals: PriceablePackageDeal[] = packageDeals
    .filter((pck) => !pck.deleted && selectedPackageDealIds.includes(pck.id))
    .map((pck) => {
      return { ...pck, status: 'available' };
    });
  const activeAndAvailablePackageDeals = updatedPackageDeals.filter(
    packageDealHelpers.buildPackageDealFilter('available', false)
  );
  return packageDealHelpers.getPackageDealsAndSparePartsTotalPriceWithoutVAT(
    activeAndAvailablePackageDeals,
    'all'
  );
}

function isKanbanRevoked(kanban: SpecificFields<Kanban>): boolean {
  return kanban.packageDeals.every(({ deleted, status }) => deleted || status === 'canceled');
}

const convertToSummary = (...kanbans: readonly Kanban[]): readonly KanbanSummary[] =>
  kanbans.map((kanban): KanbanSummary => {
    const {
      id,
      infos,
      customer,
      attributes,
      status,
      timestamp,
      sequenceId,
      creationDate,
      dueDate,
      refitEndDate,
      origin,
      contract,
      marketplaceInfos,
    } = kanban;
    const isFinished = isKanbanFinished(kanban);
    const price = packageDealHelpers.getInvoiceablePackageDealsAndSparePartsPriceWithoutVAT(
      kanban.packageDeals
    );
    const isRevoked = isKanbanRevoked(kanban);
    const marketplacePublicPrice =
      marketplaceInfos?.mandate === MKTP_MANDATE_SALE ? marketplaceInfos?.inputPrice : undefined;

    return {
      id,
      contract,
      infos,
      marketplacePublicPrice,
      customer,
      attributes,
      location: getCurrentLocation(kanban),
      status,
      timestamp,
      sequenceId,
      creationDate,
      dueDate,
      refitEndDate,
      isFinished,
      kanbanSource: origin.source,
      kanbanSourceId: origin.id,
      isRevoked,
      price,
    };
  });

function getDifferenceInDay(dueDate: number, currentDate: number): number {
  const differenceInSeconds = (dueDate - currentDate) / 1000;
  const differenceInHours = Math.floor(differenceInSeconds / 3600);
  let differenceInDays = 0;
  if (differenceInHours >= 24) {
    differenceInDays = Math.floor(differenceInHours / 24);
  }
  return differenceInDays;
}

function getMostRestrictiveDueDate(
  dueDate: number | null,
  refitEndDate: number | null
): number | null {
  if (isTruthy(dueDate) && !isTruthy(refitEndDate)) {
    return dueDate;
  }
  if (!isTruthy(dueDate) && isTruthy(refitEndDate)) {
    return refitEndDate;
  }
  if (isTruthy(dueDate) && isTruthy(refitEndDate)) {
    // Return the most relevant date
    return dueDate < refitEndDate ? dueDate : refitEndDate;
  }
  return null;
}

function getKanbanPriorityLevelForKanbansDueDate(
  dueDate: number | null,
  refitEndDate: number | null,
  threshold: number
): KanbanPriorityLevel | undefined {
  // Get most relevant date between due date and refit end date
  const relevantDate = getMostRestrictiveDueDate(dueDate, refitEndDate);

  if (relevantDate === null) {
    return undefined;
  }
  const currentDate = Date.now();
  if (relevantDate < currentDate) {
    return KanbanPriorityLevel.dueDateIsPast;
  }
  const differenceInDays = getDifferenceInDay(relevantDate, currentDate);
  return differenceInDays <= threshold ? KanbanPriorityLevel.dueDateIsWithinThreshold : undefined;
}

function getKanbanPriorityLevelRegardlessPastDueDate(
  dueDate: number | null,
  refitEndDate: number | null,
  threshold: number
): KanbanPriorityLevel | undefined {
  const level = getKanbanPriorityLevelForKanbansDueDate(dueDate, refitEndDate, threshold);
  return level === KanbanPriorityLevel.dueDateIsPast
    ? KanbanPriorityLevel.dueDateIsWithinThreshold
    : level;
}

function isKanbanOrKanbanSummaryFinished<K extends CoreFields<Kanban>>(
  kanbanOrKanbanSummary: K | KanbanSummary
): boolean {
  if ('isFinished' in kanbanOrKanbanSummary) {
    return kanbanOrKanbanSummary.isFinished;
  }
  return isKanbanFinished(kanbanOrKanbanSummary);
}

function computePriorityLevel<K extends CoreFields<Kanban>>(
  kanbanOrKanbanSummary: K | KanbanSummary,
  kanbanAgeRangeForColorCharter: KanbanColorationCharter | undefined
): KanbanPriorityLevel {
  if (isKanbanOrKanbanSummaryFinished(kanbanOrKanbanSummary)) {
    return KanbanPriorityLevel.finished;
  }
  if (kanbanAgeRangeForColorCharter) {
    const dueDatePriorityLevel = getKanbanPriorityLevelForKanbansDueDate(
      kanbanOrKanbanSummary.dueDate,
      kanbanOrKanbanSummary.refitEndDate,
      kanbanAgeRangeForColorCharter.dueDateThreshold
    );
    if (dueDatePriorityLevel !== undefined) {
      return dueDatePriorityLevel;
    }
    const { ageUnderWhichKanbansAreYoung, ageAboveWhichKanbansAreOld } =
      kanbanAgeRangeForColorCharter;
    const { days, hours } = getKanbanAgeing(kanbanOrKanbanSummary);

    if (days < ageUnderWhichKanbansAreYoung) {
      return KanbanPriorityLevel.age0;
    }
    // We have kanban-age-0 to kanban-age-9,
    // but when ageUnderWhichKanbansAreYoung is reached we start with kanban-age-1
    const NB_STEPS = 9;
    const delta = (NB_STEPS - 1) / (ageAboveWhichKanbansAreOld - ageUnderWhichKanbansAreYoung);
    const age = days + hours / 24;
    let ageRange = Math.floor(delta * (age - ageUnderWhichKanbansAreYoung)) + 1;

    if (ageRange > 9) {
      ageRange = 9;
    }
    const key = `age${ageRange}`;
    return KanbanPriorityLevel[key as keyof typeof KanbanPriorityLevel];
  }
  return KanbanPriorityLevel.none;
}

function isKanbanFrozen(kanban: Kanban, siteConfiguration: SiteConfiguration): boolean {
  const { workflows } = siteConfiguration;
  const workflow = nonnull(
    workflows.find(({ id: workflowId }) => workflowId === nonnull(kanban).workflowId)
  );
  const progress = workflowProgressHelpers.computeProgress(workflow.definition, kanban);
  const linearWorkflow = workflowHelpers.linearize(workflow.definition);
  let frozen = false;
  for (let index = 0; index < linearWorkflow.length; index += 1) {
    const standId = linearWorkflow[index];
    const stand = siteConfiguration.stands.find((cStand) => cStand.id === standId);
    const shouldKanbanBeFrozenPastThisStand =
      stand !== undefined && stand.shouldKanbanBeFrozenPastThisStand;
    const standProgress = progress.progressByStand[standId];
    const isStandFinished =
      !standProgress || standProgress.operationCount === standProgress.operationDone;
    // as soon as we detect an unfreezing unfiniseh stand or a freezing finished stand,
    // we stop looping
    if (shouldKanbanBeFrozenPastThisStand) {
      if (isStandFinished) {
        frozen = true;
        break;
      }
    } else if (!isStandFinished) {
      frozen = false;
      break;
    }
  }
  return frozen;
}

function putExpertisePriceTo0(
  kanban: Kanban,
  user: AuthenticatedUser | undefined,
  sequence: Sequence,
  reason: string
): Kanban {
  const expertisePackageDeal = packageDealHelpers.getExpertisePackageDeal(kanban.packageDeals);
  const { firstName, lastName, login } = nonnull(user);
  return {
    ...kanban,
    packageDeals: kanban.packageDeals.map((packageDeal): PackageDeal => {
      return packageDeal === expertisePackageDeal
        ? { ...packageDeal, price: 0, priceIsOverridden: true }
        : packageDeal;
    }),
    messages: [
      ...kanban.messages,
      createKanbanComment(
        sequence.next(),
        login,
        `Le tarif du forfait d'expertise a été annulé par ${firstName} ${lastName} pour le motif suivant : ${reason}`
      ),
    ],
  };
}

function assignOperationToWorkshopCategory(
  kanban: Kanban,
  operationId: string,
  qualifiedWorkshopCategory: string
): Kanban {
  const newPd = kanban.packageDeals.map((pd): PackageDeal => {
    const newOps = pd.operations.map((op): Operation => {
      if (op.id === operationId) {
        return {
          ...op,
          attributes: {
            ...op.attributes,
            [OPERATION_ATTRIBUTES.WORKSHOP_POST]: qualifiedWorkshopCategory,
          },
        };
      }
      return op;
    });
    return {
      ...pd,
      operations: newOps,
    };
  });
  const newKanban: Kanban = {
    ...kanban,
    packageDeals: newPd,
  };
  return newKanban;
}

const getCustomerLabel = (customer: KanbanCustomer, t?: TFunction): string => {
  if (!customer.individual) {
    if (isTruthyAndNotEmpty(customer.shortName)) {
      return customer.shortName;
    }
    if (isTruthyAndNotEmpty(customer.lastName)) {
      return `${customer.company} / ${
        t ? t(`common:civilities.abbreviated.${customer.civility}`) : customer.civility
      } ${customer.lastName} ${customer.firstName}`.trim();
    }
    return customer.company;
  }
  return `${t ? t(`common:civilities.abbreviated.${customer.civility}`) : customer.civility} ${
    customer.lastName
  } ${customer.firstName}`.trim();
};

function hasBeenCreatedAutomatically(origin: KanbanOrigin): boolean {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return KANBAN_AUTOMATED_SOURCES.includes(origin.source as any);
}

const GLOBAL_SITE_CONFIGURATION_VARIABLES_FROM_KANBAN: ValuableFromKanbanVariable[] = [
  {
    variableName: 'location',
    getValueFromKanban: (k): string => getCurrentLocation(k) ?? '',
    variableType: 'string',
  },
  {
    variableName: 'customerShortName',
    getValueFromKanban: (k): string => k.customer.shortName,
    variableType: 'string',
  },
  {
    variableName: 'brand',
    getValueFromKanban: (k): string => k.infos.brand,
    variableType: 'string',
  },
  {
    variableName: 'model',
    getValueFromKanban: (k): string => k.infos.model,
    variableType: 'string',
  },
  {
    variableName: 'mileage',
    getValueFromKanban: (k): string => String(k.infos.mileage),
    variableType: 'string',
  },
];

function getComputeIconFromKanbanFunction<K extends CoreFields<Kanban>>(
  computeIconFunction: string,
  workflows: readonly Workflow[]
): (kanban: K | undefined) => string | undefined {
  if (!isTruthyAndNotEmpty(computeIconFunction)) {
    return (): undefined => undefined;
  }
  const variableNames = [
    ...GLOBAL_SITE_CONFIGURATION_VARIABLES_FROM_KANBAN.map(
      (variable): string => variable.variableName
    ),
    'expectedStandIds',
  ];
  try {
    // eslint-disable-next-line no-new-func
    const functionFromConfiguration = Function(...variableNames, computeIconFunction) as (
      ...params: (string | readonly string[])[]
    ) => string | undefined;

    const businessFunction = (k: K | undefined): string | undefined => {
      if (!isTruthy(k)) {
        return undefined;
      }
      const workflow = workflows.find((w) => w.id === k.workflowId);
      const expectedStandIds = !isTruthy(workflow)
        ? []
        : workflowProgressHelpers.computeProgress(workflow.definition, k).expectedStandIds;
      const variableValues = [
        ...GLOBAL_SITE_CONFIGURATION_VARIABLES_FROM_KANBAN.map((variable): string | string[] => {
          switch (variable.variableType) {
            case 'string':
              return (variable.getValueFromKanban as GetStringValueFromKanbanFunction)(k);
            default:
              return '';
          }
        }),
        expectedStandIds,
      ];
      try {
        return functionFromConfiguration(...variableValues);
      } catch (e) {
        log.warn(e);
        return undefined;
      }
    };
    return businessFunction;
  } catch (e) {
    log.warn(e);
    return (): undefined => undefined;
  }
}

function applyStatusOnAllPackageDeals(
  kanban: CoreFields<Kanban>,
  availablePackageDealsIds: readonly string[],
  canceledPackageDealsIds: readonly string[],
  finishValidationOperations: boolean,
  userLogin: string | null
): readonly PackageDeal[] {
  const packageDeals = kanban.packageDeals.map((pck): PackageDeal => {
    if (nonDeleted(pck)) {
      let { status } = pck;
      if (availablePackageDealsIds.includes(pck.id)) {
        status = 'available';
      } else if (canceledPackageDealsIds.includes(pck.id)) {
        status = 'canceled';
      }

      // If the current package deal goes from available to canceled status and if it contains already started operations,
      // throw an error (this case should be handled in the client, this is just a gard)
      if (
        pck.status === 'available' &&
        status === 'canceled' &&
        packageDealHelpers.isPackageDealStartedOrUnremovable(pck)
      ) {
        throw Error('A started package deal cannot be canceled');
      }

      return {
        ...pck,
        status,
        operations: pck.operations.map((o): Operation => {
          if (
            finishValidationOperations &&
            !o.deleted &&
            o.type === 'Validation' &&
            !o.completionDate
          ) {
            return {
              ...o,
              completionDate: Date.now(),
              user: userLogin,
            };
          }
          return o;
        }),
      };
    }
    return pck;
  });

  if (packageDeals.filter((pck) => !pck.deleted && pck.status === null).length > 0) {
    throw Error('All packageDeals must have a status after expertise validation');
  }

  return packageDealHelpers.updateAllPackageDealsExpressionComputations({
    ...kanban,
    packageDeals,
  });
}

function completeDeliveryOperation(
  companyId: string,
  siteId: string,
  kanban: CoreFields<Kanban>,
  userLogin: string | null
): PackageDeal[] {
  let deliveryHasBeenCompleted = false;
  const newPackageDeals = kanban.packageDeals.map((pck) => {
    if (pck.code === ADMIN_PACKAGE_DEAL_CODE) {
      return {
        ...pck,
        operations: pck.operations.map((o) => {
          if (!o.deleted && !o.completionDate && o.type === 'Delivery') {
            deliveryHasBeenCompleted = true;
            return {
              ...o,
              completionDate: Date.now(),
              user: userLogin,
            };
          }
          return o;
        }),
      };
    }
    return pck;
  });

  if (!deliveryHasBeenCompleted) {
    throw Error(
      `All kanbans must have a delivery operation (${companyId} - ${siteId} - ${kanban.id})`
    );
  }

  return newPackageDeals;
}

function validateKanbanDelivery(
  sequenceId: string,
  companyId: string,
  siteId: string,
  kanban: Kanban,
  username: string,
  email: string,
  deliveryCommentContent: string
): Kanban {
  const packageDeals = completeDeliveryOperation(companyId, siteId, kanban, email);
  return {
    ...kanban,
    packageDeals,
    messages: [
      ...kanban.messages,
      {
        type: 'default',
        id: sequenceId,
        timestamp: Date.now(),
        username,
        content: deliveryCommentContent,
      },
    ],
  };
}

function deletePackageDeal(
  kanban: CoreFields<Kanban>,
  packageDealId: string
): readonly PackageDeal[] {
  const packageDeals = kanban.packageDeals.map((pd): PackageDeal => {
    if (pd.id === packageDealId) {
      return {
        ...pd,
        deleted: true,
      };
    }
    return pd;
  });

  return packageDealHelpers.updateAllPackageDealsExpressionComputations({
    ...kanban,
    packageDeals,
  });
}

interface AddNewPackageDealsResult {
  readonly packageDeals: readonly PackageDeal[];
  readonly createdPackageDealIds: readonly string[];
}

function addNewPackageDeals(
  sequence: Sequence,
  packageDealDescAndElements: {
    packageDealDesc: PackageDealDesc;
    selectedCarElement: CarElement | undefined;
  }[],
  kanban: CoreFields<Kanban>,
  sparePartManagementType: SparePartManagementType,
  options?: {
    preventExpressionComputation: boolean;
    packageDealsStatus: PackageDealStatus;
  }
): AddNewPackageDealsResult {
  const createdPackageDeals = packageDealDescAndElements.map(
    (e): PackageDeal =>
      packageDealHelpers.createPackageDealFromPackageDealDesc(
        sequence,
        e.packageDealDesc,
        e.selectedCarElement,
        sparePartManagementType,
        {
          globalVariableValues: null,
          packageDealStatus: options?.packageDealsStatus ?? null,
          roundPriceTo: kanban.contract.configuration.roundPriceTo,
        }
      )
  );

  const newPackageDeals = [...kanban.packageDeals, ...createdPackageDeals];
  return {
    packageDeals: options?.preventExpressionComputation
      ? newPackageDeals
      : packageDealHelpers.updateAllPackageDealsExpressionComputations({
          ...kanban,
          packageDeals: newPackageDeals,
        }),
    createdPackageDealIds: createdPackageDeals.map((pd): string => pd.id),
  };
}

function togglePackageDealAvailability(
  kanban: CoreFields<Kanban>,
  packageDealId: string,
  includesExpertRecoIfNoStatus = false
): readonly PackageDeal[] {
  const newPackageDeals = kanban.packageDeals.map((pd): PackageDeal => {
    if (packageDealId === pd.id) {
      if (
        !pd.deleted &&
        packageDealHelpers.isPackageDealAvailable(pd, includesExpertRecoIfNoStatus) &&
        !packageDealHelpers.isPackageDealStartedOrUnremovable(pd)
      ) {
        return {
          ...pd,
          status: 'canceled',
        };
      }
      if (
        !pd.deleted &&
        packageDealHelpers.isPackageDealCanceled(pd, includesExpertRecoIfNoStatus)
      ) {
        return {
          ...pd,
          status: 'available',
        };
      }
      throw Error('A deleted package deal should not have his status changed');
    }
    return pd;
  });

  return packageDealHelpers.updateAllPackageDealsExpressionComputations({
    ...kanban,
    packageDeals: newPackageDeals,
  });
}

function setAllPackageDealsAvailabilityTo(
  kanban: CoreFields<Kanban>,
  targetAvailability: PackageDealStatus,
  includesExpertRecoIfNoStatus = false
): readonly PackageDeal[] {
  const newPackageDeals = kanban.packageDeals.map((pd): PackageDeal => {
    if (pd.deleted) {
      return pd;
    }
    if (packageDealHelpers.isPackageDealStartedOrUnremovable(pd)) {
      return {
        ...pd,
        status: 'available',
      };
    }
    if (
      packageDealHelpers.isPackageDealAvailable(pd, includesExpertRecoIfNoStatus) ||
      packageDealHelpers.isPackageDealCanceled(pd, includesExpertRecoIfNoStatus)
    ) {
      return {
        ...pd,
        status: targetAvailability,
      };
    }
    return pd;
  });

  return packageDealHelpers.updateAllPackageDealsExpressionComputations({
    ...kanban,
    packageDeals: newPackageDeals,
  });
}

const ADMIN_PACKAGE_DEAL_LABEL: LabelDef = {
  en: 'Administrative processing',
  fr: 'Traitement administratif',
};

export const CREATE_INVOICE_OPERATION_LABEL: LabelDef = {
  en: 'Invoice creation',
  fr: 'Edition de la facture',
};

const CREATE_DELIVERY_OPERATION_LABEL: LabelDef = {
  en: 'Delivery (Acceptance document)',
  fr: 'Livraison (PV de réception)',
};
const DELIVERY_OPERATION_DURATION = 0.1;

function configureAdminPackageDeal(
  packageDeals: readonly PackageDeal[],
  sequence: Sequence,
  lang: Lang
): readonly PackageDeal[] {
  let result = packageDeals;
  const adminPkg = packageDeals.find(({ code }) => ADMIN_PACKAGE_DEAL_CODE === code);
  // If it doesn't exist, create it
  if (!adminPkg) {
    result = [
      ...packageDeals,
      {
        ...EMPTY_PACKAGE_DEAL,
        code: ADMIN_PACKAGE_DEAL_CODE,
        id: sequence.next(),
        status: 'available',
        label: ADMIN_PACKAGE_DEAL_LABEL[lang],
      },
    ];
  }
  result = result.map((pkg): PackageDeal => {
    if (pkg.code !== ADMIN_PACKAGE_DEAL_CODE) {
      return pkg;
    }
    const operations = [...pkg.operations];
    // If the "Create invoice" operation already exists, don't re-create it
    const createInvoiceOperation = operations.filter(
      ({ deleted, type, completionDate }) => !deleted && !completionDate && type === 'CreateInvoice'
    );
    if (createInvoiceOperation.length === 0) {
      operations.push({
        ...EMPTY_OPERATION,
        id: sequence.next(),
        type: 'CreateInvoice',
        label: CREATE_INVOICE_OPERATION_LABEL[lang],
        standId: ADMIN_STAND_ID,
      });
    }

    // If the "delivery" already exists, don't re-create it
    const deliveryOperation = operations.filter(
      ({ deleted, type, completionDate }) => !deleted && !completionDate && type === 'Delivery'
    );
    if (deliveryOperation.length === 0) {
      operations.push({
        ...EMPTY_OPERATION,
        id: sequence.next(),
        type: 'Delivery',
        label: CREATE_DELIVERY_OPERATION_LABEL[lang],
        workload: DELIVERY_OPERATION_DURATION,
        workloadExpression: String(DELIVERY_OPERATION_DURATION),
        standId: DELIVERY_STAND_ID,
      });
    }

    // Append an operation to create the invoice
    return {
      ...pkg,
      operations,
    };
  });
  return result;
}

function closeAllIntervals(openHandling: KanbanHandling): KanbanWorkInterval[] {
  const now = Date.now();
  return openHandling.intervals.map((i): KanbanWorkInterval => {
    if (!isTruthy(i.endDate)) {
      return {
        ...i,
        endDate: now,
      };
    }
    return i;
  });
}

function openNewIntervalInCurrentlyOpenHandlingOnPost(
  sequence: Sequence,
  kanban: Kanban,
  users: readonly string[],
  comment: string,
  typeInterval: KanbanWorkIntervalType,
  standId: string,
  postId: string
): Kanban {
  const today = Date.now();
  const allOpenHandlingsOnThePost = kanban.handlings.filter(
    (h) => !isTruthy(h.endDate) && h.standId === standId && h.postId === postId
  );
  if (allOpenHandlingsOnThePost.length === 0) {
    throw new Error(`The kanban is not handled on the given post ${postId}`);
  }
  if (allOpenHandlingsOnThePost.length > 1) {
    throw new Error(`The kanban has more than one open handling on the given post ${postId}`);
  }
  const theOpenHandling = allOpenHandlingsOnThePost[0];
  const closedIntervals = closeAllIntervals(theOpenHandling);
  const newInterval: KanbanWorkInterval = {
    id: sequence.next(),
    startDate: today,
    users,
    comment,
    type: typeInterval,
  };
  return {
    ...kanban,
    handlings: kanban.handlings.map((h): KanbanHandling => {
      if (h.id === theOpenHandling.id) {
        return {
          ...h,
          intervals: [...closedIntervals, newInterval],
        };
      }
      return h;
    }),
  };
}

function openAnomalyInterval(
  sequence: Sequence,
  kanban: Kanban,
  users: readonly string[],
  reason: string,
  postId: string,
  standId: string
): Kanban {
  return openNewIntervalInCurrentlyOpenHandlingOnPost(
    sequence,
    kanban,
    users,
    reason,
    'anomaly',
    standId,
    postId
  );
}

function closeAnomalyAndOpenNewWorkInterval(
  sequence: Sequence,
  kanban: Kanban,
  users: readonly string[],
  postId: string,
  standId: string
): Kanban {
  return openNewIntervalInCurrentlyOpenHandlingOnPost(
    sequence,
    kanban,
    users,
    '',
    'work',
    standId,
    postId
  );
}

function openAndonInterval(
  sequence: Sequence,
  kanban: Kanban,
  postId: string,
  users: readonly string[],
  standId: string
): Kanban {
  return openNewIntervalInCurrentlyOpenHandlingOnPost(
    sequence,
    kanban,
    users,
    '',
    'andon',
    standId,
    postId
  );
}

function closeAndonAndOpenNewWorkInterval(
  sequence: Sequence,
  kanban: Kanban,
  postId: string,
  users: readonly string[],
  standId: string
): Kanban {
  return openNewIntervalInCurrentlyOpenHandlingOnPost(
    sequence,
    kanban,
    users,
    '',
    'work',
    standId,
    postId
  );
}

function hasOpenAndon(kanban: Kanban): boolean {
  const handles = getOpenOperatorHandles(kanban);
  for (const handling of handles) {
    const theAndon = handling.intervals.find((i) => i.type === 'andon' && !isTruthy(i.endDate));
    if (isTruthy(theAndon)) {
      return true;
    }
  }
  return false;
}

function hasAchievablePackageDeal<T extends Kanban>(kanban: T, packageDealCode: string): boolean {
  return (
    packageDealHelpers
      .getAvailablePackageDeals(kanban.packageDeals, true)
      .find((pd) => pd.code === packageDealCode) !== undefined
  );
}

function validateKanbanExpertise(
  sequence: Sequence,
  kanban: Kanban,
  availablePackageDealsIds: readonly string[],
  canceledPackageDealsIds: readonly string[],
  username: string,
  email: string,
  validationCommentContent: string
): Kanban {
  const validatedPackageDeals = applyStatusOnAllPackageDeals(
    kanban,
    availablePackageDealsIds,
    canceledPackageDealsIds,
    true,
    email
  );
  return {
    ...kanban,
    packageDeals: validatedPackageDeals,
    messages: [
      ...kanban.messages,
      createKanbanComment(sequence.next(), username, validationCommentContent),
    ],
  };
}

function finishSparePartsReferenceOperation(kanban: Kanban, userLogin: string): Kanban {
  const { packageDeals } = kanban;
  const updatedPackageDeals = packageDeals.map((packageDeal) => {
    if (packageDealHelpers.isActiveAndAvailableExpertisePackageDeal(packageDeal)) {
      const updatedOperations = packageDeal.operations.map((operation): Operation => {
        if (
          !operation.deleted &&
          operation.type === 'ReferenceSparePart' &&
          !operation.completionDate
        ) {
          return {
            ...operation,
            completionDate: Date.now(),
            user: userLogin,
          };
        }
        return operation;
      });
      return {
        ...packageDeal,
        operations: updatedOperations,
      };
    }
    return packageDeal;
  });
  const updatedKanban: Kanban = {
    ...kanban,
    packageDeals: updatedPackageDeals,
  };
  return updatedKanban;
}

function getAllSpareParts(
  kanban: SpecificFields<Kanban>,
  includeRecommended = false
): readonly SparePart[] {
  const packageDeals = includeRecommended
    ? packageDealHelpers.getAvailableOrRecommendedPackageDeals(kanban.packageDeals)
    : packageDealHelpers.getAvailablePackageDeals(kanban.packageDeals);
  return packageDeals
    .map((pck): readonly SparePart[] => pck.spareParts.filter(nonDeleted))
    .reduce((acc, val) => acc.concat(val), []);
}

function getAllSparePartsOnStand(
  standId: string,
  kanban: SpecificFields<Kanban>,
  includeRecommended = false
): readonly SparePart[] {
  return getAllSpareParts(kanban, includeRecommended).filter((sp) => sp.standId === standId);
}

function getOrderedAndReceivedSpareParts(k: Kanban): {
  orderedParts: readonly SparePart[];
  receivedParts: readonly SparePart[];
} {
  const parts = k.packageDeals
    .filter((pkgDeal) => packageDealHelpers.isPackageDealAvailableAndAchievable(pkgDeal))
    .map((pkg) => pkg.spareParts)
    .reduce((acc, spareParts) => acc.concat(spareParts), []);

  const orderedParts = parts.filter(
    (part) =>
      nonDeleted(part) &&
      (part.managementType === 'fullyManagedByCustomer' || isTruthy(part.dateOfOrder))
  );

  const receivedParts = orderedParts.filter((part) => isTruthy(part.dateOfReception));

  return {
    orderedParts,
    receivedParts,
  };
}

function getOldestOrderedAndNotReceivedSparePartAge(k: Kanban): number | null {
  const { orderedParts } = getOrderedAndReceivedSpareParts(k);
  // We keep only the parts that were ordered (dateOfOrder != null or ordered by customer) but not yet received
  const partsOfInterest = orderedParts
    .filter((part) => part.dateOfReception === null)
    .map((part) => {
      if (isTruthy(part.dateOfOrder)) {
        return part.dateOfOrder;
      }
      return part.dateOfReference;
    });

  return partsOfInterest.reduce((oldestDateOfOrder, dateOfOrder) => {
    if (dateOfOrder !== null) {
      if (oldestDateOfOrder === null || dateOfOrder < oldestDateOfOrder) {
        return dateOfOrder;
      }
    }
    return oldestDateOfOrder;
  }, null);
}

function hasAUnfinishedDeliveryOperation<T extends SharedKanban>(kanban: T): boolean {
  return kanban.packageDeals.some((pck) => {
    if (
      packageDealHelpers.isPackageDealAvailableAndAchievable(pck) &&
      pck.code === ADMIN_PACKAGE_DEAL_CODE
    ) {
      return pck.operations.some(
        (o) => nonDeleted(o) && o.type === 'Delivery' && !isTruthy(o.completionDate)
      );
    }
    return false;
  });
}

export type DocumentType = 'Estimate' | 'Acceptance';
export type DocumentStatus = 'N/A' | 'TO_BE_VALIDATED' | 'VALIDATED';

function getDocumentStatus(
  packageDeals: PackageDeal[],
  expectedStandIds: readonly string[],
  shouldBeOnStandIds: readonly string[],
  type: OperationType
): DocumentStatus {
  if (
    expectedStandIds.length === 0 ||
    expectedStandIds.some((expected) => shouldBeOnStandIds.includes(expected))
  ) {
    const validationOperations = packageDeals
      .flatMap(({ operations }) => operations)
      .filter((ope) => ope.type === type);

    if (validationOperations.length > 0) {
      return validationOperations[0].completionDate === null ? 'TO_BE_VALIDATED' : 'VALIDATED';
    }
  }
  return 'N/A';
}

function getAllDocumentsStatus(
  kanbans: readonly Kanban[],
  site: SiteConfiguration
): Map<string, Record<DocumentType, DocumentStatus>> {
  const result = new Map<string, Record<DocumentType, DocumentStatus>>();

  site.workflows.forEach((workflow) => {
    const linearWorkflow = workflowHelpers.linearize(workflow.definition);
    const standsForVEXP = linearWorkflow.slice(linearWorkflow.indexOf('VEXP'));
    const standsForLIV = linearWorkflow.slice(linearWorkflow.indexOf('LIV'));

    kanbans.forEach((kanban) => {
      const kanbanProgress = workflowProgressHelpers.computeProgress(workflow.definition, kanban);
      const { expectedStandIds } = kanbanProgress;

      const status: Record<DocumentType, DocumentStatus> = { Estimate: 'N/A', Acceptance: 'N/A' };

      // Tests for estimate status
      status.Estimate = getDocumentStatus(
        [...kanban.packageDeals],
        expectedStandIds,
        standsForVEXP,
        'Validation'
      );

      // Tests for delivery status
      status.Acceptance = getDocumentStatus(
        [...kanban.packageDeals],
        expectedStandIds,
        standsForLIV,
        'Delivery'
      );

      result.set(kanban.id, status);
    });
  });
  return result;
}

function getNonAllocatedPackageDeals(kanban: Kanban): readonly PackageDeal[] {
  return kanban.packageDeals.filter(({ purchaseOrderId }) => !isTruthy(purchaseOrderId));
}

function getPackageDealsAllocatedToPurchaseOrderId(
  kanban: Kanban,
  givenPurchaseOrderId: string
): readonly PackageDeal[] {
  return kanban.packageDeals.filter(
    ({ purchaseOrderId }) => purchaseOrderId === givenPurchaseOrderId
  );
}

function getPackageDealsAllocatedToPurchaseNumber(
  kanban: Kanban,
  givenPurchaseNumber: string
): readonly PackageDeal[] {
  const givenPurchaseOrderId = kanban.purchaseOrders.find(
    ({ purchaseNumber }) => purchaseNumber === givenPurchaseNumber
  )?.id;

  if (isTruthy(givenPurchaseOrderId)) {
    return kanban.packageDeals.filter(
      ({ purchaseOrderId }) => purchaseOrderId === givenPurchaseOrderId
    );
  }
  return [];
}

/**
 * Price is considered as a valid for marketplace if price > MARKETPLACE_MIN_VALID_PRICE
 */
function hasValidMarketplacePrice(k: SpecificFields<Kanban>): boolean {
  if (isTruthy(k.marketplaceInfos?.inputPrice)) {
    const publicPrice = nonnull(k.marketplaceInfos?.inputPrice);
    return publicPrice > MARKETPLACE_MIN_VALID_PRICE;
  }
  return false;
}

function isMarketplaceKanban(kanban: BaseKanban): boolean {
  return isTruthy(kanban.marketplaceInfos);
}

function hasValidMarketplacePurchaseOrders(kanban: SpecificFields<Kanban>): boolean {
  const purchaseNumbers = kanban.purchaseOrders
    .filter(nonDeleted)
    .map(({ purchaseNumber }) => purchaseNumber);
  return (
    purchaseNumbers.includes(MARKETPLACE_BUY_PURCHASE_ORDER) &&
    purchaseNumbers.includes(MARKETPLACE_SALE_PURCHASE_ORDER)
  );
}

function getMarketplacePurchaseOrder(
  kanban: SpecificFields<Kanban>,
  givenPurchaseOrderNumber: MarketplacePurchaseOrder
): PurchaseOrder | undefined {
  return kanban.purchaseOrders.find(
    ({ purchaseNumber }) => purchaseNumber === givenPurchaseOrderNumber
  );
}

function getMarketplaceMandate(kanban: SpecificFields<Kanban>): MarketplaceMandate {
  return kanban.marketplaceInfos?.mandate ?? MKTP_MANDATE_UNKNOWN;
}

function isMarketplaceBuyMandate(kanban: SpecificFields<Kanban>): boolean {
  return kanban.marketplaceInfos?.mandate === MKTP_MANDATE_BUY;
}

function computeMarketplaceStatus(kanban: SpecificFields<Kanban>): MarketplaceStatus | undefined {
  const operations = packageDealHelpers.getFlatOperationList(
    kanban.packageDeals,
    'achievable',
    true
  );

  const hasCompletedMarketplacePublishOperation = operations.some(
    (o) => operationHelpers.isMarketplacePublishOperation(o) && isTruthy(o.completionDate)
  );

  const marketplaceSellOperation = operations.find((o) =>
    operationHelpers.isMarketplaceSellOperation(o)
  );

  // If there is no marketplace sell operation, kanban has no marketplace status
  if (!isTruthy(marketplaceSellOperation)) {
    return undefined;
  }

  const hasValidatedExpertise = operations.some((op) =>
    operationHelpers.isValidatedVEXPOperation(op)
  );
  if (
    isMarketplaceBuyMandate(kanban) ||
    !hasValidatedExpertise ||
    !hasValidMarketplacePrice(kanban)
  ) {
    return MKTP_OFFLINE_STATUS;
  }
  if (isTruthy(marketplaceSellOperation.completionDate)) {
    return MKTP_SOLD_STATUS;
  }
  if (hasCompletedMarketplacePublishOperation) {
    return MKTP_ONLINE_STATUS;
  }

  return MKTP_OFFLINE_STATUS;
}

function hasLocationChanged(originalKanban: Kanban, newLocation: string): boolean {
  const originalLocation = getCurrentLocation(originalKanban);
  return (originalLocation ?? '') !== (newLocation ?? '');
}

function getKanbanArchiveLink(companyId: string, kanbanId: string): string {
  return `https://${companyId}.stimcar.app/archive/${kanbanId}`;
}

function getKanbanTitle(
  kanbanInfos: KanbanInfos,
  marketplacePublicPrice: number | undefined
): string {
  const { brand, license, model } = kanbanInfos;

  const licenseText = isTruthy(license) ? license : '';
  const brandText = isTruthy(brand) ? ` - ${brand}` : '';
  const modelText = isTruthy(model) ? ` - ${model}` : '';
  const priceText = isTruthy(marketplacePublicPrice) ? ` - ${marketplacePublicPrice} €` : '';

  return `${licenseText}${brandText}${modelText}${priceText}`;
}

function createOrUpdateSparePartReferenceOperation(
  kanban: Kanban,
  markOperationAsFinished: boolean,
  userLogin: string | null,
  siteConfiguration: SiteConfiguration,
  sequence: Sequence
): Kanban {
  const updatedPackageDeals = createOrUpdateSparePartReferenceOperationForPackageDeals(
    kanban.packageDeals,
    markOperationAsFinished,
    userLogin,
    siteConfiguration,
    sequence
  );
  return {
    ...kanban,
    packageDeals: updatedPackageDeals,
  };
}

function createOrUpdateSparePartReferenceOperationForPackageDeals(
  packageDeals: readonly PackageDeal[],
  markOperationAsFinished: boolean,
  userLogin: string | null,
  siteConfiguration: SiteConfiguration,
  sequence: Sequence
): readonly PackageDeal[] {
  const expertisePackageDeal = packageDealHelpers.getExpertisePackageDeal(packageDeals);
  return packageDeals.map((packageDeal) => {
    if (expertisePackageDeal.id === packageDeal.id) {
      if (packageDealHelpers.hasSparePartsReferenceOperation(packageDeal, true)) {
        return packageDealHelpers.updateSparePartReferenceOperation(
          packageDeal,
          markOperationAsFinished,
          userLogin
        );
      }
      return packageDealHelpers.addSparePartReferenceOperation(
        packageDeal,
        userLogin,
        markOperationAsFinished,
        siteConfiguration,
        sequence
      );
    }
    return packageDeal;
  });
}

function computeSparePartReferenceOperationState(
  kanban: Kanban,
  userLogin: string | null,
  siteConfiguration: SiteConfiguration,
  sequence: Sequence
): Kanban {
  const markOperationAsFinished = !packageDealHelpers.hasSparePartsReferencingToDo(
    kanban.packageDeals,
    true
  );
  return createOrUpdateSparePartReferenceOperation(
    kanban,
    markOperationAsFinished,
    userLogin,
    siteConfiguration,
    sequence
  );
}

export const kanbanHelpers = {
  createKanbanComment,
  createIssueComment,
  hasBeenHandledOnPost,
  hasAUnfinishedDeliveryOperation,
  hasBeenHandledOnStand,
  isHandledOnPost,
  getCurrentLocation,
  getCurrentLocationElement,
  getChronologicallySortedFinishedHandles,
  isKanbanCurrentlyOnOneOfWorkshopPosts,
  closeCurrentHandling,
  pauseCurrentHandling,
  openNewHandlingWithInterval,
  getOpenOperatorHandleForPostStartingWith,
  unpauseKanbanHandling,
  completeDeliveryOperation,
  getAllSpareParts,
  isKanbanCurrentlyInImplantation,
  getKanbansCurrentlyInImplantation,
  getKanbanCurrentlyOnWorkshopQualifiedPost,
  getKanbanAgeing,
  closeAndonAndOpenNewWorkInterval,
  openAndonInterval,
  isKanbanCurrentlyOnWorkshopPost,
  getKanbanCurrentlyOnWorkshopPost,
  isCurrentlyHandled,
  closeAllHandlingStartingWith,
  hasOpenAndon,
  applyStatusOnAllPackageDeals,
  setAllPackageDealsAvailabilityTo,
  addNewPackageDeals,
  deletePackageDeal,
  hasBeenCreatedAutomatically,
  getOpenOperatorHandleForPost,
  getPostLabelForHandling,
  getOpenOperatorHandles,
  getKanbanCurrentPositionInPostImplantation,
  togglePackageDealAvailability,
  convertToSummary,
  closeAllHandlings,
  computePriorityLevel,
  isKanbanFinished,
  getAllSparePartsOnStand,
  openNewHandlingWithoutInterval,
  isKanbanFrozen,
  getCustomerLabel,
  getKanbanPriorityLevelForKanbansDueDate,
  getKanbanPriorityLevelRegardlessPastDueDate,
  validateKanbanDelivery,
  getComputeIconFromKanbanFunction,
  getDifferenceInDay,
  closeAnomalyAndOpenNewWorkInterval,
  putExpertisePriceTo0,
  validateKanbanExpertise,
  finishSparePartsReferenceOperation,
  computeSelectedWorkRevenue,
  assignOperationToWorkshopCategory,
  hasAchievablePackageDeal,
  openAnomalyInterval,
  getMostRestrictiveDueDate,
  getOldestOrderedAndNotReceivedSparePartAge,
  getOrderedAndReceivedSpareParts,
  isKanbanRevoked,
  getAllDocumentsStatus,
  getAllNotReceivedSpareParts,
  getAllNotReceivedSparePartsForPkgsDeals,
  getDecorators,
  getRelatedOperationForGivenMessageRequestId,
  getAllKanbansWithWorkOnStand,
  computeMarketplaceStatus,
  getUpdatedLogisticInfos,
  hasLocationChanged,
  configureAdminPackageDeal,
  getKanbanArchiveLink,
  isMarketplaceKanban,
  hasValidMarketplacePurchaseOrders,
  getKanbanTitle,
  getNonAllocatedPackageDeals,
  getPackageDealsAllocatedToPurchaseOrderId,
  getPackageDealsAllocatedToPurchaseNumber,
  getMarketplacePurchaseOrder,
  getMarketplaceMandate,
  createOrUpdateSparePartReferenceOperation,
  createOrUpdateSparePartReferenceOperationForPackageDeals,
  computeSparePartReferenceOperationState,
};
