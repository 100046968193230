/* eslint-disable jsx-a11y/control-has-associated-label */
import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import i18next from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { AttributeType, ContractAttributeDesc } from '@stimcar/libs-base';
import type { NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import { sortingHelpers } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  ClickableIcon,
  FaIcon,
  InputFormField,
  ModalCardDialog,
  ScrollableTableComponent,
  TableActionHeaderCell,
  TableSortableHeaderComponent,
  TruncableTableTd,
} from '@stimcar/libs-uitoolkit';
import type {
  AttributeDetailsInternalDataStructure,
  MobileDetailsSubPartState,
} from '../../app/details/typings/store.js';
import type { Store } from '../../app/state/typings/store.js';
import { kanbanToDetailsInternalAttributeStructure } from '../../app/details/kanbanDetailsUtils.js';
import { MobileDetailsModalMessageDialog } from '../../app/details/MobileDetailsModalMessageDialog.js';
import type { DisplayAttributesState, KanbanAttributeUpdateModalState } from './typings/store.js';
import { DisplayContentOrPlaceholder } from './misc/DisplayContentOrPlaceholder.js';
import { TruncableLi } from './misc/TruncableLi.js';
import { ShowHideBoxContainer } from './ShowHideContainer.js';
import { KANBAN_UPDATE_ATTRIBUTE_MODAL_EMPTY_STATE } from './typings/store.js';

export function convertToAttributeExpectedType(
  attributes: Record<string, AttributeType>,
  attributeKey: string,
  attributeValue: string
): AttributeType {
  const actualAttributeValue = attributes[attributeKey];
  const isBoolean = typeof actualAttributeValue === 'boolean';
  const isNumber = typeof actualAttributeValue === 'number';
  let newAttributeValue: AttributeType;
  if (isBoolean) {
    newAttributeValue = attributeValue === 'true';
  } else if (isNumber) {
    if (!/^[0-9]+$/.exec(attributeValue)) {
      throw Error(i18next.t('details:invalidNumericFormat', { value: attributeValue }));
    }
    newAttributeValue = Number.parseInt(attributeValue, 10);
  } else {
    newAttributeValue = attributeValue;
  }
  return newAttributeValue;
}

interface ConvertUrlsToLinksProps {
  readonly jsxElementKeyPrefix: string;
  readonly text: string;
}

// Could be a reusable component (but for instance keep this in the current
// component as it is only used here)
export function ConvertUrlsToLinks({
  text,
  jsxElementKeyPrefix,
}: ConvertUrlsToLinksProps): JSX.Element {
  let count = 0;
  const newJsxElementKey = (): string => {
    count += 1;
    return `${jsxElementKeyPrefix}-${count}`;
  };
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const html: JSX.Element[] = [];
  let parsed: RegExpExecArray | null = null;
  let lastIndex = 0;
  // eslint-disable-next-line no-cond-assign
  while ((parsed = urlRegex.exec(text)) !== null) {
    if (lastIndex < parsed.index) {
      html.push(<span key={newJsxElementKey()}>{text.substring(lastIndex, parsed.index)}</span>);
    }
    lastIndex = urlRegex.lastIndex;
    let link = parsed[0];
    let sep: string | undefined;
    const endsWithSeparator = link.endsWith(',') || link.endsWith(';');
    if (endsWithSeparator) {
      sep = link.substr(link.length - 1);
      link = link.substr(0, link.length - 1);
    }
    html.push(
      <a key={newJsxElementKey()} href={link} target="_blank" rel="noopener noreferrer">
        {link}
      </a>
    );
    if (endsWithSeparator) {
      html.push(<span key={newJsxElementKey()}>{sep}</span>);
    }
  }
  if (lastIndex < text.length) {
    html.push(<span key={newJsxElementKey()}>{text.substring(lastIndex, text.length)}</span>);
  }
  return <>{html}</>;
}

interface Props {
  readonly submitKanbanAttributeModificationCallback?: NoArgActionCallback<Store>;
  readonly isEditable: boolean;
  readonly $: StoreStateSelector<Store, DisplayAttributesState>;
  readonly showIsDisplayedInEstimate?: boolean;
  readonly showIsMandatory?: boolean;
  readonly scrollableProps?: {
    readonly sizeInPx?: number;
  };
}

export function DisplayAttributesComponent({
  submitKanbanAttributeModificationCallback,
  isEditable,
  $,
  showIsDisplayedInEstimate = false,
  showIsMandatory = false,
  scrollableProps,
}: Props): JSX.Element {
  const [t] = useTranslation('libComponents');

  const { $kanbanAttributeUpdateModalState } = $;

  const attributes = useGetState($.$attributes);
  const contractAttributeDescs = useGetState($.$contractAttributeDescs);

  const displayedAttributes = useMemo(() => {
    return kanbanToDetailsInternalAttributeStructure(attributes, contractAttributeDescs);
  }, [attributes, contractAttributeDescs]);

  const submitKanbanAttributeModificationAction = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      actionDispatch.setValue(false);
      if (submitKanbanAttributeModificationCallback) {
        await actionDispatch.execCallback(submitKanbanAttributeModificationCallback);
      }
    },
    [submitKanbanAttributeModificationCallback],
    $kanbanAttributeUpdateModalState.$isActive
  );

  const attributeUpdateModalState = useGetState($kanbanAttributeUpdateModalState);

  return (
    <DisplayContentOrPlaceholder
      displayCondition={displayedAttributes.length > 0}
      placeholder={t('kanbanAttributes.emptyPlaceholder')}
      isScrollable={isTruthy(scrollableProps)}
      scrollableProps={scrollableProps}
    >
      <>
        {isTruthy(scrollableProps) ? (
          <ScrollableTableComponent
            height={scrollableProps.sizeInPx ? `${scrollableProps.sizeInPx}PX` : undefined}
            tableClassName="table is-striped is-narrow is-hoverable is-fullwidth"
          >
            <DisplayAttributesTableContent
              displayedAttributes={displayedAttributes}
              isEditable={isEditable}
              $={$}
              showIsDisplayedInEstimate={showIsDisplayedInEstimate}
              showIsMandatory={showIsMandatory}
              submitKanbanAttributeModificationCallback={submitKanbanAttributeModificationCallback}
            />
          </ScrollableTableComponent>
        ) : (
          <table className="table is-striped is-narrow is-hoverable is-fullwidth">
            <DisplayAttributesTableContent
              displayedAttributes={displayedAttributes}
              isEditable={isEditable}
              $={$}
              showIsDisplayedInEstimate={showIsDisplayedInEstimate}
              showIsMandatory={showIsMandatory}
              submitKanbanAttributeModificationCallback={submitKanbanAttributeModificationCallback}
            />
          </table>
        )}

        <ModalCardDialog
          title={t('kanbanAttributes.updateModalTitle')}
          $active={$kanbanAttributeUpdateModalState.$isActive}
          okLabel={t('kanbanAttributes.saveButtonLabel')}
          onOkClicked={submitKanbanAttributeModificationAction}
        >
          <InputFormField
            label={attributeUpdateModalState.attributeKey}
            $={$kanbanAttributeUpdateModalState.$attributeValue}
            placeholder={
              attributeUpdateModalState.attributeValue !== ''
                ? attributeUpdateModalState.attributeValue
                : ' '
            }
          />
        </ModalCardDialog>
      </>
    </DisplayContentOrPlaceholder>
  );
}

interface EditAttributeButtonProps {
  readonly isEditable: boolean;
  readonly attribute: AttributeDetailsInternalDataStructure;
  readonly $: StoreStateSelector<Store, DisplayAttributesState>;
}

function EditAttributeButton({ isEditable, attribute, $ }: EditAttributeButtonProps): JSX.Element {
  const openEditAttributeDialog = useActionCallback(
    ({ actionDispatch }): void => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      actionDispatch.reduce((initial: KanbanAttributeUpdateModalState) => {
        return {
          ...initial,
          ...KANBAN_UPDATE_ATTRIBUTE_MODAL_EMPTY_STATE,
          isActive: true,
          attributeValue: attribute.value,
          attributeKey: attribute.label,
        };
      });
    },
    [attribute.label, attribute.value],
    $.$kanbanAttributeUpdateModalState
  );
  return (
    <ClickableIcon clickHandler={openEditAttributeDialog} id="edit" isDisabled={!isEditable} />
  );
}

interface DisplayAttributesTableContentProps {
  readonly submitKanbanAttributeModificationCallback?: () => Promise<void> | void;
  readonly isEditable: boolean;
  readonly $: StoreStateSelector<Store, DisplayAttributesState>;
  readonly showIsDisplayedInEstimate: boolean;
  readonly showIsMandatory: boolean;
  readonly displayedAttributes: readonly AttributeDetailsInternalDataStructure[];
}

function DisplayAttributesTableContent({
  submitKanbanAttributeModificationCallback,
  isEditable,
  $,
  showIsDisplayedInEstimate,
  showIsMandatory,
  displayedAttributes,
}: DisplayAttributesTableContentProps): JSX.Element {
  const [t] = useTranslation('libComponents');

  const sortBy = useGetState($.$sort.$by);
  const sortDirection = useGetState($.$sort.$direction);

  const sortedAttributes = useMemo(() => {
    let sortFunction: (
      c1: AttributeDetailsInternalDataStructure,
      c2: AttributeDetailsInternalDataStructure
    ) => number;
    switch (sortBy) {
      case 'label':
        sortFunction =
          sortingHelpers.createSortByStringField<AttributeDetailsInternalDataStructure>(
            sortDirection,
            'label'
          );
        break;
      case 'content':
        sortFunction =
          sortingHelpers.createSortByStringField<AttributeDetailsInternalDataStructure>(
            sortDirection,
            'value'
          );
        break;
      case 'isDisplayedEstimate':
        sortFunction =
          sortingHelpers.createSortByBooleanField<AttributeDetailsInternalDataStructure>(
            sortDirection,
            'isDisplayedEstimate'
          );
        break;
      case 'isMandatory':
        sortFunction =
          sortingHelpers.createSortByBooleanField<AttributeDetailsInternalDataStructure>(
            sortDirection,
            'isMandatory'
          );
        break;
      default:
        return displayedAttributes;
    }
    return displayedAttributes.slice().sort(sortFunction);
  }, [displayedAttributes, sortBy, sortDirection]);

  return (
    <>
      <thead>
        <tr>
          <TableSortableHeaderComponent
            content={t('kanbanAttributes.label')}
            isTruncable
            centerLabel={false}
            sortedField="label"
            $sort={$.$sort}
            style={{ width: '15%' }}
          />
          <TableSortableHeaderComponent
            content={t('kanbanAttributes.content')}
            isTruncable
            sortedField="content"
            $sort={$.$sort}
            centerLabel={false}
          />
          {showIsDisplayedInEstimate && (
            <TableSortableHeaderComponent
              content={t('kanbanAttributes.isDisplayedEstimate')}
              isTruncable
              centerLabel={false}
              sortedField="isDisplayedEstimate"
              $sort={$.$sort}
              className="is-narrow"
            />
          )}
          {showIsMandatory && (
            <TableSortableHeaderComponent
              content={t('kanbanAttributes.isMandatory')}
              isTruncable
              centerLabel={false}
              sortedField="isMandatory"
              $sort={$.$sort}
              className="is-narrow"
            />
          )}
          {isTruthy(submitKanbanAttributeModificationCallback) && isEditable && (
            <TableActionHeaderCell />
          )}
        </tr>
      </thead>
      <tbody>
        {sortedAttributes.map((attribute, attributeIndex): JSX.Element => {
          return (
            <tr key={attribute.label}>
              <TruncableTableTd>{attribute.label}</TruncableTableTd>
              <TruncableTableTd>
                {typeof attribute.value === 'string' ? (
                  <>
                    <ConvertUrlsToLinks
                      jsxElementKeyPrefix={`attr-${attributeIndex}`}
                      text={attribute.value}
                    />
                  </>
                ) : (
                  <>
                    {typeof attribute.value === 'boolean' && (
                      <FaIcon id={attribute.value ? 'check-square' : 'square'} />
                    )}
                    {typeof attribute.value !== 'boolean' && String(attribute.value)}
                  </>
                )}
              </TruncableTableTd>
              {showIsDisplayedInEstimate && (
                <TruncableTableTd className="has-text-centered">
                  {attribute.isDisplayedEstimate ? (
                    <FaIcon id="check" tooltip={t('kanbanAttributes.isDisplayedEstimateTooltip')} />
                  ) : (
                    <> </>
                  )}
                </TruncableTableTd>
              )}
              {showIsMandatory && (
                <TruncableTableTd className="has-text-centered">
                  {attribute.isMandatory ? (
                    <FaIcon id="check" tooltip={t('kanbanAttributes.isMandatoryTooltip')} />
                  ) : (
                    <> </>
                  )}
                </TruncableTableTd>
              )}
              {isTruthy(submitKanbanAttributeModificationCallback) && isEditable && (
                <td>
                  <EditAttributeButton $={$} attribute={attribute} isEditable={isEditable} />
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </>
  );
}

interface MobileAttributesComponentProps {
  readonly $: StoreStateSelector<
    Store,
    MobileDetailsSubPartState<AttributeDetailsInternalDataStructure>
  >;
  readonly attributes: Record<string, AttributeType>;
  readonly contractAttributeDescs: readonly ContractAttributeDesc[];
}

const computeAttributeValue = (
  t: TFunction,
  theAttribute: AttributeDetailsInternalDataStructure | undefined
): string => {
  const value = theAttribute?.value ?? '';
  return isTruthyAndNotEmpty(value) ? value : t('tabs.unknown');
};

export function MobileAttributesComponent({
  $,
  attributes,
  contractAttributeDescs,
}: MobileAttributesComponentProps): JSX.Element {
  const [t] = useTranslation('details');

  const attributesInternalDatas = useMemo(() => {
    const data = kanbanToDetailsInternalAttributeStructure(attributes, contractAttributeDescs);
    return data.sort(sortingHelpers.createSortByStringField('UP', 'label'));
  }, [attributes, contractAttributeDescs]);

  const details = useGetState($.$showDetailsFor);

  return (
    <>
      <ShowHideBoxContainer title={t('tabs.kanbanAttributes.title')} $={$.$isUnfolded} isMobile>
        <DisplayContentOrPlaceholder
          displayCondition={attributesInternalDatas.length > 0}
          placeholder={t('tabs.kanbanAttributes.emptyPlaceholder')}
        >
          <ul>
            {attributesInternalDatas.map(
              (attribute): JSX.Element => (
                <MobileAttributeLine attribute={attribute} key={attribute.label} $={$} />
              )
            )}
          </ul>
        </DisplayContentOrPlaceholder>
      </ShowHideBoxContainer>
      <MobileDetailsModalMessageDialog $={$}>
        <>
          <p>
            <strong>{`${t('tabs.kanbanAttributes.label')}: `}</strong>
            {details?.label}
          </p>
          <p>
            <strong>{`${t('tabs.kanbanAttributes.content')}: `}</strong>
            {computeAttributeValue(t, details)}
          </p>
          {details?.isMandatory && (
            <p>
              <strong>{t('tabs.kanbanAttributes.mandatory')}</strong>
            </p>
          )}
          {details?.isDisplayedEstimate && (
            <p>
              <strong>{t('tabs.kanbanAttributes.displayedInEstimate')}</strong>
            </p>
          )}
        </>
      </MobileDetailsModalMessageDialog>
    </>
  );
}

interface MobileAttributeLineProps {
  readonly attribute: AttributeDetailsInternalDataStructure;
  readonly $: StoreStateSelector<
    Store,
    MobileDetailsSubPartState<AttributeDetailsInternalDataStructure>
  >;
}

function MobileAttributeLine({ attribute, $ }: MobileAttributeLineProps): JSX.Element {
  const [t] = useTranslation('details');
  const actionCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setProperty('showDetailsFor', attribute);
    },
    [attribute],
    $
  );

  return (
    <TruncableLi
      key={attribute.label}
      actionToolbox={{ action: actionCallback, className: 'is-rounded is-small is-text' }}
    >
      <span>
        <strong>{`${attribute.label}: `}</strong>
        {computeAttributeValue(t, attribute)}
      </span>
    </TruncableLi>
  );
}
