import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { KanbanRepository } from '@stimcar/core-libs-repository';
import type {
  MarketplacePayment,
  MarketplacePaymentClient,
  MarketplacePaymentEntity,
  MarketplacePaymentType,
} from '@stimcar/libs-kernel';
import type {
  ActionContext,
  NoArgActionCallback,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type { FormFieldEntry } from '@stimcar/libs-uitoolkit';
import {
  isTruthy,
  MKTP_PAYMENT_CLIENT_BUYER,
  MKTP_PAYMENT_CLIENT_SELLER,
  MKTP_PAYMENT_CLIENT_UNKNOWN,
  MKTP_PAYMENT_TYPE_BANK_TRANSFER,
  MKTP_PAYMENT_TYPE_CASH_SENTINEL,
  MKTP_PAYMENT_TYPE_CHEQUE,
  MKTP_PAYMENT_TYPE_PAYPAL,
  MKTP_PAYMENT_TYPE_STRIPE,
  MKTP_PAYMENT_TYPE_UNKNOWN,
} from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  EMPTY_FORM_WITH_VALIDATION_STATE,
  InputFormField,
  ModalCardDialog,
  SelectFormField,
  TextAreaFormField,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { PaymentAddEditModalState } from '../typings/store.js';
import { CalendarFormField } from '../../../../lib/bulma/form/CalendarFormField.js';
import i18n from '../../../i18n.js';

const MANDATORY_FIELDS: readonly (keyof MarketplacePayment)[] = [
  'client',
  'paymentRequestDate',
  'paymentType',
  'priceWithVAT',
];

function getClientEntries(): FormFieldEntry<MarketplacePaymentClient>[] {
  return [
    {
      label: i18n.t('details:tabs.marketplace.overview.paymentHistory.values.unknown'),
      id: MKTP_PAYMENT_CLIENT_UNKNOWN,
    },
    {
      label: i18n.t('details:tabs.marketplace.overview.paymentHistory.values.client.buyer'),
      id: MKTP_PAYMENT_CLIENT_BUYER,
    },
    {
      label: i18n.t('details:tabs.marketplace.overview.paymentHistory.values.client.seller'),
      id: MKTP_PAYMENT_CLIENT_SELLER,
    },
  ];
}

function getPaymentTypeEntries(): FormFieldEntry<MarketplacePaymentType>[] {
  return [
    {
      label: i18n.t('details:tabs.marketplace.overview.paymentHistory.values.unknown'),
      id: MKTP_PAYMENT_TYPE_UNKNOWN,
    },
    {
      label: i18n.t(
        'details:tabs.marketplace.overview.paymentHistory.values.paymentType.bankTransfer'
      ),
      id: MKTP_PAYMENT_TYPE_BANK_TRANSFER,
    },
    {
      label: i18n.t(
        'details:tabs.marketplace.overview.paymentHistory.values.paymentType.cashSentinel'
      ),
      id: MKTP_PAYMENT_TYPE_CASH_SENTINEL,
    },
    {
      label: i18n.t('details:tabs.marketplace.overview.paymentHistory.values.paymentType.cheque'),
      id: MKTP_PAYMENT_TYPE_CHEQUE,
    },
    {
      label: i18n.t('details:tabs.marketplace.overview.paymentHistory.values.paymentType.paypal'),
      id: MKTP_PAYMENT_TYPE_PAYPAL,
    },
    {
      label: i18n.t('details:tabs.marketplace.overview.paymentHistory.values.paymentType.stripe'),
      id: MKTP_PAYMENT_TYPE_STRIPE,
    },
  ];
}

export function openPaymentAddModalAction({
  actionDispatch,
}: ActionContext<Store, PaymentAddEditModalState>): void {
  actionDispatch.setValue({
    ...EMPTY_FORM_WITH_VALIDATION_STATE,
    isActive: true,
    paymentId: '',
    formData: {
      client: '',
      paymentRequestDate: Date.now(),
      paymentCompletionDate: Date.now(),
      paymentType: '',
      priceWithVAT: '',
      description: '',
      warnings: {},
    },
  });
}

async function getMarketplacePaymentInfo(
  kanbanRepository: KanbanRepository,
  kanbanId: string,
  paymentId: string
): Promise<MarketplacePaymentEntity> {
  const kanban = await kanbanRepository.getEntity(kanbanId);
  const correspondingPayment = kanban?.marketplaceInfos?.paymentHistory?.find(
    ({ id }) => id === paymentId
  );
  if (!isTruthy(correspondingPayment)) {
    throw new Error(
      `Can not retrieve corresponding marketplace payment: ${paymentId} for kanbanId: ${kanban.id}`
    );
  }
  return correspondingPayment;
}

export async function openPaymentEditModalAction(
  { actionDispatch, kanbanRepository }: ActionContext<Store, PaymentAddEditModalState>,
  kanbanId: string,
  paymentId: string
): Promise<void> {
  const {
    client,
    paymentRequestDate,
    paymentCompletionDate,
    paymentType,
    priceWithVAT,
    description,
  } = await getMarketplacePaymentInfo(kanbanRepository, kanbanId, paymentId);
  actionDispatch.setValue({
    ...EMPTY_FORM_WITH_VALIDATION_STATE,
    isActive: true,
    paymentId,
    formData: {
      client,
      paymentRequestDate,
      paymentCompletionDate,
      paymentType,
      priceWithVAT,
      description,
      warnings: {},
    },
  });
}

type PaymentAddEditModalProps = {
  readonly $: StoreStateSelector<Store, PaymentAddEditModalState>;
  readonly title: string;
  readonly onFormSubmitCallback: NoArgActionCallback<Store>;
};

export function PaymentAddEditModal({
  $,
  title,
  onFormSubmitCallback,
}: PaymentAddEditModalProps): JSX.Element {
  const [t] = useTranslation('details');
  const formWarning = useGetState($.$formWarning);

  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.execCallback(onFormSubmitCallback);
    },
    [onFormSubmitCallback],
    $
  );

  const [onFormSubmit, , $formWithChangeTrigger] = useFormWithValidation({
    $,
    mandatoryFields: MANDATORY_FIELDS,
    submitValidDataAction,
    t,
  });

  return (
    <ModalCardDialog
      title={title}
      $active={$.$isActive}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <SelectFormField
        $={$formWithChangeTrigger.$client}
        label={t('tabs.marketplace.overview.paymentHistory.modal.clientField')}
        entries={getClientEntries()}
        horizontal
      />
      <CalendarFormField
        $={$formWithChangeTrigger.$paymentRequestDate}
        label={t('tabs.marketplace.overview.paymentHistory.modal.paymentRequestDateField')}
        horizontal
      />
      <CalendarFormField
        $={$formWithChangeTrigger.$paymentCompletionDate}
        label={t('tabs.marketplace.overview.paymentHistory.modal.paymentCompletionDateField')}
        horizontal
      />
      <SelectFormField
        $={$formWithChangeTrigger.$paymentType}
        label={t('tabs.marketplace.overview.paymentHistory.modal.paymentTypeField')}
        entries={getPaymentTypeEntries()}
        horizontal
      />
      <InputFormField
        $={$formWithChangeTrigger.$priceWithVAT}
        type="number"
        label={t('tabs.marketplace.overview.paymentHistory.modal.priceWithVATField')}
        horizontal
      />
      <TextAreaFormField
        $={$formWithChangeTrigger.$description}
        label={t('tabs.marketplace.overview.paymentHistory.modal.descriptionField')}
      />
    </ModalCardDialog>
  );
}
