import type {
  MainSidesStandardPictureKey,
  StandardPictureInfo,
  StandardPicturesConfiguration,
} from '../../typings/standardPictures.js';
import { MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID } from '../../typings/marketplace.js';
import { MARKETPLACE_STANDARD_PICTURES_CONFIGURATION_KEY } from '../../typings/standardPictures.js';
import {
  getStandardPictureInfoById,
  toStandardPictureConfiguration,
} from '../standardPicturesConfigurationHelpers.js';

const picturesIdsByCategory: Record<string, readonly string[]> = {
  external: [
    'frontLeftThreeQuarters',
    'front',
    'frontRightThreeQuarters',
    'rightSide',
    'rearRightThreeQuarters',
    'rear',
    'rearLeftThreeQuarters',
    'leftSide',
    'trunkInterior',
    'engineCompartment',
  ],
  internal: [
    'frontRightDoorInternal',
    'rearSeat',
    'completeDashboard',
    'steeringWheelAndMeter',
    'gearbox',
    'centralDashboard',
    'controlScreenGps',
  ],
};

export const MARKETPLACE_STANDARD_PICTURES_CATEGORY_EXTERNAL = 'external';

export const MARKETPLACE_STANDARD_PICTURES_CONFIGURATION: StandardPicturesConfiguration =
  toStandardPictureConfiguration(
    MARKETPLACE_STANDARD_PICTURES_CONFIGURATION_KEY,
    picturesIdsByCategory
  );

export const MARKETPLACE_MAIN_SIDES_STANDARD_PICTURE_INFOS: Record<
  MainSidesStandardPictureKey,
  StandardPictureInfo
> = {
  LEFT_SIDE: getStandardPictureInfoById(
    MARKETPLACE_STANDARD_PICTURES_CONFIGURATION.external,
    'leftSide'
  ),
  FRONT: getStandardPictureInfoById(MARKETPLACE_STANDARD_PICTURES_CONFIGURATION.external, 'front'),
  RIGHT_SIDE: getStandardPictureInfoById(
    MARKETPLACE_STANDARD_PICTURES_CONFIGURATION.external,
    'rightSide'
  ),
  REAR: getStandardPictureInfoById(MARKETPLACE_STANDARD_PICTURES_CONFIGURATION.external, 'rear'),
};

export const MARKETPLACE_TARGET_FOLDER_FOR_STANDARD_PICTURES =
  MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID;
