import type { Customer } from '@stimcar/libs-kernel';
import type { SortState } from '@stimcar/libs-uitoolkit';
import type { EditCustomerDialogState } from '../../../../lib/components/customer/typings/store.js';
import type { ComponentWithMountTracking } from '../../../../lib/hooks/useComponentWithMountTracking.js';
import { EMPTY_EDIT_CUSTOMER_DIALOG_STATE } from '../../../../lib/components/customer/typings/store.js';

export type AdminEditCustomerDialogState = EditCustomerDialogState & {
  readonly customerId: string;
};

export const EMPTY_ADMIN_EDIT_CUSTOMER_DIALOG_STATE: AdminEditCustomerDialogState = {
  ...EMPTY_EDIT_CUSTOMER_DIALOG_STATE,
  customerId: '',
};

export type DeleteCustomerModalState = {
  readonly active: boolean;
  readonly customerId: string;
};

export const EMPTY_DELETE_CUSTOMER_MODEL_STATE: DeleteCustomerModalState = {
  active: false,
  customerId: '',
};

export type CustomersSortValues =
  | 'contract'
  | 'shortName'
  | 'company'
  | 'civility'
  | 'firstName'
  | 'lastName'
  | 'zipCode'
  | 'city'
  | 'timestamp';

export type AdminCustomersState = ComponentWithMountTracking & {
  readonly editCustomerDialog: AdminEditCustomerDialogState;
  readonly deleteCustomerDialog: DeleteCustomerModalState;
  readonly searchFilter: string;
  readonly filteredCustomerIds: readonly string[];
  readonly customers: readonly Customer[];
  readonly sort: SortState<CustomersSortValues>;
};

export const EMPTY_ADMIN_CUSTOMERS_STATE: AdminCustomersState = {
  componentIsMounted: false,
  editCustomerDialog: EMPTY_ADMIN_EDIT_CUSTOMER_DIALOG_STATE,
  deleteCustomerDialog: EMPTY_DELETE_CUSTOMER_MODEL_STATE,
  searchFilter: '',
  filteredCustomerIds: [],
  customers: [],
  sort: {
    by: 'lastName',
    direction: 'DOWN',
  },
};
