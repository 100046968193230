import type { JSX } from 'react';
import React from 'react';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { HorizontalFormFieldProps } from '@stimcar/libs-uitoolkit';
import { nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { loadFile } from '@stimcar/libs-uitoolkit';
import { CustomContentFormField } from './CustomContentFormField.js';

export type InputFileFormFieldProps<SD extends AnyStoreDef> = {
  readonly $: StoreStateSelector<SD, File | null>;
  readonly label: string;
  readonly buttonLabel: string;
  readonly noSelectedFileLabel?: string;
  readonly horizontal?: boolean | HorizontalFormFieldProps;
  readonly disabled?: boolean;
};

export function InputFileFormField<SD extends AnyStoreDef>({
  $,
  label,
  buttonLabel,
  noSelectedFileLabel = '',
  horizontal,
  disabled,
}: InputFileFormFieldProps<SD>): JSX.Element {
  const file = useGetState($);

  const fileName = file?.name ?? noSelectedFileLabel;

  const onClickActionCallback = useActionCallback(
    async ({ actionDispatch }, event: React.ChangeEvent<HTMLInputElement>) => {
      const file = [...nonnull(nonnull(event.target).files)][0];
      await loadFile(file);
      actionDispatch.setValue(file);
    },
    [],
    $
  );

  return (
    <CustomContentFormField label={label} horizontal={horizontal}>
      <div className="file has-name">
        <label className="file-label">
          <input
            className="file-input"
            type="file"
            name="inputFile"
            onChange={onClickActionCallback}
            disabled={disabled !== undefined ? disabled : false}
          />
          <span className="file-cta">
            <span className="file-icon">
              <i className="fas fa-upload" />
            </span>
            <span className="file-label">{buttonLabel}</span>
          </span>
          <span className="file-name">{fileName}</span>
        </label>
      </div>
    </CustomContentFormField>
  );
}
