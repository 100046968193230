import { isTruthy } from '@stimcar/libs-kernel';
import {
  WORKSHOP_IMPLANTATION_ANOMALY_POST_CATEGORY_ID,
  WORKSHOP_POST_ID_SEPARATOR,
  WORKSHOP_STARTING_HOUR,
} from '../../model/index.js';
import { urlHelpers } from '../urlHelpers.js';

export const fullLetterDateFormatOptions: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const shortDateNumericFormatOptions: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  year: '2-digit',
  month: '2-digit',
  day: 'numeric',
};

export const shortDateFormatOptions: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  year: '2-digit',
  month: 'short',
  day: 'numeric',
};

export const shortDayMonthWithHourFormatOptions: Intl.DateTimeFormatOptions = {
  month: '2-digit',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

export const shortDayMonthYearHourFormatOptions: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};

export const longNumberDayMonthYearWithHourFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};

export const shortDayMonthYearDateFormatOptions: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
};

export const shortDayMonthFullYearDateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

export const shortDayMonthDateFormatOptions: Intl.DateTimeFormatOptions = {
  month: '2-digit',
  day: '2-digit',
};

export const hourMinutesTimeFormatOptions: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
};

export const STRING_DATE_SEPARATOR = '/';

export function isInteger(value: string): boolean {
  // We have to use a regex instead of Number.parseInt because parseInt will do some magic to be able to convert the given string
  // into a number, blocking us to know if the given string is a number or not.
  // For instance Number.parseInt('1234p', 10) return the number 1234
  return /^-?\d+$/.test(value);
}

export function isFloat(value: string): boolean {
  const floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
  const float = parseFloat(value);
  // eslint-disable-next-line no-restricted-globals
  if (!floatRegex.test(value) || isNaN(float)) {
    return false;
  }
  return true;
}

function isStringCorrectDateFormat(date: string): boolean {
  const parts = date.split('/');
  if (parts.length === 3 && isInteger(parts[0]) && isInteger(parts[1]) && isInteger(parts[2])) {
    const day = Number.parseInt(parts[0], 10);
    const month = Number.parseInt(parts[1], 10);
    if (day > 0 && day <= 31 && month > 0 && month <= 12 && parts[2].length === 4) {
      return true;
    }
  }
  return false;
}

const convertTimestampToDateString = (
  timestamp: number | null,
  format: Intl.DateTimeFormatOptions
): string => {
  if (timestamp === null) {
    return '';
  }
  const date = new Date(timestamp);
  return date.toLocaleDateString('fr-FR', format);
};

const getDDmmYYYYDateOrPlaceholderFromTimestamp = (
  timestamp: number | null,
  ncValue: string
): string => {
  if (timestamp === null || Number.isNaN(timestamp)) {
    return ncValue;
  }
  return new Date(timestamp).toLocaleDateString('fr-FR', shortDayMonthFullYearDateFormatOptions);
};

function computeWorkshopPostId(postCategory: string, postName: string): string {
  return `${postCategory}${WORKSHOP_POST_ID_SEPARATOR}${postName}`;
}

function computeQualifiedWorkshopPostId(
  implantationId: string,
  postCategory: string,
  postName?: string
): string {
  return (
    implantationId +
    WORKSHOP_POST_ID_SEPARATOR +
    (isTruthy(postName) ? computeWorkshopPostId(postCategory, postName) : postCategory)
  );
}

function formatNumericWithFixedSize(value: number, digitNumber = 2): string {
  return value.toFixed(digitNumber);
}

function isQualifiedWorkshopPostId(workshopPostIdCandidate: string): boolean {
  const parts = workshopPostIdCandidate.split(WORKSHOP_POST_ID_SEPARATOR);
  if (parts.length !== 3) {
    return false;
  }
  return parts.filter((part) => urlHelpers.containsInvalidURLCharacters(part)).length === 0;
}

function isQualifiedCategory(categoryCandidate: string): boolean {
  const parts = categoryCandidate.split(WORKSHOP_POST_ID_SEPARATOR);
  if (parts.length !== 2) {
    return false;
  }
  return parts.filter((part) => urlHelpers.containsInvalidURLCharacters(part)).length === 0;
}

function isQualifiedCategoryOrPostId(categoryOrPostIdCandidate: string): boolean {
  return (
    isQualifiedCategory(categoryOrPostIdCandidate) ||
    isQualifiedWorkshopPostId(categoryOrPostIdCandidate)
  );
}

function roundTo(value: number, numberOfDigits = 2): number {
  const factor = 10 ** numberOfDigits;
  return Math.round(value * factor) / factor;
}

function roundToUpper(value: number, step: number, maxNumberOfDigits = 2): number {
  const roundedValue = Math.ceil(value / step) * step;
  const factor = 10 ** maxNumberOfDigits;
  return Math.trunc(roundedValue * factor) / factor;
}

function isAnomalyPostQualifiedCategoryId(
  anomalyPostQualifiedCategoryIdCandidate: string
): boolean {
  const parts = anomalyPostQualifiedCategoryIdCandidate.split(WORKSHOP_POST_ID_SEPARATOR);
  if (parts.length !== 2) {
    return false;
  }
  if (parts.filter((part) => urlHelpers.containsInvalidURLCharacters(part)).length !== 0) {
    return false;
  }
  return parts[1] === WORKSHOP_IMPLANTATION_ANOMALY_POST_CATEGORY_ID;
}

function getWorkingDayForDate(referenceDate: Date): Date {
  const workingDay = new Date(referenceDate);
  // Set the time at noon
  workingDay.setHours(12, 0, 0, 0);

  // Check the current shift
  if (referenceDate.getHours() >= 0 && referenceDate.getHours() < WORKSHOP_STARTING_HOUR) {
    // The current working day started the previous day
    workingDay.setUTCDate(workingDay.getUTCDate() - 1);
  }

  return workingDay;
}

function getWorkingDayForTimestamp(timestamp: number): Date {
  return getWorkingDayForDate(new Date(timestamp));
}

function getCurrentWorkingDay(): Date {
  return getWorkingDayForDate(new Date());
}

function isAStimcarEmployeeEMail(signatoryEmail: string): boolean {
  return signatoryEmail.endsWith('@stimcar.center') || signatoryEmail.endsWith('@stimcar.fr');
}

function replaceNonAlphaNumChar(text: string, replaceBy = ''): string {
  return text.replace(/[\W_]+/g, replaceBy);
}

function nbOfDaysBetween(start: number, end: number): number {
  return (end - start) / 1000 / 60 / 60 / 24;
}

function computeShiftHours(startHour: number) {
  if (startHour < 0) {
    throw new Error(`Unexpected error: hours is négative! (${startHour})`);
  }
  return [startHour % 24, (startHour + 8) % 24, (startHour + 16) % 24].sort((h1, h2) => h1 - h2);
}

function computePreviousShiftStart(
  startHour: number,
  startMinute: number,
  date: Date = new Date()
): Date {
  const dateCursor = new Date();
  dateCursor.setTime(date.getTime());
  dateCursor.setSeconds(0);
  dateCursor.setMilliseconds(0);
  if (dateCursor.getMinutes() < startMinute) {
    dateCursor.setHours(dateCursor.getHours() - 1);
  }
  dateCursor.setMinutes(startMinute);
  const currentDateHours = dateCursor.getHours();
  const [shift1Start, shift2Start, shift3Start] = computeShiftHours(startHour);
  if (currentDateHours < 0) {
    throw Error(`Unexpected error: hours is négative! (${currentDateHours})`);
  } else if (currentDateHours < shift1Start) {
    dateCursor.setDate(dateCursor.getDate() - 1);
    dateCursor.setHours(shift3Start);
  } else if (currentDateHours < shift2Start) {
    dateCursor.setHours(shift1Start);
  } else if (currentDateHours < shift3Start) {
    dateCursor.setHours(shift2Start);
  } else if (currentDateHours >= 24) {
    throw Error(`Unexpected error: hours is greater than 24! (${currentDateHours})`);
  } else {
    dateCursor.setHours(shift3Start);
  }
  return dateCursor;
}

export const globalHelpers = {
  isAStimcarEmployeeEMail,
  isInteger,
  isStringCorrectDateFormat,
  roundTo,
  formatNumericWithFixedSize,
  roundToUpper,
  convertTimestampToDateString,
  computeQualifiedWorkshopPostId,
  computeWorkshopPostId,
  isQualifiedWorkshopPostId,
  getDDmmYYYYDateOrPlaceholderFromTimestamp,
  isQualifiedCategory,
  isAnomalyPostQualifiedCategoryId,
  getWorkingDayForDate,
  getWorkingDayForTimestamp,
  getCurrentWorkingDay,
  isQualifiedCategoryOrPostId,
  replaceNonAlphaNumChar,
  nbOfDaysBetween,
  computePreviousShiftStart,
  computeShiftHours,
};
