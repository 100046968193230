import type { InputType } from 'storybook/internal/types';
import type { FormFieldEntry } from '../bulma/form/typings/FormFieldEntry.js';

export const FA_ICON_SIZES = [
  'small',
  'standard',
  'standard-lg',
  'medium',
  'medium-lg',
  'medium-2x',
  'large',
  'large-lg',
  'large-2x',
  'large-3x',
];

export const STYLE_OPTIONS = [
  'is-primary',
  'is-link',
  'is-info',
  'is-success',
  'is-warning',
  'is-danger',
];

export const countries: FormFieldEntry<string>[] = [
  { id: 'FR', label: 'France' },
  { id: 'BE', label: 'Belgium' },
  { id: 'GB', label: 'United Kingdom' },
  { id: 'SP', label: 'Spain' },
  { id: 'DE', label: 'Germany' },
  { id: 'AU', label: 'Australia' },
  { id: 'BR', label: 'Brazil' },
  { id: 'IE', label: 'Ireland' },
  { id: 'DK', label: 'Denmark' },
];

export const CSS_CLASS_NAME_ARG_TYPE: InputType = {
  description: 'Additional class',
  control: 'select',
  options: STYLE_OPTIONS,
  defaultValue: undefined,
};

export const IS_FULL_WIDTH_ARG_TYPE: InputType = {
  control: 'boolean',
  defaultValue: false,
};

export const ON_CLICK_ARG_TYPE = {
  action: 'onClick',
};

export const FA_ICON_SIZES_ARG_TYPE: InputType = {
  control: {
    type: 'select',
    options: FA_ICON_SIZES,
  },
  defaultValue: undefined,
};
