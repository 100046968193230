import type { JSX } from 'react';
import i18n from 'i18next';
import React from 'react';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useArrayItemSelector, useGetState } from '@stimcar/libs-uikernel';
import { Button } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { MarketplacePaymentsState } from '../typings/store.js';
import { openPaymentEditModalAction } from './PaymentAddEditModal.js';
import { openPaymentDeleteModalAction } from './PaymentDeleteModal.js';

function getClientOptionLabel(input: string): string {
  if (isTruthyAndNotEmpty(input)) {
    return i18n.t(`details:tabs.marketplace.overview.paymentHistory.values.client.${input}`);
  }
  return '';
}

function getPaymentOptionLabel(input: string): string {
  if (isTruthyAndNotEmpty(input)) {
    return i18n.t(`details:tabs.marketplace.overview.paymentHistory.values.paymentType.${input}`);
  }
  return '';
}

function getPriceLabel(priceInput: string): string {
  return `${priceInput} €`;
}

function formatDate(inputDateAsNumber: number): string {
  if (isTruthy(inputDateAsNumber) && !Number.isNaN(inputDateAsNumber) && inputDateAsNumber !== 0) {
    const date = new Date(inputDateAsNumber);
    return date.toLocaleDateString('FR-fr');
  }
  return '';
}

export interface PaymentHistoryItemProps {
  readonly $: StoreStateSelector<Store, MarketplacePaymentsState>;
  readonly kanbanId: string;
  readonly paymentId: string;
  readonly isOnline: boolean;
}

export function PaymentHistoryItem({
  $,
  kanbanId,
  paymentId,
  isOnline,
}: PaymentHistoryItemProps): JSX.Element {
  const $payment = useArrayItemSelector($.$payments, paymentId);
  const client = useGetState($payment.$client);
  const paymentRequestDate = useGetState($payment.$paymentRequestDate);
  const paymentCompletionDate = useGetState($payment.$paymentCompletionDate);
  const paymentType = useGetState($payment.$paymentType);
  const priceWithVAT = useGetState($payment.$priceWithVAT);
  const description = useGetState($payment.$description);

  const openPaymentEditDialogCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(openPaymentEditModalAction, kanbanId, paymentId);
    },
    [kanbanId, paymentId],
    $.$paymentEditModalState
  );

  const openPaymentDeleteDialogCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(openPaymentDeleteModalAction, paymentId);
    },
    [paymentId],
    $.$paymentDeletionModalState
  );

  return (
    <tr>
      <td>{getClientOptionLabel(client)}</td>
      <td>{formatDate(paymentRequestDate)}</td>
      <td>{formatDate(paymentCompletionDate)}</td>
      <td>{getPaymentOptionLabel(paymentType)}</td>
      <td>{getPriceLabel(priceWithVAT)}</td>
      <td>{description}</td>
      <td>
        <Button
          additionalClass="is-transparent"
          size="small"
          iconId="edit"
          disabled={!isOnline}
          onClick={openPaymentEditDialogCallback}
        />
        <Button
          additionalClass="is-transparent"
          size="small"
          iconId="trash"
          disabled={!isOnline}
          onClick={openPaymentDeleteDialogCallback}
        />
      </td>
    </tr>
  );
}
