import type { JSX } from 'react';
import { t } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Attachment, AttachmentMetadata, PackageDeal } from '@stimcar/libs-base';
import type {
  DeepPartial,
  Defect,
  DefectHighlighter,
  RepositoryEntityStatus,
  StandardPictureId,
  StandardPicturesConfigurationKey,
} from '@stimcar/libs-kernel';
import type {
  ActionCallbackFromFunction,
  ActionContext,
  NoArgActionCallback,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type { AppProps, CheckFormConsistencyAction } from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes, nonDeleted } from '@stimcar/libs-base';
import {
  DEFECT_TYPES,
  getStandardPictureInfosForMainSides,
  isTruthy,
  isTruthyAndNotEmpty,
  MAIN_SIDES_STANDARD_PICTURE_KEYS,
  MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
  nonnull,
} from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import {
  ModalCardDialog,
  ReactSelectFormField,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { PictureInputState } from '../../../../../lib/components/typings/store.js';
import type { Store } from '../../../../state/typings/store.js';
import type { AddOrUpdateDefectDialogState } from '../../../typings/store.js';
import { ConfirmAttachmentRemovalDialog } from '../../../../../lib/components/attachments/ConfirmAttachmentRemovalDialog.js';
import { EMPTY_CONFIRM_ATTACHMENT_DIALOG_STATE } from '../../../../../lib/components/attachments/typings/store.js';
import { shouldAllowAttachmentDeletion } from '../../../../details/kanbanDetailsUtils.js';
import { appendRegisteredBrowserSessionToken } from '../../../../utils/security.js';
import { useComputeAttachmentUrl } from '../../../../utils/useComputeAttachmentUrl.js';
import {
  EMPTY_ADD_OR_UPDATE_DEFECT_DIALOG_STATE,
  EMPTY_ADD_OR_UPDATE_DEFECT_FORM,
  EMPTY_DEFECT_HIGHLIGHTER,
  EMPTY_POSITION_ON_CAR,
} from '../../../typings/store.js';
import { getDefectPictureName } from '../defectUtils.js';
import { PositionOnCarSelector } from '../PositionOnCarSelector.js';
import { AddPackageDealToDefectModal } from './AddPackageDealToDefectModal.js';
import { DefectPictureSelector } from './DefectPictureSelector.js';
import { DeleteRefurbishingOptionModal } from './DeleteRefurbishingOptionModal.js';
import { HighlightDefectModal } from './HighlightDefectModal.js';
import { PackageDealForDefectItem } from './PackageDealForDefectItem.js';
import { RefurbishingOptionItem } from './RefurbishingOptionItem.js';

function getMissingDefectPictureWarningMessage(): string {
  return t('operators:defects.addOrUpdateDefect.form.error.invalidPicture');
}

/**
 * Check if a picture is present
 * @returns undefined if there is no problem else a string containing the warning message
 */
function checkPresenceOfDefectPicture(pictureInputState: PictureInputState): string | undefined {
  // Check that a picture has been selected to show the defect
  const { id, isSet } = pictureInputState;
  const isPictureValid = isTruthyAndNotEmpty(id) && isSet;
  if (!isPictureValid) {
    // Add warning to form
    return getMissingDefectPictureWarningMessage();
  }
  return undefined;
}

const checkFormConsistencyAction: CheckFormConsistencyAction<
  Store,
  AddOrUpdateDefectDialogState
> = ({ formState, t }): string | undefined => {
  const { formData } = formState;
  const { positionOnCar } = formData;

  // Check that position on car is valid
  const { pictureId, x, y } = positionOnCar;
  const isPositionOnCarValid = isTruthyAndNotEmpty(pictureId) && isTruthy(x) && isTruthy(y);
  if (!isPositionOnCarValid) {
    return t('operators:defects.addOrUpdateDefect.form.error.invalidPosition');
  }

  return undefined;
};

export function openAddDefectDialogAction(
  { actionDispatch, httpClient }: ActionContext<Store, AddOrUpdateDefectDialogState>,
  selectedPictureId: string
): void {
  const newDefectId = httpClient.getBrowserSequence().next();
  actionDispatch.setValue({
    ...EMPTY_ADD_OR_UPDATE_DEFECT_DIALOG_STATE,
    active: true,
    defectId: newDefectId,
    updateMode: false,
    selectedPictureId,
    formData: {
      ...EMPTY_ADD_OR_UPDATE_DEFECT_FORM,
    },
    defectPicture: {
      id: getDefectPictureName(newDefectId),
      isSet: false,
    },
  });
}

export function openUpdateDefectDialogAction(
  { actionDispatch }: ActionContext<Store, AddOrUpdateDefectDialogState>,
  {
    id,
    type,
    positionOnCar,
    highlighter,
    fullyRefurbishingPackageDealIds,
    partiallyRefurbishingPackageDealIds,
  }: Defect
): void {
  actionDispatch.setValue({
    ...EMPTY_ADD_OR_UPDATE_DEFECT_DIALOG_STATE,
    active: true,
    defectId: id,
    updateMode: true,
    selectedPictureId: positionOnCar?.pictureId ?? '',
    fullyRefurbishingPackageDealIds,
    partiallyRefurbishingPackageDealIds,
    formData: {
      ...EMPTY_ADD_OR_UPDATE_DEFECT_FORM,
      type,
      positionOnCar: positionOnCar ?? EMPTY_POSITION_ON_CAR,
    },
    defectPicture: {
      id: getDefectPictureName(id),
      isSet: true,
    },
    defectHighlighter: highlighter,
  });
}

function addDefectToDefectsAction(
  { actionDispatch }: ActionContext<Store, readonly Defect[]>,
  newDefect: Defect
): void {
  actionDispatch.applyPayload([newDefect]);
}

function updateDefectIntoDefectsAction(
  { actionDispatch }: ActionContext<Store, readonly Defect[]>,
  updatedDefectPayload: DeepPartial<Defect>
): void {
  actionDispatch.applyPayload([updatedDefectPayload]);
}

function saveHighlighterAction(
  { actionDispatch }: ActionContext<Store, AddOrUpdateDefectDialogState>,
  highlighter: DefectHighlighter | null
): void {
  actionDispatch.setProperty('defectHighlighter', highlighter ?? EMPTY_DEFECT_HIGHLIGHTER);
}

async function submitFormAction(
  { actionDispatch, getState }: ActionContext<Store, AddOrUpdateDefectDialogState>,
  updateMode: boolean,
  addDefectToDefectsActionCallback: ActionCallbackFromFunction<Store, (newDefect: Defect) => void>,
  updateDefectIntoDefectsActionCallback: ActionCallbackFromFunction<
    Store,
    (updatedDefectPayload: DeepPartial<Defect>) => void
  >,
  setTabSelectedPictureIdActionCallback: NoArgActionCallback<Store>
): Promise<void> {
  const {
    defectId,
    fullyRefurbishingPackageDealIds,
    partiallyRefurbishingPackageDealIds,
    formData,
  } = getState();

  const { type, positionOnCar } = formData;
  const { defectPicture, defectHighlighter } = getState();
  const defectPictureWarning = checkPresenceOfDefectPicture(defectPicture);

  if (isTruthy(defectPictureWarning)) {
    // Set the warning in form and do nothing else
    actionDispatch.setProperty('formWarning', defectPictureWarning);
  } else {
    const newType = type !== '' ? type : DEFECT_TYPES[0];
    if (updateMode) {
      const defectPayload: DeepPartial<Defect> = {
        id: defectId,
        type: newType,
        picture: defectPicture.id,
        positionOnCar,
        highlighter: defectHighlighter,
        fullyRefurbishingPackageDealIds,
        partiallyRefurbishingPackageDealIds,
      };
      await actionDispatch.execCallback(updateDefectIntoDefectsActionCallback, defectPayload);
    } else {
      const defect: Defect = {
        id: defectId,
        type: newType,
        picture: defectPicture.id,
        positionOnCar,
        highlighter: defectHighlighter,
        fullyRefurbishingPackageDealIds,
        partiallyRefurbishingPackageDealIds,
      };
      await actionDispatch.execCallback(addDefectToDefectsActionCallback, defect);
    }
    await actionDispatch.execCallback(setTabSelectedPictureIdActionCallback);
    actionDispatch.setValue(EMPTY_ADD_OR_UPDATE_DEFECT_DIALOG_STATE);
  }
}

function getFirstMainSidePictureId(
  standardPicturesConfigurationKey: StandardPicturesConfigurationKey
): StandardPictureId {
  const firstMainSide = MAIN_SIDES_STANDARD_PICTURE_KEYS[0];
  const mainSidesStandardPicturesInfos = getStandardPictureInfosForMainSides(
    standardPicturesConfigurationKey
  );
  if (isTruthy(mainSidesStandardPicturesInfos)) {
    return mainSidesStandardPicturesInfos[firstMainSide].id;
  }
  return '';
}

interface AddOrUpdateDefectModalProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, AddOrUpdateDefectDialogState>;
  readonly $kanbanId: StoreStateSelector<Store, string>;
  readonly $kanbanStatus: StoreStateSelector<Store, RepositoryEntityStatus>;
  readonly $defects: StoreStateSelector<Store, readonly Defect[]>;
  readonly $packageDeals: StoreStateSelector<Store, readonly PackageDeal[]>;
  readonly $tabSelectedPictureId: StoreStateSelector<Store, string>;
  readonly standardPicturesConfigurationKey: StandardPicturesConfigurationKey;
}

export function AddOrUpdateDefectModal({
  $,
  $kanbanId,
  $kanbanStatus,
  $defects,
  $packageDeals,
  $tabSelectedPictureId,
  standardPicturesConfigurationKey,
  $gs,
}: AddOrUpdateDefectModalProps): JSX.Element {
  const [tGlobal] = useTranslation('');
  const [t] = useTranslation('operators', { keyPrefix: 'defects.addOrUpdateDefect' });
  const [tDefectsTypes] = useTranslation('operators', { keyPrefix: 'defects.types' });

  const updateMode = useGetState($.$updateMode);

  const {
    $selectedPictureId,
    $fullyRefurbishingPackageDealIds,
    $partiallyRefurbishingPackageDealIds,
  } = $;

  const selectedPictureIdOrEmpty = useGetState($selectedPictureId);

  const selectedPictureId = isTruthyAndNotEmpty(selectedPictureIdOrEmpty)
    ? selectedPictureIdOrEmpty
    : getFirstMainSidePictureId(standardPicturesConfigurationKey);

  const fullyRefurbishingPackageDealIds = useGetState($fullyRefurbishingPackageDealIds);
  const partiallyRefurbishingPackageDealIds = useGetState($partiallyRefurbishingPackageDealIds);

  const addDefectToDefectsActionCallback = useActionCallback(
    addDefectToDefectsAction,
    [],
    $defects
  );

  const updateDefectIntoDefectsActionCallback = useActionCallback(
    updateDefectIntoDefectsAction,
    [],
    $defects
  );

  const setTabSelectedPictureIdActionCallback = useActionCallback(
    ({ actionDispatch }) => actionDispatch.setValue(selectedPictureId),
    [selectedPictureId],
    $tabSelectedPictureId
  );

  const submitFormActionCallback = useActionCallback(
    async ({
      actionDispatch,
    }: ActionContext<Store, AddOrUpdateDefectDialogState>): Promise<void> => {
      await actionDispatch.exec(
        submitFormAction,
        updateMode,
        addDefectToDefectsActionCallback,
        updateDefectIntoDefectsActionCallback,
        setTabSelectedPictureIdActionCallback
      );
    },
    [
      addDefectToDefectsActionCallback,
      updateDefectIntoDefectsActionCallback,
      updateMode,
      setTabSelectedPictureIdActionCallback,
    ],
    $
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    AddOrUpdateDefectDialogState
  >({
    $,
    mandatoryFields: ['type'],
    checkFieldContentActions: undefined,
    checkFormConsistencyAction,
    submitValidDataAction: submitFormActionCallback,
    t: tGlobal,
  });

  const defectLabels = useMemo(
    () => DEFECT_TYPES.map((type) => ({ value: type, label: tDefectsTypes(type) })),
    [tDefectsTypes]
  );

  const formWarning = useGetState($.$formWarning);

  const usedPackageDealsIds = [
    ...fullyRefurbishingPackageDealIds,
    ...partiallyRefurbishingPackageDealIds,
  ];

  const hasRefurbishingOptions = usedPackageDealsIds.length > 0;

  const packageDeals = useGetState($packageDeals)
    .filter(nonDeleted)
    .filter(({ carElement }) => isTruthy(carElement));

  const packageDealsNotYetUsed = packageDeals.filter(({ id }) => !usedPackageDealsIds.includes(id));

  const addRefurbishingOptionActionCallback = useActionCallback(
    (
      { actionDispatch, getState }: ActionContext<Store, AddOrUpdateDefectDialogState>,
      packageDealId: string,
      fullyRefurbishing: boolean
    ): void => {
      if (fullyRefurbishing) {
        const { fullyRefurbishingPackageDealIds } = getState();
        const newFullyRefurbishingPackageDealIds = [
          ...fullyRefurbishingPackageDealIds,
          packageDealId,
        ];
        actionDispatch.setProperty(
          'fullyRefurbishingPackageDealIds',
          newFullyRefurbishingPackageDealIds
        );
      } else {
        const { partiallyRefurbishingPackageDealIds } = getState();
        const newPartiallyRefurbishingPackageDealIds = [
          ...partiallyRefurbishingPackageDealIds,
          packageDealId,
        ];
        actionDispatch.setProperty(
          'partiallyRefurbishingPackageDealIds',
          newPartiallyRefurbishingPackageDealIds
        );
      }
    },
    [],
    $
  );

  const kanbanId = useGetState($kanbanId);
  const kanbanStatus = useGetState($kanbanStatus);
  const computeAttachmentUrl = useComputeAttachmentUrl($gs);

  const onRemoveDefectPictureActionCallback = useActionCallback(
    async ({ httpClient, getState, actionDispatch, getGlobalState }) => {
      const { name: filename } = getState().confirmAttachmentRemovalDialog;
      await httpClient.httpGet(
        appendRegisteredBrowserSessionToken(
          CoreBackendRoutes.ATTACHMENT(
            'kanban',
            kanbanId,
            MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
            filename
          ),
          nonnull(getGlobalState().session.infos).sessionToken
        ),
        'DELETE'
      );
      actionDispatch.scopeProperty('defectPicture').setProperty('isSet', false);
      actionDispatch
        .scopeProperty('confirmAttachmentRemovalDialog')
        .setValue(EMPTY_CONFIRM_ATTACHMENT_DIALOG_STATE);
    },
    [kanbanId],
    $
  );

  const shouldRestrictAttachmentRemoval = useCallback(
    (attachment: Attachment, metadata: AttachmentMetadata | undefined): boolean => {
      return !shouldAllowAttachmentDeletion(kanbanStatus, attachment, metadata);
    },
    [kanbanStatus]
  );

  // Callback called when defect's picture has changed so we can try to re-submit the form
  const onDefectPictureChangedActionCallback = useActionCallback(
    ({ actionDispatch, getState }) => {
      const { formWarning } = getState();

      // We reset the form warning if it concerns the defect's picture
      if (formWarning === getMissingDefectPictureWarningMessage()) {
        actionDispatch.setProperty('formWarning', undefined);
        actionDispatch.setProperty('formSubmitted', false);
      }
    },
    [],
    $
  );

  const $pictureInputStateWithChangeTrigger = useSelectorWithChangeTrigger(
    $.$defectPicture,
    onDefectPictureChangedActionCallback
  );

  const defectPicture = useGetState($.$defectPicture.$id);

  const saveHighlighterActionCallback = useActionCallback(saveHighlighterAction, [], $);

  return (
    <>
      <ModalCardDialog
        title={updateMode ? t('updateTitle') : t('addTitle')}
        $active={$.$active}
        okLabel={t('saveButton')}
        onOkClicked={onFormSubmit}
        warning={formWarning}
        size="max-desktop"
      >
        <div className="fixed-grid has-2-cols">
          <div className="grid is-gap-3">
            <div className="cell">
              <PositionOnCarSelector
                $selectedPictureId={$selectedPictureId}
                $positionOnCar={$formDataWithChangeTrigger.$positionOnCar}
                $kanbanId={$kanbanId}
                standardPicturesConfigurationKey={standardPicturesConfigurationKey}
                $gs={$gs}
              />
            </div>
            <div className="cell">
              <DefectPictureSelector
                $={$pictureInputStateWithChangeTrigger}
                $defectHighlighter={$.$defectHighlighter}
                $kanbanId={$kanbanId}
                placeholderPicturePath="/img/dot.gif"
                $confirmAttachmentRemovalDialog={$.$confirmAttachmentRemovalDialog}
                $highlightDefectDialogState={$.$highlightDefectDialogState}
                $gs={$gs}
              />
              <p className="is-size-7">{t('highlighterInstructions')}</p>
            </div>
          </div>
        </div>
        <ReactSelectFormField
          label={t('type')}
          $={$formDataWithChangeTrigger.$type}
          horizontal
          suggestions={defectLabels}
        />
        <h5>{t('refurbishingOptions.title')}</h5>
        {!hasRefurbishingOptions && <p>{t('refurbishingOptions.empty')}</p>}
        {hasRefurbishingOptions && (
          <table className="table is-bordered is-fullwidth is-narrow is-striped">
            <thead>
              <tr>
                <th style={{ width: '35%' }}>{t('refurbishingOptions.type')}</th>
                <th style={{ width: '20%' }}>{t('refurbishingOptions.element')}</th>
                <th style={{ width: '35%' }}>{t('refurbishingOptions.packageDealLabel')}</th>
                <th style={{ width: '12%', whiteSpace: 'nowrap' }}>
                  {t('refurbishingOptions.price')}
                </th>
                <th style={{ width: '1%' }}>{}</th>
              </tr>
            </thead>
            <tbody>
              {fullyRefurbishingPackageDealIds.map((packageDealId): JSX.Element => {
                const packageDeal = nonnull(packageDeals.find(({ id }) => packageDealId === id));
                return (
                  <RefurbishingOptionItem
                    key={packageDealId}
                    refurbishingType="fully"
                    packageDeal={packageDeal}
                    $={$}
                  />
                );
              })}
              {partiallyRefurbishingPackageDealIds.map((packageDealId): JSX.Element => {
                const packageDeal = nonnull(packageDeals.find(({ id }) => packageDealId === id));
                return (
                  <RefurbishingOptionItem
                    key={packageDealId}
                    refurbishingType="partially"
                    packageDeal={packageDeal}
                    $={$}
                  />
                );
              })}
            </tbody>
          </table>
        )}
        <h5>{t('availablePackageDeals.title')}</h5>
        {packageDealsNotYetUsed.length <= 0 && <p>{t('availablePackageDeals.empty')}</p>}
        {packageDealsNotYetUsed.length > 0 && (
          <table className="table is-bordered is-fullwidth is-narrow is-striped">
            <thead>
              <tr>
                <th style={{ width: '23%' }}>{t('availablePackageDeals.element')}</th>
                <th style={{ width: '62%' }}>{t('availablePackageDeals.label')}</th>
                <th style={{ width: '14%' }}>{t('availablePackageDeals.price')}</th>
                <th style={{ width: '1%' }}>{}</th>
              </tr>
            </thead>
            <tbody>
              {packageDealsNotYetUsed.map((packageDeal) => (
                <PackageDealForDefectItem
                  key={packageDeal.id}
                  packageDeal={packageDeal}
                  $={$.$addPackageDealToDefectDialogState}
                />
              ))}
            </tbody>
          </table>
        )}
      </ModalCardDialog>
      <AddPackageDealToDefectModal
        $={$.$addPackageDealToDefectDialogState}
        onSubmitCallback={addRefurbishingOptionActionCallback}
      />
      <DeleteRefurbishingOptionModal $={$} />
      <ConfirmAttachmentRemovalDialog
        category="kanban"
        objectId={kanbanId}
        onOkClicked={onRemoveDefectPictureActionCallback}
        $={$.$confirmAttachmentRemovalDialog}
        computeAttachmentUrl={computeAttachmentUrl}
        okLabel={t('confirmAttachmentRemovalDialog.okLabel')}
        shouldRestrictRemove={shouldRestrictAttachmentRemoval}
      >
        <p>{t('confirmAttachmentRemovalDialog.message')}</p>
      </ConfirmAttachmentRemovalDialog>
      <HighlightDefectModal
        $={$.$highlightDefectDialogState}
        kanbanId={kanbanId}
        pictureId={defectPicture}
        saveHighlighterActionCallback={saveHighlighterActionCallback}
        $gs={$gs}
      />
    </>
  );
}
