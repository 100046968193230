import type { JSX } from 'react';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthy } from '@stimcar/libs-kernel';
import { useTextFieldWithDelayedStateChange } from './useTextField.js';

export type TextAreaProps<SD extends AnyStoreDef> = Omit<
  React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
  'onChange' | 'value' | 'type'
> & {
  readonly $: StoreStateSelector<SD, string | undefined>;
  readonly preSelectContent?: boolean;
};

export function TextArea<SD extends AnyStoreDef>({
  $,
  className,
  placeholder,
  preSelectContent = false,
  ...props
}: TextAreaProps<SD>): JSX.Element {
  const [t] = useTranslation('libBulma');
  const {
    inputFieldRef,
    scheduleDelayedStateChange,
    scheduleImmediateStateChange,
    onKeyDown: onKeyDownForTexWithDelayedStateChange,
  } = useTextFieldWithDelayedStateChange<SD, HTMLTextAreaElement>({
    $,
  });
  let placeholderValue = t('textArea.defaultPlaceholder');
  if (placeholder !== undefined) {
    placeholderValue = placeholder;
  }

  useEffect(() => {
    if (preSelectContent && isTruthy(inputFieldRef.current)) {
      inputFieldRef.current.focus();
      inputFieldRef.current.select();
    }
  }, [inputFieldRef, preSelectContent]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      // Prevent Enter propagation (that may close dialogs)
      if (e.keyCode === 13) {
        e.stopPropagation();
      }
      onKeyDownForTexWithDelayedStateChange(e);
    },
    [onKeyDownForTexWithDelayedStateChange]
  );

  return (
    <textarea
      ref={inputFieldRef}
      placeholder={placeholderValue}
      className={`textarea${className ? ` ${className}` : ''}`}
      onChange={scheduleDelayedStateChange}
      onKeyDown={onKeyDown}
      onBlur={scheduleImmediateStateChange}
      {...props}
    />
  );
}
