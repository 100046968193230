/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { DefectHighlighter } from '@stimcar/libs-kernel';
import type {
  ActionCallbackFromFunction,
  ActionContext,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { isTruthy, MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button, ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../state/typings/store.js';
import type { HighlightDefectDialogState } from '../../../typings/store.js';
import { useComputeAttachmentUrl } from '../../../../utils/useComputeAttachmentUrl.js';
import {
  EMPTY_DEFECT_HIGHLIGHTER,
  EMPTY_HIGHLIGHT_DEFECT_DIALOG_STATE,
} from '../../../typings/store.js';
import { getDefectHighlighterStyle, isValidDefectHighlighter } from '../defectUtils.js';

export type SaveHighlighterActionCallback = ActionCallbackFromFunction<
  Store,
  (highlighter: DefectHighlighter | null) => void
>;

export function openHightlightDefectModalAction(
  { actionDispatch }: ActionContext<Store, HighlightDefectDialogState>,
  initialHighlighter: DefectHighlighter
): void {
  actionDispatch.setValue({
    ...EMPTY_HIGHLIGHT_DEFECT_DIALOG_STATE,
    active: true,
    highlighter: initialHighlighter,
  });
}

function startDrawingHighlighterAction(
  { actionDispatch }: ActionContext<Store, HighlightDefectDialogState>,
  startingX: number,
  startingY: number
): void {
  actionDispatch.setProperty('isDrawing', true);
  actionDispatch.setProperty('startPositionX', startingX);
  actionDispatch.setProperty('startPositionY', startingY);
}

function stopDrawingHighlighterAction({
  actionDispatch,
  getState,
}: ActionContext<Store, HighlightDefectDialogState>): void {
  const { isDrawing } = getState();
  if (isDrawing) {
    actionDispatch.setProperty('isDrawing', false);
    actionDispatch.setProperty('startPositionX', null);
    actionDispatch.setProperty('startPositionY', null);
  }
}

function drawEllipseAction(
  { actionDispatch }: ActionContext<Store, HighlightDefectDialogState>,
  x: number,
  y: number,
  width: number,
  height: number
): void {
  actionDispatch.setProperty('highlighter', { x, y, width, height });
}

function clearEllipseAction({
  actionDispatch,
}: ActionContext<Store, HighlightDefectDialogState>): void {
  actionDispatch.setProperty('highlighter', EMPTY_DEFECT_HIGHLIGHTER);
}

interface HighlightDefectModalProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, HighlightDefectDialogState>;
  readonly kanbanId: string;
  readonly pictureId: string;
  readonly saveHighlighterActionCallback: SaveHighlighterActionCallback;
}

export function HighlightDefectModal({
  $,
  kanbanId,
  pictureId,
  saveHighlighterActionCallback,
  $gs,
}: HighlightDefectModalProps): JSX.Element {
  const [t] = useTranslation('operators', { keyPrefix: 'defects.highlightDefect' });

  const startPositionX = useGetState($.$startPositionX);
  const startPositionY = useGetState($.$startPositionY);
  const isDrawing = useGetState($.$isDrawing);
  const highlighter = useGetState($.$highlighter);

  const computeAttachmentUrl = useComputeAttachmentUrl($gs);
  const pictureSrc = computeAttachmentUrl(
    'kanban',
    MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
    pictureId,
    kanbanId
  );

  const onFormSubmitActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.execCallback(saveHighlighterActionCallback, highlighter);
      actionDispatch.setValue(EMPTY_HIGHLIGHT_DEFECT_DIALOG_STATE);
    },
    [saveHighlighterActionCallback, highlighter],
    $
  );

  const startDrawingHighlighterActionCallback = useActionCallback(
    startDrawingHighlighterAction,
    [],
    $
  );

  const stopDrawingHighlighterActionCallback = useActionCallback(
    stopDrawingHighlighterAction,
    [],
    $
  );

  const drawEllipseActionCallback = useActionCallback(drawEllipseAction, [], $);
  const clearEllipseActionCallback = useActionCallback(clearEllipseAction, [], $);

  const handleMouseDown = async (e: React.MouseEvent<HTMLImageElement>) => {
    const { left, top } = e.currentTarget.getBoundingClientRect();
    const startX = e.clientX - left;
    const startY = e.clientY - top;

    await startDrawingHighlighterActionCallback(startX, startY);
  };

  const handleMouseMove = async (e: React.MouseEvent<HTMLImageElement>) => {
    if (!isDrawing || !isTruthy(startPositionX) || !isTruthy(startPositionY)) return;

    const imgRect = e.currentTarget.getBoundingClientRect();
    const currentX = e.clientX - imgRect.left;
    const currentY = e.clientY - imgRect.top;

    const width = Math.abs(currentX - startPositionX);
    const height = Math.abs(currentY - startPositionY);

    const left = Math.min(startPositionX, currentX);
    const top = Math.min(startPositionY, currentY);

    const xPercent = (left / imgRect.width) * 100;
    const yPercent = (top / imgRect.height) * 100;
    const widthPercent = (width / imgRect.width) * 100;
    const heightPercent = (height / imgRect.height) * 100;

    await drawEllipseActionCallback(xPercent, yPercent, widthPercent, heightPercent);
  };

  const handleMouseUp = async () => {
    await stopDrawingHighlighterActionCallback();
  };

  return (
    <ModalCardDialog
      title={t('title')}
      $active={$.$active}
      okLabel={t('okButtonLabel')}
      onOkClicked={onFormSubmitActionCallback}
    >
      <div className="columns px-4">
        <div className="column">{t('instructions')}</div>
        <div className="column has-text-right">
          {' '}
          <Button label={t('clear')} tooltip={t('clear')} onClick={clearEllipseActionCallback} />
        </div>
      </div>
      <div className="highlighter-image-container is-relative is-inline-block">
        <img
          src={pictureSrc}
          alt={pictureId}
          title={pictureId}
          width="600"
          height="450"
          style={{
            cursor: 'crosshair',
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          draggable={false}
        />
        {isTruthy(highlighter) && isValidDefectHighlighter(highlighter) && (
          <span className="defect-highlighter" style={getDefectHighlighterStyle(highlighter)} />
        )}
      </div>
    </ModalCardDialog>
  );
}
