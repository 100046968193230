import { isTruthy, isTruthyAndNotEmpty } from '../../asserts.js';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type JsonParser = (text: string) => any;

export class HttpError extends Error {
  private statusCode: number;

  public constructor(statusCode: number, message: string) {
    super(message);
    this.statusCode = statusCode;
  }

  public getStatusCode(): number {
    return this.statusCode;
  }
}

export type JsonReturnType = object | boolean | string | number;

export interface NodeFile {
  readonly filename: string;
  readonly buffer: Buffer;
}

export function nodeFileGuard(entry: unknown): entry is NodeFile {
  return isTruthy((entry as NodeFile).buffer) && isTruthyAndNotEmpty((entry as NodeFile).filename);
}

export type HttpLogFormatter = (uri: string, init: RequestInit) => string | undefined;

/**
 * HTTP client component.
 *
 * The HTTP client is responsible for connecting to the server.
 *
 * It handles server push events (through SSE)
 *
 * Users can register listeners for each type of server message types.
 */
export interface HTTPClient {
  /**
   * Sends a HTTP GET request to the server.
   * @param path the request path.
   * @param method HTTP method to use (GET by default).
   * @return the server response.
   * @throws if a I/O error occurs.
   */
  httpGet(path: string, method?: 'GET' | 'DELETE' | 'HEAD'): Promise<Response>;

  /**
   * Sends a HTTP GET request to the server.
   * @param path the request path.
   * @param method HTTP method to use (GET by default).
   * @return the server response (text format).
   * @throws if a I/O error occurs.
   */
  httpGetAsText(path: string, method?: 'GET' | 'DELETE' | 'HEAD'): Promise<string>;

  /**
   * Sends a HTTP GET request to the server.
   * @param path the request path.
   * @param method HTTP method to use (GET by default).
   * @return the server response (JSON format).
   * @throws if a I/O error occurs.
   */
  httpGetAsJson<T extends JsonReturnType>(
    path: string,
    method?: 'GET' | 'DELETE' | 'HEAD'
  ): Promise<T>;

  /**
   * POST some data as JSON to the server.
   * @param path the request path.
   * @param body the data to send (JSON format).
   * @param method HTTP method to use (POST by default).
   * @param contentType the content type.
   * @return the server response as JSON (or null if no data is returned).
   * @throws if a I/O error occurs.
   */
  httpPost<R extends JsonReturnType | null>(
    path: string,
    body: string,
    method: 'POST' | 'PUT' | 'PATCH',
    contentType?: string
  ): Promise<R>;

  /**
   * POST some data as JSON to the server.
   * @param path the request path.
   * @param data the data to send (JSON format).
   * @param method HTTP method to use (POST by default).
   * @return the server response as JSON (or null if no data is returned).
   * @throws if a I/O error occurs.
   */
  httpPostAsJSON<D extends JsonReturnType, R extends JsonReturnType | null>(
    path: string,
    data: D,
    method?: 'POST' | 'PUT' | 'PATCH',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    replacer?: (k: string, v: any) => any
  ): Promise<R>;

  /**
   * POST some data as JSON to the server.
   * @param path the request path.
   * @param data the data to send (an object with string, File or NodeFile values).
   * @param method HTTP method to use (POST by default).
   * @return the server response as JSON (or null if no data is returned).
   * @throws if a I/O error occurs.
   */
  httpPostAsFormData<R extends JsonReturnType | null>(
    path: string,
    data: Record<string, NodeFile | File | string>,
    method?: 'POST' | 'PUT' | 'PATCH'
  ): Promise<R>;

  /**
   * POST some data as JSON to the server.
   * @param path the request path.
   * @param data the data to send (an object with string, File or NodeFile values).
   * @param method HTTP method to use (POST by default).
   * @return the server response as JSON (or null if no data is returned).
   * @throws if a I/O error occurs.
   */
  httpRawPostAsFormData(
    path: string,
    data: Record<string, NodeFile | File | string>,
    acceptHeader: string,
    method?: 'POST' | 'PUT' | 'PATCH'
  ): Promise<Response>;

  /**
   * POST some data as File to the server.
   * @param path the request path.
   * @param data the data to send (File or Blob format).
   * @param method HTTP method to use (POST by default).
   * @return the server response as JSON (or null if no data is returned).
   * @throws if a I/O error occurs.
   */
  httpPostAsFile<R extends JsonReturnType | null>(
    path: string,
    data: Record<string, Buffer | Blob> | readonly File[] | File,
    method?: 'POST' | 'PUT' | 'PATCH'
  ): Promise<R>;

  /**
   * POST some data as File to the server.
   * @param path the request path.
   * @param data the data to send (File or Blob format).
   * @param method HTTP method to use (POST by default).
   * @return the server response as JSON (or null if no data is returned).
   * @throws if a I/O error occurs.
   */
  httpRawPostAsFile(
    path: string,
    data: Record<string, Buffer | Blob> | readonly File[] | File,
    acceptHeader: string,
    method?: 'POST' | 'PUT' | 'PATCH'
  ): Promise<Response>;

  /**
   * Returns the base URL of the client.
   * @return the base URL of the client.
   */
  getBaseUrl(): string | undefined;

  /**
   * Add headers on the XML Http request.
   *
   * HttpClient implementation is based on fetch API (https://developer.mozilla.org/fr/docs/Web/API/Fetch_API).
   * Unfortunately, fetch API does not allow to keep track of an upload process. In such situation, one must
   * use XMLHttpRequest API. That method helps to get and XMLHttpRequest that can interact with the server
   * (with the right headers set).
   *
   * @param xhr the xml HTTP request.
   */
  copyHttpHeadersTo(xhr: XMLHttpRequest): void;
}
