import type { JSX } from 'react';
import { t } from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  AvailableStimer,
  MarketplaceOverviewRequest,
  MarketplaceStimerOverview,
  StimerCommissionLine,
} from '@stimcar/core-libs-common';
import type { Kanban, PackageDeal, User } from '@stimcar/libs-base';
import type { WarrantyExtensionValue } from '@stimcar/libs-kernel';
import type { GlobalStoreStateSelector, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { SimpleOption } from '@stimcar/libs-uitoolkit';
import {
  CoreBackendRoutes,
  MARKETPLACE_DOC_TYPE_STIMER,
  MKTP_PKG_DEAL_CODE_CLIENT_DELIVERY,
  MKTP_PKG_DEAL_CODE_VARIABLE_FEE,
  nonDeleted,
  packageDealHelpers,
  priceHelpers,
} from '@stimcar/libs-base';
import {
  applyPayload,
  computePayload,
  formatPrice,
  isTruthy,
  MKTP_WARRANTY_EXTENSION_NOT_SPECIFIED,
} from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button, ModalCardDialog, ReactSelectFormField } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type { MarketplaceStimerState } from './typings/store.js';
import { availableOrRecommended } from './overview/MarketplaceOverview.js';

const DEFAULT_STIMER_COMMISSION_RATE = 0.6;
const DEFAULT_REFURBISH_MARGIN_RATE = 0.25;
const DEFAULT_LOAN_RATE = 0.015;

type TotalCommissions = {
  readonly variableFeeWithoutVAT: number;
  readonly refurbishmentWithoutVAT: number;
  readonly warrantyExtensionWithoutVAT: number;
  readonly loanWithoutVAT: number;
  readonly clientDeliveryWithoutVAT: number;
};

type StimerCommissions = {
  readonly variableFeeWithoutVAT: number;
  readonly refurbishmentWithoutVAT: number;
  readonly warrantyExtensionWithoutVAT: number;
  readonly loanWithoutVAT: number;
  readonly clientDeliveryWithoutVAT: number;
  readonly totalStimerWithoutVAT: number;
  readonly totalStimerVAT: number;
  readonly totalStimerWithVAT: number;
};

function computeVariableFeeCommissionWithoutVAT(packageDeals: readonly PackageDeal[]): number {
  const variableFeePkgDeal = packageDeals
    .filter(nonDeleted)
    .find(({ code }) => code === MKTP_PKG_DEAL_CODE_VARIABLE_FEE);
  if (isTruthy(variableFeePkgDeal)) {
    const variableFeePriceWithoutVAT =
      packageDealHelpers.getPackageDealAndSparePartsPriceWithoutVAT(variableFeePkgDeal, 'all');
    return variableFeePriceWithoutVAT;
  }
  return 0;
}

function computeRefurbishmentCommissionWithoutVAT(packageDeals: readonly PackageDeal[]): number {
  const refurbishmentPkgDeals = packageDeals
    .filter(
      ({ marketplaceSaleCategory, marketplaceBuyCategory }) =>
        !isTruthy(marketplaceSaleCategory) && !isTruthy(marketplaceBuyCategory)
    )
    .filter((pkgDeal) =>
      packageDealHelpers.isPackageDealWithGivenCategory(pkgDeal, ['MECA', 'CARRO'])
    )
    .filter(nonDeleted)
    .filter(availableOrRecommended);

  return packageDealHelpers.getPackageDealsAndSparePartsTotalPriceWithoutVAT(
    refurbishmentPkgDeals,
    'all'
  );
}

function computeWarrantyExtensionCommissionWithoutVAT(
  warrantyExtension: WarrantyExtensionValue
): number {
  switch (warrantyExtension) {
    case '12MonthsT3':
      return 80;
    case '24MonthsT3':
      return 160;
    case '36MonthsT3':
      return 200;
    case '48MonthsT3':
      return 240;
    case '12MonthsT4':
      return 120;
    case '24MonthsT4':
      return 240;
    case '36MonthsT4':
      return 300;
    case '48MonthsT4':
      return 360;
    case '12MonthsT5':
      return 160;
    case '24MonthsT5':
      return 300;
    case '36MonthsT5':
      return 400;
    case '48MonthsT5':
      return 480;
    case 'unknown':
    default:
      return 0;
  }
}

function computeLoanCommisionWithoutVAT(
  loanAmount: number,
  loanCommisionRate: number = DEFAULT_LOAN_RATE
): number {
  return loanAmount * loanCommisionRate;
}

function computeClientDeliveryCommissionWithoutVAT(packageDeals: readonly PackageDeal[]): number {
  const clientDeliveryPackageDeal = packageDeals
    .filter(nonDeleted)
    .find(({ code }) => code === MKTP_PKG_DEAL_CODE_CLIENT_DELIVERY);
  if (isTruthy(clientDeliveryPackageDeal)) {
    const clientDeliveryPriceWithoutVAT =
      packageDealHelpers.getPackageDealAndSparePartsPriceWithoutVAT(
        clientDeliveryPackageDeal,
        'all'
      );
    return clientDeliveryPriceWithoutVAT;
  }
  return 0;
}

function computeCommissionValues(
  packageDeals: readonly PackageDeal[],
  warrantyExtensionValue: WarrantyExtensionValue,
  loanAmount: number
): TotalCommissions {
  const variableFeeWithoutVAT = computeVariableFeeCommissionWithoutVAT(packageDeals);
  const refurbishmentWithoutVAT = computeRefurbishmentCommissionWithoutVAT(packageDeals);
  const warrantyExtensionWithoutVAT =
    computeWarrantyExtensionCommissionWithoutVAT(warrantyExtensionValue);
  const loanWithoutVAT = computeLoanCommisionWithoutVAT(loanAmount);
  const clientDeliveryWithoutVAT = computeClientDeliveryCommissionWithoutVAT(packageDeals);
  return {
    variableFeeWithoutVAT,
    refurbishmentWithoutVAT,
    warrantyExtensionWithoutVAT,
    loanWithoutVAT,
    clientDeliveryWithoutVAT,
  };
}

function computeStimerCommissions(
  totalCommissions: TotalCommissions,
  stimerCommissionRate: number = DEFAULT_STIMER_COMMISSION_RATE,
  refurbishMarginRate: number = DEFAULT_REFURBISH_MARGIN_RATE
): StimerCommissions {
  const stimerVariableFeeWithoutVAT = totalCommissions.variableFeeWithoutVAT * stimerCommissionRate;
  const stimerRefurbishmentWithoutVAT =
    totalCommissions.refurbishmentWithoutVAT * refurbishMarginRate * stimerCommissionRate;
  const stimerWarrantyExtensionWithoutVAT =
    totalCommissions.warrantyExtensionWithoutVAT * stimerCommissionRate;
  const stimerLoanWithoutVAT = totalCommissions.loanWithoutVAT * stimerCommissionRate;
  const stimerClientDeliveryWithoutVAT =
    totalCommissions.clientDeliveryWithoutVAT * stimerCommissionRate;

  const totalStimerCommissionsWithoutVAT =
    stimerVariableFeeWithoutVAT +
    stimerRefurbishmentWithoutVAT +
    stimerWarrantyExtensionWithoutVAT +
    stimerLoanWithoutVAT +
    stimerClientDeliveryWithoutVAT;
  const totalStimerCommissionsVAT = priceHelpers.getVAT(totalStimerCommissionsWithoutVAT);
  const totalStimerCommissionsWithVAT =
    totalStimerCommissionsWithoutVAT + totalStimerCommissionsVAT;

  return {
    variableFeeWithoutVAT: stimerVariableFeeWithoutVAT,
    refurbishmentWithoutVAT: stimerRefurbishmentWithoutVAT,
    warrantyExtensionWithoutVAT: stimerWarrantyExtensionWithoutVAT,
    loanWithoutVAT: stimerLoanWithoutVAT,
    clientDeliveryWithoutVAT: stimerClientDeliveryWithoutVAT,
    totalStimerWithoutVAT: totalStimerCommissionsWithoutVAT,
    totalStimerVAT: totalStimerCommissionsVAT,
    totalStimerWithVAT: totalStimerCommissionsWithVAT,
  };
}

function computeStimerCommissionLines(
  stimerCommissions: StimerCommissions
): readonly StimerCommissionLine[] {
  return [
    {
      label: t('details:tabs.marketplace.stimer.commissions.variableFee', {
        stimerPercentage: DEFAULT_STIMER_COMMISSION_RATE * 100,
      }),
      priceWithoutVAT: stimerCommissions.variableFeeWithoutVAT,
    },
    {
      label: t('details:tabs.marketplace.stimer.commissions.refurbishment', {
        stimerPercentage: DEFAULT_STIMER_COMMISSION_RATE * 100 * DEFAULT_REFURBISH_MARGIN_RATE,
      }),
      priceWithoutVAT: stimerCommissions.refurbishmentWithoutVAT,
    },
    {
      label: t('details:tabs.marketplace.stimer.commissions.warrantyExtension', {
        stimerPercentage: DEFAULT_STIMER_COMMISSION_RATE * 100,
      }),
      priceWithoutVAT: stimerCommissions.warrantyExtensionWithoutVAT,
    },

    {
      label: t('details:tabs.marketplace.stimer.commissions.loan', {
        stimerPercentage: DEFAULT_STIMER_COMMISSION_RATE * 100,
      }),
      priceWithoutVAT: stimerCommissions.loanWithoutVAT,
    },
    {
      label: t('details:tabs.marketplace.stimer.commissions.clientDelivery', {
        stimerPercentage: DEFAULT_STIMER_COMMISSION_RATE * 100,
      }),
      priceWithoutVAT: stimerCommissions.clientDeliveryWithoutVAT,
    },
  ];
}

function computeAvailableStimerValues(
  availableStimer: readonly AvailableStimer[]
): SimpleOption<string>[] {
  return availableStimer.map((ref) => ({
    label: ref.name,
    value: ref.id,
  }));
}

function computeMarketplaceStimerOverview(
  stimerName: string,
  ignoreVAT: boolean,
  stimerCommissions: StimerCommissions
): MarketplaceStimerOverview {
  const stimerCommissionLines = computeStimerCommissionLines(stimerCommissions);
  return {
    stimerName,
    ignoreVAT,
    commissions: stimerCommissionLines,
    totalStimerWithoutVAT: stimerCommissions.totalStimerWithoutVAT,
    totalStimerVAT: stimerCommissions.totalStimerVAT,
    totalStimerWithVAT: stimerCommissions.totalStimerWithVAT,
  };
}

export interface MarketplaceStimerProps {
  readonly $: StoreStateSelector<Store, MarketplaceStimerState>;
  readonly $gs: GlobalStoreStateSelector<Store>;
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
}

export function MarketplaceStimer({
  $,
  $gs,
  $selectedKanban,
}: MarketplaceStimerProps): JSX.Element {
  const [t] = useTranslation('details');

  const isOnline = useGetState($gs.$session.$isOnline);
  const kanban = useGetState($selectedKanban);
  const kanbanId = useGetState($selectedKanban.$id);
  const stimerId = useGetState($.$stimerId);
  const availableStimers = useGetState($.$availableStimers);

  const totalCommissions: TotalCommissions = useMemo(
    () =>
      computeCommissionValues(
        kanban.packageDeals,
        kanban.marketplaceInfos?.warrantyExtension ?? MKTP_WARRANTY_EXTENSION_NOT_SPECIFIED,
        kanban.marketplaceInfos?.loanAmount ?? 0
      ),
    [kanban.packageDeals, kanban.marketplaceInfos]
  );
  const stimerCommissions: StimerCommissions = useMemo(
    () => computeStimerCommissions(totalCommissions),
    [totalCommissions]
  );
  const stimerSuggestions = useMemo(
    () => computeAvailableStimerValues(availableStimers),
    [availableStimers]
  );

  const openSaveConfirmationModalActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.scopeProperty('saveConfirmationDialog').setProperty('active', true);
    },
    [],
    $
  );

  const saveStimerValuesToKanban = useActionCallback(
    async ({ actionDispatch, kanbanRepository }): Promise<void> => {
      const updatedKanban = applyPayload(kanban, {
        marketplaceInfos: {
          stimerId,
        },
      });
      // Update the kanban in the repository if needed
      const payload = computePayload(kanban, updatedKanban);
      if (payload !== undefined) {
        await kanbanRepository.updateEntityFromPayload({ entityId: kanbanId, payload });
      }
      actionDispatch.scopeProperty('saveConfirmationDialog').setProperty('active', false);
    },
    [kanbanId, kanban, stimerId],
    $
  );

  const generateStimerInvoiceActionCallback = useActionCallback(
    async ({ actionDispatch, httpClient }): Promise<void> => {
      await actionDispatch.execCallback(saveStimerValuesToKanban);
      const selectedStimer = availableStimers.find(({ id }) => id === stimerId);
      const stimerName = isTruthy(selectedStimer)
        ? selectedStimer.name
        : t('tabs.marketplace.stimer.unknown');
      const stimerOverview: MarketplaceStimerOverview = computeMarketplaceStimerOverview(
        stimerName,
        false,
        stimerCommissions
      );
      await httpClient.httpPostAsJSON<MarketplaceOverviewRequest, null>(
        CoreBackendRoutes.CREATE_MARKETPLACE_OVERVIEW_DOC(kanbanId, MARKETPLACE_DOC_TYPE_STIMER),
        { stimerOverview },
        'POST'
      );
      actionDispatch.scopeProperty('overviewButtonDialog').setProperty('active', true);
    },
    [t, kanbanId, stimerId, availableStimers, stimerCommissions, saveStimerValuesToKanban],
    $
  );

  const closeOverviewGenerationModalActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.scopeProperty('overviewButtonDialog').setProperty('active', false);
    },
    [],
    $
  );

  const loadValuesAsyncEffect = useActionCallback(
    async ({ actionDispatch, httpClient }): Promise<void> => {
      const users = await httpClient.httpGetAsJson<readonly User[]>(CoreBackendRoutes.GET_USERS);
      const availableStimers: readonly AvailableStimer[] = users
        .filter(({ active, profiles }) => active && profiles.names.includes('stimer'))
        .map(
          ({ login, firstName, lastName }): AvailableStimer => ({
            id: login,
            name: `${firstName} ${lastName}`,
          })
        );
      actionDispatch.applyPayload({
        stimerId: kanban?.marketplaceInfos?.stimerId ?? '',
        availableStimers,
      });
    },
    [kanban],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadValuesAsyncEffect();
  }, [kanban, loadValuesAsyncEffect]);

  return (
    <>
      <ModalCardDialog
        $active={$.$overviewButtonDialog.$active}
        title={t('tabs.marketplace.overview.modal.generationModalTitle')}
        onOkClicked={closeOverviewGenerationModalActionCallback}
        dontShowCancelButton
      >
        <p>{t('tabs.marketplace.overview.modal.generationModalMessage')}</p>
      </ModalCardDialog>
      <ModalCardDialog
        $active={$.$saveConfirmationDialog.$active}
        title={t('tabs.marketplace.overview.modal.saveConfirmationModalTitle')}
        onOkClicked={saveStimerValuesToKanban}
        dontShowCancelButton
      >
        <p>{t('tabs.marketplace.overview.modal.saveConfirmationModalMessage')}</p>
      </ModalCardDialog>
      <div className="m-2">
        <div className="field is-grouped is-flex is-align-items-flex-end is-justify-content-center">
          <p className="control">
            <ReactSelectFormField
              label={t('tabs.marketplace.stimer.stimerId')}
              $={$.$stimerId}
              suggestions={stimerSuggestions}
              horizontal
            />
          </p>
          <p className="control">
            <Button
              iconId="save"
              label={t('tabs.marketplace.saveButton')}
              onClick={openSaveConfirmationModalActionCallback}
              additionalClass="is-primary"
              disabled={!isOnline}
            />
          </p>
          <p className="control">
            <Button
              iconId="dollar-sign"
              label={t('tabs.marketplace.stimer.generateInvoiceButton')}
              onClick={generateStimerInvoiceActionCallback}
              additionalClass="is-primary"
              disabled={!isOnline}
            />
          </p>
        </div>
      </div>
      <table className="table is-striped is-bordered is-hoverable is-fullwidth mx-2" role="none">
        <thead>
          <tr>
            <th className="has-text-centered">{t('tabs.marketplace.stimer.descriptionHeader')}</th>
            <th className="has-text-centered">{t('tabs.marketplace.stimer.totalPriceHeader')}</th>
            <th className="has-text-centered">
              {t('tabs.marketplace.stimer.stimerPriceHeader', {
                percentage: DEFAULT_STIMER_COMMISSION_RATE * 100,
              })}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr key="variableFee">
            <td className="is-size-6">{t('tabs.marketplace.stimer.commissions.variableFee')}</td>
            <td className="is-size-6 has-text-right">
              {formatPrice(totalCommissions.variableFeeWithoutVAT, 2)}
            </td>
            <td className="is-size-6 has-text-right">
              {formatPrice(stimerCommissions.variableFeeWithoutVAT, 2)}
            </td>
          </tr>
          <tr key="refurbishment">
            <td className="is-size-6 ">{t('tabs.marketplace.stimer.commissions.refurbishment')}</td>
            <td className="is-size-6 has-text-right">
              {formatPrice(totalCommissions.refurbishmentWithoutVAT, 2)}
            </td>
            <td className="is-size-6 has-text-right">
              {formatPrice(stimerCommissions.refurbishmentWithoutVAT, 2)}
            </td>
          </tr>
          <tr key="warrantyExtension">
            <td className="is-size-6 ">
              {t('tabs.marketplace.stimer.commissions.warrantyExtension')}
            </td>
            <td className="is-size-6 has-text-right">
              {formatPrice(totalCommissions.warrantyExtensionWithoutVAT, 2)}
            </td>
            <td className="is-size-6 has-text-right">
              {formatPrice(stimerCommissions.warrantyExtensionWithoutVAT, 2)}
            </td>
          </tr>
          <tr key="loan">
            <td className="is-size-6 ">{t('tabs.marketplace.stimer.commissions.loan')}</td>
            <td className="is-size-6 has-text-right">
              {formatPrice(totalCommissions.loanWithoutVAT, 2)}
            </td>
            <td className="is-size-6 has-text-right">
              {formatPrice(stimerCommissions.loanWithoutVAT, 2)}
            </td>
          </tr>
          <tr key="clientDelivery">
            <td className="is-size-6 ">
              {t('tabs.marketplace.stimer.commissions.clientDelivery')}
            </td>
            <td className="is-size-6 has-text-right">
              {formatPrice(totalCommissions.clientDeliveryWithoutVAT, 2)}
            </td>
            <td className="is-size-6 has-text-right">
              {formatPrice(stimerCommissions.clientDeliveryWithoutVAT, 2)}
            </td>
          </tr>
          <tr key="totalCommissionsWithoutVAT">
            <td colSpan={2} className="is-size-6 has-text-weight-bold has-text-right">
              {t('tabs.marketplace.stimer.commissions.totalCommissionsWithoutVAT')}
            </td>
            <td className="is-size-6 has-text-weight-bold has-text-right">
              {formatPrice(stimerCommissions.totalStimerWithoutVAT, 2)}
            </td>
          </tr>
          <tr key="finalTotalCommissionsWithoutVAT">
            <td colSpan={2} className="is-size-6 has-text-weight-bold has-text-right">
              {t('tabs.marketplace.stimer.commissions.totalVAT')}
            </td>
            <td className="is-size-6 has-text-weight-bold has-text-right">
              {formatPrice(stimerCommissions.totalStimerVAT, 2)}
            </td>
          </tr>
          <tr key="finalTotalCommissionsWithoutVAT">
            <td colSpan={2} className="is-size-6 has-text-weight-bold has-text-right">
              {t('tabs.marketplace.stimer.commissions.totalPriceWithVAT')}
            </td>
            <td className="is-size-6 has-text-weight-bold has-text-right">
              {formatPrice(stimerCommissions.totalStimerWithVAT, 2)}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
