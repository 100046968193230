import type {
  Customer,
  Defect,
  Deletable,
  Entity,
  KanbanInfos,
  Labelled,
  MarketplaceInfos,
  RepositoryEntity,
  RepositoryEntityStatus,
  SequencedObject,
  SpecificFields,
} from '@stimcar/libs-kernel';
import type {
  CarElement,
  CarViewCategory,
  MarketplaceCategory,
  OperationDesc,
  PackageDealBooleanVariableBaseType,
  PackageDealCategory,
  PackageDealNumericVariableBaseType,
  PackageDealTextualVariableBaseType,
} from './configuration.js';
import type { KanbanContract } from './contract.js';
import type {
  AttributesHolder,
  Priceable,
  SparePartManagementType,
  TimedInterval,
  WithAttachments,
} from './general.js';
import type { CoreFields } from './repository.js';
import type { Omit } from './typescript.js';

// Object types
export type KanbanCustomer = Omit<SpecificFields<Customer>, 'contract'>;

export const VOLEAN_SOURCE = 'volean';
export const AIRTABLE_SOURCE = 'airtable';
export const CLONE_SOURCE = 'clone';
export const MANUAL_SOURCE = 'manual';
export const DELEGATION_SOURCE = 'delegation';

export const KANBAN_AUTOMATED_SOURCES = [VOLEAN_SOURCE, AIRTABLE_SOURCE] as const;

export type KanbanAutomatedSource = (typeof KANBAN_AUTOMATED_SOURCES)[number];

export const KANBAN_SOURCES = [
  VOLEAN_SOURCE,
  AIRTABLE_SOURCE,
  CLONE_SOURCE,
  MANUAL_SOURCE,
  DELEGATION_SOURCE,
] as const;

export type KanbanSource = (typeof KANBAN_SOURCES)[number];

export type KanbanOrigin = {
  readonly source: KanbanSource;
  readonly id: string | null;
};

export type PurchaseOrder = Entity &
  Deletable & {
    readonly purchaseNumber: string;
    readonly label: string | null;
    readonly invoiceInfos: readonly InvoiceInfo[];
    readonly customer?: SpecificFields<Customer> | null;
  };

export type InvoiceInfo = Entity &
  Deletable & {
    readonly reference: string | null;
    readonly invoiceId: number | null;
    readonly invoiceFirmId: number | null;
    readonly isRefund: boolean | null;
    readonly refundedInvoiceId: number | null; // In case of a refund, the refund has a reference towards the refunded invoice
    readonly amount: number | null;
  };

export type BaseKanban = RepositoryEntity &
  AttributesHolder & {
    readonly creationDate: number;
    readonly dueDate: number | null;
    readonly refitEndDate: number | null;
    readonly infos: KanbanInfos;
    readonly customer: KanbanCustomer;
    readonly logisticInfos: KanbanLogisticInfos;
    readonly estimateComment: string;
    readonly deliveryComment: string;
    readonly contract: KanbanContract;
    readonly memos: Record<string, Memo>;
    readonly origin: KanbanOrigin;
    readonly workflowId: string;
    readonly purchaseOrders: readonly PurchaseOrder[];
    readonly defects: readonly Defect[];
    readonly marketplaceInfos?: MarketplaceInfos;
  };

export type PriceableKanban = BaseKanban & {
  readonly handlings: readonly KanbanHandling[];
  readonly packageDeals: readonly PriceablePackageDeal[];
};

export type Kanban = Omit<PriceableKanban, 'packageDeals'> & {
  readonly refitEndDate: number | null;
  readonly packageDeals: readonly PackageDeal[];
  readonly messages: readonly KanbanMessage[];
};

export type MemoType = 'boolean' | 'numeric' | 'text' | 'select' | 'textarea' | 'date';

export type BaseMemo<T = MemoType> = {
  readonly type: T;
  readonly category: CarViewCategory;
};

export type StringMemo = BaseMemo<'text' | 'select' | 'textarea'> & {
  readonly value: string | null | undefined;
};

export type BooleanMemo = BaseMemo<'boolean'> & {
  readonly value: boolean | null | undefined;
};

export type NumericMemo = BaseMemo<'numeric' | 'date'> & {
  readonly value: number | null | undefined;
};

export type Memo = StringMemo | BooleanMemo | NumericMemo;

export type MemoWithLabel = Memo & {
  readonly label: string;
};

type BaseKanbanMessage = Entity & {
  readonly type: 'default' | 'request';
  readonly username: string;
  readonly content: string;
  readonly timestamp: number;
};

export type DefaultKanbanMessage = BaseKanbanMessage & {
  readonly type: 'default';
};

export type RequestMessageStatus = 'pending' | 'accepted' | 'rejected' | 'canceled';
export type OperationRequestMessage = BaseKanbanMessage & {
  readonly type: 'request';
  readonly elementType: 'operation';
  readonly elementId: string;
  readonly status: RequestMessageStatus;
};

export type KanbanMessage = DefaultKanbanMessage | OperationRequestMessage;

/**
 * This type is used to determine when a kanban is selected to be used in a post.
 * It can contains several work intervals with different operators
 */
export type KanbanHandling = TimedInterval & {
  readonly postId: string;
  readonly standId: string;
  readonly intervals: readonly KanbanWorkInterval[];
};

export type KanbanWorkIntervalType = 'work' | 'anomaly' | 'andon';

export type KanbanWorkInterval = TimedInterval & {
  readonly users: readonly string[];
  readonly comment: string;
  readonly type: KanbanWorkIntervalType;
};

export type KanbanLogisticInfos = {
  readonly keyNumber: string;
  readonly locationHistory: readonly KanbanLocationElement[];
};

export type KanbanLocationElement = Entity & {
  readonly location: string;
  readonly date: number;
};

/**
 * Status of the package deal.
 * - canceled: the package deal has been once created but has been abandonned for some reasons
 * - available: the package deal has been created. If (or when) the package deal is validated by a referent, this is a task to do for operators
 * - null if no choice have been made
 */
export type PackageDealStatus = 'available' | 'canceled';

export type PackageDealStatusWithAchievability = PackageDealStatus | 'achievable';

export const OPERATION_TYPES = [
  'MarketPlaceOnlineSell',
  'MarketPlaceOnlinePublish',
  'Validation',
  'SendInvoiceMail',
  'Delivery',
  'CreateInvoice',
  'GenerateCreateInvoiceOperations',
  'Connector',
  'SparePartOrder',
  'ReferenceSparePart',
  'UploadDocument',
] as const;

export type OperationType = (typeof OPERATION_TYPES)[number];

export type OperationDocumentsInfo = {
  readonly documentLabel: string;
  readonly targetFolder: string;
  readonly documentsFileNames: readonly string[];
};

export type Operation = OperationDesc & {
  readonly operationDescId: string;
  readonly workload: number;
  readonly completionDate: number | null;
  readonly user: string | null;
  // Set with subcontractors (optional, omitted in most cases)
  readonly subcontractor?: string | null;
  // type is optional to decrease database size (in most case, it is left undefined)
  readonly type?: OperationType | undefined;
  readonly documentsInfo?: OperationDocumentsInfo | undefined;
};

export type PackageDealNumericVariable = PackageDealNumericVariableBaseType & {
  readonly value: number;
};

export type PackageDealBooleanVariable = PackageDealBooleanVariableBaseType & {
  readonly value: boolean;
};

export type PackageDealTextualVariable = PackageDealTextualVariableBaseType & {
  readonly value: string;
};

export type PackageDealVariable =
  | PackageDealBooleanVariable
  | PackageDealNumericVariable
  | PackageDealTextualVariable;

export type BasePackageDeal = Entity &
  Deletable &
  Labelled &
  Priceable &
  AttributesHolder &
  WithAttachments & {
    readonly code: string;
    readonly category: PackageDealCategory;
    readonly carElement: CoreFields<CarElement> | undefined;
    readonly estimateComment: string;
    readonly status: PackageDealStatus | null;
    readonly recommendedByExpert: boolean;
    readonly unachievableReason: string | null;
    readonly operations: readonly Operation[];
    readonly variables: Record<string, PackageDealVariable>;
    /**
     * A comment zone for the expert to other operators
     */
    readonly comment: string;
    /**
     * Following properties are optional to limit DB size
     */
    readonly purchaseOrderId?: string;
    readonly isSubcontractable?: boolean; // in most case: false
    readonly ignoreVAT?: boolean; // in most case: false
    readonly marketplaceBuyCategory?: MarketplaceCategory; // in most case: 'NA'
    readonly marketplaceSaleCategory?: MarketplaceCategory; // in most case: 'NA'
  };

export type PriceablePackageDeal = BasePackageDeal & {
  readonly spareParts: readonly PriceableSparePart[];
};

export type PackageDeal = BasePackageDeal & {
  readonly packageDealDescId: string;
  readonly hint: string;
  readonly overridablePrice: boolean;
  readonly priceExpression: string;
  readonly priceIsOverridden: boolean;
  readonly durationExpression: string;
  readonly duration: number;
  readonly spareParts: readonly SparePart[];
  readonly tags: readonly string[];
};

export type PriceableSparePart = Entity &
  Deletable &
  Labelled &
  Priceable & {
    readonly managementType: SparePartManagementType;
    readonly commentForCustomer: string;
    readonly quantity: number;
    readonly standId: string;
    readonly provider: string | null;
    readonly dateOfReception: number | null;
  };

export type SparePart = PriceableSparePart & {
  readonly comment: string;
  readonly commentForWorkshop: string;
  readonly providerUnitPrice: number;
  readonly priceIsOverridden: boolean;
  readonly dateOfOrder: number | null;
  readonly dateOfReference: number | null;
  // This is an optional information indicating the planed reception
  // date. If set to -1, indicates that the we are not able to
  // announce a delay for that spare part (anomaly status).
  readonly estimatedDateOfReception: number | null;
};

export type DisplayableSparePart = SparePart & {
  readonly displayLabel: string;
};

export type KanbanIdentity = Entity & {
  readonly license: string;
};

export type KanbanSearchResult = {
  // Total kanbans found
  readonly totalFound: number;
  // Kanbans count on each page
  readonly pageSize: number;
  readonly offset: number;
  readonly kanbans: readonly KanbanSummary[];
};

export type KanbanSummary = AttributesHolder &
  Entity &
  SequencedObject & {
    readonly contract: KanbanContract;
    readonly infos: KanbanInfos;
    readonly marketplacePublicPrice?: number;
    readonly customer: KanbanCustomer;
    readonly location: string | undefined;
    readonly status: RepositoryEntityStatus;
    readonly creationDate: number;
    readonly dueDate: number | null;
    readonly refitEndDate: number | null;
    readonly isFinished: boolean;
    readonly kanbanSource: KanbanSource;
    readonly kanbanSourceId: string | null;
    readonly price: number;
    readonly isRevoked: boolean;
  };

// Order of status in the array is important
// Position in the enum is used to sort the status
export const STAND_HANDLING_STATUS_VALUES = [
  'none',
  'hasBeenHandled',
  'waitingForHandle', // For kanbans in the waiting list of a post in the workshop
  'anomaly',
  'currentlyHandled',
] as const;

export type StandHandlingStatus = (typeof STAND_HANDLING_STATUS_VALUES)[number];

export type GetStringValueFromKanbanFunction = <K extends CoreFields<Kanban>>(k: K) => string;

export type GetNumberValueFromKanbanFunction = <K extends CoreFields<Kanban>>(k: K) => number;

export type ValuableFromKanbanVariable = {
  variableName: string;
  getValueFromKanban: GetStringValueFromKanbanFunction | GetNumberValueFromKanbanFunction;
  variableType: 'string' | 'number';
};

export type PackageDealsSignature = {
  readonly selectedPackageDeals: {
    readonly achievables: readonly PackageDealSignature[];
    readonly unachievables: readonly PackageDealSignature[];
  };
  readonly canceledPackageDeals: readonly PackageDealSignature[];
};

export type PackageDealSignature = {
  readonly code: string;
  readonly price: number;
  readonly sparePartsPrice: number;
  readonly recommendedByExpert: boolean;
};
