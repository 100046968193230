import { isTruthyAndNotEmpty } from '../asserts.js';

export function formatPrice(number: number, digits?: number): string {
  const minimumFractionDigits = digits ?? (Number.isInteger(number) ? 0 : 2);
  const formattedNumber = new Intl.NumberFormat('fr-FR', {
    currency: 'EUR',
    style: 'currency',
    useGrouping: true,
    minimumFractionDigits,
    maximumFractionDigits: 2,
  }).format(number);

  return formattedNumber.replace(/\s/g, ' ');
}

export function formatKilometers(kilometers: number): string {
  return new Intl.NumberFormat('fr-FR', {
    style: 'unit',
    unit: 'kilometer',
    unitDisplay: 'short',
  }).format(kilometers);
}

export function formatCompanyId(companyId: string): string {
  if (isTruthyAndNotEmpty(companyId)) {
    if (companyId === 'crnm1') {
      return 'Nantes';
    }
    return companyId.charAt(0).toUpperCase() + companyId.slice(1);
  }
  return '';
}

/**
 * Sanitize a string by applying the following transformations:
 * - uppercase => lowercase
 * - letter with diacritics => letter without diacritics
 * - hyphen => removed
 * - space => removed
 *
 * Example:
 * Amazing-Test -> amazingtest
 * àáâãäå -> aaaaaa
 * @param value input value
 * @return a sanitized version of the input text (see transformation above)
 */
export function sanitizeValue(value: string): string {
  return removeDiacritics(value).toLowerCase().replace(/\s/g, '').replace(/-/g, '');
}

function removeDiacritics(value: string): string {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function isValidEmailAddressStructure(emailAddress: string): boolean {
  const validEmailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validEmailRegex.test(String(emailAddress).toLowerCase());
}
