import type { JSX } from 'react';
import i18next, { t } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  CarElement,
  CarViewCategory,
  DocumentDesc,
  OperationDesc,
  PackageDealDesc,
} from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import {
  AvailablePermissionPaths,
  enumerate,
  globalHelpers,
  hasModifyPermission,
  mergeArrayItems,
  nonDeleted,
  packageDealDescHelpers,
  shortDayMonthYearHourFormatOptions,
} from '@stimcar/libs-base';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import { Button, ImportButton, ModalCardDialog, Select } from '@stimcar/libs-uitoolkit';
import type { TableToolbarConfiguration } from '../../../lib/bulma/elements/table/Table.js';
import type { ColumnDesc } from '../../../lib/bulma/elements/table/typings/store.js';
import type { Store } from '../../state/typings/store.js';
import { applyAndFilterTableItemsAction } from '../../../lib/bulma/elements/table/filterAndSortUtils.js';
import { TableWithSublines } from '../../../lib/bulma/elements/table/Table.js';
import { useComponentWithMountTracking } from '../../../lib/hooks/useComponentWithMountTracking.js';
import { useHasModifyPermission } from '../../../registeredapp/permissionHooks.js';
import {
  getAllCarViewCategoryLabels,
  useGetAllCarViewCategoryLabels,
} from '../../../utils/carViewCategoriesTranslationHooks.js';
import { downloadFileAction, uploadFileAction } from '../../utils/downloadUploadAction.js';
import type {
  AdminPackageDealDescsState,
  DeletePackageDealDescModalState,
} from './typings/store.js';
import { packageDealDescTableContentProvider } from './adminPackageDealDescUtils.js';
import {
  CopyPackageDealDescModal,
  openCopyPackageDealDescModalAction,
} from './components/CopyPackageDealDescModal.js';
import {
  EditPackageDealDescModal,
  openClonePackageDealDescModalAction,
  openCreatePackageDealDescModalAction,
  openUpdatePackageDealDescModalAction,
  packageDealDescUpdateNotificationInEditDialogAction,
} from './components/EditPackageDealDescModal.js';
import { EMPTY_ADMIN_PACKAGE_DEAL_DESCS_STATE } from './typings/store.js';

function openPackageDealDescDeleteModalAction(
  { actionDispatch }: ActionContext<Store, DeletePackageDealDescModalState>,
  pddId: string
): void {
  actionDispatch.setProperty('active', true);
  actionDispatch.setProperty('packageDealId', pddId);
}

export async function updateAdminPackageDealDescViewStateFromSSEAction(
  ctx: ActionContext<Store, AdminPackageDealDescsState>,
  updatedOrAddedPackageDealDescs: readonly PackageDealDesc[],
  removedPackageDealIds: readonly string[]
): Promise<void> {
  const { actionDispatch, getState, getGlobalState } = ctx;
  // Only update the state if the component is mounted
  if (!getState().componentIsMounted) {
    return;
  }

  // Merge actual items with SSE updates
  const { items, selectedPackageDealDatabase } = getState();
  const newPackageDeals = mergeArrayItems(
    items,
    /** only keep package deals of the same package deal database */
    updatedOrAddedPackageDealDescs.filter((pkg) => pkg.database === selectedPackageDealDatabase),
    removedPackageDealIds
  );

  const { user, isOnline } = getGlobalState().session;
  let isEditionForbidden = true;
  if (user) {
    isEditionForbidden = hasModifyPermission(
      user.permissions,
      AvailablePermissionPaths.REPOSITORY_ACCESS_PERMISSION('packageDealDesc')
    );
  }
  const carViewCategoryLabels = getAllCarViewCategoryLabels(i18next.t);
  const { carElements } = getState();
  const columnDescs = computeColumnDescs(
    carElements,
    carViewCategoryLabels,
    isEditionForbidden,
    isOnline
  );

  // Update table items
  applyAndFilterTableItemsAction(ctx, newPackageDeals, columnDescs);

  const modalActionDispatch = actionDispatch.scopeProperty('editPackageDealDescModal');
  await Promise.all(
    updatedOrAddedPackageDealDescs.map(
      async (pck): Promise<void> =>
        await modalActionDispatch.exec(packageDealDescUpdateNotificationInEditDialogAction, pck)
    )
  );
}

export function updateCarElementsInAdminPackageDealDescViewStateFromSSEAction(
  ctx: ActionContext<Store, AdminPackageDealDescsState>,
  updatedCarElements: CarElement[],
  removedCarElementIds: readonly string[]
) {
  const { actionDispatch, getState } = ctx;
  // Only update the state if the component is mounted
  if (!getState().componentIsMounted) {
    return;
  }

  // Merge actual items with SSE updates
  const { carElements } = getState();
  const newCarElements = mergeArrayItems(carElements, updatedCarElements, removedCarElementIds);
  actionDispatch.setProperty('carElements', newCarElements);
}

const computeColumnDescs = (
  carElements: readonly CarElement[],
  carViewCategoryLabels: Record<CarViewCategory, string>,
  isEditionForbidden: boolean,
  isOnline: boolean
): readonly ColumnDesc<
  Store,
  AdminPackageDealDescsState,
  PackageDealDesc,
  OperationDesc | DocumentDesc
>[] => {
  return [
    {
      columnLabel: i18next.t('packageDealDescs:table.code'),
      columnType: 'display',
      id: 'code',
      propertyType: 'string',
      getPropertyValue: (pdd): string => pdd.code,
      isNotHideable: true,
    },
    {
      columnLabel: i18next.t('packageDealDescs:table.label'),
      columnType: 'display',
      id: 'label',
      propertyType: 'string',
      getPropertyValue: (pdd): string => pdd.label,
      isDisplayedByDefault: true,
      subCell: {
        getPropertyValue: (operationOrDocumentDesc) =>
          (operationOrDocumentDesc as OperationDesc).workloadExpression
            ? operationOrDocumentDesc.label
            : t(`globals:uploadDocumentOperationLabel`, {
                docLabel: operationOrDocumentDesc.label,
              }),
        id: 'operationOrDocumentDescLabel',
        propertyType: 'string',
      },
    },
    {
      columnLabel: i18next.t('packageDealDescs:table.categoryOrStand'),
      columnType: 'display',
      id: 'categoryOrStand',
      propertyType: 'string',
      getPropertyValue: (pdd): string =>
        isTruthyAndNotEmpty(pdd.category)
          ? i18next.t(`packageDealDescs:packageDealCategory.${pdd.category}`)
          : '',
      isDisplayedByDefault: true,
      subCell: {
        getPropertyValue: ({ standId }) => {
          if (isTruthyAndNotEmpty(standId)) {
            return standId;
          }
          return i18next.t('packageDealDescs:table.standTooltip');
        },
        id: 'operationOrDocumentDescStand',
        propertyType: 'string',
      },
    },
    {
      columnLabel: i18next.t('packageDealDescs:table.carElementsCount'),
      columnType: 'display',
      id: 'carElementsCount',
      propertyType: 'number',
      getPropertyValue: (pdd): number => pdd.carElementIds.length,
      isDisplayedByDefault: true,
    },
    {
      columnLabel: i18next.t('packageDealDescs:table.packageDealTags'),
      columnTooltip: i18next.t('packageDealDescs:table.packageDealTagsTooltip'),
      columnType: 'display',
      id: 'packageDealTags',
      propertyType: 'string',
      getPropertyValue: (pdd): string => enumerate(pdd.tags) ?? '',
    },
    {
      columnLabel: i18next.t('packageDealDescs:table.variableDescs'),
      columnTooltip: i18next.t('packageDealDescs:table.variableDescsTooltip'),
      columnType: 'display',
      id: 'variableDescs',
      propertyType: 'string',
      getPropertyValue: (pdd): string =>
        packageDealDescHelpers.convertVariableDescTypeToDisplayedString(pdd.variableDescs),
    },
    {
      columnLabel: i18next.t('packageDealDescs:table.durationExpression'),
      columnTooltip: i18next.t('packageDealDescs:table.durationExpressionTooltip'),
      columnType: 'display',
      id: 'durationExpression',
      propertyType: 'string',
      getPropertyValue: (pdd): string =>
        packageDealDescHelpers.convertPackageDealRelatedExpressionToDisplayedString(
          pdd.durationExpression
        ),
      subCell: {
        getPropertyValue: (od) => {
          if (isTruthyAndNotEmpty((od as OperationDesc).workloadExpression)) {
            return packageDealDescHelpers.convertPackageDealRelatedExpressionToDisplayedString(
              'od.workloadExpression'
            );
          }
          return undefined;
        },
        id: 'odDurationExpression',
        propertyType: 'string',
      },
    },
    {
      columnLabel: i18next.t('packageDealDescs:table.priceExpression'),
      columnType: 'display',
      columnTooltip: i18next.t('packageDealDescs:table.priceExpressionTooltip'),
      id: 'priceExpression',
      propertyType: 'string',
      getPropertyValue: (pdd): string =>
        packageDealDescHelpers.convertPackageDealRelatedExpressionToDisplayedString(
          pdd.priceExpression
        ),
    },
    {
      columnLabel: i18next.t('packageDealDescs:table.overridablePrice'),
      columnType: 'display',
      id: 'overridablePrice',
      propertyType: 'boolean',
      getPropertyValue: (pdd): boolean => pdd.overridablePrice,
      isDisplayedByDefault: true,
    },
    {
      columnLabel: i18next.t('packageDealDescs:table.spareParts'),
      columnType: 'display',
      id: 'sparePartDescs',
      propertyType: 'string',
      getPropertyValue: (pdd): string =>
        enumerate(pdd.sparePartDescs.filter(nonDeleted).map((sp) => sp.label)),
      isDisplayedByDefault: true,
    },
    {
      columnLabel: i18next.t('packageDealDescs:table.documents'),
      columnType: 'display',
      id: 'documentDescs',
      propertyType: 'string',
      getPropertyValue: (pdd): string =>
        enumerate(pdd.documentDescs.filter(nonDeleted).map((doc) => doc.label)),
      isDisplayedByDefault: true,
    },
    {
      columnLabel: i18next.t('packageDealDescs:table.carViewCategories'),
      columnType: 'display',
      id: 'carViewCategories',
      propertyType: 'string',
      getPropertyValue: (pdd): string => {
        if (pdd.carElementIds.length === 0) {
          return carViewCategoryLabels.MISC;
        }
        return packageDealDescHelpers
          .findCarViewCategoriesFromCarElements(pdd, carElements)
          .map((cat) => carViewCategoryLabels[cat])
          .join(', ');
      },
      isDisplayedByDefault: true,
    },
    {
      columnLabel: i18next.t('packageDealDescs:table.hint'),
      columnType: 'display',
      id: 'hint',
      propertyType: 'string',
      getPropertyValue: (pdd): string => pdd.hint,
      isDisplayedByDefault: true,
    },
    {
      columnLabel: i18next.t('packageDealDescs:table.lastModified'),
      columnType: 'display',
      id: 'lastModified',
      propertyType: 'date',
      getPropertyValue: (pdd): number => pdd.timestamp,
      getDisplayedValue(pdd): string {
        const timestamp = this.getPropertyValue(pdd);
        if (timestamp !== undefined || (timestamp !== null && typeof timestamp === 'number')) {
          return globalHelpers.convertTimestampToDateString(
            timestamp as number,
            shortDayMonthYearHourFormatOptions
          );
        }
        return '';
      },
    },
    {
      columnType: 'action',
      id: 'copy',
      columnLabel: i18next.t('packageDealDescs:table.copyColumn'),
      columnIcon: {
        iconId: '',
        showText: false,
      },
      disabled: (): boolean => isEditionForbidden || !isOnline,
      getCellIconId: (): string => {
        return 'up-right-from-square';
      },
      cellTooltip: i18next.t('packageDealDescs:table.copyButton'),
      columnStyle: { width: '2.25em' },
      isNotHideable: true,
      action: async (dispatch, pdd): Promise<void> => {
        await dispatch
          .scopeProperty('copyPackageDealDescModal')
          .exec(openCopyPackageDealDescModalAction, pdd.id);
      },
    },
    {
      columnType: 'action',
      id: 'clone',
      columnLabel: i18next.t('packageDealDescs:table.cloneColumn'),
      columnIcon: {
        iconId: '',
        showText: false,
      },
      disabled: (): boolean => isEditionForbidden,
      getCellIconId: (): string => {
        return 'clone';
      },
      cellTooltip: i18next.t('packageDealDescs:table.cloneButton'),
      columnStyle: { width: '2.25em' },
      isNotHideable: true,
      action: async (dispatch, pdd): Promise<void> => {
        await dispatch.exec(openClonePackageDealDescModalAction, pdd.id);
      },
    },
    {
      columnType: 'action',
      id: 'update',
      columnLabel: i18next.t('packageDealDescs:table.editColumn'),
      columnIcon: {
        iconId: '',
        showText: false,
      },
      disabled: (): boolean => isEditionForbidden,
      getCellIconId: (): string => {
        return 'edit';
      },
      cellTooltip: i18next.t('packageDealDescs:table.editButton'),
      columnStyle: { width: '2.25em' },
      isNotHideable: true,
      action: async (dispatch, pdd): Promise<void> => {
        await dispatch.exec(openUpdatePackageDealDescModalAction, pdd.id);
      },
    },
    {
      columnType: 'action',
      id: 'delete',
      columnLabel: i18next.t('packageDealDescs:table.deleteColumn'),
      columnIcon: {
        iconId: '',
        showText: false,
      },
      disabled: (): boolean => isEditionForbidden,
      getCellIconId: (): string => {
        return 'trash';
      },
      cellTooltip: i18next.t('packageDealDescs:table.deleteButton'),
      columnStyle: { width: '2.25em' },
      isNotHideable: true,
      action: async (dispatch, pdd): Promise<void> => {
        await dispatch
          .scopeProperty('deleteModal')
          .exec(openPackageDealDescDeleteModalAction, pdd.id);
      },
    },
  ];
};

export async function initializeAdminPackageDealDescStateAction({
  getGlobalState,
  actionDispatch,
  carElementRepository,
}: ActionContext<Store, AdminPackageDealDescsState>): Promise<void> {
  const carElements = await carElementRepository.getAllEntities();
  actionDispatch.setProperty('carElements', carElements);
  const { packageDealDatabases } = getGlobalState().siteConfiguration;
  actionDispatch.setProperty('selectedPackageDealDatabase', packageDealDatabases[0]);
}

export function initializeDefaultSortAction({
  actionDispatch,
}: ActionContext<Store, AdminPackageDealDescsState>) {
  // Add a default sort option (sort by code)
  actionDispatch
    .scopeProperty('sortsMenuState')
    .scopeProperty('sorts')
    .setValue([
      {
        id: 'code',
        direction: 'UP',
      },
    ]);
}

export function AdminPackageDealDescs({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('packageDealDescs');
  const $ = $gs.$adminView.$adminPackageDealDescs;

  // Allows to track if the view is mounted (through a boolean in the state)
  useComponentWithMountTracking(
    $, // selector
    initializeAdminPackageDealDescStateAction, // init action
    EMPTY_ADMIN_PACKAGE_DEAL_DESCS_STATE // unmount state
  );

  const isOnline = useGetState($gs.$session.$isOnline);

  const packageDealDatabases = useGetState($gs.$siteConfiguration.$packageDealDatabases);

  const categoryLabels = useGetAllCarViewCategoryLabels();

  const isEditionForbidden = !useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.REPOSITORY_ACCESS_PERMISSION('packageDealDesc')
  );

  const openCreatePackageDealDescModalActionCallback = useActionCallback(
    async ({ actionDispatch, getState }) => {
      await actionDispatch.exec(
        openCreatePackageDealDescModalAction,
        getState().selectedPackageDealDatabase
      );
    },
    [],
    $
  );

  const carElements = useGetState($.$carElements);

  const columnDescs = useMemo(() => {
    return computeColumnDescs(carElements, categoryLabels, isEditionForbidden, isOnline);
  }, [categoryLabels, carElements, isEditionForbidden, isOnline]);

  const selectedPackageDealDatabase = useGetState($.$selectedPackageDealDatabase);

  const getSubLines = useCallback(
    (item: PackageDealDesc) => [
      ...item.operationDescs.filter(nonDeleted),
      ...item.documentDescs.filter(nonDeleted),
    ],
    []
  );

  const loadAndFilterTableItemsActionCallback = useActionCallback(
    async (ctx) => {
      const items = await packageDealDescTableContentProvider(ctx);
      applyAndFilterTableItemsAction(ctx, items, columnDescs);
    },
    [columnDescs],
    $
  );

  const deleteActionCallback = useActionCallback(
    async ({
      actionDispatch,
      getState,
      packageDealDescRepository,
    }: ActionContext<Store, DeletePackageDealDescModalState>): Promise<void> => {
      const { packageDealId } = getState();
      await packageDealDescRepository.archiveEntity(packageDealId);
      actionDispatch.setProperty('active', false);
    },
    [],
    $.$deleteModal
  );

  const $selectedPackageDealDatabaseWithChangeTrigger = useSelectorWithChangeTrigger(
    $.$selectedPackageDealDatabase,
    loadAndFilterTableItemsActionCallback
  );

  const toolbarConf = useMemo((): TableToolbarConfiguration => {
    return {
      showSorts: true,
      filters: { show: true },
      itemCount: { show: true },
      showColumnSelection: true,
      textualSearch: {
        show: true,
        customPlaceholder: t('refititCommonComponents:carElementsFilter.textField'),
      },
      localStorageKey: LocalStorageKeys.ADMIN_PDD_COLUMNS_PREFERENCE,
    };
  }, [t]);

  const downloadFileActionActionCallback = useActionCallback(
    async ({ actionDispatch }) =>
      actionDispatch.exec(downloadFileAction, 'packageDealDesc', selectedPackageDealDatabase),
    [selectedPackageDealDatabase],
    $gs
  );

  const uploadFileActionActionCallback = useActionCallback(
    async ({ actionDispatch }, files: readonly File[]) =>
      actionDispatch.exec(uploadFileAction, 'packageDealDesc', files),
    [],
    $gs
  );

  return (
    <>
      <div className="level">
        <div className="level-left">
          <div>
            <p className="title is-2">{t('title')}</p>
            <div className="subtitle is-4">
              <p>{t('subtitle1')}</p>
              <p>{t('subtitle2')}</p>
            </div>
          </div>
        </div>
        <div className="level-right">
          <div className="columns">
            <div className="column is-narrow">
              <Select
                entries={packageDealDatabases}
                $={$selectedPackageDealDatabaseWithChangeTrigger}
                isEmptyValueAllowed={false}
                sortEntries
                iconId="file-contract"
              />
            </div>
            <div className="column is-narrow">
              <Button
                label={t('importExport.downloadTitle')}
                iconId="download"
                onClick={downloadFileActionActionCallback}
              />
            </div>
            <div className="column is-narrow">
              <ImportButton
                onFilesReadyCallback={uploadFileActionActionCallback}
                label={t('importExport.uploadTitle')}
                accept="application/vnd.ms-excel"
              />
            </div>
            <div className="column is-narrow">
              <Button
                onClick={openCreatePackageDealDescModalActionCallback}
                disabled={isEditionForbidden}
                label={t('newOperationDescButton')}
                iconId="plus-circle"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <TableWithSublines
            $={$}
            columnDescs={columnDescs}
            contentProvider={packageDealDescTableContentProvider}
            preInitActionCallback={useActionCallback(initializeDefaultSortAction, [], $)}
            getSubLines={getSubLines}
            isOnline={isOnline}
            isScrollable
            isTruncable
            tableClassName="table is-bordered is-narrow is-hoverable is-fullwidth"
            toolbar={toolbarConf}
            showRepositoryStatus
          />
        </div>
      </div>
      <EditPackageDealDescModal $gs={$gs} />
      <CopyPackageDealDescModal $={$.$copyPackageDealDescModal} />
      <ModalCardDialog
        $active={$.$deleteModal.$active}
        title={t('deleteModal.title')}
        onOkClicked={deleteActionCallback}
      >
        <p>{t('deleteModal.message')}</p>
      </ModalCardDialog>
    </>
  );
}
