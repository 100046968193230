import { HTTPClient } from '@stimcar/libs-kernel';

export async function downloadAndSaveBlob(blob: Blob, filename: string): Promise<void> {
  const objectUrl = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = objectUrl;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  if (link.parentNode) {
    link.parentNode.removeChild(link);
  }
}

export async function downloadAndSaveFromResponse(
  response: Response,
  defaultFilename: string
): Promise<void> {
  let filename = defaultFilename;
  // eslint-disable-next-line no-restricted-syntax
  for (const [header, value] of response.headers.entries()) {
    if (header === 'content-disposition') {
      const elements = value.split('filename="');
      if (elements.length > 0) {
        const name = elements[1];
        const filenameEndPosition = name.indexOf('"');
        filename = name.substring(0, filenameEndPosition);
      }
      break;
    }
  }
  const blob = await response.blob();
  await downloadAndSaveBlob(blob, filename);
}

export async function downloadAndSave(
  httpClient: HTTPClient,
  url: string,
  defaultFilename: string
): Promise<void> {
  const response = await httpClient.httpGet(url);
  await downloadAndSaveFromResponse(response, defaultFilename);
}
