import type { JSX } from 'react';
import React from 'react';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { FaIcon } from '../icons/FaIcon.js';

export interface TableActionHeaderCellProps {
  readonly iconId?: string;
  readonly tooltip?: string;
  readonly additionalClass?: string;
}

export function TableActionHeaderCell({
  iconId,
  tooltip,
  additionalClass,
}: TableActionHeaderCellProps): JSX.Element {
  // we put a 2.25em (~36px) width in order to ensure That there will be enough space to display the icon if the horizontal size change
  return (
    <th
      style={{ width: '2.25em' }}
      className={`has-text-centered${
        isTruthyAndNotEmpty(additionalClass) ? ` ${additionalClass}` : ''
      }`}
    >
      {iconId && <FaIcon id={iconId} tooltip={tooltip} />}
    </th>
  );
}
