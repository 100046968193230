import type { SortDirection } from '@stimcar/libs-kernel';
import { keysOf } from '@stimcar/libs-kernel';
import type {
  Compare,
  SiteConfiguration,
  Stand,
  StandType,
  WorkflowNode,
} from '../../model/index.js';
import { objectListToMap } from '../misc.js';
import { padRight } from '../string-helper.js';

export interface GNode {
  readonly x: number;
  readonly y: number;
  readonly targets: readonly string[];
}

export interface GLayout {
  readonly nodes: Record<string, GNode>;
  readonly width: number;
  readonly height: number;
}

function computeLayout(wNode: WorkflowNode, indent = ''): GLayout {
  const nestedIndent = `${indent}  `;
  const { id, fork, join } =
    typeof wNode === 'string' ? { id: wNode, fork: undefined, join: undefined } : wNode;
  // Compute targets list
  const targets: string[] = [];
  if (fork) {
    targets.push(...fork.map((node): string => (typeof node === 'string' ? node : node.id)));
  } else if (join) {
    targets.push(typeof join === 'string' ? join : join.id);
  }
  const nodes: Record<string, GNode> = {};
  nodes[id] = { x: 0, y: 0, targets };
  let yCursor = 1;
  let xCursor = 0;
  if (fork && fork.length > 0) {
    let maxHeight = 0;
    const forEachIndent = `${nestedIndent}  `;
    fork.forEach((child) => {
      const { width, height, nodes: childNodes } = computeLayout(child, forEachIndent);
      keysOf(childNodes).forEach((childId) => {
        const { x: childX, y: childY, targets: childTargets } = childNodes[childId];
        if (nodes[childId]) {
          throw new Error(`Duplicate node ${childId}`);
        }
        nodes[childId] = {
          x: childX + xCursor,
          y: childY + yCursor,
          targets:
            childTargets.length === 0 && join
              ? [typeof join === 'string' ? join : join.id]
              : childTargets,
        };
      });
      xCursor += width;
      if (height > maxHeight) {
        maxHeight = height;
      }
    });
    yCursor += maxHeight;
  }
  let width = xCursor > 1 ? xCursor : 1;
  if (join) {
    const {
      width: subWidth,
      height: subHeight,
      nodes: joinNodes,
    } = computeLayout(join, `${indent}  `);
    keysOf(joinNodes).forEach((joinId) => {
      const { x: childX, y: childY, targets: joinTargets } = joinNodes[joinId];
      if (nodes[joinId]) {
        throw new Error(`Duplicate node ${joinId}`);
      }
      nodes[joinId] = {
        x: childX,
        y: childY + yCursor,
        targets: joinTargets,
      };
    });
    yCursor += subHeight;
    if (subWidth > width) {
      width = subWidth;
    }
  }
  const result = { width, height: yCursor, nodes };
  return result;
}

function layoutToString({ width, height, nodes }: GLayout): string {
  let maxIdLength = 0;
  keysOf(nodes).forEach((id) => {
    if (id.length > maxIdLength) {
      maxIdLength = id.length;
    }
  });
  maxIdLength += 1;
  const array: string[][] = [];
  for (let w = 0; w < width; w += 1) {
    array[w] = Array(height);
  }
  keysOf(nodes).forEach((id) => {
    const { x, y, targets } = nodes[id];
    const isLast = targets.length === 0;
    array[x][y] = padRight(id, maxIdLength, '', isLast ? ' ' : '-');
    if (targets.length > 0) {
      targets.forEach((t) => {
        const { x: tx, y: ty } = nodes[t];
        if (tx > x) {
          for (let i = x + 1; i <= tx; i += 1) {
            if (!array[i][y]) array[i][y] = padRight('+', maxIdLength, '', i === tx ? '-' : ' ');
          }
        } else if (tx < x) {
          for (let i = tx + 1; i <= x; i += 1) {
            if (!array[i][ty]) array[i][ty] = padRight('+', maxIdLength);
          }
        }
        if (ty > y) {
          for (let i = y + 1; i <= ty; i += 1) {
            if (!array[x][i])
              array[x][i] =
                i < ty ? padRight('-', maxIdLength, '', '-') : padRight('+', maxIdLength);
          }
        }
      });
    }
  });
  let str = '';
  for (let w = 0; w < width; w += 1) {
    for (let h = 0; h < height; h += 1) {
      const value = array[w][h];
      str += padRight(value, maxIdLength, '', ' ');
    }
    str += '\n';
  }
  return str;
}

const findLeafNodes = (...nodes: readonly WorkflowNode[]): readonly string[] => {
  if (nodes.length === 1) {
    const w = nodes[0];
    const { id, fork, join } =
      typeof w === 'string' ? { id: w, fork: undefined, join: undefined } : w;
    if (join) {
      return findLeafNodes(join);
    }
    if (fork) {
      return findLeafNodes(...fork);
    }
    return [id];
  }
  return nodes.reduce<readonly string[]>((p, c) => [...p, ...findLeafNodes(c)], []);
};

const findPrecedingNodesOf = (w: WorkflowNode, nodeId: string): readonly string[] | undefined => {
  const { id, fork, join } =
    typeof w === 'string' ? { id: w, fork: undefined, join: undefined } : w;
  if (id === nodeId) {
    return [];
  }
  if (fork) {
    if (fork.map((f) => (typeof f === 'string' ? f : f.id)).includes(nodeId)) {
      return [id];
    }
    for (const forkItem of fork) {
      const preceding = findPrecedingNodesOf(forkItem, nodeId);
      if (preceding !== undefined && preceding.length > 0) {
        return preceding;
      }
    }
  }
  if (!join) {
    return undefined;
  }
  const joinId = typeof join === 'string' ? join : join.id;
  if (joinId === nodeId) {
    if (!fork) {
      return [id];
    }
    return findLeafNodes(...fork);
  }
  return findPrecedingNodesOf(join, nodeId);
};

const countWorkflowNodeIdOccurences = (wn: WorkflowNode): Record<string, number> => {
  const ids: Record<string, number> = {};
  if (typeof wn === 'string') {
    ids[wn] = 1;
  } else {
    const { id: nodeId, fork, join } = wn;
    ids[nodeId] = 1;
    if (fork) {
      fork.forEach((f) => {
        const subIds = countWorkflowNodeIdOccurences(f);
        keysOf(subIds).forEach((id) => {
          ids[id] = !ids[id] ? 1 : ids[id] + 1;
        });
      });
    }
    if (join) {
      const subIds = countWorkflowNodeIdOccurences(join);
      keysOf(subIds).forEach((id) => {
        ids[id] = !ids[id] ? 1 : ids[id] + 1;
      });
    }
  }
  return ids;
};

const findDuplicateWorkflowNodeIds = (wn: WorkflowNode): string[] => {
  const duplicates: string[] = [];
  const ids = countWorkflowNodeIdOccurences(wn);
  keysOf(ids).forEach((id) => {
    if (ids[id] > 1) {
      duplicates.push(id);
    }
  });
  return duplicates;
};

const linearize = (wn: WorkflowNode): string[] => {
  const line: string[] = [];
  if (typeof wn === 'string') {
    line.push(wn);
  } else {
    const { id: nodeId, fork, join } = wn;
    line.push(nodeId);
    if (fork) {
      fork.forEach((f) => {
        line.push(...linearize(f));
      });
    }
    if (join) {
      line.push(...linearize(join));
    }
  }
  return line;
};

interface FlatOperationMinimalInterface {
  readonly standId: string;
  readonly packageDealLabel: string;
  readonly label: string;
}

function flatOperationsSorter<T extends FlatOperationMinimalInterface>(
  sortDirection: SortDirection,
  workflowNode: WorkflowNode
): Compare<T> {
  const linearWorkflow = linearize(workflowNode);
  return (op1: T, op2: T): number => {
    let result = 0;
    if (op1.standId !== op2.standId) {
      const op1Idx = linearWorkflow.indexOf(op1.standId);
      const op2Idx = linearWorkflow.indexOf(op2.standId);
      if (op1Idx > op2Idx) {
        if (sortDirection === 'UP') {
          result = 1;
        } else {
          result = -1;
        }
      } else if (op1Idx < op2Idx) {
        if (sortDirection === 'UP') {
          result = -1;
        } else {
          result = 1;
        }
      }
    } else if (op1.packageDealLabel !== op2.packageDealLabel) {
      result = op1.packageDealLabel.localeCompare(op2.packageDealLabel);
      if (sortDirection === 'DOWN') {
        result *= -1;
      }
    } else {
      result = op1.label.localeCompare(op2.label);
      if (sortDirection === 'DOWN') {
        result *= -1;
      }
    }
    return result;
  };
}

const isExpertiseStand = (stand: Stand): boolean => {
  return (stand.expertiseCategories ?? []).length > 0;
};

const getExpertiseStandsByWorkflowId = (
  siteConfiguration: SiteConfiguration
): Record<string, readonly string[]> => {
  const filterExpertiseStands = (stand: Stand) => {
    return isExpertiseStand(stand);
  };
  return getAllStandsByWorkflowIdCorrespondingToFilter(siteConfiguration, filterExpertiseStands);
};

const isStandOfType = (stand: Stand, type: StandType): boolean => {
  return stand.type === type;
};

export function getAllStandsOfType(
  siteConfiguration: SiteConfiguration,
  type: StandType
): readonly Stand[] {
  return siteConfiguration.stands.filter((stand) => isStandOfType(stand, type));
}

const getAllStandsOfTypeByWorkflowId = (
  siteConfiguration: SiteConfiguration,
  type: StandType
): Record<string, readonly string[]> => {
  const filterByStandType = (stand: Stand) => {
    return isStandOfType(stand, type);
  };
  return getAllStandsByWorkflowIdCorrespondingToFilter(siteConfiguration, filterByStandType);
};

const getAllStandsByWorkflowIdCorrespondingToFilter = (
  { workflows, stands }: SiteConfiguration,
  filter: (stand: Stand) => boolean
): Record<string, readonly string[]> => {
  const standsByWorkflowId: Record<string, string[]> = {};
  const standsMap = objectListToMap('id', stands);
  workflows.forEach((workflow) => {
    standsByWorkflowId[workflow.id] = [];
    linearize(workflow.definition).forEach((standId) => {
      if (filter(standsMap[standId])) {
        standsByWorkflowId[workflow.id].push(standId);
      }
    });
  });
  return standsByWorkflowId;
};

export const workflowHelpers = {
  findDuplicateWorkflowNodeIds,
  linearize,
  isExpertiseStand,
  layoutToString,
  computeLayout,
  flatOperationsSorter,
  getExpertiseStandsByWorkflowId,
  isStandOfType,
  getAllStandsOfType,
  getAllStandsOfTypeByWorkflowId,
  findPrecedingNodesOf,
  getAllStandsByWorkflowIdCorrespondingToFilter,
};
