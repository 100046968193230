import type { Entity } from '@stimcar/libs-kernel';
import { EMPTY_ENTITY } from '@stimcar/libs-kernel';
import { EMPTY_SITE_CONFIGURATION } from '../globalConstants.js';
import type { SiteConfiguration } from './configuration.js';

export type Named = Entity & {
  readonly name: string;
};

export const EMPTY_NAMED: Named = {
  ...EMPTY_ENTITY,
  name: '',
};

export type Company = Named;

export const EMPTY_COMPANY = {
  ...EMPTY_NAMED,
};

export type Site<NOTATION extends 'concise' | 'standard' = 'standard'> = Named & {
  readonly companyId: string;
  readonly configuration: SiteConfiguration<NOTATION>;
};

export const EMPTY_SITE: Site = {
  ...EMPTY_NAMED,
  companyId: '',
  configuration: EMPTY_SITE_CONFIGURATION,
};

export const Role = Object.freeze({
  Operator: 'Operator',
  Display: 'Display',
  WorkshopOperator: 'WorkshopOperator',
});

export type UserIdentity = {
  readonly login: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly isSubcontractor: boolean;
  // the firm is the one that actually employs the user, for instance most users are employed by Stimcar
  // but there are also users from providers like EsthétiCar
  readonly firm: string;
};

export type User = UserIdentity & {
  readonly active: boolean;
  readonly profiles: { names: readonly string[] };
};

export const EMPTY_USER: User = {
  login: '',
  firstName: '',
  lastName: '',
  isSubcontractor: true,
  firm: '',
  active: true,
  profiles: { names: [] },
};
