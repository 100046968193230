/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { DropResult } from '@hello-pangea/dnd';
import type { SortDirection } from '@stimcar/libs-kernel';
import type {
  ActionCallbackFromFunction,
  ActionContext,
  ArrayItemStateType,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type { AnyTableStoreDef } from '@stimcar/libs-uitoolkit';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { AddOnButtons, FaIcon, ScrollableContainer } from '@stimcar/libs-uitoolkit';
import { MenuButton } from '../../../components/MenuButton.js';
import type { DisplayColumnDesc, Sort, SortsMenuState } from './typings/store.js';

function removeSortAction<SD extends AnyTableStoreDef>(
  { actionDispatch }: ActionContext<SD, SortsMenuState>,
  columnId: string
): void {
  actionDispatch.reduce((initial: SortsMenuState) => {
    return {
      ...initial,
      sorts: initial.sorts.filter((s) => s.id !== columnId),
    };
  });
}

function removeAllSortsAction<SD extends AnyTableStoreDef>({
  actionDispatch,
}: ActionContext<SD, SortsMenuState>): void {
  actionDispatch.reduce((initial: SortsMenuState) => {
    return {
      ...initial,
      isActive: false,
      sorts: [],
    };
  });
}

function changeDirectionAction<SD extends AnyTableStoreDef>(
  { actionDispatch }: ActionContext<SD, SortsMenuState>,
  columnId: string,
  newDirection: SortDirection
): void {
  actionDispatch.reduce((initial: SortsMenuState) => {
    return {
      ...initial,
      sorts: initial.sorts.map((s): Sort => {
        if (s.id === columnId) {
          return {
            ...s,
            direction: newDirection,
          };
        }
        return s;
      }),
    };
  });
}

function applyDnd<SD extends AnyTableStoreDef>(
  { actionDispatch }: ActionContext<SD, SortsMenuState>,
  newSorts: readonly Sort[]
): void {
  actionDispatch.reduce((initial: SortsMenuState) => {
    return {
      ...initial,
      sorts: newSorts,
    };
  });
}

export interface SortsMenuProps<SD extends AnyTableStoreDef, O extends ArrayItemStateType> {
  readonly $: StoreStateSelector<SD, SortsMenuState>;
  readonly columnDescs: readonly DisplayColumnDesc<O>[];
  readonly buttonLabel?: string;
}

export function SortsMenu<SD extends AnyTableStoreDef, O extends ArrayItemStateType>({
  $,
  buttonLabel,
  columnDescs,
}: SortsMenuProps<SD, O>): JSX.Element {
  const [t] = useTranslation('libBulma');

  const sorts = useGetState($.$sorts);

  const removeSortActionCallback = useActionCallback(
    async ({ actionDispatch }, columnId: string) => {
      await actionDispatch.exec(removeSortAction, columnId);
    },
    [],
    $
  );

  const removeAllSortsActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(removeAllSortsAction);
    },
    [],
    $
  );

  const changeDirectionActionCallback = useActionCallback(
    async ({ actionDispatch }, columnId: string, newDirection: SortDirection) => {
      return actionDispatch.exec(changeDirectionAction, columnId, newDirection);
    },
    [],
    $
  );

  const onDragEnd = useActionCallback(
    async ({ actionDispatch }, result: DropResult): Promise<void> => {
      const { destination, source } = result;
      if (!destination) {
        return;
      }
      if (destination.index === source.index) {
        return;
      }

      const mutableArray = sorts.slice();
      // Remove Element from origin array
      const removedElement = mutableArray.splice(source.index, 1)[0];
      // Insert removed element in new position
      mutableArray.splice(destination.index, 0, removedElement);

      await actionDispatch.exec(applyDnd, mutableArray);
    },
    [sorts],
    $
  );

  return (
    <MenuButton
      label={buttonLabel || t('sortsMenu.buttonLabel', { count: sorts.length })}
      disabled={sorts.length === 0}
      $active={$.$active}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="dropdown-content">
          <button
            type="button"
            className="button has-text-centered is-text dropdown-item is-fullwidth"
            onClick={removeAllSortsActionCallback}
          >
            {t('sortsMenu.clearAll')}
          </button>
          <hr className="dropdown-divider" />
          <ScrollableContainer isNarrowUntilMaxHeight>
            <Droppable droppableId="droppable">
              {(provided): JSX.Element => (
                <div
                  // Beautiful-react-dnd API returns an any
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ref={(e): any => provided.innerRef(e)}
                  {...provided.droppableProps}
                >
                  {sorts.map((sort, index): JSX.Element => {
                    return (
                      <AllSortsMenuEntry
                        key={sort.id}
                        sort={sort}
                        columnDescs={columnDescs}
                        index={index}
                        removeSortActionCallback={removeSortActionCallback}
                        changeDirectionActionCallback={changeDirectionActionCallback}
                        $={$}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </ScrollableContainer>
        </div>
      </DragDropContext>
    </MenuButton>
  );
}

interface AllSortsMenuEntryProps<SD extends AnyTableStoreDef, O extends ArrayItemStateType> {
  readonly $: StoreStateSelector<SD, SortsMenuState>;
  readonly index: number;
  readonly sort: Sort;
  readonly columnDescs: readonly DisplayColumnDesc<O>[];
  readonly removeSortActionCallback: ActionCallbackFromFunction<
    SD,
    (columnName: string) => Promise<void> | void
  >;
  readonly changeDirectionActionCallback: ActionCallbackFromFunction<
    SD,
    (columnId: string, newDirection: SortDirection) => Promise<void> | void
  >;
}

function AllSortsMenuEntry<SD extends AnyTableStoreDef, O extends ArrayItemStateType>({
  $,
  sort,
  index,
  columnDescs,
  removeSortActionCallback,
  changeDirectionActionCallback,
}: AllSortsMenuEntryProps<SD, O>): JSX.Element {
  const [t] = useTranslation('libBulma');

  const buttons = useMemo(() => {
    return [
      {
        id: 'UP',
        label: 'A-Z',
        additionalClass: `is-rounded ${sort.direction === 'UP' ? 'is-primary' : ''}`,
      },
      {
        id: 'DOWN',
        label: 'Z-A',
        additionalClass: `is-rounded ${sort.direction === 'DOWN' ? 'is-primary' : ''}`,
      },
    ];
  }, [sort.direction]);

  const removeSortCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.execCallback(removeSortActionCallback, sort.id);
    },
    [sort.id, removeSortActionCallback],
    $
  );

  const changeDirectionCallback = useActionCallback(
    async ({ actionDispatch }, buttonId: string) => {
      await actionDispatch.execCallback(
        changeDirectionActionCallback,
        sort.id,
        buttonId as SortDirection
      );
    },
    [sort.id, changeDirectionActionCallback],
    $
  );

  const columnLabel = useMemo(() => {
    return columnDescs.find((cd) => cd.id === sort.id)?.columnLabel ?? 'Missing column';
  }, [columnDescs, sort.id]);

  return (
    <Draggable draggableId={sort.id} index={index}>
      {(provided): JSX.Element => (
        <div
          className="dropdown-item"
          // Beautiful-react-dnd API returns an any
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={(e): any => provided.innerRef(e)}
          {...provided.draggableProps}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'minmax(1px, auto) 5fr minmax(10px, auto) minmax(10px, auto)',
              gridColumnGap: '10px',
              alignItems: 'center',
            }}
          >
            <a role="button" onClick={removeSortCallback}>
              <FaIcon id="times" />
            </a>
            <div>{t('sortsMenu.sortedColumn', { colName: columnLabel })}</div>
            <div>
              <AddOnButtons onClick={changeDirectionCallback} buttons={buttons} size="small" />
            </div>
            <div {...provided.dragHandleProps}>
              <FaIcon id="grip-vertical" />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}
