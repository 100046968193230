import type { JSX } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useScreenMatchesBulmaScreenSize,
  useSetCallback,
} from '@stimcar/libs-uikernel';
import { DeleteClickableIcon, loadFile } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../app/state/typings/store.js';
import type { ComputeAttachmentUrlCallback } from '../attachments/typings/attachment.js';
import type { ConfirmAttachmentDialogState } from '../attachments/typings/store.js';
import type { PictureInputState } from '../typings/store.js';
import { importAttachmentsAction } from '../../../app/utils/attachmentGalleryActions.js';
import './GenericPictureInput.scss';
import { PictureInputCameraModal } from './PictureInputCameraModal.js';
import { useIsPortrait } from './utils/pictureInputUtils.js';

interface GenericPictureInputProps extends AppProps<Store> {
  readonly label?: string;
  readonly folder: string;
  readonly kanbanId: string;
  readonly filename: string;
  readonly isEditable: boolean;
  readonly placeholderPicturePath: string;
  readonly notSetPictureAdditionalLayer: string;
  readonly $: StoreStateSelector<Store, PictureInputState>;
  readonly computeAttachmentUrlCallback: ComputeAttachmentUrlCallback;
  readonly $confirmAttachmentRemovalDialog: StoreStateSelector<Store, ConfirmAttachmentDialogState>;
  readonly overridingOnClickCallback?: NoArgActionCallback<Store>;
  readonly customAttachmentUploadRoute?: string;
  readonly maskPath?: string;
}

export function GenericPictureInput({
  $,
  $gs,
  label,
  folder,
  kanbanId,
  filename,
  isEditable,
  placeholderPicturePath,
  notSetPictureAdditionalLayer,
  $confirmAttachmentRemovalDialog,
  overridingOnClickCallback,
  computeAttachmentUrlCallback,
  customAttachmentUploadRoute,
  maskPath,
}: GenericPictureInputProps): JSX.Element {
  const [t] = useTranslation('libComponents');

  const isPortrait = useIsPortrait();
  const isNotMobileDevice = useScreenMatchesBulmaScreenSize($gs.$window, 'FullHD');

  const isSet = useGetState($.$isSet);
  const [isLoading, setIsLoading] = useState(true);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const displayCameraModal = useGetState($.$displayCameraModal);

  const pictureUrl = useMemo(
    () => computeAttachmentUrlCallback('kanban', folder, filename, kanbanId),
    [computeAttachmentUrlCallback, filename, folder, kanbanId]
  );

  const checkImageIsSetAsyncEffect = useActionCallback(
    async ({ actionDispatch, httpClient }): Promise<void> => {
      try {
        await httpClient.httpGet(pictureUrl);
        actionDispatch.setProperty('isSet', true);
      } catch {
      } finally {
        setIsLoading(false);
      }
    },
    [pictureUrl],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    checkImageIsSetAsyncEffect();
  }, [checkImageIsSetAsyncEffect]);

  const isClickable = useMemo(() => isSet || isEditable, [isSet, isEditable]);

  const src = useMemo(() => {
    if (isLoading) {
      return 'transp.png';
    }
    if (isSet) {
      // We need to add a unique url parameter to avoid the browser to cache the image
      // Otherwise, a deleted image would still appear after a new image has been set
      return `${pictureUrl}?t=${new Date().getTime()}`;
    }
    return placeholderPicturePath;
  }, [isSet, pictureUrl, placeholderPicturePath, isLoading]);

  const openConfirmAttachmentRemovalModalActionCallback = useSetCallback(
    $confirmAttachmentRemovalDialog,
    {
      id: '',
      active: true,
      name: filename,
      metadata: undefined,
      folder,
    }
  );

  const closeCameraModalActionCallback = useSetCallback($.$displayCameraModal, false);

  const onImportAttachmentCallback = useActionCallback(
    async ({ actionDispatch, globalActionDispatch }, blob: Blob): Promise<void> => {
      const file = new File([blob], filename);

      await loadFile(file);

      try {
        await globalActionDispatch.exec(
          importAttachmentsAction,
          'kanban',
          kanbanId,
          folder,
          [file],
          undefined,
          customAttachmentUploadRoute
        );

        actionDispatch.setProperty('isSet', true);
      } catch (error) {
        globalActionDispatch.setProperty('message', (error as Error).message);
      } finally {
        await actionDispatch.execCallback(closeCameraModalActionCallback);
      }
    },
    [filename, kanbanId, folder, customAttachmentUploadRoute, closeCameraModalActionCallback],
    $
  );

  const onFileInputChangeCallback = useActionCallback(
    async ({ actionDispatch }, event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      await actionDispatch.execCallback(onImportAttachmentCallback, nonnull(event.target.files)[0]);
    },
    [onImportAttachmentCallback],
    $
  );

  const onClickActionCallback = useActionCallback(
    async ({ actionDispatch, globalActionDispatch }) => {
      if (isSet) {
        const url = computeAttachmentUrlCallback('kanban', folder, filename, kanbanId);
        if (isTruthy(overridingOnClickCallback)) {
          await globalActionDispatch.execCallback(overridingOnClickCallback);
        } else {
          globalActionDispatch.applyPayload({
            imageModal: {
              active: true,
              imageIndex: 0,
              imagesUrls: [url],
            },
          });
        }
      } else if (isNotMobileDevice) {
        fileInputRef.current?.click();
      } else if ('ImageCapture' in window) {
        actionDispatch.setProperty('displayCameraModal', true);
      } else {
        globalActionDispatch.setProperty('message', t('cameraModal.imageCaptureApiNotAvailable'));
      }
    },
    [
      computeAttachmentUrlCallback,
      filename,
      folder,
      isNotMobileDevice,
      isSet,
      kanbanId,
      overridingOnClickCallback,
      t,
    ],
    $
  );

  const onKeyDownActionCallback = useActionCallback(
    async ({ actionDispatch }, e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        await actionDispatch.execCallback(onClickActionCallback);
      }
    },
    [onClickActionCallback],
    $
  );

  const onSelectFileCallback = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  return (
    <>
      <figure
        className={`picture-input image is-4by3${isClickable ? ' is-clickable' : ''} has-background-light`}
      >
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img
          alt=""
          src={src}
          className="picture-input-image"
          onClick={isSet ? onClickActionCallback : undefined}
          onKeyDown={isSet ? onKeyDownActionCallback : undefined}
        />
        {isSet && isEditable && (
          <DeleteClickableIcon
            isSmall
            customStyle={{ position: 'absolute', top: 2, right: 2 }}
            handler={openConfirmAttachmentRemovalModalActionCallback}
          />
        )}
        {!isSet && isClickable && (
          <>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <img
              alt=""
              onClick={onClickActionCallback}
              src={notSetPictureAdditionalLayer}
              onKeyDown={onKeyDownActionCallback}
            />
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              className="is-hidden"
              onChange={onFileInputChangeCallback}
            />
          </>
        )}
      </figure>
      {isTruthy(label) && <p className="has-text-centered">{label}</p>}
      {displayCameraModal && (
        <PictureInputCameraModal
          $gs={$gs}
          kanbanId={kanbanId}
          filename={filename}
          isPortrait={isPortrait}
          onSelectFile={onSelectFileCallback}
          onClose={closeCameraModalActionCallback}
          onImportAttachmentCallback={onImportAttachmentCallback}
          maskPath={maskPath}
        />
      )}
    </>
  );
}
