import type { JSX } from 'react';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, RepositoryEntityPayload } from '@stimcar/libs-base';
import type { GlobalStoreStateSelector, StoreStateSelector } from '@stimcar/libs-uikernel';
import { nonDeleted } from '@stimcar/libs-base';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { MarketplacePaymentsState } from '../typings/store.js';
import { openPaymentAddModalAction, PaymentAddEditModal } from './PaymentAddEditModal.js';
import { PaymentDeleteModal } from './PaymentDeleteModal.js';
import { PaymentHistoryItem } from './PaymentHistoryItem.js';

export interface MarketplacePaymentsProps {
  readonly $: StoreStateSelector<Store, MarketplacePaymentsState>;
  readonly $gs: GlobalStoreStateSelector<Store>;
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
}

export function MarketplacePayments({
  $,
  $gs,
  $selectedKanban,
}: MarketplacePaymentsProps): JSX.Element {
  const [t] = useTranslation('details');
  const isOnline = useGetState($gs.$session.$isOnline);
  const kanban = useGetState($selectedKanban);
  const payments = useGetState($.$payments);

  const activePayments = useMemo(() => payments.filter(nonDeleted), [payments]);

  const paymentAddModalOnFormSubmitCallback = useActionCallback(
    async ({ actionDispatch, getState, httpClient, kanbanRepository }): Promise<void> => {
      const {
        client,
        paymentRequestDate,
        paymentCompletionDate,
        paymentType,
        priceWithVAT,
        description,
      } = getState().formData;
      const payload: RepositoryEntityPayload<Kanban> = {
        entityId: kanban.id,
        payload: {
          marketplaceInfos: {
            paymentHistory: [
              {
                id: httpClient.getBrowserSequence().next(),
                client,
                paymentRequestDate,
                paymentCompletionDate,
                paymentType,
                priceWithVAT,
                description,
              },
            ],
          },
        },
      };
      await kanbanRepository.updateEntityFromPayload(payload);
      actionDispatch.setProperty('isActive', false);
    },
    [kanban],
    $.$paymentAddModalState
  );

  const paymentEditModalOnFormSubmitCallback = useActionCallback(
    async ({ actionDispatch, getState, kanbanRepository }): Promise<void> => {
      const { paymentId, formData } = getState();
      const {
        client,
        paymentRequestDate,
        paymentCompletionDate,
        paymentType,
        priceWithVAT,
        description,
      } = formData;
      const payload: RepositoryEntityPayload<Kanban> = {
        entityId: kanban.id,
        payload: {
          marketplaceInfos: {
            paymentHistory: [
              {
                id: paymentId,
                client,
                paymentRequestDate,
                paymentCompletionDate,
                paymentType,
                priceWithVAT,
                description,
              },
            ],
          },
        },
      };
      await kanbanRepository.updateEntityFromPayload(payload);
      actionDispatch.setProperty('isActive', false);
    },
    [kanban],
    $.$paymentEditModalState
  );

  const openPaymentAddDialogCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(openPaymentAddModalAction);
    },
    [],
    $.$paymentAddModalState
  );

  const loadValuesFromKanbanAsyncEffect = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.applyPayload({
        payments: kanban?.marketplaceInfos?.paymentHistory ?? [],
      });
    },
    [kanban],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadValuesFromKanbanAsyncEffect();
  }, [loadValuesFromKanbanAsyncEffect]);

  return (
    <div>
      <PaymentAddEditModal
        $={$.$paymentAddModalState}
        title={t('tabs.marketplace.overview.paymentHistory.modal.addTitle')}
        onFormSubmitCallback={paymentAddModalOnFormSubmitCallback}
      />
      <PaymentAddEditModal
        $={$.$paymentEditModalState}
        title={t('tabs.marketplace.overview.paymentHistory.modal.editTitle')}
        onFormSubmitCallback={paymentEditModalOnFormSubmitCallback}
      />
      <PaymentDeleteModal $={$.$paymentDeletionModalState} kanbanId={kanban.id} />
      <div className="has-text-right my-2">
        <Button
          label={t('tabs.marketplace.overview.paymentHistory.addButton')}
          size="small"
          iconId="plus"
          disabled={!isOnline}
          onClick={openPaymentAddDialogCallback}
        />
      </div>
      <table className="table is-striped is-bordered is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th colSpan={7} className="has-text-centered">
              {t('tabs.marketplace.overview.paymentHistory.tableTitle')}
            </th>
          </tr>
          <tr className="has-text-weight-bold">
            <td>{t('tabs.marketplace.overview.paymentHistory.clientHeader')}</td>
            <td>{t('tabs.marketplace.overview.paymentHistory.paymentRequestDateHeader')}</td>
            <td>{t('tabs.marketplace.overview.paymentHistory.paymentCompletionDateHeader')}</td>
            <td>{t('tabs.marketplace.overview.paymentHistory.paymentTypeHeader')}</td>
            <td>{t('tabs.marketplace.overview.paymentHistory.priceWithVATHeader')}</td>
            <td>{t('tabs.marketplace.overview.paymentHistory.descriptionHeader')}</td>
            <td>{t('tabs.marketplace.overview.paymentHistory.actionHeader')}</td>
          </tr>
        </thead>
        <tbody>
          {activePayments.map((payment) => (
            <PaymentHistoryItem
              $={$}
              key={payment.id}
              kanbanId={kanban.id}
              paymentId={payment.id}
              isOnline
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
