import { t } from 'i18next';
import type { EcoStickerValue, EnergyValue } from '@stimcar/libs-kernel';
import type { SimpleOption } from '@stimcar/libs-uitoolkit';
import { globalHelpers } from '@stimcar/libs-base';
import {
  formatKilometers,
  formatPrice,
  isTruthy,
  isTruthyAndNotEmpty,
  MKTP_ECO_STICKERS,
  MKTP_ENERGIES,
  MKTP_GEARBOXES,
} from '@stimcar/libs-kernel';
import type { TabElementDef } from '../../../lib/components/Tabs.js';
import type { MarketplaceTabs } from './typings/store.js';

export function getDateLabel(date?: number | null): string | undefined {
  if (isTruthy(date)) {
    return globalHelpers.getDDmmYYYYDateOrPlaceholderFromTimestamp(date, '');
  }
  return undefined;
}

export function getPriceLabel(price?: number): string | undefined {
  if (isTruthy(price)) {
    return formatPrice(price);
  }
  return undefined;
}

export function getMileageLabel(mileage?: number): string | undefined {
  if (isTruthy(mileage)) {
    return formatKilometers(mileage);
  }
  return undefined;
}

export function getNumberLabel(number?: number): string | undefined {
  if (isTruthy(number)) {
    return String(number);
  }
  return undefined;
}

export function getEnergyLabel(energy?: string): string {
  if (isTruthyAndNotEmpty(energy)) {
    return t(`details:tabs.marketplace.mainSpecs.energyValues.${energy}`);
  }
  return t('details:tabs.marketplace.mainSpecs.energyValues.unknown');
}

export function getGearboxLabel(gearbox?: string): string {
  if (isTruthyAndNotEmpty(gearbox)) {
    return t(`details:tabs.marketplace.mainSpecs.gearboxValues.${gearbox}`);
  }
  return t('details:tabs.marketplace.mainSpecs.gearboxValues.unknown');
}

export function getEcoStickerLabel(ecoSticker?: string): string {
  if (isTruthyAndNotEmpty(ecoSticker)) {
    return t(`details:tabs.marketplace.mainSpecs.ecoStickerValues.${ecoSticker}`);
  }
  return t('details:tabs.marketplace.mainSpecs.ecoStickerValues.unknown');
}

export function computeGearboxValues(): SimpleOption<EnergyValue>[] {
  return MKTP_GEARBOXES.map((value) => {
    return {
      label: t(
        `details:tabs.marketplace.mainSpecs.gearboxValues.${isTruthyAndNotEmpty(value) ? value : 'unknown'}`
      ),
      value,
    };
  });
}

export function computeEnergyValues(): SimpleOption<EnergyValue>[] {
  return MKTP_ENERGIES.map((value) => {
    return {
      label: t(
        `details:tabs.marketplace.mainSpecs.energyValues.${isTruthyAndNotEmpty(value) ? value : 'unknown'}`
      ),
      value,
    };
  });
}

export function computeEcoStickerValues(): SimpleOption<EcoStickerValue>[] {
  return MKTP_ECO_STICKERS.map((value) => {
    return {
      label: t(
        `details:tabs.marketplace.mainSpecs.ecoStickerValues.${isTruthyAndNotEmpty(value) ? value : 'unknown'}`
      ),
      value,
    };
  });
}

export function computeMarketplaceTabs(): Record<MarketplaceTabs, string | TabElementDef> {
  return {
    overview: {
      label: t('details:tabs.marketplace.overview.title'),
      icon: { id: 'file-invoice-dollar' },
    },
    payment: {
      label: t('details:tabs.marketplace.overview.paymentHistory.title'),
      icon: { id: 'sack-dollar' },
    },
    specs: {
      label: t('details:tabs.marketplace.equipmentTitle'),
      icon: { id: 'list' },
    },
    preview: {
      label: t('details:tabs.marketplace.previewTitle'),
      icon: { id: 'globe' },
    },
    stimer: {
      label: t('details:tabs.marketplace.stimer.title'),
      icon: { id: 'user' },
    },
    photoAttachmentsGallery: {
      label: t('details:tabs.marketplace.photoAttachmentsGallery'),
      icon: { id: 'image' },
    },
    textAttachmentsGallery: {
      label: t('details:tabs.marketplace.textAttachmentsGallery'),
      icon: { id: 'brands/markdown' },
    },
  };
}
