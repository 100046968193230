import type { JSX } from 'react';
import i18next from 'i18next';
import { marked } from 'marked';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban } from '@stimcar/libs-base';
import type {
  ActionContext,
  GlobalStoreStateSelector,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import { getMarketplaceUrl } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, keysOf, Logger } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type { MarketplaceAdPreviewState } from './typings/store.js';
import { getPriceLabel } from './i18nUtils.js';

const log: Logger = Logger.new(import.meta.url);

type VariableKey =
  | 'brand'
  | 'model'
  | 'price'
  | 'securityEquipments'
  | 'comfortEquipments'
  | 'multimediaEquipments'
  | 'othersEquipments'
  | 'workshopAddress';
type Variables = Record<VariableKey, string | undefined>;

function computeVariablesValues(kanban: Kanban, workshopAddress: string): Variables {
  return {
    brand: kanban.infos.brand,
    model: kanban.infos.model,
    price: getPriceLabel(kanban.marketplaceInfos?.inputPrice),
    securityEquipments: buildMarkdownListWithTitle(
      i18next.t('details:tabs.marketplace.equipments.security'),
      kanban.marketplaceInfos?.equipments.security
    ),
    comfortEquipments: buildMarkdownListWithTitle(
      i18next.t('details:tabs.marketplace.equipments.comfort'),
      kanban.marketplaceInfos?.equipments.comfort
    ),
    multimediaEquipments: buildMarkdownListWithTitle(
      i18next.t('details:tabs.marketplace.equipments.multimedia'),
      kanban.marketplaceInfos?.equipments.multimedia
    ),
    othersEquipments: buildMarkdownListWithTitle(
      i18next.t('details:tabs.marketplace.equipments.others'),
      kanban.marketplaceInfos?.equipments.others
    ),
    workshopAddress,
  };
}

function replaceVariables(templateAsText: string, variables: Variables): string {
  return keysOf(variables).reduce((result, key) => {
    const value = variables[key];
    if (isTruthy(value)) {
      const regex: RegExp = new RegExp(`\\$\\{${key}\\}`, 'g');
      return result.replace(regex, String(value));
    }
    return result;
  }, templateAsText);
}

async function fetchAndConvertMarkdownContentToHtml(
  variables: Variables
): Promise<string | undefined> {
  try {
    const response: Response = await fetch(`template/ad-preview-template.md`);
    if (response.status === 200) {
      const markdownTemplateAsText = await response.text();
      const markdownTemplateWithValues = replaceVariables(markdownTemplateAsText, variables);
      return await marked(markdownTemplateWithValues);
    }
  } catch (error) {
    log.error(`Error fetching markdown file:`, error);
  }
  return undefined;
}

function buildMarkdownListWithTitle(title: string, list: readonly string[] | undefined): string {
  if (isTruthy(list) && list.length > 0) {
    const markdownTitle = `### ${title} :\n`;
    const markdownElements = list.map((value) => `* ${value}`).join(`\n`);
    return `${markdownTitle}${markdownElements}<br /><br />`;
  }
  return '';
}

export interface MarketplaceAdPreviewProps {
  readonly $: StoreStateSelector<Store, MarketplaceAdPreviewState>;
  readonly $gs: GlobalStoreStateSelector<Store>;
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
}

export function MarketplaceAdPreview({
  $,
  $gs,
  $selectedKanban,
}: MarketplaceAdPreviewProps): JSX.Element {
  const [t] = useTranslation('details');
  const selectedKanban = useGetState($selectedKanban);
  const htmlContent = useGetState($.$htmlContent);
  const workshopAddress = useGetState($gs.$siteConfiguration.$infos.$workshopAddress);

  const variables: Variables = useMemo(
    () => computeVariablesValues(selectedKanban, workshopAddress),
    [selectedKanban, workshopAddress]
  );

  const displayMarkdownOrErrorMessage = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, MarketplaceAdPreviewState>): Promise<void> => {
      const markdownContentAsHTML = await fetchAndConvertMarkdownContentToHtml(variables);
      if (isTruthyAndNotEmpty(markdownContentAsHTML)) {
        actionDispatch.setProperty('htmlContent', markdownContentAsHTML);
      } else {
        const errorMessage = t(`tabs.marketplace.preview.error`);
        actionDispatch.setProperty('htmlContent', errorMessage);
      }
    },
    [t, variables],
    $
  );

  const copyMarkdownToClipboardActionCallback = useActionCallback(
    async (): Promise<void> => {
      try {
        // We use a temp HTML element to have a formatted text
        const tmpElement = document.createElement('div');
        tmpElement.innerHTML = htmlContent;
        const plainText = tmpElement.innerText;
        // Warning: navigator.clipboard.writeText works only with secure context (HTTPS / localhost)
        // ==> it does not work in dev
        // For additional infos:
        // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText#security_considerations
        await navigator.clipboard.writeText(plainText);
      } catch (error) {
        log.error(`Failed to copy text: `, error);
      }
    },
    [htmlContent],
    $
  );

  const redirectToMarketplaceActionCallback = useActionCallback(
    ({ httpClient }: ActionContext<Store, MarketplaceAdPreviewState>): void => {
      const { company, site } = httpClient.getBrowserInfos();
      const url = getMarketplaceUrl(company.id, site.id, selectedKanban.id);
      window.open(url, '_blank'); // '_blank' opens a new tab
    },
    [selectedKanban],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    displayMarkdownOrErrorMessage();
  }, [displayMarkdownOrErrorMessage]);

  return (
    <>
      <div className="is-flex mb-2">
        <Button
          iconId="copy"
          additionalClass="is-primary mr-2"
          label={t('tabs.marketplace.preview.copyButton')}
          onClick={copyMarkdownToClipboardActionCallback}
        />
        <Button
          iconId="link"
          additionalClass="is-primary"
          label={t('tabs.marketplace.preview.marketplaceButton')}
          onClick={redirectToMarketplaceActionCallback}
        />
      </div>
      <div
        className="content"
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    </>
  );
}
