/* eslint-disable jsx-a11y/control-has-associated-label */
import type { JSX } from 'react';
import { marked } from 'marked';
import React, { Suspense, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { MDXEditorMethods } from '@mdxeditor/editor';
import type { Kanban, KanbanMessage, RepositoryEntityPayload } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { CheckFormFieldContentActions } from '@stimcar/libs-uitoolkit';
import {
  kanbanHelpers,
  shortDayMonthWithHourFormatOptions,
  SUBCONTRACTOR_REQUEST_MESSAGE_ICON_ID,
} from '@stimcar/libs-base';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useFormFieldWarning, useGetState } from '@stimcar/libs-uikernel';
import { FaIcon, useFormWithValidation, WithWarning } from '@stimcar/libs-uitoolkit';
import type { AddKanbanMessageInputState } from '../../../app/details/typings/store.js';
import type { Store, StoreState } from '../../../app/state/typings/store.js';
import { EMPTY_ADD_KANBAN_MESSAGE_INPUT_STATE } from '../../../app/details/typings/store.js';
import { LazyMarkdownEditor } from '../../../utils/mdxeditor/LazyMarkdownEditor.js';
import { Quickview } from '../../bulma/extensions/Quickview.js';
import { kanbanPriorityLevelHelpers } from '../../utils/kanbanPriorityLevelHelpers.js';
import './KanbanMessageQuickviewComponent.scss';

const KANBAN_MESSAGE_MAX_LENGTH = 200;

interface Props {
  readonly kanban: Kanban;
  readonly isReadonly: boolean;
  readonly $active: StoreStateSelector<Store, boolean>;
  readonly $: StoreStateSelector<Store, AddKanbanMessageInputState>;
}

export function KanbanMessageQuickviewComponent({
  $,
  kanban,
  $active,
  isReadonly,
}: Props): JSX.Element {
  const [t] = useTranslation('bulmaLegacy');

  return (
    <Quickview
      $active={$active}
      title={t('kanbanMessage.title')}
      footer={<AddKanbanMessageInput $={$} kanbanId={kanban.id} isReadonly={isReadonly} />}
    >
      {kanban.messages.map(
        (c): JSX.Element => (
          <KanbanMessageChatStyleItem key={c.id} message={c} />
        )
      )}
    </Quickview>
  );
}

async function submitKanbanMessageModification(
  { getState, kanbanRepository }: ActionContext<Store, StoreState>,
  kanbanId: string,
  message: string
): Promise<void> {
  const { login } = nonnull(getState().session.user);

  const newKanban: RepositoryEntityPayload<Kanban> = {
    entityId: kanbanId,
    payload: {
      messages: [kanbanHelpers.createKanbanComment(kanbanRepository.createId(), login, message)],
    },
  };

  await kanbanRepository.updateEntityFromPayload(newKanban);
}

interface AddKanbanMessageInputProps {
  readonly $: StoreStateSelector<Store, AddKanbanMessageInputState>;
  readonly kanbanId: string;
  readonly isReadonly: boolean;
}

export function AddKanbanMessageInput({
  $,
  kanbanId,
  isReadonly,
}: AddKanbanMessageInputProps): JSX.Element {
  const [t] = useTranslation('bulmaLegacy');

  const markdownEditorRef = useRef<MDXEditorMethods>(null);

  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch, globalActionDispatch, getState }): Promise<void> => {
      await globalActionDispatch.exec(
        submitKanbanMessageModification,
        kanbanId,
        getState().formData.message
      );
      actionDispatch.setValue(EMPTY_ADD_KANBAN_MESSAGE_INPUT_STATE);
      markdownEditorRef.current?.setMarkdown(EMPTY_ADD_KANBAN_MESSAGE_INPUT_STATE.formData.message);
    },
    [kanbanId],
    $
  );

  const checkFieldContentActions: CheckFormFieldContentActions<Store, AddKanbanMessageInputState> =
    {
      message: ({ value, t }): string | undefined => {
        if (value.length > KANBAN_MESSAGE_MAX_LENGTH) {
          return t('kanbanMessage.maxLengthReached', {
            messageLength: value.length,
            maxLength: KANBAN_MESSAGE_MAX_LENGTH,
          });
        }
        return undefined;
      },
    };

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    AddKanbanMessageInputState
  >({
    $,
    mandatoryFields: ['message'],
    checkFieldContentActions,
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  const formWarning = useGetState($.$formWarning);

  const disableSubmitButton = useMemo(
    () => isReadonly || isTruthy(formWarning),
    [isReadonly, formWarning]
  );

  return (
    <>
      <Suspense fallback="...">
        <div className="add-kanban-message-container">
          <WithWarning
            noExclamationTriangleIfWarning
            additionalWarningTextClassName="pl-5"
            warning={useFormFieldWarning($formDataWithChangeTrigger.$message)}
          >
            <LazyMarkdownEditor
              readonly={isReadonly}
              editorRef={markdownEditorRef}
              placeholder={t('kanbanMessage.label')}
              $={$formDataWithChangeTrigger.$message}
              className="add-kanban-message-markdown-editor"
            />
          </WithWarning>
        </div>
      </Suspense>
      <button
        type="button"
        onClick={onFormSubmit}
        disabled={disableSubmitButton}
        className="button is-primary is-fullwidth m-t-md"
      >
        <FaIcon tooltip="save" id="paper-plane" />
      </button>
    </>
  );
}

interface KanbanMessageChatStyleItemProps {
  readonly message: KanbanMessage;
}

export function KanbanMessageChatStyleItem({
  message,
}: KanbanMessageChatStyleItemProps): JSX.Element {
  const date = new Date(message.timestamp);

  const className = kanbanPriorityLevelHelpers.getKanbanMessageColorClassName(message, 'text');

  const markdownMessageContentAsHTML = useMemo(() => {
    marked.use({ async: false });
    return marked.parse(message.content) as string;
  }, [message.content]);

  return (
    <article className="media m-sm">
      <div className="media-content">
        <div className="content">
          <div className="columns is-mobile">
            <div className="column is-narrow">
              <strong>{`${message.username}:`}</strong>
              <br />
              <span className="has-text-weight-light">
                {date.toLocaleDateString('fr-FR', shortDayMonthWithHourFormatOptions)}
              </span>
            </div>
            <div className="column" style={{ display: 'flex' }}>
              {message.type === 'request' && (
                <FaIcon
                  additionalClass={`${className} m-r-md`}
                  id={SUBCONTRACTOR_REQUEST_MESSAGE_ICON_ID}
                />
              )}
              <p
                className="multiline-markdown-content"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: markdownMessageContentAsHTML }}
              />
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}
