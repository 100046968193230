import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PurchaseOrder } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { FormFieldEntry } from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes, nonDeleted, purchaseOrderHelpers } from '@stimcar/libs-base';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  InputFormField,
  ModalCardDialog,
  SelectFormField,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type {
  InvoiceReferenceAddFormData,
  InvoiceReferenceAddModalState,
} from './typings/store.js';
import { extractMessageFromError } from './invoicingUtils.js';
import { INVOICE_REFERENCE_ADD_MODAL_EMPTY_STATE } from './typings/store.js';

export function openAddInvoiceReferenceModalAction({
  actionDispatch,
}: ActionContext<Store, InvoiceReferenceAddModalState>): void {
  actionDispatch.setValue({
    ...INVOICE_REFERENCE_ADD_MODAL_EMPTY_STATE,
    isActive: true,
  });
}

async function addInvoiceReferenceAction(
  {
    httpClient,
    getState,
    actionDispatch,
    globalActionDispatch,
  }: ActionContext<Store, InvoiceReferenceAddModalState>,
  kanbanId: string,
  t: TFunction
): Promise<void> {
  const { purchaseOrderId, invoiceReference } = getState().formData;
  try {
    await httpClient.httpPostAsJSON(CoreBackendRoutes.INVOICE_REFERENCE, {
      purchaseOrderId,
      kanbanId,
      newInvoiceReference: invoiceReference,
    });
  } catch (e) {
    // Close dialog
    actionDispatch.setValue({ ...INVOICE_REFERENCE_ADD_MODAL_EMPTY_STATE });
    // and display error in message dialog
    globalActionDispatch.scopeProperty('message').setValue({
      type: 'warning',
      title: t('tabs.invoice.errorTitle'),
      content: extractMessageFromError(e as Error),
    });
    return;
  }
  actionDispatch.setValue({ ...INVOICE_REFERENCE_ADD_MODAL_EMPTY_STATE });
}

type AddInvoiceReferenceModalProps = {
  readonly $: StoreStateSelector<Store, InvoiceReferenceAddModalState>;
  readonly kanbanId: string;
  readonly purchaseOrders: readonly PurchaseOrder[];
  readonly isPurchaseOrderMandatory: boolean;
};

export function AddInvoiceReferenceModal({
  $,
  kanbanId,
  purchaseOrders,
  isPurchaseOrderMandatory,
}: AddInvoiceReferenceModalProps): JSX.Element {
  const [t] = useTranslation('details');

  const submitInvoiceReferenceActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(addInvoiceReferenceAction, kanbanId, t);
    },
    [kanbanId, t],
    $
  );

  const MANDATORY_FIELDS: readonly (keyof InvoiceReferenceAddFormData)[] = isPurchaseOrderMandatory
    ? ['purchaseOrderId', 'invoiceReference']
    : ['invoiceReference'];

  const [onFormSubmit, , $formWithChangeTrigger] = useFormWithValidation({
    $,
    mandatoryFields: MANDATORY_FIELDS,
    submitValidDataAction: submitInvoiceReferenceActionCallback,
    t,
  });

  const formWarning = useGetState($.$formWarning);

  const purchaseOrderEntries: FormFieldEntry<string>[] = useMemo(() => {
    return purchaseOrders.filter(nonDeleted).map((purchaseOrder) => ({
      id: purchaseOrder.id,
      label: purchaseOrderHelpers.getPurchaseOrderDisplayedLabel(purchaseOrder),
    }));
  }, [purchaseOrders]);

  return (
    <ModalCardDialog
      title={t('tabs.invoice.modal.addTitle')}
      $active={$.$isActive}
      okLabel={t('tabs.invoice.modal.saveButtonLabel')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <SelectFormField
        label={t('tabs.invoice.modal.purchaseOrder')}
        entries={purchaseOrderEntries}
        $={$formWithChangeTrigger.$purchaseOrderId}
        isFullWidth
        sortEntries
        isEmptyValueAllowed={!isPurchaseOrderMandatory}
      />
      <InputFormField
        label={t('tabs.invoice.modal.invoiceReference')}
        $={$formWithChangeTrigger.$invoiceReference}
      />
    </ModalCardDialog>
  );
}
