import { nonnull } from '@stimcar/libs-kernel';
import type {
  DecoratorDefinition,
  SiteConfiguration,
  Stand,
  Workflow,
  WorkflowNode,
  WorkshopPost,
  WorkshopPostLevel,
  WorkshopStandImplantation,
} from '../model/index.js';

function workshopLevelToStandardNotation(
  definitions: readonly WorkshopPostLevel<'concise'>[]
): readonly WorkshopPostLevel[] {
  return definitions.map((d): WorkshopPostLevel => {
    const { id, toggleOperationsFromAnywhere, ancestors } = d;
    const migratedLevel: WorkshopPostLevel = {
      id,
      toggleOperationsFromAnywhere,
      posts: d.posts.map((p): WorkshopPost => {
        if (typeof p === 'string') {
          return {
            isOptional: false,
            id: p,
          };
        }
        return p;
      }),
      ...(ancestors ? { ancestors: workshopLevelToStandardNotation(ancestors) } : {}),
    };
    return migratedLevel;
  });
}

export function standToStandardNotation({ implantations, ...stand }: Stand<'concise'>): Stand {
  return {
    ...stand,
    ...(implantations
      ? {
          implantations: implantations.map((i): WorkshopStandImplantation => {
            if (i.definition.length > 0) {
              return {
                ...i,
                definition: workshopLevelToStandardNotation(i.definition),
              };
            }
            return { ...i, definition: [] };
          }),
        }
      : {}),
  };
}

export function packageDealDecoratorDefinitionToStandardNotation(
  decoratorDefinition: DecoratorDefinition<'concise'>
): DecoratorDefinition {
  return {
    ...decoratorDefinition,
    packageDeals: decoratorDefinition.packageDeals.map((c) => {
      if (typeof c === 'string') {
        return {
          id: c,
          variable: '',
          expectedValues: [],
        };
      }
      return c;
    }),
  };
}

export function workflowNodeToStandardNotation(node: WorkflowNode<'concise'>): WorkflowNode {
  if (typeof node === 'string') {
    return {
      id: node,
    };
  }
  return {
    id: node.id,
    ...(node.fork
      ? { fork: node.fork.map((f) => nonnull(workflowNodeToStandardNotation(f))) }
      : {}),
    ...(node.join ? { join: workflowNodeToStandardNotation(node.join) } : {}),
  };
}

export function siteConfigurationToStandardNotation(
  configuration: SiteConfiguration<'concise'>
): SiteConfiguration {
  const stands = configuration.stands ?? [];
  const packageDealDecorators = configuration.packageDealDecorators ?? [];
  const workflows = configuration.workflows ?? [];
  return {
    ...configuration,
    stands: stands.map(standToStandardNotation),
    packageDealDecorators: packageDealDecorators.map(
      packageDealDecoratorDefinitionToStandardNotation
    ),
    workflows: workflows.map(
      ({ definition, ...w }): Workflow => ({
        ...w,
        definition: nonnull(workflowNodeToStandardNotation(definition)),
      })
    ),
  };
}
