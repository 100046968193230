import type { Kanban, PackageDeal, PurchaseOrder, UIContract } from '@stimcar/libs-base';
import type { Civility } from '@stimcar/libs-kernel';
import type { WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import {
  CLONE_SOURCE,
  contractHelpers,
  EMPTY_KANBAN,
  EMPTY_MARKETPLACE_INFO,
  kanbanHelpers,
  nonDeleted,
  packageDealHelpers,
} from '@stimcar/libs-base';
import { EMPTY_KANBAN_INFOS, isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import type { DisplayAttributesState } from '../../lib/components/typings/store.js';
import type { NewKanbanFormData } from './typings/store.js';

type PackageDealWithPurchaseOrder = {
  readonly packageDeal: PackageDeal;
  readonly purchaseOrder: PurchaseOrder | null;
};

function getRelatedPurchaseOrder(
  purchaseOrderId: string | undefined,
  purchaseOrders: readonly PurchaseOrder[]
): PurchaseOrder | undefined {
  if (isTruthy(purchaseOrderId)) {
    return purchaseOrders.find(({ id }) => id === purchaseOrderId);
  }
  return undefined;
}

/**
 * Return a list of objects associating a package deal and its corresponding purchase order when available
 * This function filters out package deals pointing to deleted purchase orders
 * @param packageDeals
 * @param purchaseOrders
 * @returns
 */
export function getPackageDealsWithPurchaseOrder(
  packageDeals: readonly PackageDeal[],
  purchaseOrders: readonly PurchaseOrder[]
): readonly PackageDealWithPurchaseOrder[] {
  return packageDeals
    .filter(({ purchaseOrderId }) => {
      if (isTruthy(purchaseOrderId)) {
        const relatedPurchaseOrder = getRelatedPurchaseOrder(purchaseOrderId, purchaseOrders);
        return isTruthy(relatedPurchaseOrder) && nonDeleted(relatedPurchaseOrder);
      }
      return true;
    })
    .map((pkg) => {
      const { purchaseOrderId } = pkg;
      if (isTruthy(purchaseOrderId)) {
        const relatedPurchaseOrder = getRelatedPurchaseOrder(purchaseOrderId, purchaseOrders);
        return {
          packageDeal: pkg,
          purchaseOrder: relatedPurchaseOrder ?? null,
        };
      }
      return {
        packageDeal: pkg,
        purchaseOrder: null,
      };
    });
}

function computeMarketplaceInfosIfNeeded(formData: NewKanbanFormData): Partial<Kanban> {
  if (formData.kanbanType === 'marketplace') {
    return {
      marketplaceInfos: {
        ...EMPTY_MARKETPLACE_INFO,
        mandate: formData.marketplaceMandate,
      },
    };
  }
  return {};
}

export function createKanbanFromState(
  contracts: readonly UIContract[],
  formData: WithFormValidationWarnings<NewKanbanFormData>,
  packageDeals: readonly PackageDeal[],
  purchaseOrders: readonly PurchaseOrder[],
  attributesState: DisplayAttributesState,
  parentKanbanId: string | null,
  newKanbanId?: string,
  locationHistoryEntryId?: string
): Kanban {
  const uiContract = contractHelpers.findContractByCode(contracts, formData.contractCode);
  const contract = contractHelpers.getKanbanContractFromUIContract(nonnull(uiContract));

  // This call will filter package deals related to a deleted purchase order
  const pkgDealsWithPurchaseOrder = getPackageDealsWithPurchaseOrder(packageDeals, purchaseOrders);
  const filteredPackageDeals = pkgDealsWithPurchaseOrder.map(({ packageDeal }) => packageDeal);
  const filteredPurchaseOrders = purchaseOrders.filter(nonDeleted);

  return {
    ...EMPTY_KANBAN,
    id: newKanbanId ?? '',
    origin: isTruthyAndNotEmpty(parentKanbanId)
      ? { source: CLONE_SOURCE, id: parentKanbanId }
      : { ...EMPTY_KANBAN.origin },
    packageDeals: filteredPackageDeals,
    infos: {
      ...EMPTY_KANBAN_INFOS,
      brand: formData.brand,
      color: formData.color,
      dateOfRegistration: !Number.isNaN(formData.dateOfRegistration)
        ? formData.dateOfRegistration
        : null,
      license: formData.license,
      mileage: Number(formData.mileage),
      model: formData.model,
      motor: formData.motor,
      vin: formData.vin,
    },
    customer: {
      individual: formData.type === 'individual',
      company: formData.company,
      civility: formData.civility as Civility,
      shortName: formData.shortName,
      lastName: formData.lastName,
      firstName: formData.firstName,
      street: formData.street,
      zipCode: formData.zipCode,
      city: formData.city,
      phone: formData.phone,
      email: formData.email,
      invoiceId: Number.parseInt(formData.invoiceId, 10),
      ignoreVAT: formData.ignoreVAT,
    },
    dueDate: !Number.isNaN(formData.dueDate) ? formData.dueDate : null,
    refitEndDate: !Number.isNaN(formData.refitEndDate) ? formData.refitEndDate : null,
    contract,
    workflowId: formData.workflowId,
    creationDate: Date.now(),
    purchaseOrders: filteredPurchaseOrders,
    ...computeMarketplaceInfosIfNeeded(formData),
    attributes: {
      ...attributesState.attributes,
    },
    logisticInfos: locationHistoryEntryId
      ? kanbanHelpers.getUpdatedLogisticInfos(
          EMPTY_KANBAN,
          formData.carLocation,
          locationHistoryEntryId
        )
      : EMPTY_KANBAN.logisticInfos,
  };
}

export function updatePackageExpressionComputationsFromFormData(
  contracts: readonly UIContract[],
  newKanbanFormData: WithFormValidationWarnings<NewKanbanFormData>,
  packageDeals: readonly PackageDeal[],
  purchaseOrders: readonly PurchaseOrder[],
  attributesState: DisplayAttributesState,
  parentKanbanId: string | null,
  newKanbanId?: string
): readonly PackageDeal[] {
  if (!isTruthyAndNotEmpty(newKanbanFormData.contractCode)) {
    return [];
  }
  const kanban = createKanbanFromState(
    contracts,
    newKanbanFormData,
    packageDeals,
    purchaseOrders,
    attributesState,
    parentKanbanId,
    newKanbanId
  );

  return packageDealHelpers.updateAllPackageDealsExpressionComputations(kanban, true);
}
