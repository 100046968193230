import type {
  ActionToApply,
  ApplyActionReturnType,
  KnownKeysOf,
  ListenerUnregisterer,
  PollerErrorHandler,
  RepositoryEntities,
  ServerActionsLog,
  ServerEntitiesSnapshot,
  Shutdownable,
} from '@stimcar/libs-base';
import type { KeyValueStorage } from '@stimcar/libs-kernel';
import {
  BackendSSEMessages,
  countActions,
  EntitiesBackendRoutes,
  newPoller,
} from '@stimcar/libs-base';
import {
  applyAsyncCallbackSequentially,
  ensureSequentialMethodCalls,
  getHttpStatusCode,
  HttpErrorCodes,
  isTruthy,
  Logger,
} from '@stimcar/libs-kernel';
import type { RepositoryHTTPClient } from '../httpclient/typings/RepositoryHTTPClient.js';
import type { EntityMutationsDAO, EntityQueriesDAO } from './dao/typings/repository-dao.js';
import { LAST_KNOWN_SEQUENCE_ID_PROPERTY } from './typings/repository.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

export interface ServerPushHandler<ENAME extends keyof RepositoryEntities> extends Shutdownable {
  handle: (message: ServerActionsLog<RepositoryEntities[ENAME]>) => Promise<void>;
}

type LastSeqIdGetter = () => number | null;
type LastSeqIdSetter = (value: number) => void;

export class ServerPushHandlerImpl<ENAME extends keyof RepositoryEntities>
  implements ServerPushHandler<ENAME>
{
  private entityName: ENAME;

  private getLastKnownSequenceId: LastSeqIdGetter;

  private setLastKnownSequenceId: LastSeqIdSetter;

  private httpClient: RepositoryHTTPClient;

  private entityMutationsDAO: EntityMutationsDAO<ENAME>;

  private isClosed = false;

  private unregisterers: readonly ListenerUnregisterer[];

  public static async asyncConstructor<ENAME extends keyof RepositoryEntities>(
    entityName: ENAME,
    keyValueStorage: KeyValueStorage,
    httpClient: RepositoryHTTPClient,
    entityQueriesDAO: EntityQueriesDAO<ENAME>,
    entityMutationsDAO: EntityMutationsDAO<ENAME>,
    pollerErrorHandler: PollerErrorHandler
  ): Promise<ServerPushHandler<ENAME>> {
    const lastKnownSequenceIdProperty = LAST_KNOWN_SEQUENCE_ID_PROPERTY(entityName);
    const getLastKnownSequenceId: LastSeqIdGetter = (): number | null =>
      keyValueStorage.getNumberItem(lastKnownSequenceIdProperty);
    const setLastKnownSequenceId: LastSeqIdSetter = (value: number): void => {
      log.debug(`[${entityName}] new sequence id: ${value}`);
      keyValueStorage.setItem(lastKnownSequenceIdProperty, value);
    };

    const unregisterers: ListenerUnregisterer[] = [];
    const serverPushHandler = ensureSequentialMethodCalls<ServerPushHandlerImpl<ENAME>>(
      new ServerPushHandlerImpl(
        entityName,
        httpClient,
        getLastKnownSequenceId,
        setLastKnownSequenceId,
        entityMutationsDAO,
        unregisterers
      ),
      100
    );
    const startPoller = (): void => {
      // Register SSE Network listeners
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      let wasOnline = httpClient.isOnline();
      unregisterers.push(
        httpClient.registerNetworkStatusListener(
          // eslint-disable-next-line @typescript-eslint/require-await
          async (online): Promise<void> => {
            if (online && !wasOnline) {
              const lastKnownSequenceId = getLastKnownSequenceId();
              if (lastKnownSequenceId !== null && httpClient.isBrowserRegistered()) {
                // Asynchronous processing that will be executed in //
                await serverPushHandler.loadMissingActionLog(lastKnownSequenceId + 1);
              }
            }
            wasOnline = online;
          }
        )
      );
      // Register SSE Repository Action listeners
      unregisterers.push(
        httpClient.registerServerMessageListener(
          BackendSSEMessages.SERVER_ACTIONS_LOG(entityName as KnownKeysOf<RepositoryEntities>),
          async (data): Promise<void> => {
            await serverPushHandler.handle(data);
          }
        )
      );
      const poller = newPoller(
        1000,
        async (): Promise<void> => {
          if (httpClient.isOnline()) {
            // Retrieve non acknowledged actions (FIXME we could use an index
            // instead of filtering programmatically)
            const actions = await entityQueriesDAO.getLocalActionsToExecute();
            if (actions.length > 0) {
              // Send the actions to the server
              const result = await httpClient.httpPostAsJSON<
                readonly ActionToApply<RepositoryEntities[ENAME]>[],
                readonly ApplyActionReturnType[]
              >(
                EntitiesBackendRoutes.REPOSITORY_ACTION_EXECUTE(
                  entityName as KnownKeysOf<RepositoryEntities>
                ),
                actions.map(
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  ({ acknowledged, ...action }): ActionToApply<RepositoryEntities[ENAME]> => action
                )
              );
              // TODO result should be processed to detect rejected actions to notify the UI
              // For now we simply generate a warning in the error log
              const rejectedEntityIds = result.reduce<readonly string[]>(
                (previous, current, index) => {
                  const { entityId } = actions[index];
                  if (current === 'rejected' && !previous.includes(entityId)) {
                    return [...previous, entityId];
                  }
                  return previous;
                },
                []
              );
              if (rejectedEntityIds.length > 0) {
                log.warn('Rejected entity ids :', rejectedEntityIds);
              }
              // If everything is fine, acknowledge the local actions
              await entityMutationsDAO.acknowledgeLocalActions(
                ...actions.map(({ id }): string => id)
              );
            }
          }
        },
        pollerErrorHandler
      );
      unregisterers.push(async (): Promise<void> => {
        await poller.stop();
      });
      // Start poller
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      poller.start();
    };
    /**
     * Data initialization...
     */
    const isFirstInit = !isTruthy(getLastKnownSequenceId());
    // If the repository is created for the first time with a registered http client, try to
    // load the data
    if (isFirstInit) {
      if (httpClient.isBrowserRegistered()) {
        await serverPushHandler.downloadEntitiesFromServer();
        startPoller();
      } else {
        unregisterers.push(
          httpClient.registerBrowserRegistrationListener(async (becomes) => {
            if (becomes === 'registered') {
              if (!getLastKnownSequenceId()) {
                await serverPushHandler.downloadEntitiesFromServer();
                startPoller();
              }
            }
          })
        );
      }
    } else {
      if (httpClient.isOnline()) {
        const lastKnownSequence = getLastKnownSequenceId();
        await serverPushHandler.loadMissingActionLog(
          lastKnownSequence !== null ? lastKnownSequence + 1 : 0
        );
      }
      startPoller();
    }
    // Return the instance
    return serverPushHandler;
  }

  private constructor(
    entityName: ENAME,
    httpClient: RepositoryHTTPClient,
    getLastKnownSequenceId: LastSeqIdGetter,
    setLastKnownSequenceId: LastSeqIdSetter,
    entityMutationsDAO: EntityMutationsDAO<ENAME>,
    unregisterers: readonly ListenerUnregisterer[]
  ) {
    this.entityName = entityName;
    this.httpClient = httpClient;
    this.entityMutationsDAO = entityMutationsDAO;
    this.unregisterers = unregisterers;
    this.getLastKnownSequenceId = getLastKnownSequenceId;
    this.setLastKnownSequenceId = setLastKnownSequenceId;
  }

  public shutdown = async (): Promise<void> => {
    log.info('Shutdowning', this.entityName, 'repository server push handler...');
    // Unregister all registered listeners
    await applyAsyncCallbackSequentially(
      this.unregisterers,
      async (unregisterer): Promise<void> => {
        await unregisterer();
      }
    );
    this.isClosed = true;
    log.debug('Server push handler is shut down');
  };

  public isShutdown = (): boolean => {
    return this.isClosed;
  };

  public async handle(message: ServerActionsLog<RepositoryEntities[ENAME]>): Promise<void> {
    // Remove 'fix' actions from actions log
    const { holders, interval } = message;
    const start = new Date().getTime();
    const { from, to } = interval;
    const holdersCount = holders.length;
    const actionsCount = countActions(holders);
    log.info(
      `[${this.entityName}] handleRepoSSE : interval: ${from}->${to},`,
      `${holdersCount} holder${holdersCount > 1 ? 's' : ''} (totalizing ${actionsCount} action${
        actionsCount > 1 ? 's' : ''
      })`
    );
    {
      const maxLogCount = 5;
      const loggedHolders =
        holders.length > maxLogCount
          ? [...holders.slice(0, maxLogCount), `... (${holders.length - maxLogCount} more)`]
          : holders;
      log.debug(`[${this.entityName}] handleRepoSSE : message:`, interval, ...loggedHolders);
    }
    // Check sequence id
    const lastKnownSequenceId = this.getLastKnownSequenceId();
    const nextExpectedSequenceId = lastKnownSequenceId == null ? 0 : lastKnownSequenceId + 1;
    if (lastKnownSequenceId == null || nextExpectedSequenceId < from) {
      log.verbose(
        `[${this.entityName}] sequence id mismatch, last known:${lastKnownSequenceId}, actual:${from}`
      );
      await this.loadMissingActionLog(nextExpectedSequenceId, from - 1);
    }
    // Handle the message
    await this.applyActionsAndSaveLastKnownSequence({ holders, interval });
    log.verbose(`[${this.entityName}] handleRepoSSE took ${new Date().getTime() - start} ms`);
  }

  public async downloadEntitiesFromServer(): Promise<void> {
    const start = new Date().getTime();
    log.info(`[${this.entityName}] download entities from server...`);
    const { sequenceId, entities } = await this.httpClient.httpGetAsJson<
      ServerEntitiesSnapshot<RepositoryEntities[ENAME]>
    >(
      EntitiesBackendRoutes.REPOSITORY_ENTITIES(
        this.entityName as KnownKeysOf<RepositoryEntities>,
        true
      )
    );
    // Replace all entities (remove exiting entities, and inserted provided
    // entities)
    await this.entityMutationsDAO.replaceAllEntitiesFromServerSnapshot(entities);
    log.info(
      `[${this.entityName}] downloaded ${entities.length} entit${
        entities.length > 1 ? 'ies' : 'y'
      } in ${new Date().getTime() - start} ms`
    );
    this.setLastKnownSequenceId(sequenceId);
    await this.loadMissingActionLog(sequenceId + 1);
  }

  /**
   * Loads missing actions from a given interval.
   * @param from the beginning of the interval to request.
   * @param to the end of the interval to request.
   */
  private async loadMissingActionLog(from: number | null, to?: number): Promise<void> {
    const start = new Date().getTime();
    log.info(`[${this.entityName}] load missing actions (${from} to ${to})...`);
    try {
      const pastActions = await this.httpClient.httpGetAsJson<
        ServerActionsLog<RepositoryEntities[ENAME]>
      >(
        EntitiesBackendRoutes.REPOSITORY_ACTION_LOG(
          this.entityName,
          String(!from ? 0 : from),
          to !== undefined ? String(to) : undefined
        )
      );
      await this.applyActionsAndSaveLastKnownSequence(pastActions);
      const holdersCount = pastActions.holders.length;
      const actionsCount = countActions(pastActions.holders);
      log.verbose(
        `[${this.entityName}] loaded ${holdersCount} holder${
          holdersCount > 1 ? 's' : ''
        } (totalizing ${actionsCount} action${actionsCount > 1 ? 's' : ''}) in ${
          new Date().getTime() - start
        } ms`
      );
    } catch (e) {
      // If the sequence range is too big simply download all entities (which will be quicker)
      if (e instanceof Error && getHttpStatusCode(e) === HttpErrorCodes.RANGE_NOT_SATISFIABLE) {
        await this.downloadEntitiesFromServer();
      } else {
        throw e;
      }
    }
  }

  private async applyActionsAndSaveLastKnownSequence(
    actionLog: ServerActionsLog<RepositoryEntities[ENAME]>
  ): Promise<void> {
    if (actionLog.holders.length > 0) {
      await this.entityMutationsDAO.handleServerActions(
        this.httpClient.getBrowserInfos().id,
        actionLog.holders
      );
      this.setLastKnownSequenceId(actionLog.interval.to);
    }
  }
}
