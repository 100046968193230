import type { Entity } from './kernel.js';

export const MARKETPLACE_STANDARD_PICTURES_CONFIGURATION_KEY = 'marketplace';
export const TOYOTA_STANDARD_PICTURES_CONFIGURATION_KEY = 'toyota';

export const STANDARD_PICTURES_CONFIGURATIONS_KEYS = [
  '',
  MARKETPLACE_STANDARD_PICTURES_CONFIGURATION_KEY,
  TOYOTA_STANDARD_PICTURES_CONFIGURATION_KEY,
] as const;
export type StandardPicturesConfigurationKey =
  (typeof STANDARD_PICTURES_CONFIGURATIONS_KEYS)[number];

export type StandardPictureCategory = string; // External, Interior, ...

export type StandardPictureId = string; // leftSide.webp, ...

export type StandardPictureInfo = {
  readonly id: StandardPictureId;
  readonly index: number;
  readonly placeholderPath?: string;
  readonly maskPath?: string;
};

export type StandardPicturesConfiguration = Record<
  StandardPictureCategory,
  readonly StandardPictureInfo[]
>;

export type StandardPictureConfEntry = Entity & {
  readonly standardPictureId: StandardPictureId;
  readonly category: StandardPictureCategory;
  readonly displayed: boolean;
  readonly improveImage: boolean;
};

export const MAIN_SIDES_STANDARD_PICTURE_KEYS = [
  'FRONT',
  'RIGHT_SIDE',
  'REAR',
  'LEFT_SIDE',
] as const;
export type MainSidesStandardPictureKey = (typeof MAIN_SIDES_STANDARD_PICTURE_KEYS)[number];

export const CAR_INTERIOR_PICTURE_ID = 'car-interior-view.webp';
export const CAR_INTERIOR_PICTURE_TO_DISPLAY = 'car-interior-view-640x480.webp';
