import type {
  Kanban,
  PackageDeal,
  PackageDealDesc,
  PurchaseOrder,
  UIContract,
} from '@stimcar/libs-base';
import type { Customer, KanbanInfos, MarketplaceMandate } from '@stimcar/libs-kernel';
import type { BusinessObjectToFormData, WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';
import { EMPTY_KANBAN_INFOS, MKTP_MANDATE_SALE } from '@stimcar/libs-kernel';
import { EMPTY_FORM_WITH_VALIDATION_STATE } from '@stimcar/libs-uitoolkit';
import type { PictureEditionToolkitState } from '../../../lib/components/attachments/typings/store.js';
import type { CustomerFormData } from '../../../lib/components/customer/typings/store.js';
import type { DisplayAttributesState } from '../../../lib/components/typings/store.js';
import { EMPTY_PICTURE_EDITION_TOOLKIT_STATE } from '../../../lib/components/attachments/typings/store.js';
import { EMPTY_EDIT_CUSTOMER_DIALOG_STATE } from '../../../lib/components/customer/typings/store.js';
import { DISPLAY_ATTRIBUTES_EMPTY_STATE } from '../../../lib/components/typings/store.js';

export type NewKanbanFormData = BusinessObjectToFormData<Omit<KanbanInfos, 'dateOfRegistration'>> &
  Pick<Kanban, 'workflowId'> & {
    readonly kanbanType: 'standard' | 'marketplace';
    readonly marketplaceMandate: MarketplaceMandate;
    readonly dueDate: number;
    readonly refitEndDate: number;
    readonly dateOfRegistration: number;
    readonly contractCode: string;
    readonly carLocation: string;
    readonly purchaseOrders: string;
  } & Omit<CustomerFormData, 'contract'>;

export type NewKanbanFormDataWithWarning = WithFormValidationWarnings<NewKanbanFormData>;

export const EMPTY_CREATE_KANBAN_FORM: NewKanbanFormDataWithWarning = {
  ...EMPTY_KANBAN_INFOS,
  ...EMPTY_EDIT_CUSTOMER_DIALOG_STATE.formData,
  kanbanType: 'standard',
  marketplaceMandate: MKTP_MANDATE_SALE,
  mileage: '',
  contractCode: '',
  dueDate: NaN,
  refitEndDate: NaN,
  dateOfRegistration: NaN,
  workflowId: '',
  carLocation: '',
  purchaseOrders: '',
};

export type SelectPredefinedCustomerFormData = {
  readonly customerId: string;
};

export type SelectPredefinedCustomerDialogState =
  FormWithValidationState<SelectPredefinedCustomerFormData> & {
    readonly active: boolean;
    readonly customers: readonly Customer[];
  };

export const EMPTY_SELECT_PREDEFINED_CUSTOMER_DIALOG_SATE: SelectPredefinedCustomerDialogState = {
  ...EMPTY_FORM_WITH_VALIDATION_STATE,
  active: false,
  customers: [],
  formData: {
    customerId: '',
    warnings: {},
  },
};
export type CreateKanbanViewState = FormWithValidationState<NewKanbanFormData> & {
  readonly packageDeals: readonly PackageDeal[];
  readonly purchaseOrders: readonly PurchaseOrder[];
  readonly isIncorrectOrientationModalOpen: boolean;
  readonly contract: UIContract | undefined;
  readonly parentKanbanId: string | null;
  // Has to be computed during view's initialization because otherwise multiple kanbans can be created
  // if user click many times on the creation button
  readonly newKanbanId: string;
  readonly packageDealDescs: readonly PackageDealDesc[];
  readonly attributesState: DisplayAttributesState;
  readonly isAttachmentRemovalDialogActive: boolean;
  readonly selectPredefinedCustomerDialogState: SelectPredefinedCustomerDialogState;
  readonly isPredefinedCustomer: boolean;
  readonly isLoaded: boolean;
  readonly pictureEditionToolkitState: PictureEditionToolkitState;
};

export const CREATE_KANBAN_VIEW_EMPTY_STATE: CreateKanbanViewState = {
  packageDeals: [],
  purchaseOrders: [],
  parentKanbanId: null,
  isIncorrectOrientationModalOpen: false,
  packageDealDescs: [],
  contract: undefined,
  newKanbanId: '',
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formData: EMPTY_CREATE_KANBAN_FORM,
  attributesState: DISPLAY_ATTRIBUTES_EMPTY_STATE,
  isAttachmentRemovalDialogActive: false,
  selectPredefinedCustomerDialogState: EMPTY_SELECT_PREDEFINED_CUSTOMER_DIALOG_SATE,
  isPredefinedCustomer: false,
  isLoaded: false,
  pictureEditionToolkitState: EMPTY_PICTURE_EDITION_TOOLKIT_STATE,
};
