import type { TFunction } from 'i18next';
import type {
  AirtableConnectorArgs,
  CarsExpectedAtStandMailNotificationArgs,
  ConnectorArgs,
  CustomersBasedMailNotificationArgs,
  DailyProdMailNotificationArgs,
  DAY_OF_WEEK,
  InvoiceMailNotificationArgs,
  MailNotificationArgs,
  ScheduledTask,
  ScheduledTaskSchedule,
  ScheduledTaskType,
  VOLeanConnectorArgs,
} from '@stimcar/libs-base';
import type { WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import type {
  CheckFormFieldContentActions,
  FormWithValidationState,
} from '@stimcar/libs-uitoolkit';
import { ALL_DAYS_OF_WEEK_ALIAS, enumerate, scheduledTaskHelpers } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, isValidEmailAddressStructure } from '@stimcar/libs-kernel';
import type { Store } from '../../state/typings/store.js';
import type {
  AirtableConnectorArgsData,
  BaseScheduledTaskDataForUpdate,
  CarsExpectedAtStandMailNotificationArgsData,
  ConnectorArgsData,
  CreateScheduledTaskDialogState,
  CustomersBasedMailNotificationArgsData,
  DailyProdMailNotificationArgsData,
  InvoiceMailNotificationArgsData,
  MailNotificationArgsData,
  ScheduledTaskIdentityData,
  ScheduledTaskScheduleData,
  SparePartsMailNotificationArgsData,
  VOLeanConnectorArgsData,
} from './typings/store.js';
import { SCHEDULED_TASK_LABEL_MAX_SIZE } from './typings/store.js';

interface ScheduledTaskScheduleForInputResult {
  readonly hours: readonly number[];
  readonly minutes: readonly number[];
}

function getScheduledTaskScheduleForInput(
  schedule: ScheduledTaskSchedule
): ScheduledTaskScheduleForInputResult {
  const { hour, min } = schedule;
  const hours: number[] = isTruthy(hour) ? [...hour] : [];
  const minutes: number[] = isTruthy(min) ? [...min] : [];
  return {
    hours,
    minutes,
  };
}

function getForUpdateBaseFormData(
  task: ScheduledTask
): WithFormValidationWarnings<BaseScheduledTaskDataForUpdate> {
  const { hours, minutes } = getScheduledTaskScheduleForInput(task.schedule);
  return {
    label: task.label,
    scheduledTaskActive: task.active,
    scheduledWeekDays: task.schedule.weekday,
    scheduledHours: hours,
    scheduledMinutes: minutes,
    asOftenAsPossibleWithinADay: hours.length === 0 && minutes.length === 0,
    warnings: {},
  };
}

function getForUpdateMailFormData(
  task: ScheduledTask
): WithFormValidationWarnings<BaseScheduledTaskDataForUpdate & MailNotificationArgsData> {
  const argumentsObject = task.scheduledTaskArgs as MailNotificationArgs;
  return {
    ...getForUpdateBaseFormData(task),
    to: argumentsObject.to,
    cc: argumentsObject.cc,
    subject: argumentsObject.subject ?? '',
    overrideDefaultSubject: isTruthyAndNotEmpty(argumentsObject.subject),
    replyTo: argumentsObject.replyTo ?? '',
    warnings: {},
  };
}

export function getScheduledTaskSchedule(
  scheduledTaskScheduleData: ScheduledTaskScheduleData
): ScheduledTaskSchedule {
  const { scheduledWeekDays, scheduledHours, scheduledMinutes, asOftenAsPossibleWithinADay } =
    scheduledTaskScheduleData;
  return {
    weekday: scheduledWeekDays,
    hour: asOftenAsPossibleWithinADay ? undefined : scheduledHours,
    min: asOftenAsPossibleWithinADay ? undefined : scheduledMinutes,
  };
}

export function getHoursOrMinutesAsStringArray(
  hourOrMin: number | readonly number[] | undefined,
  t: TFunction
): string {
  if (!isTruthy(hourOrMin)) {
    return t('adminScheduledTasks:schedule.asOftenAsPossible');
  }
  if (!isTruthy((hourOrMin as number[]).length)) {
    return String(hourOrMin as number);
  }
  return enumerate((hourOrMin as number[]).map((n) => String(n)));
}

export const checkLabelFieldContentActions: CheckFormFieldContentActions<
  Store,
  FormWithValidationState<Omit<ScheduledTaskIdentityData, 'type'>>
> = {
  label: ({ value, t }): string | undefined => {
    if (value && value.length > SCHEDULED_TASK_LABEL_MAX_SIZE) {
      return t('errors.inputTooLong', { size: SCHEDULED_TASK_LABEL_MAX_SIZE });
    }
    return undefined;
  },
};

export const checkIdentityFieldContentActions: CheckFormFieldContentActions<
  Store,
  CreateScheduledTaskDialogState
> = {
  type: ({ value, t }): string | undefined => {
    if (scheduledTaskHelpers.isCrossSitesScheduledTaskType(value)) {
      return t('errors.creationOrEditionNotAllowedForType');
    }
    if (
      !scheduledTaskHelpers.isEmailScheduledTaskType(value) &&
      !scheduledTaskHelpers.isImportScheduledTaskType(value)
    ) {
      return t('errors.unknownType');
    }
    return undefined;
  },
};

export const SCHEDULE_MANDATORY_FIELDS: (keyof ScheduledTaskScheduleData)[] = ['scheduledWeekDays'];

export const checkScheduleFieldContentActions: CheckFormFieldContentActions<
  Store,
  CreateScheduledTaskDialogState
> = {
  scheduledWeekDays: ({ value, t }): string | undefined => {
    return value.length > 0 ? undefined : t('warning.emptyExecutionDays');
  },
};

export function getMailNotificationMandatoryFields(
  overrideDefaultSubject: boolean
): (keyof MailNotificationArgsData)[] {
  const mandatoryFields: (keyof MailNotificationArgsData)[] = ['to'];
  if (overrideDefaultSubject) {
    mandatoryFields.push('subject');
  }
  return mandatoryFields;
}

export function getCustomerBasedMailNotificationMandatoryFields(
  customerShortNames: readonly string[] | undefined
): (keyof CustomersBasedMailNotificationArgsData)[] {
  if (
    isTruthy(customerShortNames) &&
    customerShortNames.length === 1 &&
    customerShortNames[0] === '*'
  ) {
    return ['customerShortNames'];
  }
  return ['customerShortNames', 'customersGroupName'];
}

export const checkEmailFieldContentActions: CheckFormFieldContentActions<
  Store,
  FormWithValidationState<MailNotificationArgsData>
> = {
  to: ({ value, t }): string | undefined => {
    let warningMsg;
    value.forEach((singleEmailAddress) => {
      if (!isValidEmailAddressStructure(singleEmailAddress.toLowerCase())) {
        warningMsg = t(`warning.malformedEmailAddress`);
      }
    });
    return warningMsg;
  },
  cc: ({ value, t }): string | undefined => {
    let warningMsg;
    value.forEach((singleEmailAddress) => {
      if (!isValidEmailAddressStructure(singleEmailAddress.toLowerCase())) {
        warningMsg = t(`warning.malformedEmailAddress`);
      }
    });
    return warningMsg;
  },
};

export const checkInvoiceEmailFieldContentActions: CheckFormFieldContentActions<
  Store,
  FormWithValidationState<InvoiceMailNotificationArgsData>
> = {
  replyTo: ({ value, t }): string | undefined => {
    return isValidEmailAddressStructure(value.toLowerCase())
      ? undefined
      : t(`warning.malformedEmailAddress`);
  },
};

export const checkImportAirtableFieldContentActions: CheckFormFieldContentActions<
  Store,
  FormWithValidationState<AirtableConnectorArgsData>
> = {};

export function getActualScheduleDays(
  scheduledDaysInput: readonly number[]
): readonly DAY_OF_WEEK[] {
  const actualScheduledDays: Set<DAY_OF_WEEK> = new Set();
  const allDaysOfWeek = scheduledTaskHelpers.getActualDaysOfWeek(ALL_DAYS_OF_WEEK_ALIAS);
  scheduledDaysInput.forEach((singleInput) => {
    if ((allDaysOfWeek as number[]).includes(singleInput)) {
      actualScheduledDays.add(singleInput as DAY_OF_WEEK);
    } else {
      const actualDays = scheduledTaskHelpers.getActualDaysOfWeek(singleInput);
      actualDays.forEach((day) => {
        actualScheduledDays.add(day);
      });
    }
  });
  return Array.from(actualScheduledDays);
}

export function getForUpdateConnectorFormData(
  task: ScheduledTask
): WithFormValidationWarnings<BaseScheduledTaskDataForUpdate & ConnectorArgsData> {
  const argumentsObject = task.scheduledTaskArgs as ConnectorArgs;
  return {
    ...getForUpdateBaseFormData(task),
    contractCode: argumentsObject.contractCode,
    workflowId: argumentsObject.workflowId,
    to: argumentsObject.importErrorMail?.to ?? [],
    cc: argumentsObject.importErrorMail?.cc ?? [],
    replyTo: argumentsObject.importErrorMail?.replyTo ?? '',
    subject: argumentsObject.importErrorMail?.subject ?? '',
    overrideDefaultSubject: false,
    warnings: {},
  };
}

export function getForUpdateVOLeanImportFormData(
  task: ScheduledTask
): WithFormValidationWarnings<BaseScheduledTaskDataForUpdate & VOLeanConnectorArgsData> {
  const argumentsObject = task.scheduledTaskArgs as VOLeanConnectorArgs;
  return {
    ...getForUpdateConnectorFormData(task),
    voLeanClientId: argumentsObject.voLeanClientId,
    voLeanPole: argumentsObject.voLeanPole,
    enablePkgDealsModifications: argumentsObject.enablePkgDealsModifications,
    qualityControlInVOLean: argumentsObject.qualityControlInVOLean,
    warnings: {},
  };
}

export function getForUpdateAirTableImportFormData(
  task: ScheduledTask
): WithFormValidationWarnings<
  ScheduledTaskIdentityData & ScheduledTaskScheduleData & AirtableConnectorArgsData
> {
  const argumentsObject = task.scheduledTaskArgs as AirtableConnectorArgs;
  return {
    ...getForUpdateConnectorFormData(task),
    token: argumentsObject.token,
    databaseId: argumentsObject.databaseId,
    databaseName: argumentsObject.databaseName,
    tableNames: argumentsObject.tableNames,
    customerAsFieldMode: argumentsObject.customerAsFieldMode,
    type: task.type,
    warnings: {},
  };
}

export function getForUpdateInvoiceMailFormData(
  task: ScheduledTask
): WithFormValidationWarnings<BaseScheduledTaskDataForUpdate & InvoiceMailNotificationArgsData> {
  const argumentsObject = task.scheduledTaskArgs as InvoiceMailNotificationArgs;
  return {
    ...getForUpdateMailFormData(task),
    customerShortNames:
      argumentsObject.customerShortNames !== '*' ? argumentsObject.customerShortNames : ['*'],
    replyTo: argumentsObject.replyTo ?? '',
    warnings: {},
  };
}

export function getForUpdateCustomersBasedMailFormData(
  task: ScheduledTask
): WithFormValidationWarnings<
  BaseScheduledTaskDataForUpdate & CustomersBasedMailNotificationArgsData
> {
  const argumentsObject = task.scheduledTaskArgs as CustomersBasedMailNotificationArgs;
  return {
    ...getForUpdateMailFormData(task),
    customerShortNames:
      argumentsObject.customerShortNames !== '*' ? argumentsObject.customerShortNames : ['*'],
    customersGroupName: argumentsObject.customersGroupName,
    warnings: {},
  };
}

export function getForUpdateDailyProdMailFormData(
  task: ScheduledTask
): WithFormValidationWarnings<BaseScheduledTaskDataForUpdate & DailyProdMailNotificationArgsData> {
  const argumentsObject = task.scheduledTaskArgs as DailyProdMailNotificationArgs;
  return {
    ...getForUpdateCustomersBasedMailFormData(task),
    standId: argumentsObject.standId,
    displayEstimateInfos: argumentsObject.displayEstimateInfos,
    displayAcceptanceInfos: argumentsObject.displayAcceptanceInfos,
    warnings: {},
  };
}

export function getForUpdateSparePartsMailFormData(
  task: ScheduledTask
): WithFormValidationWarnings<BaseScheduledTaskDataForUpdate & SparePartsMailNotificationArgsData> {
  return {
    ...getForUpdateCustomersBasedMailFormData(task),
    warnings: {},
  };
}

export function getForUpdateCarsExpectedAtStandMailFormData(
  task: ScheduledTask
): WithFormValidationWarnings<
  BaseScheduledTaskDataForUpdate & CarsExpectedAtStandMailNotificationArgsData
> {
  const argumentsObject = task.scheduledTaskArgs as CarsExpectedAtStandMailNotificationArgs;
  return {
    ...getForUpdateDailyProdMailFormData(task),
    mode: argumentsObject.mode,
    tagsFilteringPkgs: argumentsObject.tagsFilteringPkgs ?? [],
    warnings: {},
  };
}

export function getActualCustomerShortnames(
  customerShortNames: readonly string[]
): readonly string[] | '*' {
  return customerShortNames.length === 1 && customerShortNames[0] === '*'
    ? '*'
    : customerShortNames;
}

export function getArgumentsMandatoryFields(
  cronTaskType: ScheduledTaskType,
  overrideDefaultSubject: boolean,
  customerShortNames: readonly string[] | undefined
):
  | (keyof VOLeanConnectorArgsData)[]
  | (keyof AirtableConnectorArgsData)[]
  | (keyof InvoiceMailNotificationArgsData)[]
  | (keyof DailyProdMailNotificationArgsData)[]
  | (keyof SparePartsMailNotificationArgsData)[]
  // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
  | (keyof CustomersBasedMailNotificationArgsData)[]
  | (keyof CarsExpectedAtStandMailNotificationArgsData)[] {
  if (!isTruthyAndNotEmpty(cronTaskType)) {
    return [];
  }
  switch (cronTaskType) {
    case 'voLeanImport':
      return ['contractCode', 'workflowId', 'voLeanClientId', 'voLeanPole'];
    case 'airtableImport':
      return ['tableNames', 'contractCode', 'databaseId', 'databaseName', 'workflowId', 'token'];
    case 'invoiceMail':
      return [
        ...getMailNotificationMandatoryFields(overrideDefaultSubject),
        'customerShortNames',
        'replyTo',
      ];
    case 'ctmail':
    case 'dailyprodmail':
    case 'sparepartsmail':
    case 'carsexpectedatstandmail':
      return [
        ...getMailNotificationMandatoryFields(overrideDefaultSubject),
        ...getCustomerBasedMailNotificationMandatoryFields(customerShortNames),
      ];
    default:
      throw Error('Unexpected cron task type');
  }
}
