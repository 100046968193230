import type { RepositoryEntities } from '@stimcar/libs-base';
import type { SpecificFields } from '@stimcar/libs-kernel';
import type {
  Database,
  DatabaseFactory,
  DatabaseStoresConfigurator,
  DatabaseTx,
} from '../../database/index.js';
import type { LocalEntityHolder, RepositoryStoreDesc } from '../typings/repository-internals.js';
import { HOLDER_LOCAL_ENTITY, HOLDER_SERVER_ENTITY } from '../typings/repository-internals.js';
import type { DatabaseConfigurator } from './typings/repository-dao.js';

export const LOCAL_ACTION_LOG_BY_ENTITY_ID_INDEX = 'byEntityIdIndex';

export const LOCAL_ENTITIES_BY_STATUS_INDEX = 'byStatusIndex';

export const INDEX_LOCAL_SUFFIX = 'Local';

export const INDEX_SERVER_SUFFIX = 'Server';

const FACTOR_FOR_CORE_VERSION = 1000;

const CORE_DATABASE_VERSION = 2;

/**
 * Base component for the repository entry point.
 */
export class RepositoryDAOImpl<ENAME extends keyof RepositoryEntities> {
  private database: Database<RepositoryStoreDesc<ENAME>>;

  public readonly entityName: ENAME;

  public constructor(entityName: ENAME, database: Database<RepositoryStoreDesc<ENAME>>) {
    this.database = database;
    this.entityName = entityName;
  }

  public beginTx(): DatabaseTx<RepositoryStoreDesc<ENAME>> {
    return this.database.beginTx();
  }

  /**
   * Return the entity stored in the given {@link LocalEntityHolder}. The holder can come from the client or the server databases
   *
   * @param holder The given holder
   * @returns the held {@link Entity} without the ID field
   */
  protected getEntityData = (
    holder: LocalEntityHolder<RepositoryEntities[ENAME]>
  ): SpecificFields<RepositoryEntities[ENAME]> | undefined => {
    if (holder.localEntity) {
      return holder.localEntity;
    }
    if (holder.serverEntity) {
      return holder.serverEntity;
    }
    return undefined;
  };

  protected toLocalEntity = (
    holder: LocalEntityHolder<RepositoryEntities[ENAME]>
  ): RepositoryEntities[ENAME] => {
    const entity = this.getEntityData(holder);
    if (!entity) {
      throw new Error(`Illegal state : entity data missing in holder ${holder.id}`);
    }
    const dirty = holder.localEntity !== null && holder.localEntity !== undefined;
    const { sequenceId, timestamp, status } = holder;
    return { ...entity, id: holder.id, dirty, timestamp, sequenceId, status };
  };
}

function handleSpecificIndexesCreationOrDeletion<ENAME extends keyof RepositoryEntities>(
  dbc: DatabaseStoresConfigurator<RepositoryStoreDesc<ENAME>>,
  configurator: DatabaseConfigurator | undefined,
  oldVersion: number
): void {
  if (configurator) {
    const indexes = configurator.getDataBaseIndexesToCreateOrDelete(oldVersion);
    indexes.forEach((i): void => {
      const localIndexName = `${i.name}${INDEX_LOCAL_SUFFIX}`;
      const localIndexPath = `${HOLDER_LOCAL_ENTITY}.${i.path}`;

      const serverIndexName = `${i.name}${INDEX_SERVER_SUFFIX}`;
      const serverIndexPath = `${HOLDER_SERVER_ENTITY}.${i.path}`;

      if (i.action === 'delete') {
        dbc.deleteIndex('entities', localIndexName, localIndexPath);
        dbc.deleteIndex('entities', serverIndexName, serverIndexPath);
      }

      if (i.action === 'create') {
        dbc.createIndex('entities', localIndexName, localIndexPath);
        dbc.createIndex('entities', serverIndexName, serverIndexPath);
      }
    });
  }
}

function getCoreDatabaseVersion(oldVersion: number): number {
  return oldVersion < FACTOR_FOR_CORE_VERSION
    ? oldVersion
    : Math.trunc(oldVersion / FACTOR_FOR_CORE_VERSION);
}

function getExtenderDatabaseVersion<ENAME extends keyof RepositoryEntities>(
  oldVersion: number,
  entityName: ENAME
): number {
  const oldCoreVersion = getCoreDatabaseVersion(oldVersion);
  let defaultVersion = 0;
  /**
   * FIXME Hack for the switch between old and new version management.
   * Should be removed when enough time has passed since the switch
   *
   * Use 1 as default version for carElement and packageDealDesc repositories only
   * if we are not in a start from scratch launch
   */
  if (oldCoreVersion !== 0 && (entityName === 'carElement' || entityName === 'packageDealDesc')) {
    defaultVersion = 1;
  }
  return oldVersion < FACTOR_FOR_CORE_VERSION
    ? defaultVersion
    : oldVersion - oldCoreVersion * FACTOR_FOR_CORE_VERSION;
}

export const newDatabase = async <ENAME extends keyof RepositoryEntities>(
  entityName: ENAME,
  databaseFactory: DatabaseFactory,
  configurator?: DatabaseConfigurator
): Promise<Database<RepositoryStoreDesc<ENAME>>> => {
  const extenderVersion = configurator?.getExtenderVersion() ?? 0;

  // The version of the database is coreVersion * 1000 + extenderVersion to be able to distinguish core and extender version during schema migration
  const globalVersion = CORE_DATABASE_VERSION * FACTOR_FOR_CORE_VERSION + extenderVersion;

  const database: Database<RepositoryStoreDesc<ENAME>> = await databaseFactory.create(
    `${entityName}Repository`,
    globalVersion,
    (
      dbc: DatabaseStoresConfigurator<RepositoryStoreDesc<ENAME>>,
      oldVersion: number,
      newVersion: number | null // eslint-disable-line @typescript-eslint/no-unused-vars
    ): void => {
      const oldCoreVersion = getCoreDatabaseVersion(oldVersion);
      const oldExtenderVersion = getExtenderDatabaseVersion(oldVersion, entityName);

      /* eslint-disable no-fallthrough */
      /* no-fallthrough -> The rule is disabled as we intentionally don't put a break on the previous line  */
      // Inspired by https://developers.google.com/web/ilt/pwa/working-with-indexeddb#using_database_versioning
      switch (oldCoreVersion) {
        case 0: {
          dbc.createObjectStore('entities', 'id');

          dbc.createObjectStore('localActionLog', 'id');
          // Create indexes
          dbc.createIndex('localActionLog', LOCAL_ACTION_LOG_BY_ENTITY_ID_INDEX, 'entityId');
        }
        case 1:
          dbc.createIndex('entities', LOCAL_ENTITIES_BY_STATUS_INDEX, 'status');
        default:
        /* eslint-enable no-fallthrough */
      }
      handleSpecificIndexesCreationOrDeletion(dbc, configurator, oldExtenderVersion);
    }
  );
  return database;
};
