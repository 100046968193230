import type { SortDirection, SpecificFields } from '@stimcar/libs-kernel';
import { keysOf } from '@stimcar/libs-kernel';
import type {
  CoreFields,
  Kanban,
  KanbanIdentity,
  PriceableKanban,
  WorkflowNode,
} from '../../model/index.js';
import { ADMIN_STAND_ID, DELIVERY_STAND_ID } from '../../model/index.js';
import { enumerate } from '../misc.js';
import type { PackageDealWithRevenueProgress, RevenueProgress } from './typings/progress.js';
import { packageDealHelpers } from './packageDealHelpers.js';
import { EMPTY_REVENUE_PROGRESS } from './typings/progress.js';
import { workflowHelpers } from './workflowHelpers.js';

export type StandProgressWithRevenue = RevenueProgress & {
  readonly workInProgressPost?: string;
};

export type WorkflowProgressWithRevenue = {
  readonly progressByStand: Record<string, StandProgressWithRevenue>;
  readonly globalProgress: Omit<StandProgressWithRevenue, 'workInProgressPost'>;
  readonly expectedStandIds: readonly string[];
  readonly currentStandIds: readonly string[];
};

// const getNodeId = (node: WorkflowNode): string => (typeof node === 'string' ? node : node.id);

function isFinished({
  operationCount,
  operationDone,
  sparePartCount,
  sparePartReceived,
}: StandProgressWithRevenue): boolean {
  return operationCount === operationDone && sparePartCount === sparePartReceived;
}

function getExpectedWorkflowPosition(
  workflowNode: WorkflowNode,
  progressByStand: Record<string, StandProgressWithRevenue>
): readonly string[] {
  let id: string;
  let fork: readonly WorkflowNode[] | undefined;
  let join: WorkflowNode | undefined;
  if (typeof workflowNode === 'string') {
    id = workflowNode;
  } else {
    id = workflowNode.id;
    fork = workflowNode.fork;
    join = workflowNode.join;
  }
  const standProgress = progressByStand[id];
  if (!isFinished(standProgress)) {
    return [id];
  }
  if (fork) {
    const stands: string[] = [];
    fork.forEach((node) => {
      const position = getExpectedWorkflowPosition(node, progressByStand);
      if (position.length > 0) {
        stands.push(...position);
      }
    });
    if (stands.length > 0) {
      return stands;
    }
  }
  if (join) {
    return getExpectedWorkflowPosition(join, progressByStand);
  }
  return [];
}

export const EMPTY_STAND_PROGRESS: Omit<StandProgressWithRevenue, 'workInProgressPost'> = {
  operationCount: 0,
  operationDone: 0,
  operationTotalWorkload: 0,
  operationDoneWorkload: 0,
  operationDoneRevenue: 0,
  operationTotalRevenue: 0,
  sparePartCount: 0,
  sparePartReceived: 0,
  sparePartReceivedRevenue: 0,
  sparePartTotalRevenue: 0,
};

function mergeProgresses(
  progresses: readonly PackageDealWithRevenueProgress[],
  standIdFilter?: string
): RevenueProgress {
  const finalProgress = progresses
    .reduce<readonly RevenueProgress[]>((p, c) => {
      const packageDealProgresses = keysOf(c.progressByStand)
        .filter((standId) => !standIdFilter || standIdFilter === standId)
        .map((standId): RevenueProgress => c.progressByStand[standId]);
      return [...p, ...packageDealProgresses];
    }, [])
    .reduce<RevenueProgress>((p, c) => {
      return {
        operationCount: p.operationCount + c.operationCount,
        operationDone: p.operationDone + c.operationDone,
        operationTotalWorkload: p.operationTotalWorkload + c.operationTotalWorkload,
        operationDoneWorkload: p.operationDoneWorkload + c.operationDoneWorkload,
        operationDoneRevenue: p.operationDoneRevenue + c.operationDoneRevenue,
        operationTotalRevenue: p.operationTotalRevenue + c.operationTotalRevenue,
        sparePartCount: p.sparePartCount + c.sparePartCount,
        sparePartReceived: p.sparePartReceived + c.sparePartReceived,
        sparePartReceivedRevenue: p.sparePartReceivedRevenue + c.sparePartReceivedRevenue,
        sparePartTotalRevenue: p.sparePartTotalRevenue + c.sparePartTotalRevenue,
      };
    }, EMPTY_REVENUE_PROGRESS);
  return finalProgress;
}

function computeProgress<T extends SpecificFields<PriceableKanban>>(
  workflow: WorkflowNode,
  k: T,
  throwErrorOnUnknownStand = false
): WorkflowProgressWithRevenue {
  const { handlings } = k;
  const workflowProgress: Record<string, StandProgressWithRevenue> = {};
  const linearWorkflow = workflowHelpers.linearize(workflow);
  const packageDealsWithProgressByStand = packageDealHelpers.computePackageDealsWithRevenueProgress(
    k.packageDeals
  );

  if (throwErrorOnUnknownStand) {
    const standIds = packageDealsWithProgressByStand.reduce<readonly string[]>(
      (p, c): readonly string[] => {
        const newStandIds = keysOf(c.progressByStand).filter((standId) => !p.includes(standId));
        return [...p, ...newStandIds];
      },
      []
    );
    // Check that all stands are valid stands
    standIds.forEach((standId) => {
      if (!linearWorkflow.includes(standId)) {
        throw Error(`Unknown stand id '${standId}' in workflow '${enumerate(linearWorkflow)}'`);
      }
    });
  }

  // Merge stand progresses
  linearWorkflow.forEach((standId) => {
    workflowProgress[standId] = {
      ...mergeProgresses(packageDealsWithProgressByStand, standId),
    };
  });
  const currentStandIds: string[] = [];
  handlings
    .filter(({ endDate }) => !endDate)
    .forEach((wip) => {
      workflowProgress[wip.standId] = {
        ...workflowProgress[wip.standId],
        workInProgressPost: wip.postId,
      };
      currentStandIds.push(wip.standId);
    });
  return {
    progressByStand: workflowProgress,
    expectedStandIds: getExpectedWorkflowPosition(workflow, workflowProgress),
    currentStandIds,
    // Compute global progress
    globalProgress: keysOf(workflowProgress).reduce<
      Omit<StandProgressWithRevenue, 'workInProgressPost'>
    >((p, standId) => {
      return {
        operationCount: p.operationCount + workflowProgress[standId].operationCount,
        operationDone: p.operationDone + workflowProgress[standId].operationDone,
        operationTotalWorkload:
          p.operationTotalWorkload + workflowProgress[standId].operationTotalWorkload,
        operationDoneWorkload:
          p.operationDoneWorkload + workflowProgress[standId].operationDoneWorkload,
        operationDoneRevenue:
          p.operationDoneRevenue + workflowProgress[standId].operationDoneRevenue,
        operationTotalRevenue:
          p.operationTotalRevenue + workflowProgress[standId].operationTotalRevenue,
        sparePartCount: p.sparePartCount + workflowProgress[standId].sparePartCount,
        sparePartReceived: p.sparePartReceived + workflowProgress[standId].sparePartReceived,
        sparePartReceivedRevenue:
          p.sparePartReceivedRevenue + workflowProgress[standId].sparePartReceivedRevenue,
        sparePartTotalRevenue:
          p.sparePartTotalRevenue + workflowProgress[standId].sparePartTotalRevenue,
      };
    }, EMPTY_STAND_PROGRESS),
  };
}

function hasFinishedRefurbishProcess(expectedStandIds: readonly string[]): boolean {
  return (
    expectedStandIds.filter((s) => s !== ADMIN_STAND_ID && s !== DELIVERY_STAND_ID).length === 0
  );
}

function isKanbanAtEndOfRefurbishProcess(
  workflow: WorkflowNode,
  k: SpecificFields<PriceableKanban>
): boolean {
  const progress = computeProgress(workflow, k);
  return hasFinishedRefurbishProcess(progress.expectedStandIds);
}

function getStandFilter(
  workflowNode: WorkflowNode,
  standId: string,
  includeUpstream?: boolean
): (kanban: Kanban) => boolean {
  const linearizedWorkflow = workflowHelpers.linearize(workflowNode);
  const standIdx = linearizedWorkflow.indexOf(standId);
  return (kanban: Kanban): boolean => {
    const progress = computeProgress(workflowNode, kanban);
    if (includeUpstream) {
      const minIndex = progress.expectedStandIds.reduce<number>((p, c) => {
        const idx = linearizedWorkflow.indexOf(c);
        return idx <= p ? idx : p;
      }, 0);
      return minIndex <= standIdx;
    }

    return progress.expectedStandIds.includes(standId);
  };
}

export type WithExpectedStandIds<K> = K & {
  readonly expectedStandIds: readonly string[];
};

export type WithCurrentStandIds<K> = K & {
  readonly currentStandIds: readonly string[];
};

export type WithProgress<K> = WithExpectedStandIds<K> &
  WithCurrentStandIds<K> &
  Pick<WorkflowProgressWithRevenue, 'progressByStand' | 'globalProgress'>;

function getMainExpectedStandId<K extends CoreFields<Kanban>>(k: WithProgress<K>): string {
  if (k.expectedStandIds.length > 0) {
    const openHandlings = k.handlings.filter(
      (h) => !h.endDate && k.expectedStandIds.includes(h.standId)
    );
    return openHandlings.length > 0
      ? openHandlings.sort(({ startDate: d1 }, { startDate: d2 }) => d2 - d1)[0].standId
      : k.expectedStandIds[0];
  }
  const finished = packageDealHelpers
    .getFlatOperationList(k.packageDeals, 'achievable')
    .reduce<boolean>((p, c) => p && !!c.completionDate, true);
  return finished ? 'END' : '';
}

function createSortKanbanByCurrentlyHandled<K extends CoreFields<Kanban>>(
  sortDirection: SortDirection
): (a: WithProgress<K>, b: WithProgress<K>) => number {
  return function sort(a, b): number {
    const aCurrentlyHandled =
      a.handlings.filter((h) => !h.endDate && a.expectedStandIds.includes(h.standId)).length > 0;
    const bCurrentlyHandled =
      b.handlings.filter((h) => !h.endDate && b.expectedStandIds.includes(h.standId)).length > 0;
    if (aCurrentlyHandled !== bCurrentlyHandled) {
      if (aCurrentlyHandled) {
        return sortDirection === 'UP' ? +1 : -1;
      }
      if (bCurrentlyHandled) {
        return sortDirection === 'UP' ? -1 : +1;
      }
    }
    return 0;
  };
}

function createSortKanbanByHandledInThePast<K extends CoreFields<Kanban>>(
  sortDirection: SortDirection
): (a: WithProgress<K>, b: WithProgress<K>) => number {
  return function sort(a, b): number {
    const aPastHandled =
      a.handlings.filter((h) => a.expectedStandIds.includes(h.standId)).length > 0;
    const bPastHandled =
      b.handlings.filter((h) => b.expectedStandIds.includes(h.standId)).length > 0;
    if (aPastHandled !== bPastHandled) {
      if (aPastHandled) {
        return sortDirection === 'UP' ? +1 : -1;
      }
      if (bPastHandled) {
        return sortDirection === 'UP' ? -1 : +1;
      }
    }
    return 0;
  };
}

function createSortKanbanByCreationDate<K extends CoreFields<Kanban>>(
  sortDirection: SortDirection
): (a: WithProgress<K>, b: WithProgress<K>) => number {
  return function sort(a, b): number {
    if (a.creationDate > b.creationDate) {
      return sortDirection === 'UP' ? +1 : -1;
    }
    if (a.creationDate < b.creationDate) {
      return sortDirection === 'UP' ? -1 : +1;
    }
    return 0;
  };
}

function createSortKanbanByLicense<K extends CoreFields<Kanban>>(
  sortDirection: SortDirection
): (a: WithProgress<K>, b: WithProgress<K>) => number {
  return function sort(a, b): number {
    const aLicenseAndModel = `${a.infos.license}-${a.infos.model}`;
    const bLicenseAndModel = `${b.infos.license}-${b.infos.model}`;
    return sortDirection === 'UP'
      ? aLicenseAndModel.localeCompare(bLicenseAndModel)
      : bLicenseAndModel.localeCompare(aLicenseAndModel);
  };
}

function createSortKanbanByStand<K extends CoreFields<Kanban>>(
  sortDirection: SortDirection,
  linearWorkflow: readonly string[]
): (a: WithProgress<K>, b: WithProgress<K>) => number {
  return function sort(a, b): number {
    const aMainExpectedStandId = getMainExpectedStandId(a);
    const bMainExpectedStandId = getMainExpectedStandId(b);
    if (aMainExpectedStandId !== bMainExpectedStandId) {
      if (sortDirection === 'UP') {
        return linearWorkflow.indexOf(aMainExpectedStandId) >
          linearWorkflow.indexOf(bMainExpectedStandId)
          ? 1
          : -1;
      }
      return linearWorkflow.indexOf(aMainExpectedStandId) >
        linearWorkflow.indexOf(bMainExpectedStandId)
        ? -1
        : 1;
    }
    return 0;
  };
}

function sortKanbanAccordingToWorkflow<K extends CoreFields<Kanban>>(
  workflowNode: WorkflowNode,
  kanbans: readonly K[]
): readonly WithProgress<K>[] {
  const array: WithProgress<K>[] = [];
  kanbans.forEach((k) => {
    const { expectedStandIds, progressByStand, globalProgress, currentStandIds } = computeProgress(
      workflowNode,
      k
    );
    array.push({
      ...k,
      expectedStandIds,
      currentStandIds,
      progressByStand,
      globalProgress,
    });
  });

  const linearWorkflow = [...workflowHelpers.linearize(workflowNode), 'END'];
  array
    .sort(createSortKanbanByLicense('UP'))
    .sort(createSortKanbanByCreationDate('UP'))
    .sort(createSortKanbanByHandledInThePast('UP'))
    .sort(createSortKanbanByCurrentlyHandled('UP'))
    .sort(createSortKanbanByStand('UP', linearWorkflow));
  return array;
}

function sortKanbanAccordingToWorkflowIgnoringHandlings<K extends CoreFields<Kanban>>(
  workflowNode: WorkflowNode,
  kanbans: readonly K[],
  sortDirection: SortDirection
): readonly WithProgress<K>[] {
  const array: WithProgress<K>[] = [];
  kanbans.forEach((k) => {
    const { expectedStandIds, progressByStand, globalProgress, currentStandIds } = computeProgress(
      workflowNode,
      k
    );
    array.push({
      ...k,
      expectedStandIds,
      currentStandIds,
      progressByStand,
      globalProgress,
    });
  });

  const linearWorkflow = [...workflowHelpers.linearize(workflowNode), 'END'];
  array
    .sort(createSortKanbanByLicense(sortDirection))
    .sort(createSortKanbanByCreationDate(sortDirection))
    .sort(createSortKanbanByStand(sortDirection, linearWorkflow));
  return array;
}

function convertFromWithProgressType<K>(e: WithProgress<K>): K {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expectedStandIds, progressByStand, globalProgress, ...rest } = e;
  return rest as unknown as K;
}

export type KanbanIdentityWithProgress = WithProgress<KanbanIdentity>;

export type KANBAN_ID_TYPE = string;
export type WorkflowState = Record<KANBAN_ID_TYPE, KanbanIdentityWithProgress>;
export type WORKFLOW_ID_TYPE = string;
export interface WorkflowsState {
  readonly day: string;
  readonly state: Record<WORKFLOW_ID_TYPE, WorkflowState>;
}

function computeWorkflowState<K extends CoreFields<Kanban>>(
  workflowKanbans: readonly WithProgress<K>[]
): WorkflowState {
  const result: Record<string, KanbanIdentityWithProgress> = {};
  workflowKanbans.forEach(
    ({ id, infos, expectedStandIds, progressByStand, globalProgress, currentStandIds }) => {
      const { license } = infos;
      result[id] = {
        id,
        license,
        expectedStandIds,
        currentStandIds,
        progressByStand,
        globalProgress,
      };
    }
  );
  return result;
}

function filterKanbansByWorkflowAndComputeProgress<K extends CoreFields<Kanban>>(
  workflowId: string,
  fefinition: WorkflowNode,
  kanbans: readonly K[]
): readonly WithProgress<K>[] {
  const array: WithProgress<K>[] = [];
  const kanbansOfTheWorkflow = kanbans.filter((k) => k.workflowId === workflowId);
  kanbansOfTheWorkflow.forEach((k) => {
    const { expectedStandIds, progressByStand, globalProgress, currentStandIds } = computeProgress(
      fefinition,
      k
    );
    array.push({
      ...k,
      expectedStandIds,
      currentStandIds,
      progressByStand,
      globalProgress,
    });
  });
  return array;
}

function collectUpstreamStands(wn: WorkflowNode, lastStand: string): readonly string[] {
  if (typeof wn === 'string') {
    return wn === lastStand ? [wn] : [];
  }
  const { id: standId, fork, join } = wn;
  if (standId === lastStand) {
    return [standId];
  }
  if (fork && fork.length > 0) {
    for (let i = 0; i < fork.length; i += 1) {
      const forkUpstreamStands = collectUpstreamStands(fork[i], lastStand);
      if (forkUpstreamStands.length > 0) {
        return [standId, ...forkUpstreamStands];
      }
    }
  }
  if (join) {
    const joinUpstreamStands = collectUpstreamStands(join, lastStand);
    if (joinUpstreamStands.length > 0) {
      const forkStands: string[] = [];
      if (fork && fork.length > 0) {
        fork.forEach((f) => forkStands.push(...workflowHelpers.linearize(f)));
      }
      return [standId, ...forkStands, ...joinUpstreamStands];
    }
  }
  return [];
}

function getSiblingsForStand(wn: WorkflowNode, stand: string): readonly string[] {
  if (typeof wn === 'string') {
    return [];
  }
  const { id: standId, fork, join } = wn;

  // No sibling here
  if (standId === stand) {
    return [];
  }

  if (fork && fork.length > 0) {
    // Collect all node ids in fork
    const nodeIds = fork.map((node) => (typeof node === 'string' ? node : node.id));
    if (nodeIds.includes(stand)) {
      // The stand we are looking for is in fork, so we can return its siblings
      const result = fork.reduce<string[]>((acc, node) => {
        const allNodes = workflowHelpers.linearize(node);
        if (allNodes.length > 0 && allNodes[0] !== stand) {
          return [...acc, ...allNodes];
        }
        return acc;
      }, []);
      return result;
    }

    // Else we search in the sub nodes
    const siblings = fork.reduce<string[]>((acc, node) => {
      return [...acc, ...getSiblingsForStand(node, stand)];
    }, []);
    if (siblings.length > 0) {
      return siblings;
    }
  }

  if (join) {
    return getSiblingsForStand(join, stand);
  }

  return [];
}

export const workflowProgressHelpers = {
  computeProgress,
  collectUpstreamStands,
  mergeProgresses,
  getExpectedWorkflowPosition,
  sortKanbanAccordingToWorkflow,
  convertFromWithProgressType,
  getStandFilter,
  computeWorkflowState,
  sortKanbanAccordingToWorkflowIgnoringHandlings,
  filterKanbansByWorkflowAndComputeProgress,
  createSortKanbanByCreationDate,
  hasFinishedRefurbishProcess,
  isKanbanAtEndOfRefurbishProcess,
  getSiblingsForStand,
};
