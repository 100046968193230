import type { Deletable, Entity } from './kernel.js';
import type { StandardPictureId } from './standardPictures.js';

export const DEFECT_FRICTION = 'defectFriction';
export const DEFECT_SCRATCH = 'defectScratch';
export const DEFECT_PAINT_CHIP = 'defectPaintChip';
export const DEFECT_IMPACT = 'defectImpact';
export const DEFECT_BODYWORK_DENT = 'defectBodyDent';
export const DEFECT_PAINT_DAMAGE = 'defectPaintDamage';
export const DEFECT_INTERIOR_SCRATCH = 'defectInteriorScratch';
export const DEFECT_TEAR = 'defectTear';
export const DEFECT_MISSING_OR_BROKEN_PART = 'defectMissingOrBrokenPart';

export const DEFECT_TYPES = [
  DEFECT_FRICTION,
  DEFECT_SCRATCH,
  DEFECT_PAINT_CHIP,
  DEFECT_IMPACT,
  DEFECT_BODYWORK_DENT,
  DEFECT_PAINT_DAMAGE,
  DEFECT_INTERIOR_SCRATCH,
  DEFECT_TEAR,
  DEFECT_MISSING_OR_BROKEN_PART,
] as const;

export type DefectType = (typeof DEFECT_TYPES)[number];

export type PositionOnCar = {
  readonly pictureId: StandardPictureId | null;
  readonly x: number | null;
  readonly y: number | null;
};

export type DefectHighlighter = {
  readonly x: number;
  readonly y: number;
  readonly width: number;
  readonly height: number;
};

export const REFURBISHING_TYPES = ['fully', 'partially'] as const;

export type RefurbishingType = (typeof REFURBISHING_TYPES)[number];

export type Defect = Entity &
  Deletable & {
    readonly type: DefectType;
    readonly picture: StandardPictureId;
    readonly highlighter: DefectHighlighter;
    readonly positionOnCar: PositionOnCar | null;
    readonly fullyRefurbishingPackageDealIds: readonly string[];
    readonly partiallyRefurbishingPackageDealIds: readonly string[];
  };
