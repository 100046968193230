import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { RegisteredBrowserInfos } from '@stimcar/core-libs-repository';
import type {
  ActionContext,
  StoreStateSelector,
  WithFormValidationWarnings,
} from '@stimcar/libs-uikernel';
import type { AppProps, FormFieldEntry, HorizontalFormFieldProps } from '@stimcar/libs-uitoolkit';
import { i18nHelpers, Role } from '@stimcar/libs-base';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import { ModalCardDialog, SelectFormField, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import { RadioButtonsFormField } from '../lib/bulma/form/RadioButtonsFormField.js';
import { useStandIds } from '../registeredapp/components/authentication/utils/useStandIds.js';
import type {
  HandledKanbanActions,
  Store,
  SwitchToRoleDialogState,
  SwitchToRoleForm,
} from './state/typings/store.js';
import { closeOrPauseHandledKanbansAction } from './utils/postConfigurationUtils.js';
import { useRoleEntries } from './utils/useRoleEntries.js';

async function switchToRoleAction({
  getState,
  globalActionDispatch,
  httpClient,
  actionDispatch,
}: ActionContext<Store, SwitchToRoleDialogState>): Promise<void> {
  const { newRole, newStandId, actionToPerformOnRoleChange } = getState().formData;
  await globalActionDispatch.exec(
    closeOrPauseHandledKanbansAction,
    actionToPerformOnRoleChange as HandledKanbanActions
  );
  httpClient.switchToRole(newRole, newStandId);
  globalActionDispatch.scopeProperty('session').applyPayload({
    infos: {
      role: newRole,
      standId: newStandId,
    },
  });
  actionDispatch.setProperty('active', false);
}

const getMandatoryFields = (
  formData: SwitchToRoleDialogState['formData']
): (keyof SwitchToRoleForm)[] => {
  const { newRole } = formData;
  const result: (keyof SwitchToRoleForm)[] = ['newRole'];
  if (Role.WorkshopOperator === newRole) {
    result.push('newStandId');
  }
  return result;
};

const HORIZONTAL_PROPS: HorizontalFormFieldProps = { bodyFlexGrow: 5, labelFlexGrow: 1 };

export function SwitchToRoleDialog({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation(['refitit', 'globals']);
  const { $navbar } = $gs;
  const { $switchToRoleDialogState } = $navbar;

  const formWarning = useGetState($switchToRoleDialogState.$formWarning);

  const submitValidDataAction = useActionCallback(switchToRoleAction, [], $switchToRoleDialogState);

  const browserInfos = useGetState($gs.$session.$infos);

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    SwitchToRoleDialogState
  >({
    $: $switchToRoleDialogState,
    mandatoryFields: getMandatoryFields,
    checkFieldContentActions: undefined, // No field content check
    checkFormConsistencyAction: undefined, // No specific form consistency check
    submitValidDataAction,
    t,
  });

  const roleEntries = useRoleEntries(t, Role.WorkshopOperator);

  const newRole = useGetState($formDataWithChangeTrigger.$newRole);
  const standIds = useStandIds($gs, newRole);

  const onRoleChangeHandlerCallback = useActionCallback(
    ({ actionDispatch, getState }) => {
      const { newRole } = getState();
      if (Role.Operator !== newRole) {
        actionDispatch.setProperty('newStandId', '');
      }
    },
    [],
    $formDataWithChangeTrigger
  );

  const handledKanban = useGetState($navbar.$handledKanban);

  const changeRoleTitleLabel = useMemo((): string => {
    if (isTruthy(handledKanban)) {
      const { infos } = handledKanban;
      return t('switchRoleDialog.oneHandledKanban', {
        brand: i18nHelpers.displayStringOrPlaceholder(t, infos.brand),
        model: infos.model,
        license: infos.license,
      });
    }
    return t('switchRoleDialog.defaultContent');
  }, [handledKanban, t]);

  const onCancelClickedHandler = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.reduce((initial: SwitchToRoleDialogState): SwitchToRoleDialogState => {
        return {
          ...initial,
          formData: {
            ...initial.formData,
            actionToPerformOnRoleChange: 'pause',
          },
        };
      });
    },
    [],
    $switchToRoleDialogState
  );

  const isNotWorkshopOperatorRole = newRole !== Role.WorkshopOperator;
  return (
    <ModalCardDialog
      warning={formWarning}
      $active={$switchToRoleDialogState.$active}
      onOkClicked={onFormSubmit}
      onCancelClicked={onCancelClickedHandler}
      title={t('user.switchRole')}
    >
      <SelectFormField
        label={t('switchRoleDialog.role')}
        entries={roleEntries}
        isEmptyValueAllowed
        sortEntries
        horizontal={HORIZONTAL_PROPS}
        isFullWidth
        $={useSelectorWithChangeTrigger(
          $formDataWithChangeTrigger.$newRole,
          onRoleChangeHandlerCallback
        )}
      />
      {!isNotWorkshopOperatorRole && (
        <SelectFormField
          label={t('switchRoleDialog.stand')}
          entries={standIds}
          isEmptyValueAllowed
          horizontal={HORIZONTAL_PROPS}
          sortEntries
          isFullWidth
          $={$formDataWithChangeTrigger.$newStandId}
        />
      )}
      {isTruthy(handledKanban) && (
        <>
          <div>{changeRoleTitleLabel}</div>
          <br />
          <div className="control">
            <CloseHandledKanban
              browserInfos={nonnull(browserInfos)}
              horizontal={HORIZONTAL_PROPS}
              $formData={$formDataWithChangeTrigger}
            />
          </div>
        </>
      )}
    </ModalCardDialog>
  );
}

interface CloseHandledKanbanProps {
  readonly browserInfos: RegisteredBrowserInfos;
  readonly $formData: StoreStateSelector<Store, WithFormValidationWarnings<SwitchToRoleForm>>;
  readonly horizontal?: boolean | HorizontalFormFieldProps;
}
const CloseHandledKanban = ({ $formData, horizontal }: CloseHandledKanbanProps): JSX.Element => {
  const [t] = useTranslation(['refitit', 'globals']);

  const actions = useMemo(
    (): FormFieldEntry<HandledKanbanActions>[] => [
      {
        label: t('switchRoleDialog.changeRoleAndDoNothing'),
        id: 'none',
      },
      {
        label: t('switchRoleDialog.changeRoleAndClose'),
        id: 'close',
      },
      {
        label: t('switchRoleDialog.changeRoleAndPause'),
        id: 'pause',
      },
    ],
    [t]
  );

  return (
    <RadioButtonsFormField
      id="handledKanbanAction"
      label={t('switchRoleDialog.kanbanHandledTitle')}
      sortEntries
      entries={actions}
      $={$formData.$actionToPerformOnRoleChange}
      horizontal={horizontal}
      radioGroupLayout="vertical"
    />
  );
};
