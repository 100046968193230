import type { AvailableStimer } from '@stimcar/core-libs-common';
import type {
  EnergyValue,
  GearboxValue,
  MarketplacePayment,
  MarketplacePaymentEntity,
  WarrantyExtensionValue,
} from '@stimcar/libs-kernel';
import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';
import { EMPTY_FORM_WITH_VALIDATION_STATE } from '@stimcar/libs-uitoolkit';
import type { AttachmentGalleryDialogState } from '../../../../lib/components/attachments/typings/store.js';
import { EMPTY_ATTACHMENT_GALLERY_STATE } from '../../../../lib/components/attachments/typings/store.js';

export type MarketplaceMessageDialogState = {
  readonly active: boolean;
};

export type MarketplaceEditMainSpecsForm = {
  readonly energy: EnergyValue;
  readonly gearbox: GearboxValue;
  readonly horsepower: string;
  readonly taxHorsepower: string;
  readonly doors: string;
  readonly seats: string;
  readonly ecoSticker: string;
};

export type MarketplaceEditMainSpecsDialogState =
  FormWithValidationState<MarketplaceEditMainSpecsForm> & {
    readonly active: boolean;
  };

export type MarketplaceEquipmentForm = {
  readonly selectedEquipments: readonly string[];
  readonly additionalEquipments: readonly string[];
  readonly newEquipmentValue: string;
};

export type MarketplaceEditEquipmentsForm = {
  readonly security: MarketplaceEquipmentForm;
  readonly comfort: MarketplaceEquipmentForm;
  readonly multimedia: MarketplaceEquipmentForm;
  readonly others: MarketplaceEquipmentForm;
};

export type MarketplaceEditEquipmentsDialogState =
  FormWithValidationState<MarketplaceEditEquipmentsForm> & {
    readonly active: boolean;
  };

export type MarketplaceOverviewState = {
  readonly inputPrice: string;
  readonly negotiation: string;
  readonly warrantyExtension: WarrantyExtensionValue;
  readonly loanAmount: string;
  readonly depositThirdPartyService: string;
  readonly deposit: string;
  readonly canceledPackageDealIds: readonly string[];
  readonly isDropdownButtonActive: boolean;
  readonly overviewButtonDialog: MarketplaceMessageDialogState;
  readonly saveConfirmationDialog: MarketplaceMessageDialogState;
  readonly variableFeeConfirmationDialog: MarketplaceMessageDialogState;
};

export type PaymentAddEditModalState = FormWithValidationState<MarketplacePayment> & {
  readonly isActive: boolean;
  readonly paymentId: string;
};

export type PaymentDeletionModalState = {
  readonly isActive: boolean;
  readonly paymentId: string;
};

export type MarketplacePaymentsState = {
  readonly payments: readonly MarketplacePaymentEntity[];
  readonly paymentAddModalState: PaymentAddEditModalState;
  readonly paymentEditModalState: PaymentAddEditModalState;
  readonly paymentDeletionModalState: PaymentDeletionModalState;
};

export type MarketplaceStimerState = {
  readonly stimerId: string;
  readonly availableStimers: readonly AvailableStimer[];
  readonly overviewButtonDialog: MarketplaceMessageDialogState;
  readonly saveConfirmationDialog: MarketplaceMessageDialogState;
};

export type MarketplaceAdPreviewState = {
  readonly htmlContent: string;
};

export const MKTP_TABS_OVERVIEW = 'overview';
export const MKTP_TABS_SPECS = 'specs';
export const MKTP_TABS_AD_PREVIEW = 'preview';
export const MKTP_TABS_PAYMENT = 'payment';
export const MKTP_TABS_STIMER = 'stimer';
export const MKTP_TABS_PHOTO_GALLERY = 'photoAttachmentsGallery';
export const MKTP_TABS_TEXT_ATTACHMENT_GALLERY = 'textAttachmentsGallery';
export const MKTP_TABS = [
  MKTP_TABS_OVERVIEW,
  MKTP_TABS_PAYMENT,
  MKTP_TABS_SPECS,
  MKTP_TABS_AD_PREVIEW,
  MKTP_TABS_STIMER,
  MKTP_TABS_PHOTO_GALLERY,
  MKTP_TABS_TEXT_ATTACHMENT_GALLERY,
];
export type MarketplaceTabs = (typeof MKTP_TABS)[number];

export type MarketplaceDetailsState = {
  readonly marketplacePreview: MarketplaceAdPreviewState;
  readonly marketplacePayments: MarketplacePaymentsState;
  readonly marketplaceStimer: MarketplaceStimerState;
  readonly marketplaceOverview: MarketplaceOverviewState;
  readonly editMainSpecsDialog: MarketplaceEditMainSpecsDialogState;
  readonly editEquipmentsDialog: MarketplaceEditEquipmentsDialogState;
  readonly attachmentGalleryDialogModal: AttachmentGalleryDialogState;
  readonly selectedTab: MarketplaceTabs;
};

export const EMPTY_MARKETPLACE_EQUIPMENT_CATEGORY: MarketplaceEquipmentForm = {
  selectedEquipments: [],
  additionalEquipments: [],
  newEquipmentValue: '',
};

export const MARKETPLACE_PAYMENT_ADD_EDIT_MODAL_EMPTY_STATE: PaymentAddEditModalState = {
  ...EMPTY_FORM_WITH_VALIDATION_STATE,
  isActive: false,
  paymentId: '',
  formData: {
    client: '',
    paymentRequestDate: 0,
    paymentCompletionDate: 0,
    paymentType: '',
    priceWithVAT: '',
    description: '',
    warnings: {},
  },
};

export const MARKETPLACE_PAYMENT_DELETION_MODAL_EMPTY_STATE: PaymentDeletionModalState = {
  isActive: false,
  paymentId: '',
};

export const MARKETPLACE_DETAILS_EMPTY_STATE: MarketplaceDetailsState = {
  attachmentGalleryDialogModal: {
    active: false,
    ...EMPTY_ATTACHMENT_GALLERY_STATE,
  },
  selectedTab: 'overview',
  editMainSpecsDialog: {
    active: false,
    formSubmitClickedOnce: false,
    formSubmitted: false,
    formWarning: undefined,
    formData: {
      energy: '',
      gearbox: '',
      horsepower: '',
      taxHorsepower: '',
      doors: '',
      seats: '',
      ecoSticker: '',
      warnings: {},
    },
  },
  editEquipmentsDialog: {
    active: false,
    formSubmitClickedOnce: false,
    formSubmitted: false,
    formWarning: undefined,
    formData: {
      security: EMPTY_MARKETPLACE_EQUIPMENT_CATEGORY,
      comfort: EMPTY_MARKETPLACE_EQUIPMENT_CATEGORY,
      multimedia: EMPTY_MARKETPLACE_EQUIPMENT_CATEGORY,
      others: EMPTY_MARKETPLACE_EQUIPMENT_CATEGORY,
      warnings: {},
    },
  },
  marketplacePreview: {
    htmlContent: '',
  },
  marketplaceOverview: {
    inputPrice: '0',
    negotiation: '0',
    warrantyExtension: 'unknown',
    loanAmount: '0',
    depositThirdPartyService: '',
    deposit: '0',
    canceledPackageDealIds: [],
    isDropdownButtonActive: false,
    overviewButtonDialog: {
      active: false,
    },
    saveConfirmationDialog: {
      active: false,
    },
    variableFeeConfirmationDialog: {
      active: false,
    },
  },
  marketplacePayments: {
    payments: [],
    paymentAddModalState: MARKETPLACE_PAYMENT_ADD_EDIT_MODAL_EMPTY_STATE,
    paymentEditModalState: MARKETPLACE_PAYMENT_ADD_EDIT_MODAL_EMPTY_STATE,
    paymentDeletionModalState: MARKETPLACE_PAYMENT_DELETION_MODAL_EMPTY_STATE,
  },
  marketplaceStimer: {
    stimerId: '',
    availableStimers: [],
    overviewButtonDialog: {
      active: false,
    },
    saveConfirmationDialog: {
      active: false,
    },
  },
};
