import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ContractAttributeDesc, Kanban, UIContract } from '@stimcar/libs-base';
import type {
  ActionContext,
  NoArgActionCallback,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type {
  CheckFormConsistencyAction,
  CheckFormFieldContentActions,
} from '@stimcar/libs-uitoolkit';
import {
  DELEGATION_SOURCE,
  globalHelpers,
  kanbanHelpers,
  shortDayMonthFullYearDateFormatOptions,
  shortDayMonthYearDateFormatOptions,
  shortDayMonthYearHourFormatOptions,
  toUpperFirst,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  ClickableIcon,
  InputFormField,
  ModalCardDialog,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../app/state/typings/store.js';
import { isIntegerCheck } from '../../utils/commonFormChecks.js';
import { CalendarModalDialog } from '../CalendarModalDialog.js';
import type {
  EditDueDateDialogState,
  EditMileageDialogState,
  EditRefitEndDateDialogState,
} from './typings/store.js';
import type {
  DueDateEditionToolkit,
  MileageEditionToolkit,
  RefitEndDateEditionToolkit,
} from './typings/toolkits.js';
import {
  EMPTY_EDIT_DUE_DATE_DIALOG_STATE,
  EMPTY_EDIT_REFIT_END_DATE_DIALOG_STATE,
} from './typings/store.js';

export function openEditDueDateDialogAction(
  { actionDispatch }: ActionContext<Store, EditDueDateDialogState>,
  initialDueDate: number | null,
  initialRefitEndDate: number | null
): void {
  actionDispatch.reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (initial: EditDueDateDialogState): EditDueDateDialogState => {
      return {
        ...initial, // important to keep parent additions (the state may be a subclass)
        ...EMPTY_EDIT_DUE_DATE_DIALOG_STATE,
        active: true,
        initialDueDate,
        initialRefitEndDate,
        formData: {
          dueDate: initialDueDate ?? NaN,
          warnings: {},
        },
      };
    }
  );
}

export function openDeleteDueDateDialogAction(
  { actionDispatch }: ActionContext<Store, EditDueDateDialogState>,
  initialDueDate: number | null
): void {
  actionDispatch.reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (initial: EditDueDateDialogState): EditDueDateDialogState => {
      return {
        ...initial, // important to keep parent additions (the state may be a subclass)
        ...EMPTY_EDIT_DUE_DATE_DIALOG_STATE,
        deletionActive: true,
        initialDueDate,
        formData: {
          dueDate: NaN,
          warnings: {},
        },
      };
    }
  );
}

export function openEditRefitEndDateDialogAction(
  { actionDispatch }: ActionContext<Store, EditRefitEndDateDialogState>,
  initialDueDate: number | null,
  initialRefitEndDate: number | null
): void {
  actionDispatch.reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (initial: EditRefitEndDateDialogState): EditRefitEndDateDialogState => {
      return {
        ...initial, // important to keep parent additions (the state may be a subclass)
        ...EMPTY_EDIT_REFIT_END_DATE_DIALOG_STATE,
        active: true,
        initialDueDate,
        initialRefitEndDate,
        formData: {
          refitEndDate: initialRefitEndDate ?? NaN,
          warnings: {},
        },
      };
    }
  );
}

export function openDeleteRefitEndDateDialogAction(
  { actionDispatch }: ActionContext<Store, EditRefitEndDateDialogState>,
  initialRefitEndDate: number | null
): void {
  actionDispatch.reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (initial: EditRefitEndDateDialogState): EditRefitEndDateDialogState => {
      return {
        ...initial, // important to keep parent additions (the state may be a subclass)
        ...EMPTY_EDIT_DUE_DATE_DIALOG_STATE,
        deletionActive: true,
        initialRefitEndDate,
        formData: {
          refitEndDate: NaN,
          warnings: {},
        },
      };
    }
  );
}

interface InEstimateAttributesDisplayProps {
  readonly kanban: Kanban;
  readonly attributeDescs: readonly ContractAttributeDesc[];
}

export function InEstimateAttributesDisplay({
  kanban,
  attributeDescs,
}: InEstimateAttributesDisplayProps): JSX.Element {
  const [t] = useTranslation('libComponents');

  const showInEstimateAD = attributeDescs.filter((ad) => ad.showInEstimate);
  if (showInEstimateAD.length === 0) {
    return <></>;
  }

  return (
    <>
      {showInEstimateAD.map((ad): JSX.Element => {
        const attributeValue = kanban.attributes[ad.id];
        let displayedAttributeValue = t('kanbanGeneralInformations.notCommunicated');
        if (typeof attributeValue !== 'string' || isTruthyAndNotEmpty(attributeValue)) {
          displayedAttributeValue = String(attributeValue);
        }
        return (
          <React.Fragment key={ad.id}>
            <b>{`${ad.id}: `}</b>
            {displayedAttributeValue}
            <br />
          </React.Fragment>
        );
      })}
    </>
  );
}

interface DueDateModalsProps {
  readonly kanban: Kanban;
  readonly $: StoreStateSelector<Store, EditDueDateDialogState>;
}

export function DueDateModalsDisplay({ kanban, $ }: DueDateModalsProps): JSX.Element {
  const onDueDateChanged = useActionCallback(
    async ({
      actionDispatch,
      getState,
      kanbanRepository,
    }: ActionContext<Store, EditDueDateDialogState>): Promise<void> => {
      const { formData } = getState();
      const newKanban: Kanban = {
        ...kanban,
        dueDate: !Number.isNaN(formData.dueDate) ? formData.dueDate : null,
      };
      await kanbanRepository.updateEntity(newKanban);
      actionDispatch.setProperty('active', false);
      actionDispatch.setProperty('deletionActive', false);
    },
    [kanban],
    $
  );
  return (
    <>
      <EditDueDateModal $={$} submitValidDataAction={onDueDateChanged} />
      <DeleteDueDateModal $={$} submitValidDataAction={onDueDateChanged} />
    </>
  );
}

interface RefitEndDateModalsProps {
  readonly kanban: Kanban;
  readonly $: StoreStateSelector<Store, EditRefitEndDateDialogState>;
}

export function RefitEndDateModalsDisplay({ kanban, $ }: RefitEndDateModalsProps): JSX.Element {
  const onRefitEndDateChanged = useActionCallback(
    async ({
      actionDispatch,
      getState,
      kanbanRepository,
    }: ActionContext<Store, EditRefitEndDateDialogState>): Promise<void> => {
      const { formData } = getState();
      const newKanban: Kanban = {
        ...kanban,
        refitEndDate: !Number.isNaN(formData.refitEndDate) ? formData.refitEndDate : null,
      };
      await kanbanRepository.updateEntity(newKanban);
      actionDispatch.setProperty('active', false);
      actionDispatch.setProperty('deletionActive', false);
    },
    [kanban],
    $
  );
  return (
    <>
      <EditRefitEndDateModal $={$} submitValidDataAction={onRefitEndDateChanged} />
      <DeleteRefitEndDateModal $={$} submitValidDataAction={onRefitEndDateChanged} />
    </>
  );
}

interface EditMileageModalDisplayProps {
  readonly kanban: Kanban;
  readonly $: StoreStateSelector<Store, EditMileageDialogState>;
}

export function EditMileageModalDisplay({ kanban, $ }: EditMileageModalDisplayProps): JSX.Element {
  const onMileageChanged = useActionCallback(
    async ({
      actionDispatch,
      getState,
      kanbanRepository,
    }: ActionContext<Store, EditMileageDialogState>): Promise<void> => {
      const { formData } = getState();
      const newKanban: Kanban = {
        ...kanban,
        infos: {
          ...kanban.infos,
          mileage: Number.parseInt(formData.mileage, 10),
        },
      };
      await kanbanRepository.updateEntity(newKanban);
      actionDispatch.setProperty('active', false);
    },
    [kanban],
    $
  );
  return (
    <>
      <EditMileageModal $={$} submitValidDataAction={onMileageChanged} />
    </>
  );
}
interface KanbanGeneralInformationsProps {
  readonly kanban: Kanban;
  readonly contract: UIContract | undefined;
  readonly displayShowInEstimateAttributes?: boolean;
  readonly dueDateEditionToolkit?: DueDateEditionToolkit;
  readonly refitEndDateEditionToolkit?: RefitEndDateEditionToolkit;
  readonly mileageEditionToolkit?: MileageEditionToolkit;
}

export function KanbanGeneralInformations({
  kanban,
  contract,
  displayShowInEstimateAttributes = false,
  dueDateEditionToolkit,
  refitEndDateEditionToolkit,
  mileageEditionToolkit,
}: KanbanGeneralInformationsProps): JSX.Element {
  const [t] = useTranslation('libComponents');

  return (
    <>
      <div className="column is-5">
        <b>{`${t('kanbanGeneralInformations.origin')}: `}</b>
        {kanban.origin.source !== DELEGATION_SOURCE && toUpperFirst(kanban.origin.source)}
        {kanban.origin.source === DELEGATION_SOURCE &&
          `Stimcar ${kanban.origin.id?.split('/')[1] ?? '<?>'}`}
        <br />
        <b>{`${t('kanbanGeneralInformations.contractAndWorkflow')}: `}</b>
        {`${kanban.contract.code}/${kanban.workflowId}`}
        <br />
        <b>{`${t('kanbanGeneralInformations.customer')}: `}</b>
        {kanbanHelpers.getCustomerLabel(kanban.customer, t)}
        <br />
        <b>{`${t('kanbanGeneralInformations.keyLocation')}: `}</b>
        {isTruthyAndNotEmpty(kanban.logisticInfos.keyNumber)
          ? kanban.logisticInfos.keyNumber
          : t('libUtils:noValue')}
        <br />
        <b>{`${t('kanbanGeneralInformations.creationDate')}: `}</b>
        {new Date(kanban.creationDate).toLocaleDateString(
          'fr-FR',
          shortDayMonthYearHourFormatOptions
        )}
        <br />
        <b>{`${t('kanbanGeneralInformations.lastModified')}: `}</b>
        {new Date(kanban.timestamp).toLocaleDateString('fr-FR', shortDayMonthYearHourFormatOptions)}
        <br />
        {displayShowInEstimateAttributes && (
          <InEstimateAttributesDisplay
            kanban={kanban}
            attributeDescs={contract?.attributeDescs ?? []}
          />
        )}
      </div>
      <div className="column is-4">
        <b>{`${t('kanbanGeneralInformations.vin')}: `}</b>
        {kanban.infos.vin}
        <br />
        <b>{`${t('kanbanGeneralInformations.motor')}: `}</b>
        {kanban.infos.motor}
        <br />
        <b>{`${t('kanbanGeneralInformations.dateOfRegistration')}: `}</b>
        {globalHelpers.getDDmmYYYYDateOrPlaceholderFromTimestamp(
          kanban.infos.dateOfRegistration,
          t('libUtils:noValue')
        )}
        <br />
        <b>{`${t('kanbanGeneralInformations.mileage')}: `}</b>
        {String(kanban.infos.mileage)}
        {isTruthy(mileageEditionToolkit) && (
          <ClickableIcon
            clickHandler={mileageEditionToolkit.onEditMileageButtonClicked}
            id="edit"
            elementClassName="m-l-sm"
          />
        )}
        <br />
        <b>{`${t('kanbanGeneralInformations.dueDate')}: `}</b>
        {isTruthy(kanban.dueDate)
          ? new Date(kanban.dueDate).toLocaleDateString('fr-FR', shortDayMonthYearDateFormatOptions)
          : t('kanbanGeneralInformations.notFilled')}
        {isTruthy(dueDateEditionToolkit) && (
          <ClickableIcon
            clickHandler={dueDateEditionToolkit.onEditDueDateButtonClicked}
            id="edit"
            elementClassName="m-l-sm"
          />
        )}
        {isTruthy(dueDateEditionToolkit) && isTruthy(kanban.dueDate) && (
          <ClickableIcon
            clickHandler={dueDateEditionToolkit.onDeleteDueDateButtonClicked}
            id="trash"
            elementClassName="m-l-sm"
          />
        )}
        <br />
        <b>{`${t('kanbanGeneralInformations.refitEndDate')}: `}</b>
        {isTruthy(kanban.refitEndDate)
          ? new Date(kanban.refitEndDate).toLocaleDateString(
              'fr-FR',
              shortDayMonthYearDateFormatOptions
            )
          : t('kanbanGeneralInformations.notFilled')}
        {isTruthy(refitEndDateEditionToolkit) && (
          <ClickableIcon
            clickHandler={refitEndDateEditionToolkit.onEditRefitEndDateButtonClicked}
            id="edit"
            elementClassName="m-l-sm"
          />
        )}
        {isTruthy(refitEndDateEditionToolkit) && isTruthy(kanban.refitEndDate) && (
          <ClickableIcon
            clickHandler={refitEndDateEditionToolkit.onDeleteRefitEndDateButtonClicked}
            id="trash"
            elementClassName="m-l-sm"
          />
        )}
        <br />
      </div>
      {isTruthy(dueDateEditionToolkit) && (
        <DueDateModalsDisplay kanban={kanban} $={dueDateEditionToolkit.$} />
      )}
      {isTruthy(refitEndDateEditionToolkit) && (
        <RefitEndDateModalsDisplay kanban={kanban} $={refitEndDateEditionToolkit.$} />
      )}
      {isTruthy(mileageEditionToolkit) && (
        <EditMileageModalDisplay kanban={kanban} $={mileageEditionToolkit.$} />
      )}
    </>
  );
}

const checkEditDueDateFormConsistencyAction: CheckFormConsistencyAction<
  Store,
  EditDueDateDialogState
> = ({ formState, t }): string | undefined => {
  const { initialDueDate, initialRefitEndDate, formData } = formState;
  if (initialDueDate) {
    if (initialDueDate === formData.dueDate) {
      return t('kanbanGeneralInformations.form.warnings.noChange');
    }
    if (
      isTruthy(initialRefitEndDate) &&
      isTruthy(formData.dueDate) &&
      formData.dueDate < initialRefitEndDate
    ) {
      return t('kanbanGeneralInformations.form.errors.dueDateAfterRefitEndDate', {
        refitEndDate: globalHelpers.convertTimestampToDateString(
          initialRefitEndDate,
          shortDayMonthFullYearDateFormatOptions
        ),
      });
    }
  }
  return undefined;
};
interface EditDueDateModalProps {
  readonly $: StoreStateSelector<Store, EditDueDateDialogState>;
  readonly submitValidDataAction: NoArgActionCallback<Store>;
}

export function EditDueDateModal({ $, submitValidDataAction }: EditDueDateModalProps): JSX.Element {
  const [t] = useTranslation('libComponents');
  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    EditDueDateDialogState
  >({
    $,
    mandatoryFields: ['dueDate'],
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: checkEditDueDateFormConsistencyAction,
    submitValidDataAction,
    t,
  });

  const formWarning = useGetState($.$formWarning);
  return (
    <CalendarModalDialog
      title={t('kanbanGeneralInformations.dueDateModalTitle')}
      $active={$.$active}
      okLabel={t('kanbanGeneralInformations.saveButton')}
      onOkClicked={onFormSubmit}
      datePickerType="date"
      showFooter={false}
      warning={formWarning}
      $={$formDataWithChangeTrigger.$dueDate}
    />
  );
}

export function DeleteDueDateModal({
  $,
  submitValidDataAction,
}: EditDueDateModalProps): JSX.Element {
  const [t] = useTranslation('libComponents');

  const [onFormSubmit] = useFormWithValidation<Store, EditDueDateDialogState>({
    $,
    mandatoryFields: [],
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  return (
    <>
      <ModalCardDialog
        title={t('kanbanGeneralInformations.deleteDueDateModalTitle')}
        $active={$.$deletionActive}
        okLabel={t('kanbanGeneralInformations.saveButton')}
        onOkClicked={onFormSubmit}
      >
        <p>{t('kanbanGeneralInformations.deleteDueDateModalMsg')}</p>
      </ModalCardDialog>
    </>
  );
}

const checkEditRefitEndDateFormConsistencyAction: CheckFormConsistencyAction<
  Store,
  EditRefitEndDateDialogState
> = ({ formState, t }): string | undefined => {
  const { initialDueDate, initialRefitEndDate, formData } = formState;
  if (initialRefitEndDate) {
    if (initialRefitEndDate === formData.refitEndDate) {
      return t('kanbanGeneralInformations.form.warnings.noChange');
    }
    if (
      isTruthy(initialDueDate) &&
      isTruthy(formData.refitEndDate) &&
      initialDueDate < formData.refitEndDate
    ) {
      return t('kanbanGeneralInformations.form.errors.refitEndDateBeforeDueDate', {
        dueDate: globalHelpers.convertTimestampToDateString(
          initialDueDate,
          shortDayMonthFullYearDateFormatOptions
        ),
      });
    }
  }
  return undefined;
};
interface EditRefitEndDateModalProps {
  readonly $: StoreStateSelector<Store, EditRefitEndDateDialogState>;
  readonly submitValidDataAction: NoArgActionCallback<Store>;
}

export function EditRefitEndDateModal({
  $,
  submitValidDataAction,
}: EditRefitEndDateModalProps): JSX.Element {
  const [t] = useTranslation('libComponents');
  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    EditRefitEndDateDialogState
  >({
    $,
    mandatoryFields: ['refitEndDate'],
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: checkEditRefitEndDateFormConsistencyAction,
    submitValidDataAction,
    t,
  });
  const formWarning = useGetState($.$formWarning);
  return (
    <CalendarModalDialog
      title={t('kanbanGeneralInformations.refitEndDateModalTitle')}
      $active={$.$active}
      okLabel={t('kanbanGeneralInformations.saveButton')}
      onOkClicked={onFormSubmit}
      datePickerType="date"
      showFooter={false}
      warning={formWarning}
      $={$formDataWithChangeTrigger.$refitEndDate}
    />
  );
}

export function DeleteRefitEndDateModal({
  $,
  submitValidDataAction,
}: EditRefitEndDateModalProps): JSX.Element {
  const [t] = useTranslation('libComponents');

  const [onFormSubmit] = useFormWithValidation<Store, EditRefitEndDateDialogState>({
    $,
    mandatoryFields: [],
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  return (
    <>
      <ModalCardDialog
        title={t('kanbanGeneralInformations.deleteRefitEndDateModalTitle')}
        $active={$.$deletionActive}
        okLabel={t('kanbanGeneralInformations.saveButton')}
        onOkClicked={onFormSubmit}
      >
        <p>{t('kanbanGeneralInformations.deleteRefitEndDateModalMsg')}</p>
      </ModalCardDialog>
    </>
  );
}

const checkEditMileageFormConsistencyAction: CheckFormConsistencyAction<
  Store,
  EditMileageDialogState
> = ({ formState, t }): string | undefined => {
  const { initialMileage, formData } = formState;
  if (initialMileage) {
    if (String(initialMileage) === formData.mileage) {
      return t('kanbanGeneralInformations.form.warnings.noChange');
    }
  }
  return undefined;
};

const checkEditMileageFieldContentActions: CheckFormFieldContentActions<
  Store,
  EditMileageDialogState
> = {
  mileage: ({ value, t }) => {
    return isIntegerCheck(value, t);
  },
};

interface EditMileageModalProps {
  readonly $: StoreStateSelector<Store, EditMileageDialogState>;
  readonly submitValidDataAction: NoArgActionCallback<Store>;
}

export function EditMileageModal({ $, submitValidDataAction }: EditMileageModalProps): JSX.Element {
  const [t] = useTranslation('libComponents');

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    EditMileageDialogState
  >({
    $,
    mandatoryFields: ['mileage'],
    checkFieldContentActions: checkEditMileageFieldContentActions,
    checkFormConsistencyAction: checkEditMileageFormConsistencyAction,
    submitValidDataAction,
    t,
  });

  return (
    <ModalCardDialog
      title={t('kanbanGeneralInformations.newMileageTitle')}
      $active={$.$active}
      okLabel={t('kanbanGeneralInformations.saveButton')}
      onOkClicked={onFormSubmit}
    >
      <InputFormField
        label={t('kanbanGeneralInformations.newMileage')}
        $={$formDataWithChangeTrigger.$mileage}
      />
    </ModalCardDialog>
  );
}
