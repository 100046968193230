import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { CheckFormFieldContentActions } from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes, userHelpers } from '@stimcar/libs-base';
import { getHttpStatusCode, HttpErrorCodes } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import {
  InputFormField,
  ModalCardDialog,
  ReactSelectFormField,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { CheckboxFormField } from '../../../lib/bulma/form/CheckboxFormField.js';
import { ReactSelectMultiFormField } from '../../../lib/bulma/form/custom/ReactSelectMultiFormField.js';
import type {
  CreateUserData,
  CreateUserDialogState,
  OnUserChangeActionCallback,
} from './typings/store.js';
import { EMPTY_CREATE_USER_DIALOG_STATE } from './typings/store.js';

const checkFieldContentActions: CheckFormFieldContentActions<Store, CreateUserDialogState> = {
  createdUserLogin: async ({ formState, httpClient, t }): Promise<string | undefined> => {
    const { createdUserLogin } = formState.formData;
    try {
      await httpClient.httpPostAsJSON(CoreBackendRoutes.USER_EXISTS(createdUserLogin), {});
      return undefined;
    } catch (e) {
      if (e instanceof Error && getHttpStatusCode(e) === HttpErrorCodes.CONFLICT) {
        return t('users.warning.loginAlreadyExists');
      }
      throw e;
    }
  },
  createdPinCode: ({ formState, t }): string | undefined => {
    const { createdPinCode } = formState.formData;
    if (!userHelpers.isCorrectPINCode(createdPinCode)) {
      return t('users.warning.incorrectPIN');
    }
    return undefined;
  },
};

async function saveUserAction(
  { getState, httpClient, actionDispatch }: ActionContext<Store, CreateUserDialogState>,
  onUserChangeActionCallback: OnUserChangeActionCallback
): Promise<void> {
  const { formData } = getState();
  const {
    createdUserFirstName,
    createdUserLastName,
    createdPassword,
    createdUserLogin,
    createdPinCode,
    selectedProfiles,
    active,
    createdIsSubcontractor,
    firm,
  } = formData;
  const newUser = {
    login: createdUserLogin,
    firstName: createdUserFirstName,
    lastName: createdUserLastName,
    password: createdPassword,
    profiles: selectedProfiles,
    pinCode: Number.parseInt(createdPinCode, 10),
    active,
    isSubcontractor: createdIsSubcontractor,
    firm,
  };
  await httpClient.httpPostAsJSON(CoreBackendRoutes.CREATE_USER, {
    ...newUser,
    login: createdUserLogin,
  });
  actionDispatch.setValue(EMPTY_CREATE_USER_DIALOG_STATE);
  // Notify UI
  await actionDispatch.execCallback(
    onUserChangeActionCallback,
    [
      {
        ...newUser,
        // For an unknown reason, the profile names array is declared as
        // a nested object field in the final User object
        profiles: { names: newUser.profiles },
        id: createdUserLogin,
      },
    ],
    []
  );
}

interface UserCreationModalProps {
  readonly $: StoreStateSelector<Store, CreateUserDialogState>;
  readonly availableProfiles: readonly string[];
  readonly onUserChangeActionCallback: OnUserChangeActionCallback;
}

export function UserCreationModal({
  $,
  availableProfiles,
  onUserChangeActionCallback,
}: UserCreationModalProps): JSX.Element {
  const [t] = useTranslation('usersAdmin');
  const formWarning = useGetState($.$formWarning);
  const createdIsSubcontractor = useGetState($.$formData.$createdIsSubcontractor);
  const subcontractors = useGetState($.$subcontractors);

  const mandatoryFields: (keyof CreateUserData)[] = useMemo(() => {
    const mandatoryFields = [
      'createdUserLogin',
      'createdUserFirstName',
      'createdUserLastName',
      'createdPassword',
      'createdPinCode',
      'selectedProfiles',
    ] as (keyof CreateUserData)[];
    if (createdIsSubcontractor) return [...mandatoryFields, 'firm' as keyof CreateUserData];
    return mandatoryFields;
  }, [createdIsSubcontractor]);

  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) => actionDispatch.exec(saveUserAction, onUserChangeActionCallback),
    [onUserChangeActionCallback],
    $
  );
  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    CreateUserDialogState
  >({
    $,
    mandatoryFields,
    checkFieldContentActions,
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });
  const isSubcontractorDispatchTrigger = useActionCallback(
    ({ getState, actionDispatch }) => {
      const { formData } = getState();
      const { createdIsSubcontractor } = formData;
      // Force Stimcar
      actionDispatch
        .scopeProperty('formData')
        .setProperty('firm', createdIsSubcontractor ? '' : 'Stimcar');
    },
    [],
    $
  );
  const $createdIsSubcontractorWithChangeTrigger = useSelectorWithChangeTrigger(
    $formDataWithChangeTrigger.$createdIsSubcontractor,
    isSubcontractorDispatchTrigger
  );

  return (
    <ModalCardDialog
      $active={$.$active}
      titleIconId="user-plus"
      title={t('users.createNewUser.title')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <InputFormField
        label={t('users.createNewUser.login')}
        placeholder={t('users.createNewUser.login')}
        $={$formDataWithChangeTrigger.$createdUserLogin}
        horizontal
      />
      <InputFormField
        label={t('users.createNewUser.firstName')}
        placeholder={t('users.createNewUser.firstName')}
        $={$formDataWithChangeTrigger.$createdUserFirstName}
        horizontal
      />
      <InputFormField
        label={t('users.createNewUser.lastName')}
        placeholder={t('users.createNewUser.lastName')}
        $={$formDataWithChangeTrigger.$createdUserLastName}
        horizontal
      />
      <CheckboxFormField
        label={t('users.createNewUser.isSubcontractor')}
        $={$createdIsSubcontractorWithChangeTrigger}
        horizontal
        style={{ verticalAlign: 'bottom' }}
      />
      <ReactSelectFormField
        label={t('users.createNewUser.firm')}
        $={$formDataWithChangeTrigger.$firm}
        horizontal
        suggestions={subcontractors}
        isDisabled={!createdIsSubcontractor}
      />
      <InputFormField
        type="password"
        label={t('users.createNewUser.password')}
        placeholder={t('users.createNewUser.password')}
        $={$formDataWithChangeTrigger.$createdPassword}
        horizontal
      />
      <InputFormField
        type="password"
        label={t('users.createNewUser.pinCode')}
        placeholder={t('users.createNewUser.pinCode')}
        $={$formDataWithChangeTrigger.$createdPinCode}
        horizontal
      />
      <ReactSelectMultiFormField
        label={t('users.createNewUser.profiles')}
        $={$formDataWithChangeTrigger.$selectedProfiles}
        horizontal
        suggestions={availableProfiles}
      />
    </ModalCardDialog>
  );
}
