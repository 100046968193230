import type { JSX } from 'react';
import React from 'react';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes } from '@stimcar/libs-base';
import type { Store } from '../../../app/state/typings/store.js';
import type { ComputeAttachmentUrlCallback } from '../attachments/typings/attachment.js';
import type { ConfirmAttachmentDialogState } from '../attachments/typings/store.js';
import type { PictureInputState } from '../typings/store.js';
import { GenericPictureInput } from './GenericPictureInput.js';

interface StandardPictureInputProps extends AppProps<Store> {
  readonly label?: string;
  readonly kanbanId: string;
  readonly folder: string;
  readonly filename: string;
  readonly isEditable: boolean;
  readonly placeholderPicturePath: string;
  readonly notSetPictureAdditionalLayer: string;
  readonly $: StoreStateSelector<Store, PictureInputState>;
  readonly $confirmAttachmentRemovalDialog: StoreStateSelector<Store, ConfirmAttachmentDialogState>;
  readonly computeAttachmentUrlCallback: ComputeAttachmentUrlCallback;
  readonly maskPath?: string;
}

export function StandardPictureInput({
  $,
  $gs,
  label,
  kanbanId,
  folder,
  filename,
  isEditable,
  placeholderPicturePath,
  notSetPictureAdditionalLayer,
  $confirmAttachmentRemovalDialog,
  computeAttachmentUrlCallback,
  maskPath,
}: StandardPictureInputProps): JSX.Element {
  return (
    <GenericPictureInput
      $={$}
      $gs={$gs}
      label={label}
      kanbanId={kanbanId}
      filename={filename}
      isEditable={isEditable}
      computeAttachmentUrlCallback={computeAttachmentUrlCallback}
      folder={folder}
      placeholderPicturePath={placeholderPicturePath}
      notSetPictureAdditionalLayer={notSetPictureAdditionalLayer}
      $confirmAttachmentRemovalDialog={$confirmAttachmentRemovalDialog}
      customAttachmentUploadRoute={CoreBackendRoutes.ATTACHMENT_STANDARD_PICTURE_FOLDER(
        kanbanId,
        folder,
        true
      )}
      maskPath={maskPath}
    />
  );
}
