import type { Civility, Customer } from './typings/customer.js';
import type { KanbanInfos } from './typings/kanbans.js';
import type { Entity } from './typings/kernel.js';
import type { RepositoryEntity, SpecificFields } from './typings/repository.js';
import type { Vehicle } from './typings/vehicle.js';

export const EMPTY_ENTITY: Entity = { id: '' };

export const EMPTY_REPOSITORY_ENTITY: RepositoryEntity = {
  ...EMPTY_ENTITY,
  sequenceId: 0,
  timestamp: 0,
  status: 'open',
};

export const HttpErrorCodes = {
  OK: 200,
  NO_CONTENT: 204,
  NOT_MODIFIED: 304,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  GONE: 410,
  UNSUPPORTED_MEDIA_TYPE: 415,
  RANGE_NOT_SATISFIABLE: 416,
  INTERNAL_SERVER_ERROR: 500,
};

export const CIVILITIES: readonly Civility[] = ['notset', 'company', 'mr', 'mrs', 'mr&mrs'];

export const EMPTY_CUSTOMER_SPECIFIC_FIELDS: SpecificFields<Customer> = {
  contract: '',
  shortName: '',
  individual: false,
  company: '',
  civility: 'notset',
  lastName: '',
  firstName: '',
  street: '',
  zipCode: '',
  city: '',
  phone: '',
  email: '',
  invoiceId: 0,
  ignoreVAT: false,
};

export const EMPTY_CUSTOMER: Customer = {
  ...EMPTY_REPOSITORY_ENTITY,
  ...EMPTY_CUSTOMER_SPECIFIC_FIELDS,
};

export const EMPTY_KANBAN_INFOS: KanbanInfos = {
  brand: '',
  model: '',
  vin: '',
  license: '',
  motor: '',
  mileage: 0,
  dateOfRegistration: null,
  color: '',
};

export const EMPTY_VEHICLE: Vehicle = {
  ...EMPTY_REPOSITORY_ENTITY,
  ...EMPTY_KANBAN_INFOS,
  customerId: '',
};
