import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Customer, SpecificFields } from '@stimcar/libs-kernel';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { ClickableIcon } from '@stimcar/libs-uitoolkit';

type Line = {
  readonly id: string;
  readonly value: string;
};

interface CustomerInformationsProps {
  readonly customer: Omit<SpecificFields<Customer>, 'contract'>;
  readonly onEditCustomerButtonClicked?: () => Promise<void>;
  readonly onSelectPredefinedCustomerButtonClicked?: () => Promise<void>;
  readonly collapsable?: boolean;
}

function getCustomerLines(
  {
    city,
    civility,
    company,
    email,
    firstName,
    lastName,
    phone,
    street,
    zipCode,
  }: Omit<SpecificFields<Customer>, 'contract'>,
  t: TFunction<string, undefined>
): readonly Line[] {
  const lines: Line[] = [];
  if (isTruthyAndNotEmpty(company)) {
    lines.push({
      id: 'company',
      value: company,
    });
  } else {
    const civilityText = t(`common:civilities.${civility}`);
    const personText = `${civilityText} ${lastName} ${firstName}`;
    lines.push({
      id: 'person',
      value: personText,
    });
  }

  if (isTruthyAndNotEmpty(street)) {
    lines.push({
      id: 'street',
      value: street,
    });
  }

  if (isTruthyAndNotEmpty(zipCode) && isTruthyAndNotEmpty(city)) {
    const addressText = `${zipCode} - ${city}`;
    lines.push({
      id: 'addressText',
      value: addressText,
    });
  } else if (isTruthyAndNotEmpty(zipCode)) {
    lines.push({
      id: 'addressText',
      value: zipCode,
    });
  } else if (isTruthyAndNotEmpty(city)) {
    lines.push({
      id: 'addressText',
      value: city,
    });
  }

  if (isTruthyAndNotEmpty(email)) {
    lines.push({
      id: 'email',
      value: email,
    });
  }

  if (isTruthyAndNotEmpty(phone)) {
    lines.push({
      id: 'phone',
      value: phone,
    });
  }

  return lines.map((line) => ({
    id: line.id,
    value: line.value.trim(),
  }));
}

interface CustomerInfoIcons {
  readonly onEditCustomerButtonClicked?: () => Promise<void>;
  readonly onSelectPredefinedCustomerButtonClicked?: () => Promise<void>;
}

function CustomerInfoIcons({
  onEditCustomerButtonClicked,
  onSelectPredefinedCustomerButtonClicked,
}: CustomerInfoIcons): JSX.Element {
  return (
    <>
      {onEditCustomerButtonClicked && (
        <ClickableIcon
          id="edit"
          clickHandler={onEditCustomerButtonClicked}
          elementClassName="m-l-sm"
        />
      )}
      {onSelectPredefinedCustomerButtonClicked && (
        <ClickableIcon
          id="address-book"
          clickHandler={onSelectPredefinedCustomerButtonClicked}
          elementClassName="m-l-sm"
        />
      )}
    </>
  );
}

export function CustomerInformations({
  customer,
  onEditCustomerButtonClicked,
  onSelectPredefinedCustomerButtonClicked,
  collapsable = false,
}: CustomerInformationsProps): JSX.Element {
  const [t] = useTranslation('libComponents');
  const customerLines = useMemo(() => getCustomerLines(customer, t), [customer, t]);

  if (collapsable) {
    return (
      <div>
        <details>
          {customerLines.map(({ id, value }, index) => {
            if (index === 0) {
              return (
                <summary key={id}>
                  {value}
                  <CustomerInfoIcons
                    onEditCustomerButtonClicked={onEditCustomerButtonClicked}
                    onSelectPredefinedCustomerButtonClicked={
                      onSelectPredefinedCustomerButtonClicked
                    }
                  />
                </summary>
              );
            }
            return (
              <span key={id} className="ml-4">
                {value}
                <br />
              </span>
            );
          })}
        </details>
      </div>
    );
  }
  return (
    <div>
      {customerLines.map(({ id, value }, index) => (
        <span key={id}>
          {index !== 0 && <br />}
          {value}
          {index === 0 && (
            <CustomerInfoIcons
              onEditCustomerButtonClicked={onEditCustomerButtonClicked}
              onSelectPredefinedCustomerButtonClicked={onSelectPredefinedCustomerButtonClicked}
            />
          )}
        </span>
      ))}
    </div>
  );
}
