import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ExtendedRepository, PackageDealDescExtender } from '@stimcar/core-libs-repository';
import type { CarElement, CarViewCategory } from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type {
  AppProps,
  CheckFormConsistencyAction,
  CheckFormFieldContentActions,
  FormFieldEntry,
} from '@stimcar/libs-uitoolkit';
import { PDD_BY_PACKAGE_DEAL_DESC_CATEGORY_INDEX } from '@stimcar/core-libs-repository';
import { EMPTY_CAR_ELEMENT } from '@stimcar/libs-base';
import { applyPayload, computePayload, EMPTY_REPOSITORY_ENTITY } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useFormFieldWarning,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import {
  FormField,
  InputFormField,
  ModalCardDialog,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { RadioButtonsFormField } from '../../../lib/bulma/form/RadioButtonsFormField.js';
import { CarViewsSwitch } from '../../../lib/components/carviews/CarViewsSwitch.js';
import { useGetAllCarViewCategoryLabels } from '../../../utils/carViewCategoriesTranslationHooks.js';
import { isUniqueEntityCheck } from '../../utils/coreFormChecks.js';
import type {
  CarElementForm,
  CarElementFormData,
  EditCarElementDialogState,
} from './typings/store.js';
import { EMPTY_EDIT_CAR_ELEMENT_DIALOG_STATE } from './typings/store.js';

function convertToFormData({ label, category, shapes }: CarElement): CarElementForm {
  return {
    label,
    category,
    shapes,
    warnings: {},
  };
}

async function getReferencingPackageDealDescs(
  packageDealDescRepository: ExtendedRepository<'packageDealDesc', PackageDealDescExtender>,
  carElementId: string,
  category: string
): Promise<string[]> {
  const pdds = await packageDealDescRepository.getEntitiesFromIndex(
    PDD_BY_PACKAGE_DEAL_DESC_CATEGORY_INDEX,
    category
  );
  const usedInPackageDealDescs: string[] = [];
  for (const pdd of pdds) {
    const isUsedInPackageDealDesc = pdd.carElementIds.includes(carElementId);
    if (isUsedInPackageDealDesc) {
      usedInPackageDealDescs.push(pdd.code);
    }
  }
  return usedInPackageDealDescs;
}

export async function openEditDialogAction(
  {
    carElementRepository,
    httpClient,
    actionDispatch,
  }: ActionContext<Store, EditCarElementDialogState>,
  selectedCarElementId?: string
): Promise<void> {
  let carElement: CarElement = EMPTY_CAR_ELEMENT;
  if (selectedCarElementId) {
    carElement = await carElementRepository.getEntity(selectedCarElementId);
  } else {
    carElement = {
      ...EMPTY_CAR_ELEMENT,
      id: httpClient.getBrowserSequence().next(),
    };
  }
  actionDispatch.reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (initial: EditCarElementDialogState): EditCarElementDialogState => {
      return {
        ...EMPTY_EDIT_CAR_ELEMENT_DIALOG_STATE,
        active: true,
        initialCarElement: selectedCarElementId ? carElement : undefined,
        formData: convertToFormData(carElement),
      };
    }
  );
}

export async function openCreateDialogAction(
  ctx: ActionContext<Store, EditCarElementDialogState>
): Promise<void> {
  return await openEditDialogAction(ctx);
}

// eslint-disable-next-line @typescript-eslint/require-await
async function closeEditDialogAction({
  actionDispatch,
}: ActionContext<Store, EditCarElementDialogState>): Promise<void> {
  actionDispatch.setProperty('active', false);
}

function computeFormDataPayload(
  initialCarElement: CarElement | undefined,
  carElement: CarElementForm
): undefined | DeepPartial<CarElementForm> {
  return initialCarElement === undefined
    ? carElement
    : computePayload(convertToFormData(initialCarElement), carElement);
}

// eslint-disable-next-line @typescript-eslint/require-await
export async function carElementUpdateNotificationInEditDialogAction(
  { getState, actionDispatch }: ActionContext<Store, EditCarElementDialogState>,
  updated: CarElement
): Promise<void> {
  const { active, initialCarElement, formData } = getState();
  if (active && initialCarElement && initialCarElement.id === updated.id) {
    actionDispatch.setProperty('initialCarElement', updated);
    const updatedToUI = convertToFormData(updated);
    const payload = computeFormDataPayload(initialCarElement, formData);
    const newCarElement = payload ? applyPayload(updatedToUI, payload) : updatedToUI;
    actionDispatch.setProperty('formData', newCarElement);
  }
}

async function saveCarElementAction({
  carElementRepository,
  getState,
  actionDispatch,
  httpClient,
}: ActionContext<Store, EditCarElementDialogState>): Promise<void> {
  const { initialCarElement, formData } = getState();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { warnings, ...formDataWithoutWarnings } = formData;

  const businessCarElement: CarElement = {
    ...(!initialCarElement ? EMPTY_REPOSITORY_ENTITY : initialCarElement),
    ...formDataWithoutWarnings,
    category: formData.category as CarViewCategory,
    id: initialCarElement ? initialCarElement.id : httpClient.getBrowserSequence().next(),
    index: initialCarElement ? initialCarElement.index : EMPTY_CAR_ELEMENT.index,
  };
  if (!initialCarElement) {
    await carElementRepository.createEntity(businessCarElement);
  } else {
    await carElementRepository.updateEntity(businessCarElement);
  }

  // Close the dialog
  await actionDispatch.exec(closeEditDialogAction);
}

const checkFieldContentActions: CheckFormFieldContentActions<Store, EditCarElementDialogState> = {
  label: async ({ value, t, carElementRepository, formState }): Promise<string | undefined> => {
    const isUnique = await isUniqueEntityCheck(
      carElementRepository,
      formState.initialCarElement,
      'label',
      value,
      PDD_BY_PACKAGE_DEAL_DESC_CATEGORY_INDEX,
      formState.formData.category
    );
    return !isUnique ? t(`editCarElementDialog.form.warnings.labelAlreadyUsed`) : undefined;
  },
  category: async ({
    value,
    t,
    packageDealDescRepository,
    formState,
  }): Promise<string | undefined> => {
    if (formState.initialCarElement && formState.initialCarElement.category !== value) {
      const pddCodes = await getReferencingPackageDealDescs(
        packageDealDescRepository,
        formState.initialCarElement.id,
        formState.initialCarElement.category
      );
      if (pddCodes.length > 0) {
        return t('editCarElementDialog.form.warnings.forbiddenCategorySwitch', {
          value: pddCodes.join(', '),
        });
      }
    }
    return undefined;
  },
};

const checkFormConsistencyAction: CheckFormConsistencyAction<Store, EditCarElementDialogState> = ({
  formState,
  t,
}): string | undefined => {
  const { initialCarElement, formData } = formState;
  if (initialCarElement) {
    const payload = computeFormDataPayload(initialCarElement, formData);
    const updated = payload !== undefined && Reflect.ownKeys(payload).length !== 0;
    if (!updated) {
      return t('editCarElementDialog.form.warnings.noChange');
    }
  }
  return undefined;
};

const mandatoryFields: (keyof CarElementFormData)[] = ['label', 'category', 'shapes'];

export function EditCarElementDialog({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation(['adminCarElements', 'globals']);
  const { $editCarElementDialog } = $gs.$adminView.$adminCarElements;

  const categoryLabels = useGetAllCarViewCategoryLabels();

  const categories = useMemo(
    (): FormFieldEntry<CarViewCategory>[] => [
      {
        label: categoryLabels.BUMP,
        id: 'BUMP',
      },
      {
        label: categoryLabels.EXTE,
        id: 'EXTE',
      },
      {
        label: categoryLabels.INTE,
        id: 'INTE',
      },
      {
        label: categoryLabels.MECA,
        id: 'MECA',
      },
    ],
    [categoryLabels.BUMP, categoryLabels.EXTE, categoryLabels.INTE, categoryLabels.MECA]
  );
  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) => actionDispatch.exec(saveCarElementAction),
    [],
    $editCarElementDialog
  );
  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    EditCarElementDialogState
  >({
    $: $editCarElementDialog,
    mandatoryFields,
    checkFieldContentActions,
    checkFormConsistencyAction,
    submitValidDataAction,
    t,
  });

  const onCategoryChangeTriggerActionCallback = useActionCallback(
    function onCategoryChangeTriggerAction({ actionDispatch }) {
      actionDispatch.setProperty('shapes', []);
    },
    [],
    $formDataWithChangeTrigger
  );

  const $categoryWithChangeTrigger = useSelectorWithChangeTrigger(
    $formDataWithChangeTrigger.$category,
    onCategoryChangeTriggerActionCallback
  );

  const toggleShapeActionCallback = useActionCallback(
    function toggleShapeAction({ actionDispatch, getState }, shape: string) {
      const actualShapes = getState().shapes;
      actionDispatch.setProperty(
        'shapes',
        actualShapes.includes(shape)
          ? actualShapes.filter((aShape): boolean => {
              return aShape !== shape;
            })
          : [shape, ...actualShapes].sort()
      );
    },
    [],
    $formDataWithChangeTrigger
  );
  const initialCarElement = useGetState($editCarElementDialog.$initialCarElement);
  const formWarning = useGetState($editCarElementDialog.$formWarning);
  const category = useGetState($formDataWithChangeTrigger.$category);
  const shapes = useGetState($formDataWithChangeTrigger.$shapes);
  const shapesWarning = useFormFieldWarning($formDataWithChangeTrigger.$shapes);
  return (
    <ModalCardDialog
      title={t(`editCarElementDialog.title.${!initialCarElement ? 'create' : 'update'}`)}
      $active={$editCarElementDialog.$active}
      okLabel={t(
        `editCarElementDialog.buttons.validate.${!initialCarElement ? 'create' : 'update'}`
      )}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <div className="columns">
        <div className="column is-half">
          <InputFormField
            label={t('editCarElementDialog.form.label')}
            placeholder={t('editCarElementDialog.form.labelPlaceHolder')}
            $={$formDataWithChangeTrigger.$label}
            horizontal
          />
          <RadioButtonsFormField
            id="category"
            label={t('editCarElementDialog.form.category')}
            entries={categories}
            sortEntries
            $={$categoryWithChangeTrigger}
            horizontal
            radioGroupLayout="vertical"
          />
        </div>
        <div className="column">
          <FormField
            label={t('editCarElementDialog.form.shapes')}
            warning={shapesWarning}
            horizontal
          >
            <CarViewsSwitch
              category={category}
              selectedShapes={shapes}
              shapeClicked={toggleShapeActionCallback}
            />
          </FormField>
        </div>
      </div>
    </ModalCardDialog>
  );
}
