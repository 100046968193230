export const LocalStorageKeys = {
  WORKSHOP_IMPLANTATION_VIEW_MIRROR_EFFECT: `WorkshopImplantationMirrorEffect`,
  LAST_DISPLAY_PATH: 'LastDisplayPath',
  STAND_ACHIEVEMENT_DISPLAY: 'StandAchievementsDisplay',
  SHIFT_PROGRESS_DISPLAY: 'ShiftProgressDisplay',
  ALREADY_KNOWN_USERS_LIST: 'knownUsersOnThisPost',
  KANBAN_SELECTION_FILTER_PREFERENCE: 'kanban.selection.filter.preference',
  ADMIN_PDD_COLUMNS_PREFERENCE: 'admin.od.columns.preference',
  ADMIN_CAR_ELEMENTS_COLUMNS_PREFERENCE: 'admin.carElements.columns.preference',
  ADMIN_USERS_COLUMNS_PREFERENCE: 'admin.users.columns.preference',
  ADMIN_CONTRACTS_COLUMNS_PREFERENCE: 'admin.contracts.columns.preference',
  DESKTOP_EXPERTISE_STATE_DUMP: 'expertise.desktop.state.dump',
  SHARE_PREFERENCES: 'share.preferences',
  ADMIN_SCHEDULED_TASKS_COLUMNS_PREFERENCE: 'admin.scheduledTasks.columns.preference',
  ADMIN_SPAREPARTSPROVIDERS_COLUMN_PREFERENCE: 'admin.sparepartsproviders.columns.preference',
};

export const CoreHttpErrorCodes = {
  BROWSER_LABEL_ALREADY_IN_USE: 460,
  UNKNOWN_BROWSER_LABEL: 461,
  CUSTOMER_VALIDATION_KANBAN_TOKEN_INCORRECT: 464,
  CUSTOMER_VALIDATION_KANBAN_SIGNATURE_INCORRECT: 465,
  CANNOT_ENGAGE_KANBAN_CURRENTLY_EXPERTIZED: 466,
  CANNOT_DELIVER_REFURBISH_NOT_FINISHED: 467,
  CANNOT_ENGAGE_KANBAN_NEEDS_SPARE_PART_REFERENCING: 468,
  CANNOT_ENGAGE_KANBAN_FORBIDDEN_PACKAGE_DEALS_CANCELED: 469,
  SIGNATURE_ALREADY_DONE: 470,
  CUSTOMER_VALIDATION_INCORRECT_ADDITIONAL_DATA: 471,
};
