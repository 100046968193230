import type {
  ColumnToDestinationDef,
  DAY_OF_WEEK,
  PackageDealColumToHandle,
  PackageDealColumToHandleOption,
  ScheduledTask,
  ScheduledTaskType,
} from '@stimcar/libs-base';
import type { Customer } from '@stimcar/libs-kernel';
import type { ActionCallbackFromFunction, StateWarnings } from '@stimcar/libs-uikernel';
import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';
import type { TableState } from '../../../../lib/bulma/elements/table/typings/store.js';
import type { Store } from '../../../state/typings/store.js';
import { TABLE_EMPTY_STATE } from '../../../../lib/bulma/elements/table/typings/store.js';

export const SCHEDULED_TASK_LABEL_MAX_SIZE = 50;

export type OnScheduledTaskChangeActionCallback = ActionCallbackFromFunction<
  Store,
  (addedOrUpdated: readonly ScheduledTask[], removedIds: readonly string[]) => void
>;

export type ScheduledTaskIdentityData = {
  readonly type: ScheduledTaskType;
  readonly label: string;
  readonly scheduledTaskActive: boolean;
};
export const EMPTY_SCHEDULED_TASK_IDENTITY_DATA: ScheduledTaskIdentityData = {
  type: 'ctmail',
  label: '',
  scheduledTaskActive: true,
};
export type ScheduledTaskScheduleData = {
  readonly scheduledWeekDays: readonly DAY_OF_WEEK[];
  readonly scheduledHours: readonly number[];
  readonly scheduledMinutes: readonly number[];
  readonly asOftenAsPossibleWithinADay: boolean;
};
export const EMPTY_SCHEDULED_TASK_SCHEDULE_DATA: ScheduledTaskScheduleData = {
  scheduledWeekDays: [],
  scheduledHours: [],
  scheduledMinutes: [],
  asOftenAsPossibleWithinADay: false,
};

export type ConnectorArgsData = MailNotificationArgsData & {
  readonly contractCode: string;
  readonly workflowId: string;
};
export type VOLeanConnectorArgsData = ConnectorArgsData & {
  readonly voLeanClientId: string;
  readonly voLeanPole: string;
  readonly enablePkgDealsModifications: boolean;
  readonly qualityControlInVOLean: boolean;
};
export type AirtableConnectorArgsData = ConnectorArgsData & {
  readonly token: string;
  readonly databaseId: string;
  readonly databaseName: string;
  readonly tableNames: readonly string[];
  readonly customerAsFieldMode?: boolean;
};
export type MailNotificationArgsData = {
  readonly overrideDefaultSubject: boolean;
  readonly subject: string;
  readonly to: readonly string[];
  readonly cc: readonly string[];
  readonly replyTo: string;
};
export type CustomersBasedMailNotificationArgsData = MailNotificationArgsData & {
  readonly customerShortNames: readonly string[];
  readonly customersGroupName: string;
};
export type SparePartsMailNotificationArgsData = CustomersBasedMailNotificationArgsData;

export type DailyProdMailNotificationArgsData = CustomersBasedMailNotificationArgsData & {
  readonly standId: string;
  readonly displayEstimateInfos: boolean;
  readonly displayAcceptanceInfos: boolean;
};

export type CarsExpectedAtStandMailNotificationArgsData = CustomersBasedMailNotificationArgsData & {
  readonly standId: string;
  readonly tagsFilteringPkgs: readonly string[];
  readonly mode: 'CarsList' | 'CarsListWithStandOperations';
};

export type InvoiceMailNotificationArgsData = Omit<
  CustomersBasedMailNotificationArgsData,
  'customersGroupName'
>;

export type CreateScheduledTaskData = ScheduledTaskIdentityData &
  ScheduledTaskScheduleData &
  VOLeanConnectorArgsData &
  AirtableConnectorArgsData &
  InvoiceMailNotificationArgsData &
  DailyProdMailNotificationArgsData &
  SparePartsMailNotificationArgsData &
  // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
  CustomersBasedMailNotificationArgsData &
  CarsExpectedAtStandMailNotificationArgsData;

export const EMPTY_CREATE_SCHEDULED_TASK_DATA: CreateScheduledTaskData = {
  ...EMPTY_SCHEDULED_TASK_IDENTITY_DATA,
  ...EMPTY_SCHEDULED_TASK_SCHEDULE_DATA,
  customerShortNames: [],
  contractCode: '',
  token: '',
  databaseId: '',
  databaseName: '',
  workflowId: '',
  tableNames: [],
  customerAsFieldMode: false,
  to: [],
  cc: [],
  replyTo: '',
  customersGroupName: '',
  overrideDefaultSubject: false,
  subject: '',
  standId: '',
  displayEstimateInfos: false,
  displayAcceptanceInfos: false,
  mode: 'CarsListWithStandOperations',
  voLeanClientId: '',
  voLeanPole: '',
  enablePkgDealsModifications: false,
  qualityControlInVOLean: true,
  tagsFilteringPkgs: [],
};

export type CustomerSummary = Pick<Customer, 'id' | 'shortName' | 'contract'>;

export type CreateScheduledTaskDialogState = FormWithValidationState<CreateScheduledTaskData> & {
  readonly siteId: string;
  readonly allCustomerSummaries: readonly CustomerSummary[];
  readonly allStandIds?: readonly string[];
  readonly allWorkflowIds?: readonly string[];
  readonly active: boolean;
};
export const EMPTY_CREATE_SCHEDULED_TASK_DIALOG_STATE: CreateScheduledTaskDialogState = {
  active: false,
  siteId: '',
  allCustomerSummaries: [],
  allStandIds: [],
  allWorkflowIds: [],
  formSubmitted: false,
  formSubmitClickedOnce: false,
  formWarning: undefined,
  formData: {
    ...EMPTY_CREATE_SCHEDULED_TASK_DATA,
    warnings: {},
  },
};
export type UpdateScheduledTaskBaseDialogState = {
  readonly type: ScheduledTaskType;
  readonly active: boolean;
  readonly scheduledTaskId: string;
  readonly siteId: string;
};

export type BaseScheduledTaskDataForUpdate = Omit<ScheduledTaskIdentityData, 'type'> &
  ScheduledTaskScheduleData;
export type ScheduledTaskArgsDataForUpdate =
  | VOLeanConnectorArgsData
  | InvoiceMailNotificationArgsData
  | DailyProdMailNotificationArgsData
  | SparePartsMailNotificationArgsData
  // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
  | CustomersBasedMailNotificationArgsData
  | CarsExpectedAtStandMailNotificationArgsData;
export type ScheduledTaskDataForUpdate = BaseScheduledTaskDataForUpdate &
  ScheduledTaskArgsDataForUpdate;
export type UpdateImportAirtableDialogState = FormWithValidationState<
  ScheduledTaskIdentityData & ScheduledTaskScheduleData & AirtableConnectorArgsData
> & {
  readonly scheduledTaskId: string;
  readonly type: ScheduledTaskType;
  readonly active: boolean;
  readonly siteId: string;
  readonly allCustomerSummaries: readonly CustomerSummary[];
  readonly allWorkflowIds?: readonly string[];
  readonly entityPresence: boolean;
  readonly selectedPackageDealColumnToHandleId: string;
  readonly customColumnDialogState: CustomColumnDialogState;
  readonly documentColumnsToDownloadState: ColumnsToDestinationsState;
  readonly textToDocumentColumnsToDownloadState: ColumnsToDestinationsState;
  readonly textColumnsToAttributesState: ColumnsToDestinationsState;
};
export type UpdateScheduledTaskDialogState = FormWithValidationState<ScheduledTaskDataForUpdate> & {
  readonly scheduledTaskId: string;
  readonly type: ScheduledTaskType;
  readonly active: boolean;
  readonly siteId: string;
  readonly allCustomerSummaries: readonly CustomerSummary[];
  readonly allWorkflowIds?: readonly string[];
  readonly allStandIds?: readonly string[];
};

export const EMPTY_UPDATE_SCHEDULED_TASK_DIALOG_STATE: UpdateScheduledTaskDialogState = {
  active: false,
  scheduledTaskId: '',
  siteId: '',
  type: 'carsexpectedatstandmail',
  allCustomerSummaries: [],
  formSubmitted: false,
  formSubmitClickedOnce: false,
  formWarning: undefined,
  formData: {
    label: '',
    scheduledTaskActive: true,
    subject: '',
    cc: [],
    to: [],
    replyTo: '',
    overrideDefaultSubject: false,
    customerShortNames: [],
    customersGroupName: '',
    scheduledWeekDays: [],
    scheduledHours: [],
    scheduledMinutes: [],
    asOftenAsPossibleWithinADay: false,
    warnings: {},
  },
};

export type DeleteScheduledTaskDialogState = {
  readonly active: boolean;
  readonly scheduledTaskId: string;
  readonly scheduledTaskTaskLabel: string;
};

export const EMPTY_DELETE_SCHEDULED_TASK_DIALOG_STATE: DeleteScheduledTaskDialogState = {
  active: false,
  scheduledTaskId: '',
  scheduledTaskTaskLabel: '',
};

export type AdminScheduledTasksState = TableState<ScheduledTask> & {
  readonly createScheduledTaskDialogState:
    | CreateScheduledTaskDialogState
    | UpdateImportAirtableDialogState;
  readonly updateScheduledTaskDialogState:
    | UpdateScheduledTaskDialogState
    | UpdateImportAirtableDialogState;
  readonly deleteScheduledTaskDialogState: DeleteScheduledTaskDialogState;
  readonly warnings: StateWarnings<AdminScheduledTasksState>;
};

export const EMPTY_ADMIN_SCHEDULED_TASKS_STATE: AdminScheduledTasksState = {
  ...TABLE_EMPTY_STATE,
  createScheduledTaskDialogState: EMPTY_CREATE_SCHEDULED_TASK_DIALOG_STATE,
  updateScheduledTaskDialogState: EMPTY_UPDATE_SCHEDULED_TASK_DIALOG_STATE,
  deleteScheduledTaskDialogState: EMPTY_DELETE_SCHEDULED_TASK_DIALOG_STATE,
  warnings: {},
};

export type PackageDealColumToHandleOptionData = {
  readonly optionName: string;
  readonly pkgCodes: readonly string[];
};

export type SingleOptionInputDialogState =
  FormWithValidationState<PackageDealColumToHandleOptionData> & {
    readonly active: boolean;
    readonly mode: 'create' | 'update' | undefined;
    readonly options: readonly PackageDealColumToHandleOption[];
    readonly optionNameToUpdate: string | undefined;
    readonly optionToUpdate: PackageDealColumToHandleOption | undefined;
  };

export const EMPTY_SINGLE_OPTION_DIALOG_STATE: SingleOptionInputDialogState = {
  active: false,
  mode: undefined,
  optionNameToUpdate: undefined,
  options: [],
  optionToUpdate: undefined,
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formData: {
    optionName: '',
    pkgCodes: [],
    warnings: {},
  },
};

export type CustomColumnData = {
  readonly columnName: string;
};

export type CustomColumnDialogState = FormWithValidationState<CustomColumnData> & {
  readonly active: boolean;
  readonly mode: 'create' | 'update' | undefined;
  readonly customColumns: readonly PackageDealColumToHandle[];
  readonly customColumnNameToUpdate: string | undefined;
  readonly selectedOptionId: string;
  readonly singleOptionInputDialogState: SingleOptionInputDialogState;
  readonly workflowId: string;
  readonly allPkgCodesWithinContract: readonly string[];
};
export const EMPTY_CUSTOM_COLUMN_DIALOG_STATE: CustomColumnDialogState = {
  active: false,
  mode: undefined,
  customColumns: [],
  customColumnNameToUpdate: undefined,
  selectedOptionId: '',
  singleOptionInputDialogState: EMPTY_SINGLE_OPTION_DIALOG_STATE,
  workflowId: '',
  allPkgCodesWithinContract: [],
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formData: {
    columnName: '',
    warnings: {},
  },
};

export type ColumnsToDestinationsState = {
  readonly selectedColumnId: string;
  readonly columnsToDestinations: readonly ColumnToDestinationDef[];
  readonly columnsToDestinationsDialogState: ColumnsToDestinationsDialogState;
};

export type ColumnToDestinationData = {
  readonly column: string;
  readonly destination: string;
};

export type ColumnsToDestinationsDialogState = FormWithValidationState<ColumnToDestinationData> & {
  readonly active: boolean;
  readonly mode: 'create' | 'update' | undefined;
};

export const EMPTY_COLUMN_TO_DESTINATION_DIALOG_STATE: ColumnsToDestinationsDialogState = {
  active: false,
  mode: undefined,
  formSubmitted: false,
  formSubmitClickedOnce: false,
  formWarning: undefined,
  formData: {
    column: '',
    destination: '',
    warnings: {},
  },
};
